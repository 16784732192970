import { Injectable } from '@angular/core';
import { Store, select, ActionsSubject } from '@ngrx/store';

import { Observable } from 'rxjs';

import {
  ScenarioBinData,
  ScenarioBinStoreState,
} from './scenarioBin-store.model';

import { scenarioBinSelectors } from './scenarioBin-store.reducer';

import {
  ScenarioBinGetAction,
  ScenarioDeleteBinAction,
  ScenarioBinSaveAction,
  ScenarioDeleteAllBinAction,
  ScenarioBinActionTypes,
  ScenarioBinClearCacheAction,
} from './scenarioBin-store.actions';
import { filter, last } from 'rxjs/operators';

@Injectable()
export class ScenarioBinStore {
  state$: Observable<ScenarioBinStoreState> = this._store$.pipe(
    select(scenarioBinSelectors.state),
  );

  loading$: Observable<boolean> = this._store$.pipe(
    select(scenarioBinSelectors.loading),
  );
  data$: Observable<ScenarioBinData> = this._store$.pipe(
    select(scenarioBinSelectors.data),
  );
  cachedData$: Observable<Map<string, ScenarioBinData>> = this._store$.pipe(
    select(scenarioBinSelectors.cachedData),
  );

  isSaveSuccess$: Observable<any> = this._actionSubject$.pipe(
    filter(
      (action) =>
        action.type === ScenarioBinActionTypes.SAVE_SCENARIO_BIN_SUCCESS,
    ),
  );

  isSaveFailure$: Observable<any> = this._actionSubject$.pipe(
    filter(
      (action) =>
        action.type === ScenarioBinActionTypes.SAVE_SCENARIO_BIN_FAILURE,
    ),
  );

  constructor(
    private _store$: Store<ScenarioBinStoreState>,
    private _actionSubject$: ActionsSubject,
  ) {}

  get(
    projectId: string,
    caseId: string,
    scenarioId: string,
    location: string,
    localId: string,
    isCustom: boolean,
  ): Observable<ScenarioBinData> {
    this._store$.dispatch(
      new ScenarioBinGetAction(
        projectId,
        caseId,
        scenarioId,
        location,
        localId,
        isCustom,
      ),
    );
    return this.data$;
  }

  delete(
    projectId: string,
    caseId: string,
    scenarioId: string,
    location: string,
    localId: string,
  ): Observable<ScenarioBinData> {
    this._store$.dispatch(
      new ScenarioDeleteBinAction(
        projectId,
        caseId,
        scenarioId,
        location,
        localId,
      ),
    );
    return this.data$;
  }

  save(
    projectId: string,
    caseId: string,
    scenarioId: string,
    location: string,
    localId: string,
    data: ScenarioBinData,
  ) {
    this._store$.dispatch(
      new ScenarioBinSaveAction(
        projectId,
        caseId,
        scenarioId,
        location,
        localId,
        data,
      ),
    );
    return this._actionSubject$.pipe(
      filter(
        (action) =>
          action.type === ScenarioBinActionTypes.SAVE_SCENARIO_BIN_SUCCESS,
      ),
    );
  }

  deleteAll(
    projectId: string,
    caseId: string,
    scenarioId: string,
    data: any,
  ): Observable<any> {
    this._store$.dispatch(
      new ScenarioDeleteAllBinAction(projectId, caseId, scenarioId, data),
    );
    return this.data$;
  }

  clearCachedData(): Observable<any> {
    this._store$.dispatch(new ScenarioBinClearCacheAction());
    return this.data$;
  }
}
