import {
  contains,
  FormFieldOption,
  sortByField,
} from 'prosumer-libs/eyes-shared';
import {
  mapToNodesViewModel,
  mapToOverviewModel,
} from 'prosumer-shared/modules/system-visualization/mappers';
import {
  SystemVisualizationData,
  YearlySystemVisualizationData,
} from 'prosumer-shared/modules/system-visualization/models';

import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import {
  ResultActions,
  ResultActionTypes,
  resultFeatureName,
} from './result.actions';
import { ResultState } from './result.state';

/**
 * Initial state of simulation result store
 */
export const resultInitialState: ResultState = {
  latestScenarioVersion: '',
  error: undefined,
  loading: false,
  data: undefined,
  currentScenarioID: undefined,
  chartDataMapping: [],
  systemVisualization: undefined,
  errorSystemVisualization: undefined,
  isOutputSplit: false,
  optimized_years: [],
  all_years: [],
  isMultiNode: false,
  mainData: undefined,
  equipmentData: {
    DER_investments: undefined,
    all_years: [],
  },
  topologyData: {
    line_investments: undefined,
    all_years: [],
  },
  energyBalanceData: {
    energy_balance: undefined,
    all_years: [],
  },
  cashFlowData: {
    cumulative_cashflow: undefined,
    incremental_cashflow: undefined,
  },
  co2EmissionsData: {
    cumulative_emissions: undefined,
    incremental_emissions: undefined,
  },
  dispatchData: {
    dispatch: undefined,
  },
  flowsData: {
    sankey: undefined,
  },
};

/**
 * Reducer function that updates the state of the results store
 *
 * @param state = State of the simulation result store
 * @param action = Most recent dispatched action by the sim result store
 */

export function resultReducer(
  state: ResultState = resultInitialState,
  action: ResultActions,
): ResultState {
  switch (action.type) {
    case ResultActionTypes.GET:
      return {
        ...state,
        loading: true,
      };
    case ResultActionTypes.GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ResultActionTypes.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        currentScenarioID: action.payload.currentScenarioID,
      };
    case ResultActionTypes.GET_SYSTEM_VISUALIZATION:
      return {
        ...state,
        loading: true,
      };
    case ResultActionTypes.GET_SYSTEM_VISUALIZATION_FAILURE:
      return {
        ...state,
        loading: false,
        errorSystemVisualization: action.payload.errorSystemVisualization,
      };
    case ResultActionTypes.GET_SYSTEM_VISUALIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        systemVisualization: action.payload.systemVisualization,
        currentScenarioID: action.payload.currentScenarioID,
      };
    case ResultActionTypes.MIGRATE:
      return {
        ...state,
        loading: true,
      };
    case ResultActionTypes.MIGRATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ResultActionTypes.MIGRATE_SUCCESS:
      return {
        ...state,
        currentScenarioID: action.payload.currentScenarioID,
      };
    case ResultActionTypes.CLEAR:
      return {
        ...resultInitialState,
        latestScenarioVersion: state.latestScenarioVersion,
      };
    case ResultActionTypes.UPDATE_CHART_DATA: {
      const payload = action.payload;
      return {
        ...state,
        chartDataMapping: [
          ...state.chartDataMapping.filter(
            (chart) => chart.chartId !== payload.chartId,
          ),
          { chartId: payload.chartId, chartData: payload.chartData },
        ],
      };
    }
    case ResultActionTypes.GET_MAIN_DATA:
      return {
        ...state,
        loading: true,
        currentScenarioVariationID: action.payload.scenarioVariationId,
      };
    case ResultActionTypes.GET_MAIN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        currentScenarioID: action.payload.currentScenarioID,
      };
    case ResultActionTypes.GET_MAIN_DATA_SUCCESS_SPLIT:
      return {
        ...state,
        loading: false,
        mainData: action.payload.data.mainData,
        all_years: action.payload.data.allYears,
        optimized_years: action.payload.data.optimizedYears,
        isMultiNode: action.payload.data.isMultiNode,
        currentScenarioID: action.payload.currentScenarioID,
        isOutputSplit: action.payload.isOutputSplit,
      };
    case ResultActionTypes.GET_MAIN_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ResultActionTypes.GET_EQUIPMENT_DATA:
      return {
        ...state,
        loading: true,
      };
    case ResultActionTypes.GET_EQUIPMENT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        equipmentData: {
          ...state.equipmentData,
          ...action.payload.data,
        },
      };
    case ResultActionTypes.GET_EQUIPMENT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ResultActionTypes.GET_TOPOLOGY_DATA:
      return {
        ...state,
        loading: true,
      };
    case ResultActionTypes.GET_TOPOLOGY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        topologyData: action.payload.data,
      };
    case ResultActionTypes.GET_TOPOLOGY_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ResultActionTypes.GET_ENERGY_BALANCE_DATA:
      return {
        ...state,
        loading: true,
      };
    case ResultActionTypes.GET_ENERGY_BALANCE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        energyBalanceData: action.payload.data,
      };
    case ResultActionTypes.GET_ENERGY_BALANCE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ResultActionTypes.GET_CASH_FLOW_DATA:
      return {
        ...state,
        loading: true,
      };

    case ResultActionTypes.GET_CASH_FLOW_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        cashFlowData: action.payload.data,
      };
    case ResultActionTypes.GET_CASH_FLOW_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ResultActionTypes.GET_CO2_EMISSIONS_DATA:
      return {
        ...state,
        loading: true,
      };
    case ResultActionTypes.GET_CO2_EMISSIONS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        co2EmissionsData: action.payload.data,
      };
    case ResultActionTypes.GET_CO2_EMISSIONS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ResultActionTypes.GET_DISPATCH_DATA:
      return {
        ...state,
        loading: true,
      };
    case ResultActionTypes.GET_DISPATCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dispatchData: action.payload.data,
      };
    case ResultActionTypes.GET_DISPATCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ResultActionTypes.GET_FLOWS_DATA:
      return {
        ...state,
        loading: true,
      };
    case ResultActionTypes.GET_FLOWS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        flowsData: action.payload.data,
      };
    case ResultActionTypes.GET_FLOWS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ResultActionTypes.PUT_FLOWS_DATA:
    case ResultActionTypes.DELETE_FLOWS_DATA:
      return {
        ...state,
        loading: true,
      };
    case ResultActionTypes.PUT_FLOWS_DATA_SUCCESS:
    case ResultActionTypes.DELETE_FLOWS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ResultActionTypes.PUT_FLOWS_DATA_FAILURE:
    case ResultActionTypes.DELETE_FLOWS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ResultActionTypes.GET_LATEST_SCENARIO_VERSION_SUCCESS:
      return {
        ...state,
        latestScenarioVersion: action.payload.version,
      };
    default:
      return state;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const resultFeature: MemoizedSelector<any, ResultState> =
  createFeatureSelector<ResultState>(resultFeatureName);

// eslint-disable-next-line @typescript-eslint/ban-types
export const resultSelectors: { [key: string]: MemoizedSelector<any, any> } = {
  state: resultFeature,
  loading: createSelector(resultFeature, (state: ResultState) => state.loading),
  data: createSelector(resultFeature, (state: ResultState) => state.data),
  error: createSelector(resultFeature, (state: ResultState) => state.error),
  currentScenarioID: createSelector(
    resultFeature,
    (state: ResultState) => state.currentScenarioID,
  ),
  currentScenarioVariationID: createSelector(
    resultFeature,
    (state: ResultState) => state.currentScenarioVariationID,
  ),
  chartDataMapping: createSelector(
    resultFeature,
    (state: ResultState) => state.chartDataMapping,
  ),
  systemVisualization: createSelector(
    resultFeature,
    (state: ResultState) => state.systemVisualization,
  ),
  errorSystemVisualization: createSelector(
    resultFeature,
    (state: ResultState) => state.errorSystemVisualization,
  ),
  isMultiNode: createSelector(
    resultFeature,
    (state: ResultState) => state.isMultiNode,
  ),
  latestScenarioVersion: createSelector(
    resultFeature,
    (state: ResultState) => state.latestScenarioVersion,
  ),

  /**
   *  Selectors for each result category
   */
  // Map DER Investments from the Father JSON
  derInvestments: createSelector(resultFeature, (state: ResultState) => {
    const fatherClone = { ...state.data };
    const derInvestments = [];
    if (fatherClone && fatherClone['DER_investments']) {
      const derInvAttrib = fatherClone['DER_investments'];
      Object.keys(derInvAttrib).forEach((hehe) => {
        const extracted = { ...derInvAttrib[hehe] };
        const input = extracted['input_fluid'];
        const output = extracted['output_fluid'];
        extracted['energy_vector'] =
          input === output ? input : `${input}/${output}`;
        derInvestments.push(extracted);
      });
    }
    return derInvestments;
  }),
  // Map DER Opex and Capex
  derExpenses: createSelector(resultFeature, (state: ResultState) => {
    const clone = { ...state.data };
    const capex = [];
    if (clone && clone['Main_outputs'] && clone['Main_outputs']['capex']) {
      const loopIn = clone['Main_outputs']['capex'];
      Object.keys(loopIn).forEach((capexElement) => {
        capex.push({
          name: clone['Main_outputs']['capex'][capexElement]['name'],
          value: clone['Main_outputs']['capex'][capexElement]['capex'],
        });
      });
    }

    const opex = [];
    if (clone && clone['Main_outputs'] && clone['Main_outputs']['opex']) {
      const loopIn = clone['Main_outputs']['opex'];
      Object.keys(loopIn).forEach((capexElement) => {
        opex.push({
          name: clone['Main_outputs']['opex'][capexElement]['name'],
          value: clone['Main_outputs']['opex'][capexElement]['opex'],
        });
      });
    }

    return {
      capex,
      opex,
    };
  }),
  // Map Line Investments from the Father JSON
  lineInvestments: createSelector(resultFeature, (state: ResultState) => {
    const data = [];
    if (state.data) {
      const lineInvData = state.data['line_investments'];
      if (lineInvData) {
        Object.keys(lineInvData).forEach((key) => data.push(lineInvData[key]));
      }
      return data;
    }
    return undefined;
  }),
  // Map Energy Balance from the Father JSON
  energyBalance: createSelector(resultFeature, (state: ResultState) => {
    if (!state.data) {
      return;
    }
    const fatherClone = { ...state.data };
    const energyBalanceKey = 'energy_balance';
    const data = [];
    Object.keys(fatherClone[energyBalanceKey]).forEach((year) => {
      const yearData = fatherClone[energyBalanceKey][year];
      Object.keys(yearData).forEach((node) => {
        const nodeData = yearData[node];
        Object.keys(nodeData).forEach((energyVector) => {
          const energyVectorData = nodeData[energyVector];
          Object.keys(energyVectorData).map((type) => {
            const names = energyVectorData[type];
            Object.keys(names).map((name) => {
              data.push({
                year,
                node,
                energyVector,
                value: names[name],
                type,
                name,
              });
            });
          });
        });
      });
    });
    return data;
  }),
  costs: createSelector(resultFeature, (state: ResultState) => {
    if (!state.data && !state.mainData) {
      return;
    }
    const MAIN_OUTPUTS_PROPER_NAME = {
      total_cost: 'TOTAL DISCOUNTED COST',
      global_capex: 'TOTAL CAPEX (DISCOUNTED)',
      final_opex: 'FINAL YEAR OPEX',
      final_co2_emissions: 'FINAL YEAR CO2 EMISSIONS',
    };
    const fatherClone = state.isOutputSplit
      ? { ...state.mainData }
      : { ...state.data };
    const mainOutputs = fatherClone['Main_outputs'];
    const costs = mainOutputs ? mainOutputs['global'] : {};
    const returnData = [];
    // set default values for KPIs
    Object.entries(MAIN_OUTPUTS_PROPER_NAME).forEach(([key, value]) => {
      returnData.push({
        id: key,
        name: value.toUpperCase(),
        value: 0,
      });
    });

    Object.keys(costs).forEach((key) => {
      const normalizedKey = key.toLowerCase();
      const newCost = {
        id: normalizedKey,
        name:
          normalizedKey in MAIN_OUTPUTS_PROPER_NAME
            ? MAIN_OUTPUTS_PROPER_NAME[normalizedKey]
            : key.toUpperCase(),
        value: costs[key],
      };

      if (returnData.find((item) => item.id === normalizedKey)) {
        returnData[returnData.findIndex((item) => item.id === normalizedKey)] =
          newCost;
      } else {
        returnData.push(newCost);
      }
    });
    return returnData;
  }),
  // Map Cashflow from the Father JSON
  cashflow: createSelector(resultFeature, (state: ResultState) => {
    const returnData = [];
    Object.keys((state.data || {})['cashflow'] || {}).forEach((yearKey) => {
      const series = [];
      Object.keys(
        ((state.data || {})['cashflow'] || {})[yearKey] || {},
      ).forEach((yearProperty) => {
        series.push({
          name: yearProperty,
          value: (((state.data || {})['cashflow'] || {})[yearKey] || {})[
            yearProperty
          ],
        });
      });

      returnData.push({
        name: yearKey,
        series,
      });
    });
    return returnData;
  }),
  // Map Dispatch from data
  dispatch: createSelector(resultFeature, (state: ResultState) => {
    if (!state.data && !state.dispatchData?.dispatch) {
      return;
    }
    const dispatchData = state.isOutputSplit
      ? { ...state.dispatchData['dispatch'] }
      : { ...(state.data?.dispatch || {}) };
    const returnData = [];
    const keyNames = ['year', 'node', 'energyVector', 'type', 'der', 'values'];

    function flatten(data, object, depth: number) {
      if (data) {
        depth++;

        Object.keys(data).map((propName) => {
          const propValue = data[propName];
          if (
            Object.keys(propValue).length === 0 &&
            propValue.constructor === Object
          ) {
            return;
          }

          if (!Array.isArray(propValue)) {
            const obj = Object.assign(
              {},
              { ...object, [keyNames[depth - 1]]: propName },
            );
            flatten(propValue, obj, depth);
          } else {
            const finalObj = Object.assign(
              {},
              {
                ...object,
                [keyNames[depth - 1]]: propName,
                [keyNames[depth]]: propValue,
              },
            );
            returnData.push(finalObj);
          }
        });
      }
    }
    flatten(dispatchData, {}, 0);
    return returnData;
  }),

  sizingData: createSelector(resultFeature, (state: ResultState) => {
    if (!state.data) {
      return;
    }
    const fatherClone = { ...state.data };
    if (fatherClone['Main_outputs'] && fatherClone['Main_outputs']['sizes']) {
      const mainSizingData = fatherClone['Main_outputs']['sizes'];
      const sizingData = [];
      if (mainSizingData) {
        Object.keys(mainSizingData).forEach((sizeKey) => {
          if (mainSizingData[sizeKey]['size_kw'] > 0) {
            sizingData.push({
              name: mainSizingData[sizeKey]['name'],
              value: mainSizingData[sizeKey]['size_kw'],
            });
          }
        });
        sizingData.sort((a, b) => sortByField('value', a, b, true));
        return sizingData;
      }
    }
    return [];
  }),

  // Map Emissions from the Father JSON
  emissions: createSelector(resultFeature, (state: ResultState) => {
    const returnData = [];
    Object.keys((state.data || {})['Emissions'] || {}).forEach((scopeKey) => {
      const assetType = [];
      Object.keys(
        ((state.data || {})['Emissions'] || {})[scopeKey] || {},
      ).forEach((assetKey) => {
        const year = [];
        Object.keys(
          (((state.data || {})['Emissions'] || {})[scopeKey] || {})[assetKey] ||
            {},
        ).forEach((yearKey) => {
          year.push({
            name: yearKey,
            value: ((((state.data || {})['Emissions'] || {})[scopeKey] || {})[
              assetKey
            ] || {})[yearKey],
          });
        });
        /* Convert "converters" to "equipment" */
        const convertAssetName =
          assetKey === 'converters' ? 'equipment' : assetKey;
        assetType.push({
          name: convertAssetName,
          year,
        });
      });
      returnData.push({
        name: scopeKey,
        assetType,
      });
    });
    return returnData;
  }),

  systemVisualizationSelector: createSelector(
    resultFeature,
    (state: ResultState) => {
      const sysViz = { ...state.systemVisualization };
      if (!sysViz || !Object.keys(sysViz).length) {
        return;
      } else if (sysViz['nodes']) {
        return {
          overviewData: mapToOverviewModel(sysViz),
          nodeViewData: mapToNodesViewModel(sysViz),
        } as SystemVisualizationData;
      } else {
        const yearsList = Object.keys(sysViz);
        yearsList.forEach((year) => {
          sysViz[year] = {
            overviewData: mapToOverviewModel(sysViz[year]),
            nodeViewData: mapToNodesViewModel(sysViz[year]),
          };
        });
        sysViz['yearsList'] = yearsList;
        return sysViz as YearlySystemVisualizationData;
      }
    },
  ),

  isOutputSplit: createSelector(
    resultFeature,
    (state: ResultState) => state.isOutputSplit,
  ),
  optimizedYearsSelector: createSelector(
    resultFeature,
    (state: ResultState) => state.optimized_years,
  ),
  allYearsSelector: createSelector(
    resultFeature,
    (state: ResultState) => state.all_years,
  ),
  mainDataSelector: createSelector(
    resultFeature,
    (state: ResultState) => state.mainData,
  ),
  equipmentDataSelector: createSelector(
    resultFeature,
    (state: ResultState) => ({
      DER_investments: state.equipmentData.DER_investments,
      all_years: state.all_years,
    }),
  ),
  topologyDataSelector: createSelector(resultFeature, (state: ResultState) => ({
    line_investments: state.topologyData.line_investments,
    all_years: state.all_years,
  })),
  energyBalanceDataSelector: createSelector(
    resultFeature,
    (state: ResultState) => state.energyBalanceData,
  ),
  cashFlowDataSelector: createSelector(resultFeature, (state: ResultState) => ({
    all_years: state.all_years,
    cumulative_cashflow: state.cashFlowData.cumulative_cashflow,
    incremental_cashflow: state.cashFlowData.incremental_cashflow,
    optimized_years: state.optimized_years,
  })),
  co2EmissionsDataSelector: createSelector(
    resultFeature,
    (state: ResultState) => ({
      all_years: state.all_years,
      optimized_years: state.optimized_years,
      cumulative_emissions: state.co2EmissionsData.cumulative_emissions,
      incremental_emissions: state.co2EmissionsData.incremental_emissions,
    }),
  ),
  dispatchDataSelector: createSelector(
    resultFeature,
    (state: ResultState) => state.dispatchData.dispatch,
  ),
  flowsDataSelector: createSelector(
    resultFeature,
    (state: ResultState) => state.flowsData,
  ),
};

export const chartDataSelector = {
  chartDataMapping: createSelector(
    resultSelectors.chartDataMapping,
    (chartDataMapping, chartID: string) => {
      const cachedData = chartDataMapping.find(
        (cachedEntry) => chartID === cachedEntry.chartId,
      );
      return cachedData ? cachedData.chartData : undefined;
    },
  ),
};

export const lineInvestmentSelector = {
  getLineInvestmentFilterOptions: createSelector(
    resultSelectors.lineInvestments,
    (
      listData: Array<any>,
      parameters: { field?: 'origin_node' | 'destination_node' | 'fluid' },
    ) => {
      const filterOptions =
        !listData || !parameters.field
          ? []
          : listData.map((data) => data[parameters.field]);
      return [...new Set(filterOptions)].map(
        (option) =>
          ({
            name: option,
            value: option,
          }) as FormFieldOption<string>,
      );
    },
  ),

  getLineInvestmentByParams: createSelector(
    resultSelectors.lineInvestments,
    (
      listData: Array<any>,
      parameters: {
        originNodes?: Array<string>;
        destinationNodes?: Array<string>;
        fluids?: Array<string>;
      },
    ) => {
      const filteredData = !listData
        ? []
        : listData.filter(
            (data) =>
              (parameters.originNodes && parameters.originNodes.length > 0
                ? contains(parameters.originNodes, data.origin_node)
                : true) &&
              (parameters.destinationNodes &&
              parameters.destinationNodes.length > 0
                ? contains(parameters.destinationNodes, data.destination_node)
                : true) &&
              (parameters.fluids && parameters.fluids.length > 0
                ? contains(parameters.fluids, data.fluid)
                : true),
          );
      return filteredData;
    },
  ),
};

export const rawDispatchSelectors = {
  getRawDispatchByParams: createSelector(
    resultSelectors.dispatch,
    (
      listData: Array<any>,
      parameters: {
        year?: string;
        node?: string;
        energyVector?: string;
        type?: 'consumption' | 'production';
      },
    ) =>
      !listData
        ? []
        : listData.filter(
            (data) =>
              (parameters.year ? parameters.year === data.year : true) &&
              (parameters.node ? parameters.node === data.node : true) &&
              (parameters.energyVector
                ? parameters.energyVector === data.energyVector
                : true) &&
              (parameters.type ? parameters.type === data.type : true),
          ),
  ),
  getStorageOptions: createSelector(
    resultSelectors.dispatch,
    (
      listData: Array<any>,
      parameters: { year?: string; node?: string; energyVector?: string },
    ) => {
      const filteredData = !listData
        ? []
        : listData.filter(
            (data) =>
              (parameters.year ? parameters.year === data.year : true) &&
              (parameters.node ? parameters.node === data.node : true) &&
              (parameters.energyVector
                ? parameters.energyVector === data.energyVector
                : true) &&
              (data.type ? data.type === 'soc' : true),
          );
      const storages = [];
      if (filteredData && filteredData.length > 0) {
        filteredData.forEach((data) =>
          storages.push({ name: data.der, value: data.der }),
        );
      }
      return storages;
    },
  ),
  getStorageSoc: createSelector(
    resultSelectors.dispatch,
    (
      listData: Array<any>,
      parameters: {
        year?: string;
        node?: string;
        energyVector?: string;
        storage?: string;
      },
    ) => {
      const filteredData = !listData
        ? []
        : listData.filter(
            (data) =>
              (parameters.year ? parameters.year === data.year : true) &&
              (parameters.node ? parameters.node === data.node : true) &&
              (parameters.energyVector
                ? parameters.energyVector === data.energyVector
                : true) &&
              (data.type ? data.type === 'soc' : true),
          );
      if (filteredData && filteredData.length > 0) {
        return filteredData.filter(
          (filter) => filter.der === parameters.storage,
        )[0];
      }
    },
  ),
  getStorageCharge: createSelector(
    resultSelectors.dispatch,
    (
      listData: Array<any>,
      parameters: {
        year?: string;
        node?: string;
        energyVector?: string;
        storage?: string;
      },
    ) => {
      const filteredData = !listData
        ? []
        : listData.filter(
            (data) =>
              (parameters.year ? parameters.year === data.year : true) &&
              (parameters.node ? parameters.node === data.node : true) &&
              (parameters.energyVector
                ? parameters.energyVector === data.energyVector
                : true) &&
              (data.type ? data.type === 'storage_mvts' : true),
          );
      if (filteredData && filteredData.length > 0) {
        return filteredData.filter(
          (filter) => filter.der === parameters.storage,
        )[0];
      }
    },
  ),
  getStorageRawDispatchByParams: createSelector(
    resultSelectors.dispatch,
    (
      listData: Array<any>,
      parameters: {
        year?: string;
        node?: string;
        energyVector?: string;
        type?: 'soc' | 'storage_mvts';
        storage?: string;
      },
    ) => {
      if (parameters.storage && parameters.type === 'storage_mvts') {
        return !listData
          ? []
          : listData.filter(
              (data) =>
                (parameters.year ? parameters.year === data.year : true) &&
                (parameters.node ? parameters.node === data.node : true) &&
                (parameters.energyVector
                  ? parameters.energyVector === data.energyVector
                  : true) &&
                (parameters.type ? parameters.type === data.type : true) &&
                (parameters.storage
                  ? parameters.storage === data.storage
                  : true),
            );
      }
      return !listData
        ? []
        : listData.filter(
            (data) =>
              (parameters.year ? parameters.year === data.year : true) &&
              (parameters.node ? parameters.node === data.node : true) &&
              (parameters.energyVector
                ? parameters.energyVector === data.energyVector
                : true) &&
              (parameters.type ? parameters.type === data.type : true),
          );
    },
  ),
  getRawDispatchFilterOptions: createSelector(
    resultSelectors.dispatch,
    (
      listData: Array<any>,
      parameters: { field?: 'year' | 'node' | 'energyVector' },
    ) => {
      const filterOptions =
        !listData || !parameters.field
          ? []
          : listData.map((data) => data[parameters.field]);
      return [...new Set(filterOptions)].map(
        (option) =>
          ({
            name: option,
            value: option,
          }) as FormFieldOption<string>,
      );
    },
  ),
};

export const energyBalanceSelectors = {
  getEnergyBalanceByParams: createSelector(
    resultSelectors.energyBalance,
    (
      listData: Array<any>,
      parameters: {
        year?: Array<string>;
        node?: Array<string>;
        energyVector?: Array<string>;
        type?: 'consumption' | 'production';
      },
    ) => {
      const filteredData = !listData
        ? []
        : listData.filter(
            (data) =>
              (parameters.year.length > 0
                ? parameters.year.includes(data.year)
                : true) &&
              (parameters.node.length > 0
                ? parameters.node.includes(data.node)
                : true) &&
              (parameters.energyVector.length > 0
                ? parameters.energyVector.includes(data.energyVector)
                : true) &&
              (parameters.type ? parameters.type === data.type : true),
          );
      return !filteredData ? [] : filteredData;
    },
  ),
  getEnergyBalanceFilterOptions: createSelector(
    resultSelectors.energyBalance,
    (
      listData: Array<any>,
      parameters: { field?: 'year' | 'node' | 'energyVector' },
    ) => {
      const filterOptions =
        !listData || !parameters.field
          ? []
          : listData.map((data) => data[parameters.field]);
      return filterOptions
        ? [...new Set(filterOptions)].map(
            (option) =>
              ({
                name: option,
                value: option,
              }) as FormFieldOption<string>,
          )
        : [];
    },
  ),
};
