export enum FilledStatus {
  full = 'full',
  half = 'half',
  empty = 'empty',
}

export enum ScenarioWizardStep {
  general = 'general',
  timeHorizon = 'timeHorizon',
  topology = 'topology',
  loads = 'loads',
  equipment = 'equipment',
  commodities = 'commodities',
  mobility = 'mobility',
  frequencyControl = 'reserves',
  netting = 'netting',
  regulation = 'regulation',
  optimizationCockpit = 'optimizationCockpit',
  launch = 'launch',
}

export enum ScenarioWizardStepForDetail {
  line = ScenarioWizardStep.topology,
  load = ScenarioWizardStep.loads,
  equipment = ScenarioWizardStep.equipment,
  operationalCost = ScenarioWizardStep.commodities,
  energyGridConnection = ScenarioWizardStep.commodities,
  egcEmission = ScenarioWizardStep.commodities,
  marketLimits = ScenarioWizardStep.commodities,
  fuel = ScenarioWizardStep.commodities,
  netting = ScenarioWizardStep.netting,
  taxAndSubsidy = ScenarioWizardStep.netting,
  route = ScenarioWizardStep.mobility,
  vehiclesDispatch = ScenarioWizardStep.mobility,
  stationVehicleAssociation = ScenarioWizardStep.mobility,
  metering = ScenarioWizardStep.regulation,
  shortCircuitPowerNeeded = ScenarioWizardStep.frequencyControl,
  shortCircuitEquipment = ScenarioWizardStep.frequencyControl,
  reserveMargin = ScenarioWizardStep.frequencyControl,
  reserveActivation = ScenarioWizardStep.frequencyControl,
  reserveMarket = ScenarioWizardStep.frequencyControl,
  reserveEquipment = ScenarioWizardStep.frequencyControl,
  reserveNetwork = ScenarioWizardStep.frequencyControl,
}

export interface WizardStepCriteria {
  readonly removeMobility: boolean;
  readonly removeNetting: boolean;
  readonly removeFrequencyControl: boolean;
  readonly removeTopology: boolean;
}
