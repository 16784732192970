import {
  AuthStore,
  ConfigService,
  UserAPIService,
} from 'prosumer-app/libs/eyes-core';
import { UserInfo } from 'prosumer-app/libs/eyes-shared';
import { filter } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@oculus/auth/amplify';

@Injectable()
export class UserService extends UserAPIService {
  clientInfo: UserInfo;
  constructor(
    _http: HttpClient,
    _config: ConfigService,
    _authStore: AuthStore,
    _authService: AuthService,
  ) {
    super(_http, _config, _authStore);
    _authService.user$
      .pipe(filter((data) => !!data))
      .subscribe((authData) => (this.clientInfo = authData.attributes));
  }
}
