import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResourceCheckerInterceptor } from './resource-checker.interceptor';

@NgModule()
export class ResourceCheckerModule {
  static forRoot(): ModuleWithProviders<ResourceCheckerModule> {
    return {
      ngModule: ResourceCheckerModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ResourceCheckerInterceptor,
          multi: true,
        },
      ],
    };
  }
}
