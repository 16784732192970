<div class="message-dialog">
  <h2 mat-dialog-title class="p-0">
    {{ data?.dialogTitle }} {{ data?.data?.name }}
  </h2>
  <div
    class="mb-6"
    fxLayoutAlign="center"
    *ngIf="messageStore.loading$ | async"
  >
    <mat-spinner color="accent" mode="indeterminate"></mat-spinner>
  </div>
  <div class="mb-2.5" mat-dialog-content *ngIf="version$ | async as version">
    <div *ngIf="version === '0.0'">
      <ng-container
        *ngIf="(isImportMessage$ | async) !== true; else importMessages"
      >
        <div markdown *ngFor="let message of messages$ | async">
          {{ message }}
        </div>
      </ng-container>
    </div>
    <div *ngIf="version === '1.0'">
      <prosumer-messages-log-table
        [messages]="messages$ | async"
        [loading]="loading$ | async"
        [version]="version"
        [filtersData]="filtersDataForTranslatedV1"
        [tableMetaData]="tableMetaDataForTranslatedV1"
      ></prosumer-messages-log-table>
    </div>
    <div *ngIf="version === '1.1'">
      <prosumer-messages-log-table
        [loading]="loading$ | async"
        [messages]="messages$ | async"
        [version]="version"
      ></prosumer-messages-log-table>
    </div>
  </div>

  <div
    *ngIf="!!!(messageStore.loading$ | async)"
    mat-dialog-actions
    fxLayout="row"
    fxLayoutGap="10px"
    fxLayoutAlign="space-between center"
  >
    <button mat-flat-button color="" (click)="onClose()">
      {{ 'Generic.labels.close' | translate }}
    </button>
    <button
      *ngIf="isClientExpert"
      mat-flat-button
      type="button"
      [ngClass]="{
        'scenario-action-link-active': !logDisabled,
        'scenario-action-inactive': logDisabled
      }"
      color="primary"
      [disabled]="logDisabled"
      (click)="onDownloadLog()"
      data-testid="message-dialog-download-full-logs-file"
    >
      <mat-icon aria-hidden="false" aria-label="Download Full Log File"
        >cloud_download</mat-icon
      >
      {{ 'Case.labels.downloadFullLogsFile' | translate }}
    </button>
  </div>
</div>

<ng-template #importMessages>
  <mat-list>
    <ng-container
      *ngFor="let step of importMessagesMapByStep$ | async; let i = index"
    >
      <mat-divider *ngIf="i > 0"></mat-divider>
      <h3 mat-subheader class="prosumer-validation-header">
        {{ step?.stepLabel }}
      </h3>
      <mat-list-item *ngFor="let validation of step?.validations">
        <div [ngSwitch]="validation?.type" fxLayout="row">
          <div *ngSwitchCase="'error'">
            <mat-icon aria-hidden="false" ngClass="failure" matListIcon
              >{{ validation?.type }}
            </mat-icon>
          </div>
          <div *ngSwitchCase="'warning'">
            <mat-icon aria-hidden="false" ngClass="warning" matListIcon
              >{{ validation?.type }}
            </mat-icon>
          </div>
          <p class="prosumer-validation-message">
            <span *ngIf="validation.groupId">
              <b>{{ validation.groupId }}</b> :
            </span>
            {{ validation?.message }}
          </p>
        </div>
      </mat-list-item>
    </ng-container>
  </mat-list>
</ng-template>
