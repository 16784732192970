import { LibraryLoads } from 'prosumer-app/+scenario';
import { Observable } from 'rxjs';
import { filter, take, mergeMapTo } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import {
  LibraryClearAction,
  LibraryGetAction,
  LibraryGetLoadsAction,
} from './library-store.actions';
import { LibraryCache, LibraryState, LoadsCache } from './library-store.model';
import { librarySelectors } from './library-store.reducer';

@Injectable()
export class LibraryService {
  state$: Observable<LibraryState> = this._store$.pipe(
    select(librarySelectors.state),
  );

  loading$: Observable<boolean> = this._store$.pipe(
    select(librarySelectors.loading),
  );
  library$: Observable<LibraryCache> = this._store$.pipe(
    select(librarySelectors.library),
  );
  loads$: Observable<LoadsCache> = this._store$.pipe(
    select(librarySelectors.loads),
  );
  error$: Observable<any> = this._store$.pipe(select(librarySelectors.error));

  constructor(private _store$: Store<LibraryState>) {}

  getLibraryList$ = (
    type: string,
    energyVector?: string,
    outputEnergyVector?: string,
    secondOutputEnergyVector?: string,
  ) =>
    this._store$.pipe(
      select(librarySelectors.filterLibraryAsList, {
        type,
        energyVector,
        outputEnergyVector,
        secondOutputEnergyVector,
      }),
    );

  getLibraryListForConverters$ = (type: string, energyVectors?: Array<any>) =>
    this._store$.pipe(
      select(librarySelectors.filterLibraryListForConverters, {
        type,
        energyVectors,
      }),
    );

  findLibraryById$ = (id: string) =>
    this._store$.pipe(select(librarySelectors.findLibraryById, { id }));
  findLoadsById$ = (id: string) =>
    this._store$.pipe(select(librarySelectors.findLoadsById, { id }));

  getLibraryFilterOptions$ = (
    library?: Array<any>,
    field?:
      | 'vectorType'
      | 'businessType'
      | 'buildingType'
      | 'buildingCategory'
      | 'location',
  ) =>
    this._store$.pipe(
      select(librarySelectors.getLibraryFilterOptions, { library, field }),
    );

  filterLibraryByParams$ = (
    vectorType?: Array<string>,
    businessType?: Array<string>,
    buildingType?: Array<string>,
    buildingCategory?: Array<string>,
    location?: Array<string>,
    energyVector?: string,
  ) =>
    this._store$.pipe(
      select(librarySelectors.filterLibraryByParams, {
        vectorType,
        businessType,
        buildingType,
        buildingCategory,
        location,
        energyVector,
      }),
    );

  /**
   * Clears the library from the store
   */
  clear(): Observable<LibraryCache> {
    this._store$.dispatch(new LibraryClearAction());
    return this.library$;
  }

  /**
   * Gets the library from the backend or cache
   *
   * @param type the type of the library
   */
  get(type: string): Observable<LibraryCache> {
    this._store$.dispatch(new LibraryGetAction(type));
    return this.library$;
  }

  /**
   * Gets the loads from the backend or cache
   *
   * @param id  the library id
   */
  getLoads(id: string): Observable<LibraryLoads> {
    this._store$.dispatch(new LibraryGetLoadsAction(id));
    return this.loads$.pipe(
      filter((loads) => loads !== undefined),
      take(1),
      mergeMapTo(this.findLoadsById$(id)),
    );
  }
}
