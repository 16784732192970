import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, APP_INITIALIZER, NgModule } from '@angular/core';
import { akitaDevtools } from '@datorama/akita';
const DEVTOOLS_OPTIONS = new InjectionToken('DevtoolsOptions');
class AkitaDevtools {
  constructor(ngZone, options) {
    this.ngZone = ngZone;
    this.options = options;
    akitaDevtools(this.ngZone, this.options);
  }
}
AkitaDevtools.ɵfac = function AkitaDevtools_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AkitaDevtools)(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(DEVTOOLS_OPTIONS));
};
AkitaDevtools.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: AkitaDevtools,
  factory: AkitaDevtools.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AkitaDevtools, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [DEVTOOLS_OPTIONS]
      }]
    }];
  }, null);
})();
// auto initialize the devtools
function d() {}
function init(akitaDevtools) {
  return d;
}
class AkitaNgDevtools {
  static forRoot(options) {
    return {
      ngModule: AkitaNgDevtools,
      providers: [{
        provide: DEVTOOLS_OPTIONS,
        useValue: options
      }, {
        provide: APP_INITIALIZER,
        useFactory: init,
        deps: [AkitaDevtools],
        multi: true
      }]
    };
  }
}
AkitaNgDevtools.ɵfac = function AkitaNgDevtools_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AkitaNgDevtools)();
};
AkitaNgDevtools.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AkitaNgDevtools
});
AkitaNgDevtools.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AkitaNgDevtools, [{
    type: NgModule,
    args: [{}]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AkitaDevtools, AkitaNgDevtools, DEVTOOLS_OPTIONS, d, init };
