import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { DialogDivComponent } from './dialog-div.component';

@NgModule({
  declarations: [DialogDivComponent],
  imports: [CommonModule, FlexLayoutModule, MatButtonModule, TranslateModule],
  exports: [DialogDivComponent],
})
export class DialogDivModule {}
