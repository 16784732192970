import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Scenario } from '../models';
import { ScenarioFacadeService } from './scenario-facade.service';

@Injectable({ providedIn: 'root' })
export class ScenarioFacadeExtensions {
  private scenario: Scenario | undefined;

  constructor(private scenarios: ScenarioFacadeService) {
    this.subscribeToSelectedForStoring();
  }

  selectSiblingsOf(scenario: Scenario): Observable<Scenario[]> {
    return this.selectTruthyScenarios().pipe(
      map((scenarios) =>
        this.onlyMatchingProjectAndCaseIDs(
          scenarios,
          scenario.projectId,
          scenario.caseId,
        ),
      ),
    );
  }

  getSelected(): Scenario {
    return this.scenario;
  }

  private subscribeToSelectedForStoring(): void {
    this.scenarios.selectedData$.subscribe(
      (scenario) => (this.scenario = scenario),
    );
  }

  private onlyMatchingProjectAndCaseIDs(
    from: Scenario[],
    proj: string,
    cesa: string,
  ): Scenario[] {
    return from.filter((scenario) =>
      this.isMatchingProjAndCase(scenario, proj, cesa),
    );
  }

  private isMatchingProjAndCase(
    scenario: Scenario,
    proj: string,
    cesa: string,
  ): boolean {
    return [scenario.projectId === proj, scenario.caseId === cesa].every(
      Boolean,
    );
  }

  private selectTruthyScenarios(): Observable<Scenario[]> {
    return this.scenarios.dataList$.pipe(filter((scenarios) => !!scenarios));
  }
}
