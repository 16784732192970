/* eslint-disable @typescript-eslint/naming-convention */
import { ResultsData } from './base-results.model';

export enum EnergyBalanceDirection {
  CONSUMPTION = 'consumption',
  PRODUCTION = 'production',
}
export interface EnergyBalanceResult extends ResultsData {
  readonly node: string;
  readonly energyVector: string;
  readonly direction: EnergyBalanceDirection;
}
