import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { postTimePeriod, TimePeriodInfo } from './time-period.state';
import {
  ScenarioDetailInfo,
  ScenarioDetailStore,
  ScenarioDetailType,
} from '../scenario-detail';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.timePeriod, idKey: 'periodId' })
export class TimePeriodStore extends ScenarioDetailStore<TimePeriodInfo> {
  createTimePeriod(data: Record<string, unknown>): Observable<unknown> {
    const body: TimePeriodInfo = postTimePeriod(data);
    return this.handleAdd(this.post({ key: 'createDetail', data: {}, body }));
  }

  updateTimePeriod(data: Record<string, unknown>): Observable<unknown> {
    const body: TimePeriodInfo = postTimePeriod(data);
    return this.handleUpdate(
      this.patch({
        key: 'updateDetail',
        data: { periodId: data.periodId },
        body,
      }),
    );
  }

  deleteTimePeriod(periodId: string): Observable<unknown> {
    return this.handleDelete(
      this.delete({ key: 'deleteDetail', data: { periodId }, body: undefined }),
      periodId,
    );
  }

  getTimePeriods(data: Record<string, unknown>): Observable<unknown> {
    return this.handleAdd(
      this.get({ key: 'getDetailByDataType', data, body: undefined }).pipe(
        map((data: ScenarioDetailInfo<TimePeriodInfo>) => data.details),
      ),
    );
  }

  handleAdd($: Observable<unknown>): Observable<unknown> {
    return $.pipe(tap((data: TimePeriodInfo) => this.add(data)));
  }

  handleUpdate($: Observable<unknown>): Observable<unknown> {
    return $.pipe(
      tap((data: TimePeriodInfo) => this.update(data.periodId, data)),
    );
  }

  handleDelete(
    $: Observable<unknown>,
    timePeriodId: string,
  ): Observable<unknown> {
    return $.pipe(tap(() => this.remove(timePeriodId)));
  }

  protected getDataUuid(data: Record<string, unknown>): unknown {
    return data['periodId'];
  }
}
