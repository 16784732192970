/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  MessagesLogCombinedVesions,
  MessagesLogv1_0Translated,
  MessagesLogv1_1,
} from 'prosumer-app/shared/models/message-logs.model';

@Injectable({
  providedIn: 'root',
})
export class MessagesLogService {
  private messageSplitterForv1 = ':';

  constructor() {}

  translateV1Intov1_1(messages): MessagesLogv1_0Translated[] {
    return messages.map((message) => {
      if (message.type === 'text') {
        return this.mapTextTypeTov1_1(message.value);
      } else if (message.type === 'output') {
        return this.mapOutputTypeToText(message);
      } else if (message.type === 'error') {
        return this.mapErrorTypeToText(message);
      } else {
        return { Message: message.value };
      }
    });
  }

  private mapTextTypeTov1_1(value: string): MessagesLogv1_0Translated {
    const splitMessage = value.split(this.messageSplitterForv1);
    return {
      Level: splitMessage[0],
      Message: this.rejoinMessage(splitMessage),
    };
  }

  private rejoinMessage(splitMessage: string[]): string {
    return splitMessage.slice(1).join(this.messageSplitterForv1).trimLeft();
  }

  private mapOutputTypeToText(message: any): MessagesLogv1_0Translated {
    return {
      Name: message.name,
      Message: message.value,
    };
  }

  private mapErrorTypeToText(message: any): MessagesLogv1_0Translated {
    return {
      Level: 'ERROR',
      Message: message.value,
    };
  }

  mapMessagesToVersion(messages, version): MessagesLogCombinedVesions[] {
    if (version === '1.1') {
      return this.parseMessages(messages);
    } else if (version === '1.0') {
      return this.translateV1Intov1_1(messages);
    } else if (Array.isArray(messages)) {
      return messages;
    } else {
      return [];
    }
  }

  private parseMessages(messages: any): Array<MessagesLogv1_1> {
    if (!messages) {
      return [];
    }
    if (Array.isArray(messages)) {
      return this.turnIDValuestoStrings(messages);
    }
    messages = JSON.parse(messages);
    return this.turnIDValuestoStrings(Object.values(messages));
  }

  private turnIDValuestoStrings(messages): MessagesLogv1_1[] {
    return messages.map((message) => ({
      ...message,
      ID: message.ID.toString(),
    }));
  }

  applyFilters(
    allMessages: Array<MessagesLogCombinedVesions>,
    filtersSelected: Record<string, string[]>,
  ) {
    return allMessages.filter((message: MessagesLogCombinedVesions) =>
      this.checkMessageToPassFilters(message, filtersSelected),
    );
  }

  private checkMessageToPassFilters(
    message: MessagesLogCombinedVesions,
    filtersSelected: Record<string, string[]>,
  ): boolean {
    const boolArrayOfPassedFilters = Object.entries(filtersSelected).map(
      ([key, value]) =>
        (value as any).length ? (value as any).includes(message[key]) : true,
    );
    const allPassed = boolArrayOfPassedFilters.every((value) => value === true);
    return allPassed;
  }
}
