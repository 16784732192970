import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  BaseDialogComponent,
  toTitleCase,
} from 'prosumer-app/libs/eyes-shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CascadeConfirmationDialog {
  constructor(
    private readonly dialogService: DialogService,
    private readonly translator: TranslateService,
  ) {}

  open(deletedVariations: string[], affectedMsg = ''): Observable<unknown> {
    return this.dialogService.openDialog(BaseDialogComponent, {
      message: this.generateConfirmationMsg(deletedVariations, affectedMsg),
      title: this.translator.instant(
        'Scenario.dialog.selectionDeleted.scenarioVariation.title',
      ),
      confirm: this.translator.instant('Generic.labels.yes'),
      close: this.translator.instant('Generic.labels.no'),
      width: 400,
    });
  }

  private generateConfirmationMsg(deleted: string[], affected: string): string {
    return this.generateMessage(
      this.translator.instant(
        'Scenario.dialog.selectionDeleted.scenarioVariation.message',
      ),
      deleted,
      affected,
    );
  }

  private generateMessage(
    base: string,
    deletedNames: string[],
    affectedMessage: string,
  ) {
    let deletedStr = '';
    deletedNames.forEach(
      (name) => (deletedStr += `<li>${toTitleCase(name)}</li>`),
    );

    return `${base}<div class="list-wrapper"><b>To Delete</b><ul>${deletedStr}</ul></div>${affectedMessage}`;
  }
}
