/* eslint-disable @typescript-eslint/naming-convention */
import { ResultsData } from './base-results.model';

export enum ScopeType {
  SCOPE1 = 'scope1',
  SCOPE2 = 'scope2',
  SCOPE3 = 'scope3',
}

export interface EmissionsData extends ResultsData {
  readonly type: string;
  readonly node: string;
  readonly scope: ScopeType;
}
