/* eslint-disable @typescript-eslint/naming-convention */
import { ResultsData } from './base-results.model';

export interface EquipmentResult extends ResultsData {
  readonly node: string;

  readonly invest_cost: number;
  readonly oper_cost: number;
  readonly size_kw: number;
  readonly size_kwh: number;
  readonly total_cost: number;
  readonly yearly_cons: number;
  readonly yearly_prod: number;

  readonly input_fluid: string;
  readonly output_fluid: string;
  readonly type: string;
}

export const EQUIPMENT_RESULT_NUMERICAL_PROPERTIES = [
  'invest_cost',
  'oper_cost',
  'size_kw',
  'size_kwh',
  'total_cost',
  'yearly_cons',
  'yearly_prod',
];

export const EQUIPMENT_RESULT_STRING_PROPERTIES = [
  'input_fluid',
  'node',
  'name',
  'type',
  'output_fluid',
];
