import {
  PowerNeeded,
  PowerNeededIncoming,
  PowerNeededOutgoing,
} from 'prosumer-app/stores/power-needed/power-needed.state';
import { ReserveNetwork } from 'prosumer-app/stores/reserve-network/reserve-network.state';
import {
  ShortCircuitEquipment,
  ShortCircuitEquipmentIncoming,
  ShortCircuitEquipmentOutgoing,
} from 'prosumer-app/stores/short-circuit-equipment';
import {
  SpinningReserveMargin,
  SpinningReserveMarginIncoming,
  SpinningReserveMarginOutgoing,
} from 'prosumer-app/stores/spinning-reserve-margin';
import { Coerce } from 'prosumer-core/utils';
import {
  CommonReserve,
  EquipmentReserve,
  FrequencyControlForm,
  MarginReserve,
  MarketReserve,
  NetworkReserve,
  ProfileBE,
  ProfileGroupBE,
  SpinningReserve,
} from 'prosumer-scenario/models';
import {
  mapYearlyProfileToBackend,
  mapYearlyProfileToFrontend,
  mapYearlyValuesToBackend,
  mapYearlyValuesToFrontend,
} from 'prosumer-shared/utils';

export class FrequencyControlMapper {
  static mapToBackend = (frequencyControlForm: FrequencyControlForm) =>
    !!frequencyControlForm
      ? {
          shortCircuit: {
            powers: FrequencyControlMapper.powersNeededToBE(
              Coerce.toObject(frequencyControlForm.shortCircuit).energyVectors,
            ),
            equipments: (
              (frequencyControlForm.shortCircuit || {}).equipments || []
            ).map((eci) => ({
              equipment: eci.equipmentId,
              factor: mapYearlyValuesToBackend(eci.shortCircuitFactor),
              threshold: mapYearlyValuesToBackend(eci.shortCircuitThreshold),
            })),
          },
          spinningReserves: {
            ...FrequencyControlMapper.mapReserveToBackend(
              frequencyControlForm.spinningReserve,
            ),
          },
        }
      : {};

  static mapToFrontend = (
    scenario: {
      shortCircuit: any;
      spinningReserves: any;
    },
    startYear?: number,
    endYear?: number,
  ): FrequencyControlForm => ({
    shortCircuit: {
      energyVectors: FrequencyControlMapper.powersNeededToFE(
        Coerce.toObject(scenario.shortCircuit).powers,
      ),
      equipments: (
        (scenario.shortCircuit && scenario.shortCircuit.equipments) ||
        []
      ).map((e) => ({
        equipmentId: e.equipment,
        shortCircuitFactor: mapYearlyValuesToFrontend(
          e.factor,
          startYear,
          endYear,
        ),
        shortCircuitThreshold: mapYearlyValuesToFrontend(
          e.threshold,
          startYear,
          endYear,
        ),
      })),
    },
    spinningReserve: {
      ...FrequencyControlMapper.mapReserveToFrontend(scenario.spinningReserves),
      binToDelete: [],
    },
  });

  static powerNeededToBE(from: PowerNeeded): PowerNeededOutgoing {
    return {
      powerNeeded: mapYearlyValuesToBackend(from.neededPower),
      energyVectorId: from.energyVectorId,
    };
  }

  static powerNeededToFE(be: PowerNeededIncoming): PowerNeeded {
    return {
      ...be,
      neededPower: mapYearlyValuesToFrontend(JSON.parse(be.powerNeeded)),
    };
  }

  static scEquipmentToBE(
    from: ShortCircuitEquipment,
  ): ShortCircuitEquipmentOutgoing {
    return {
      ...from,
      factor: mapYearlyValuesToBackend(from.shortCircuitFactor),
      threshold: mapYearlyValuesToBackend(from.shortCircuitThreshold),
    };
  }

  static scEquipmentToFE(
    from: ShortCircuitEquipmentIncoming,
  ): ShortCircuitEquipment {
    return {
      ...from,
      shortCircuitFactor: mapYearlyValuesToFrontend(JSON.parse(from.factor)),
      shortCircuitThreshold: mapYearlyValuesToFrontend(
        JSON.parse(from.threshold),
      ),
    };
  }

  static srMarginToBE(
    from: SpinningReserveMargin,
  ): SpinningReserveMarginOutgoing {
    return {
      ...(from as SpinningReserveMarginOutgoing),
      margin: this.mapMarginProfiles(from.profiles),
    };
  }

  static srMarginToFE(
    from: SpinningReserveMarginIncoming,
  ): SpinningReserveMargin {
    return {
      ...(from as SpinningReserveMargin),
      profiles: FrequencyControlMapper.extractProfiles(from.margin).map(
        (profile) => mapYearlyProfileToFrontend(profile, 'RESERVE_MARGIN'),
      ),
    };
  }

  static srNetworkToBe(from): ReserveNetwork {
    return {
      ...from,
      requirement: String(from.requirement),
    };
  }

  private static powersNeededToFE(bes: PowerNeededIncoming[]): PowerNeeded[] {
    return Coerce.toArray(bes).map((be) =>
      FrequencyControlMapper.powerNeededToFE(be),
    );
  }

  private static powersNeededToBE(from: PowerNeeded[]): PowerNeededOutgoing[] {
    return Coerce.toArray(from).map((p) =>
      FrequencyControlMapper.powerNeededToBE(p),
    );
  }

  private static mapMarginProfiles(profiles: any) {
    const margin = { name: 'loads', profiles: [] };
    margin.profiles = profiles.map((profile) =>
      mapYearlyProfileToBackend(profile),
    );
    return margin;
  }
  private static mapReserveToBackend = (reserve: SpinningReserve) => {
    if (!!!reserve) {
      return {};
    }

    return {
      margins: (reserve.margins || []).map((margin) => ({
        energyVectorId: margin.energyVectorId,
        direction: margin.direction,
        // binary data props
        margin: FrequencyControlMapper.mapMarginProfiles(margin.profiles),
      })),
      activations: Coerce.toArray(reserve.activations),
      equipments: (reserve.equipments || []).map((equipment) => ({
        equipmentId: equipment.equipmentId,
        energyVectorId: equipment.energyVectorId,
        ...FrequencyControlMapper.mapCommonReserve(equipment),
      })),
      markets: (reserve.markets || []).map((market) => ({
        energyGridConnectionId: market.energyGridConnectionId,
        energyVectorId: market.energyVectorId,
        ...FrequencyControlMapper.mapCommonReserve(market),
      })),
      networks: (reserve.networks || []).map((network) => ({
        ...FrequencyControlMapper.mapCommonReserve(network),
        energyVectorId: network.energyVectorId,
      })),
    };
  };

  private static mapReserveToFrontend = (reserve: any) =>
    !!reserve
      ? ({
          activations: Coerce.toArray(reserve.activations),
          equipments: (reserve.equipments || []).map(
            (equipment) =>
              ({
                equipmentId: equipment.equipmentId,
                energyVectorId: equipment.energyVectorId,
                ...FrequencyControlMapper.mapCommonReserve(equipment),
                nature: 'equipment',
              }) as EquipmentReserve,
          ),
          margins: (reserve.margins || []).map(
            (margin) =>
              ({
                energyVectorId: margin.energyVectorId,
                direction: margin.direction,
                nature: 'margin',
                // Binary Data Fields
                profiles: margin.margin.profiles.map((profile) =>
                  mapYearlyProfileToFrontend(profile, 'RESERVE_MARGIN'),
                ) as any,
              }) as MarginReserve,
          ),
          markets: (reserve.markets || []).map(
            (market) =>
              ({
                energyVectorId: market.energyVectorId,
                energyGridConnectionId: market.energyGridConnectionId,
                ...FrequencyControlMapper.mapCommonReserve(market),
                nature: 'market',
              }) as MarketReserve,
          ),
          networks: (reserve.networks || []).map(
            (network) =>
              ({
                ...FrequencyControlMapper.mapCommonReserve(network),
                energyVectorId: network.energyVectorId,
                nature: 'network',
              }) as NetworkReserve,
          ),
        } as SpinningReserve)
      : {};

  private static mapCommonReserve = (common: CommonReserve) => ({
    direction: common.direction,
    participation: common.participation,
    requirement: String(common.requirement),
  });

  private static extractProfiles(profile: ProfileGroupBE): ProfileBE[] {
    return Coerce.toArray(Coerce.toObject(profile).profiles);
  }
}
