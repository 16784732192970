import { Injectable } from '@angular/core';
import { ScenarioDetailType } from 'prosumer-app/stores/scenario-detail';
import {
  ScenarioVariationInfo,
  ScenarioVariationStore,
} from 'prosumer-app/stores/scenario-variation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VariationInfoService {
  private readonly dataType: string = ScenarioDetailType.variation;
  constructor(private readonly store: ScenarioVariationStore) {}

  getVariations(): Observable<unknown> {
    return this.store.getVariations({});
  }

  addVariation(name: string): Observable<unknown> {
    return this.store.createVariation(name).pipe(
      map((response: any) => ({
        name: response.name,
        value: response.variationUuid,
      })),
    );
  }

  deleteVariation(data: ScenarioVariationInfo): Observable<unknown> {
    return this.store.deleteVariation(data);
  }

  updateVariation(id: string, data: Partial<ScenarioVariationInfo>) {
    return this.store.edit(id, data as ScenarioVariationInfo);
  }
}
