import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MessageStore } from './scenario-message-store.service';
import { messageFeatureName } from './scenario-message.actions';
import { MessageEffects } from './scenario-message.effects';
import { messageReducer } from './scenario-message.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(messageFeatureName, messageReducer),
    EffectsModule.forFeature([MessageEffects]),
  ],
})
export class MessageStoreModule {
  static forRoot(): ModuleWithProviders<MessageStoreModule> {
    return {
      ngModule: MessageStoreModule,
      providers: [MessageStore],
    };
  }
}
