import { Utils } from 'prosumer-core/utils';

import {
  EQUIPMENT_RESULT_NUMERICAL_PROPERTIES,
  EQUIPMENT_RESULT_STRING_PROPERTIES,
  EquipmentResult,
  ValueType,
} from '../models';
import { BaseExtractor } from './base.extractor';

export class EquipmentExtractor implements BaseExtractor<EquipmentResult> {
  extract(resultsJson: unknown): EquipmentResult[] {
    const years = Utils.resolveToEmptyArray(
      resultsJson['all_years'],
    ) as number[];
    return this.buildEquipmentList(resultsJson['DER_investments'], years);
  }

  private buildEquipmentList(
    data: unknown = {},
    allYears: number[],
  ): EquipmentResult[] {
    return Object.values(data).reduce(
      (acc, curr) => [
        ...acc,
        ...this.buildIncrementalData(curr, allYears),
        ...this.buildCumulativeData(curr, allYears),
      ],
      [],
    );
  }

  private buildIncrementalData(
    singleInvestment: unknown,
    allYears: number[],
  ): EquipmentResult[] {
    return allYears.map((year) =>
      this.buildEquipmentData(singleInvestment, year, ValueType.INCREMENTAL),
    );
  }

  private buildCumulativeData(
    singleInvestment: unknown,
    allYears: number[],
  ): EquipmentResult[] {
    return allYears.map((year) =>
      this.buildEquipmentData(singleInvestment, year, ValueType.CUMULATIVE),
    );
  }

  private buildEquipmentData(
    single: unknown,
    year: number,
    valueType: ValueType,
  ): EquipmentResult {
    return {
      year,
      valueType,
      ...this.buildNumericalProperties(single, year, valueType),
      ...this.buildStringProperties(single),
    } as EquipmentResult;
  }

  private buildNumericalProperties(
    single: unknown,
    year: number,
    valueType: ValueType,
  ): Partial<EquipmentResult> {
    return EQUIPMENT_RESULT_NUMERICAL_PROPERTIES.reduce((acc, curr) => {
      const concatenatedNumericalKey = `${valueType}_${curr}`;
      acc[curr] = Utils.resolveToEmptyObject(single[concatenatedNumericalKey])[
        String(year)
      ];
      return acc;
    }, {});
  }

  private buildStringProperties(single: unknown): Partial<EquipmentResult> {
    return EQUIPMENT_RESULT_STRING_PROPERTIES.reduce((acc, curr) => {
      acc[curr] = single[curr];
      return acc;
    }, {});
  }
}
