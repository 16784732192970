import { DetailEntity } from '../scenario-detail';

export interface TimePeriodInfo extends DetailEntity {
  readonly scenarioUuid: string;
  readonly periodId: string;
  readonly year: number;
  readonly startDate: string;
  readonly endDate: string;
  readonly weight: string;
  readonly blockLength: number;
}

export const postTimePeriod = (
  params: Record<string, unknown>,
): TimePeriodInfo => ({
  scenarioUuid: undefined,
  periodId: params['periodId'] as string,
  year: params['year'] as number,
  startDate: params['startDate'] as string,
  endDate: params['endDate'] as string,
  weight: params['weight'] as string,
  blockLength: params['blockLength'] as number,
});
