import {
  ConfigService,
  HttpService,
  LoggerService,
} from 'prosumer-app/libs/eyes-core';
import { generateEndpoint } from 'prosumer-app/libs/eyes-shared';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

/* eslint-disable @typescript-eslint/naming-convention */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ResultsDownloadResponse } from '@prosumer/results/models';

import {
  ResultActions,
  ResultActionTypes,
  ResultGetAction,
  ResultGetFailureAction,
  ResultGetSuccessAction,
  ResultGetSystemVisualizationAction,
  ResultGetSystemVisualizationFailureAction,
  ResultGetSystemVisualizationSuccessAction,
  ResultMigrateAction,
  ResultMigrateFailureAction,
  ResultMigrateSuccessAction,
  ResultsDeleteFlowsAction,
  ResultsDeleteFlowsFailureAction,
  ResultsDeleteFlowsSuccessAction,
  ResultsGetCashFlowAction,
  ResultsGetCashFlowFailureAction,
  ResultsGetCashFlowSuccessAction,
  ResultsGetCO2EmissionsAction,
  ResultsGetCO2EmissionsFailureAction,
  ResultsGetCO2EmissionsSuccessAction,
  ResultsGetDispatchAction,
  ResultsGetDispatchFailureAction,
  ResultsGetDispatchSuccessAction,
  ResultsGetEnergyBalanceAction,
  ResultsGetEnergyBalanceFailureAction,
  ResultsGetEnergyBalanceSuccessAction,
  ResultsGetEquipmentAction,
  ResultsGetEquipmentFailureAction,
  ResultsGetEquipmentSuccessAction,
  ResultsGetFlowsAction,
  ResultsGetFlowsFailureAction,
  ResultsGetFlowsSuccessAction,
  ResultsGetLatestScenarioVersionFailureAction,
  ResultsGetLatestScenarioVersionSuccessAction,
  ResultsGetMainAction,
  ResultsGetMainFailureAction,
  ResultsGetMainSuccessAction,
  ResultsGetMainSuccessSplitAction,
  ResultsGetTopologyAction,
  ResultsGetTopologyFailureAction,
  ResultsGetTopologySuccessAction,
  ResultsPutFlowsAction,
  ResultsPutFlowsFailureAction,
  ResultsPutFlowsSuccessAction,
} from './result.actions';

@Injectable()
export class ResultEffects {
  getTabResults$({
    endPoint,
    payloads: { projectId, caseId, scenarioId },
    mapFn,
    catchErrorFn,
  }: {
    endPoint: string;
    payloads: {
      projectId;
      caseId;
      scenarioId;
      scenarioVariationId?;
    };
    mapFn: (response) => ResultActions;
    catchErrorFn: (error: HttpErrorResponse) => Observable<any>;
  }) {
    return this._http
      .get(
        generateEndpoint(
          this._config.api.baseUrl,
          endPoint,
          projectId,
          caseId,
          scenarioId,
        ),
      )
      .pipe(
        switchMap((resultsResponse: ResultsDownloadResponse) => {
          const { url, isOutputSplit } = resultsResponse;
          return this._http.get(url, { responseType: 'blob' }).pipe(
            map((response) => ({
              jsonResult: response,
              isOutputSplit,
            })),
            map(mapFn),
            catchError(catchErrorFn),
          );
        }),
        catchError(catchErrorFn),
      );
  }

  addVariationQueryParam = (variationId?: string) =>
    variationId ? `&variationUuid=${variationId}` : '';

  /** @deprecated No longer used after tab splits  */

  get$: Observable<ResultActions> = createEffect(() =>
    this._action$.pipe(
      ofType(ResultActionTypes.GET),
      switchMap((action: ResultGetAction) => {
        const projId = action.payload.projectId;
        const caseId = action.payload.caseId;
        const scenarioId = action.payload.scenarioId;

        return this._http
          .get(
            generateEndpoint(
              this._config.api.baseUrl,
              this._config.api.endpoints.scenario.getResultSigned,
              projId,
              caseId,
              scenarioId,
            ),
          )
          .pipe(
            switchMap((downloadSignedUrl: any) =>
              this._http
                .get(downloadSignedUrl.url, { responseType: 'blob' })
                .pipe(
                  map(
                    (response) =>
                      new ResultGetSuccessAction(
                        scenarioId,
                        response,
                        this._translate.instant(
                          'Result.messages.getResultsSuccess',
                        ),
                      ),
                  ),
                  catchError((errResponse: HttpErrorResponse) =>
                    of(
                      new ResultGetFailureAction(
                        (errResponse &&
                          errResponse.error &&
                          errResponse.error.error) ||
                          this._translate.instant(
                            'Result.messages.getResultsFailure',
                          ),
                      ),
                    ),
                  ),
                ),
            ),
            catchError((errResponse: HttpErrorResponse) =>
              of(
                new ResultGetFailureAction(
                  (errResponse &&
                    errResponse.error &&
                    errResponse.error.error) ||
                    this._translate.instant(
                      'Result.messages.getResultsFailure',
                    ),
                ),
              ),
            ),
          );
      }),
    ),
  );

  getFailure$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.GET_FAILURE),
        tap((action: ResultGetFailureAction) =>
          this._notification.showError(action.payload.error),
        ),
      ),
    { dispatch: false },
  );

  getSuccess$: Observable<ResultActions> = createEffect(
    () => this._action$.pipe(ofType(ResultActionTypes.GET_SUCCESS)),
    { dispatch: false },
  );

  getSystemVisualization$: Observable<ResultActions> = createEffect(() =>
    this._action$.pipe(
      ofType(ResultActionTypes.GET_SYSTEM_VISUALIZATION),
      switchMap((action: ResultGetSystemVisualizationAction) => {
        const projId = action.payload.projectId;
        const caseId = action.payload.caseId;
        const scenarioId = action.payload.scenarioId;

        return this._http
          .get(
            generateEndpoint(
              this._config.api.baseUrl,
              `${
                this._config.api.endpoints.scenario.getResultSigned
              }?tab=system&action=download${this.addVariationQueryParam(
                action.payload.scenarioVariationId,
              )}`,
              projId,
              caseId,
              scenarioId,
            ),
          )
          .pipe(
            switchMap((downloadSignedUrl: any) =>
              this._http
                .get(downloadSignedUrl.url, { responseType: 'blob' })
                .pipe(
                  map(
                    (response) =>
                      new ResultGetSystemVisualizationSuccessAction(
                        scenarioId,
                        response,
                        this._translate.instant(
                          'Result.messages.getSystemVisualizationSuccess',
                        ),
                      ),
                  ),
                  catchError((errResponse: HttpErrorResponse) =>
                    of(
                      new ResultGetSystemVisualizationFailureAction(
                        (errResponse &&
                          errResponse.error &&
                          errResponse.error.error) ||
                          this._translate.instant(
                            'Result.messages.getSystemVisualizationFailure',
                          ),
                      ),
                    ),
                  ),
                ),
            ),
            catchError((errResponse: HttpErrorResponse) =>
              of(
                new ResultGetSystemVisualizationFailureAction(
                  (errResponse &&
                    errResponse.error &&
                    errResponse.error.error) ||
                    this._translate.instant(
                      'Result.messages.getSystemVisualizationFailure',
                    ),
                ),
              ),
            ),
          );
      }),
    ),
  );

  getSystemVisualizationFailure$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.GET_SYSTEM_VISUALIZATION_FAILURE),
        tap((action: ResultGetSystemVisualizationFailureAction) =>
          this._logger.error(
            'System Visualization Error: ',
            action.payload.errorSystemVisualization,
          ),
        ),
      ),
    { dispatch: false },
  );

  getSystemVisualizationSuccess$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.GET_SYSTEM_VISUALIZATION_SUCCESS),
      ),
    { dispatch: false },
  );

  migrate$: Observable<ResultActions> = createEffect(() =>
    this._action$.pipe(
      ofType(ResultActionTypes.MIGRATE),
      switchMap((action: ResultMigrateAction) => {
        const projId = action.payload.projectId;
        const caseId = action.payload.caseId;
        const scenarioId = action.payload.scenarioId;

        return this._http
          .post(
            generateEndpoint(
              this._config.api.baseUrl,
              this._config.api.endpoints.scenario.migrate,
              projId,
              caseId,
              scenarioId,
            ),
          )
          .pipe(
            map(
              (response) =>
                new ResultMigrateSuccessAction(
                  scenarioId,
                  response,
                  this._translate.instant('Result.messages.migrateSuccess'),
                ),
            ),
            catchError((errResponse: HttpErrorResponse) =>
              of(
                new ResultMigrateFailureAction(
                  (errResponse &&
                    errResponse.error &&
                    errResponse.error.error) ||
                    this._translate.instant('Result.messages.migrateFailure'),
                ),
              ),
            ),
          );
      }),
    ),
  );

  migrateSuccess$: Observable<ResultActions> = createEffect(
    () => this._action$.pipe(ofType(ResultActionTypes.MIGRATE_SUCCESS)),
    { dispatch: false },
  );

  migrateFailure$: Observable<ResultActions> = createEffect(
    () => this._action$.pipe(ofType(ResultActionTypes.MIGRATE_FAILURE)),
    { dispatch: false },
  );

  getMain$: Observable<ResultActions> = createEffect(() =>
    this._action$.pipe(
      ofType(ResultActionTypes.GET_MAIN_DATA),
      switchMap((action: ResultsGetMainAction) =>
        this.getTabResults$({
          endPoint: `${
            this._config.api.endpoints.scenario.getResultSigned
          }?tab=main&action=download${this.addVariationQueryParam(
            action.payload.scenarioVariationId,
          )}`,
          payloads: {
            ...action.payload,
          },
          mapFn: ({ jsonResult, isOutputSplit }) => {
            if (isOutputSplit) {
              const {
                Main_outputs,
                all_years,
                optimized_years,
                is_multi_node,
              } = jsonResult;
              return new ResultsGetMainSuccessSplitAction(
                action.payload.scenarioId,
                {
                  mainData: {
                    Main_outputs,
                  },
                  allYears: all_years,
                  optimizedYears: optimized_years,
                  isMultiNode: is_multi_node,
                },
                isOutputSplit,
                this._translate.instant(
                  'Result.messages.getResultsMainSuccess',
                ),
              );
            }
            return new ResultsGetMainSuccessAction(
              action.payload.scenarioId,
              jsonResult,
              this._translate.instant('Result.messages.getResultsMainSuccess'),
            );
          },
          catchErrorFn: (errResponse: HttpErrorResponse) =>
            of(
              new ResultsGetMainFailureAction(
                (errResponse && errResponse.error && errResponse.error.error) ||
                  this._translate.instant(
                    'Result.messages.getResultsMainFailure',
                  ),
              ),
            ),
        }),
      ),
    ),
  );

  getMainFailure$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.GET_MAIN_DATA_FAILURE),
        tap((action: ResultsGetMainFailureAction) =>
          this._notification.showError(action.payload.error),
        ),
      ),
    { dispatch: false },
  );

  getMainSuccess$: Observable<ResultActions> = createEffect(
    () => this._action$.pipe(ofType(ResultActionTypes.GET_MAIN_DATA_SUCCESS)),
    { dispatch: false },
  );

  getEquipment$: Observable<ResultActions> = createEffect(() =>
    this._action$.pipe(
      ofType(ResultActionTypes.GET_EQUIPMENT_DATA),
      switchMap((action: ResultsGetEquipmentAction) =>
        this.getTabResults$({
          endPoint: `${
            this._config.api.endpoints.scenario.getResultSigned
          }?tab=equipment&action=download${this.addVariationQueryParam(
            action.payload.scenarioVariationId,
          )}`,
          payloads: {
            ...action.payload,
          },
          mapFn: ({ jsonResult }) => {
            const { DER_investments } = jsonResult;
            return new ResultsGetEquipmentSuccessAction(
              {
                DER_investments,
              },
              this._translate.instant(
                'Result.messages.getResultsEquipmentSuccess',
              ),
            );
          },
          catchErrorFn: (errResponse: HttpErrorResponse) =>
            of(
              new ResultsGetEquipmentFailureAction(
                (errResponse && errResponse.error && errResponse.error.error) ||
                  this._translate.instant(
                    'Result.messages.getResultsEquipmentFailure',
                  ),
              ),
            ),
        }),
      ),
    ),
  );

  getEquipmentFailure$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.GET_EQUIPMENT_DATA_FAILURE),
        tap((action: ResultsGetEquipmentFailureAction) =>
          this._notification.showError(action.payload.error),
        ),
      ),
    { dispatch: false },
  );

  getEquipmentSuccess$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(ofType(ResultActionTypes.GET_EQUIPMENT_DATA_SUCCESS)),
    { dispatch: false },
  );

  getTopology$: Observable<ResultActions> = createEffect(() =>
    this._action$.pipe(
      ofType(ResultActionTypes.GET_TOPOLOGY_DATA),
      switchMap((action: ResultsGetTopologyAction) =>
        this.getTabResults$({
          endPoint: `${
            this._config.api.endpoints.scenario.getResultSigned
          }?tab=topology&action=download${this.addVariationQueryParam(
            action.payload.scenarioVariationId,
          )}`,
          payloads: {
            ...action.payload,
          },
          mapFn: ({ jsonResult }) => {
            const { line_investments } = jsonResult;
            return new ResultsGetTopologySuccessAction(
              {
                line_investments,
              },
              this._translate.instant(
                'Result.messages.getResultsTopologySuccess',
              ),
            );
          },
          catchErrorFn: (errResponse: HttpErrorResponse) =>
            of(
              new ResultsGetTopologyFailureAction(
                (errResponse && errResponse.error && errResponse.error.error) ||
                  this._translate.instant(
                    'Result.messages.getResultsTopologyFailure',
                  ),
              ),
            ),
        }),
      ),
    ),
  );

  getTopologyFailure$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.GET_TOPOLOGY_DATA_FAILURE),
        tap((action: ResultsGetTopologyFailureAction) =>
          this._notification.showError(action.payload.error),
        ),
      ),
    { dispatch: false },
  );

  getTopologySuccess$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(ofType(ResultActionTypes.GET_TOPOLOGY_DATA_SUCCESS)),
    { dispatch: false },
  );

  getEnergyBalance$: Observable<ResultActions> = createEffect(() =>
    this._action$.pipe(
      ofType(ResultActionTypes.GET_ENERGY_BALANCE_DATA),
      switchMap((action: ResultsGetEnergyBalanceAction) =>
        this.getTabResults$({
          endPoint: `${
            this._config.api.endpoints.scenario.getResultSigned
          }?tab=energy_balance&action=download${this.addVariationQueryParam(
            action.payload.scenarioVariationId,
          )}`,
          payloads: {
            ...action.payload,
          },
          mapFn: ({ jsonResult }) => {
            const { energy_balance } = jsonResult;
            return new ResultsGetEnergyBalanceSuccessAction(
              {
                energy_balance,
              },
              this._translate.instant(
                'Result.messages.getResultsEnergyBalanceSuccess',
              ),
            );
          },
          catchErrorFn: (errResponse: HttpErrorResponse) =>
            of(
              new ResultsGetEnergyBalanceFailureAction(
                (errResponse && errResponse.error && errResponse.error.error) ||
                  this._translate.instant(
                    'Result.messages.getResultsEnergyBalanceFailure',
                  ),
              ),
            ),
        }),
      ),
    ),
  );

  getEnergyBalanceFailure$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.GET_ENERGY_BALANCE_DATA_FAILURE),
        tap((action: ResultsGetEnergyBalanceFailureAction) =>
          this._notification.showError(action.payload.error),
        ),
      ),
    { dispatch: false },
  );

  getEnergyBalanceSuccess$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.GET_ENERGY_BALANCE_DATA_SUCCESS),
      ),
    { dispatch: false },
  );

  getCashFlow$: Observable<ResultActions> = createEffect(() =>
    this._action$.pipe(
      ofType(ResultActionTypes.GET_CASH_FLOW_DATA),
      switchMap((action: ResultsGetCashFlowAction) =>
        this.getTabResults$({
          endPoint: `${
            this._config.api.endpoints.scenario.getResultSigned
          }?tab=cash_flow&action=download${this.addVariationQueryParam(
            action.payload.scenarioVariationId,
          )}`,
          payloads: {
            ...action.payload,
          },
          mapFn: ({ jsonResult }) => {
            const { cumulative_cashflow, incremental_cashflow } = jsonResult;
            return new ResultsGetCashFlowSuccessAction(
              {
                cumulative_cashflow,
                incremental_cashflow,
              },
              this._translate.instant(
                'Result.messages.getResultsCashFlowSuccess',
              ),
            );
          },
          catchErrorFn: (errResponse: HttpErrorResponse) =>
            of(
              new ResultsGetCashFlowFailureAction(
                (errResponse && errResponse.error && errResponse.error.error) ||
                  this._translate.instant(
                    'Result.messages.getResultsCashFlowFailure',
                  ),
              ),
            ),
        }),
      ),
    ),
  );

  getCashFlowFailure$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.GET_CASH_FLOW_DATA_FAILURE),
        tap((action: ResultsGetCashFlowFailureAction) =>
          this._notification.showError(action.payload.error),
        ),
      ),
    { dispatch: false },
  );

  getCashFlowSuccess$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(ofType(ResultActionTypes.GET_CASH_FLOW_DATA_SUCCESS)),
    { dispatch: false },
  );

  getCO2Emission$: Observable<ResultActions> = createEffect(() =>
    this._action$.pipe(
      ofType(ResultActionTypes.GET_CO2_EMISSIONS_DATA),
      switchMap((action: ResultsGetCO2EmissionsAction) =>
        this.getTabResults$({
          endPoint: `${
            this._config.api.endpoints.scenario.getResultSigned
          }?tab=co2_emissions&action=download${this.addVariationQueryParam(
            action.payload.scenarioVariationId,
          )}`,
          payloads: {
            ...action.payload,
          },
          mapFn: ({ jsonResult }) => {
            const { cumulative_emissions, incremental_emissions } = jsonResult;
            return new ResultsGetCO2EmissionsSuccessAction(
              {
                cumulative_emissions,
                incremental_emissions,
              },
              this._translate.instant(
                'Result.messages.getResultsCO2EmissionSuccess',
              ),
            );
          },
          catchErrorFn: (errResponse: HttpErrorResponse) =>
            of(
              new ResultsGetCO2EmissionsFailureAction(
                (errResponse && errResponse.error && errResponse.error.error) ||
                  this._translate.instant(
                    'Result.messages.getResultsCO2EmissionFailure',
                  ),
              ),
            ),
        }),
      ),
    ),
  );

  getCO2EmissionFailure$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.GET_CO2_EMISSIONS_DATA_FAILURE),
        tap((action: ResultsGetCO2EmissionsFailureAction) =>
          this._notification.showError(action.payload.error),
        ),
      ),
    { dispatch: false },
  );

  getCO2EmissionSuccess$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.GET_CO2_EMISSIONS_DATA_SUCCESS),
      ),
    { dispatch: false },
  );

  getDispatch$: Observable<ResultActions> = createEffect(() =>
    this._action$.pipe(
      ofType(ResultActionTypes.GET_DISPATCH_DATA),
      switchMap((action: ResultsGetDispatchAction) =>
        this.getTabResults$({
          endPoint: `${
            this._config.api.endpoints.scenario.getResultSigned
          }?tab=dispatch&action=download${this.addVariationQueryParam(
            action.payload.scenarioVariationId,
          )}`,
          payloads: {
            ...action.payload,
          },
          mapFn: ({ jsonResult }) => {
            const { dispatch } = jsonResult;
            return new ResultsGetDispatchSuccessAction(
              {
                dispatch,
              },
              this._translate.instant(
                'Result.messages.getResultsDispatchSuccess',
              ),
            );
          },
          catchErrorFn: (errResponse: HttpErrorResponse) =>
            of(
              new ResultsGetDispatchFailureAction(
                (errResponse && errResponse.error && errResponse.error.error) ||
                  this._translate.instant(
                    'Result.messages.getResultsDispatchFailure',
                  ),
              ),
            ),
        }),
      ),
    ),
  );

  getDispatchFailure$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.GET_DISPATCH_DATA_FAILURE),
        tap((action: ResultsGetDispatchFailureAction) =>
          this._notification.showError(action.payload.error),
        ),
      ),
    { dispatch: false },
  );

  getDispatchSuccess$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(ofType(ResultActionTypes.GET_DISPATCH_DATA_SUCCESS)),
    { dispatch: false },
  );

  getFlows$: Observable<ResultActions> = createEffect(() =>
    this._action$.pipe(
      ofType(ResultActionTypes.GET_FLOWS_DATA),
      switchMap((action: ResultsGetFlowsAction) =>
        this.getTabResults$({
          endPoint: `${
            this._config.api.endpoints.scenario.getResultSigned
          }?tab=flows&action=download${this.addVariationQueryParam(
            action.payload.scenarioVariationId,
          )}`,
          payloads: {
            ...action.payload,
          },
          mapFn: ({ jsonResult }) => {
            const { sankey } = jsonResult;
            return new ResultsGetFlowsSuccessAction(
              {
                sankey,
              },
              this._translate.instant('Result.messages.getResultsFlowsSuccess'),
            );
          },
          catchErrorFn: (errResponse: HttpErrorResponse) =>
            of(
              new ResultsGetFlowsFailureAction(
                (errResponse && errResponse.error && errResponse.error.error) ||
                  this._translate.instant(
                    'Result.messages.getResultsFlowsFailure',
                  ),
              ),
            ),
        }),
      ),
    ),
  );

  getFlowFailure$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.GET_FLOWS_DATA_FAILURE),
        tap((action: ResultsGetFlowsFailureAction) =>
          this._notification.showError(action.payload.error),
        ),
      ),
    { dispatch: false },
  );

  getFlowSuccess$: Observable<ResultActions> = createEffect(
    () => this._action$.pipe(ofType(ResultActionTypes.GET_FLOWS_DATA_SUCCESS)),
    { dispatch: false },
  );

  putFlows$: Observable<ResultActions> = createEffect(() =>
    this._action$.pipe(
      ofType(ResultActionTypes.PUT_FLOWS_DATA),
      switchMap((action: ResultsPutFlowsAction) => {
        const { projectId, caseId, scenarioId, file } = action.payload;
        const catchErrorFn = (errResponse: HttpErrorResponse) =>
          of(
            new ResultsPutFlowsFailureAction(
              (errResponse && errResponse.error && errResponse.error.error) ||
                this._translate.instant(
                  'Result.messages.putResultsFlowsFailure',
                ),
            ),
          );
        return this._http
          .get(
            generateEndpoint(
              this._config.api.baseUrl,
              `${
                this._config.api.endpoints.scenario.getResultSigned
              }?tab=flows&action=upload${this.addVariationQueryParam(
                action.payload.scenarioVariationId,
              )}`,
              projectId,
              caseId,
              scenarioId,
            ),
          )
          .pipe(
            switchMap((resultsResponse: ResultsDownloadResponse) =>
              this._http
                .put(resultsResponse.url, file, { responseType: 'blob' })
                .pipe(
                  map(
                    (response) =>
                      new ResultsPutFlowsSuccessAction(
                        response,
                        this._translate.instant(
                          'Result.messages.putResultsFlowsSuccess',
                        ),
                      ),
                  ),
                  catchError(catchErrorFn),
                ),
            ),
            catchError(catchErrorFn),
          );
      }),
    ),
  );
  putFlowFailure$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.PUT_FLOWS_DATA_FAILURE),
        tap((action: ResultsPutFlowsFailureAction) =>
          this._notification.showError(action.payload.error),
        ),
      ),
    { dispatch: false },
  );
  putFlowSuccess$: Observable<ResultActions> = createEffect(
    () => this._action$.pipe(ofType(ResultActionTypes.PUT_FLOWS_DATA_SUCCESS)),
    { dispatch: false },
  );

  deleteFlows$: Observable<ResultActions> = createEffect(() =>
    this._action$.pipe(
      ofType(ResultActionTypes.DELETE_FLOWS_DATA),
      switchMap((action: ResultsDeleteFlowsAction) => {
        const { projectId, caseId, scenarioId } = action.payload;
        return this._http
          .delete(
            generateEndpoint(
              this._config.api.baseUrl,
              `${
                this._config.api.endpoints.scenario.getResultSigned
              }?tab=flows&action=delete${this.addVariationQueryParam(
                action.payload.scenarioVariationId,
              )}`,
              projectId,
              caseId,
              scenarioId,
            ),
          )
          .pipe(
            map(
              (response) =>
                new ResultsDeleteFlowsSuccessAction(
                  response,
                  this._translate.instant(
                    'Result.messages.deleteResultsFlowsSuccess',
                  ),
                ),
            ),
            catchError((errResponse: HttpErrorResponse) =>
              of(
                new ResultsDeleteFlowsFailureAction(
                  (errResponse &&
                    errResponse.error &&
                    errResponse.error.error) ||
                    this._translate.instant(
                      'Result.messages.deleteResultsFlowsFailure',
                    ),
                ),
              ),
            ),
          );
      }),
    ),
  );
  deleteFlowFailure$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.DELETE_FLOWS_DATA_FAILURE),
        tap((action: ResultsDeleteFlowsFailureAction) =>
          this._notification.showError(action.payload.error),
        ),
      ),
    { dispatch: false },
  );
  deleteFlowSuccess$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(ofType(ResultActionTypes.DELETE_FLOWS_DATA_SUCCESS)),
    { dispatch: false },
  );

  getLatestScenarioVersion$: Observable<ResultActions> = createEffect(() =>
    this._action$.pipe(
      ofType(ResultActionTypes.GET_LATEST_SCENARIO_VERSION),
      switchMap(() =>
        this._http
          .get(
            generateEndpoint(
              this._config.api.baseUrl,
              `${this._config.api.endpoints.scenario.latestScenarioVersion}`,
            ),
          )
          .pipe(
            map(
              ({ version }) =>
                new ResultsGetLatestScenarioVersionSuccessAction(version),
            ),
            catchError((errResponse: HttpErrorResponse) =>
              of(
                new ResultsGetLatestScenarioVersionFailureAction(
                  (errResponse &&
                    errResponse.error &&
                    errResponse.error.error) ||
                    this._translate.instant(
                      'Result.messages.getLatestScenarioVersionFailure',
                    ),
                ),
              ),
            ),
          ),
      ),
    ),
  );

  getLatestScenarioVersionFailure$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.GET_LATEST_SCENARIO_VERSION_FAILURE),
        tap((action: ResultsGetCO2EmissionsFailureAction) =>
          this._notification.showError(action.payload.error),
        ),
      ),
    { dispatch: false },
  );

  getLatestScenarioVersionSuccess$: Observable<ResultActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(ResultActionTypes.GET_LATEST_SCENARIO_VERSION_SUCCESS),
      ),
    { dispatch: false },
  );

  constructor(
    /**
     * Inject them beaches!
     */
    private _action$: Actions,
    private _config: ConfigService,
    private _http: HttpService,
    private _notification: NotificationsService,
    private _translate: TranslateService,
    private _logger: LoggerService,
  ) {}
}
