import { createSelector } from '@ngrx/store';

import { Case } from '../models';
import { caseStateFactory } from './case.factory';

const filterCases = createSelector(
  caseStateFactory.queries.getDataList,
  (caseList: Array<Case>, props: { projectId: string }) =>
    caseList.filter(
      (_case) =>
        _case.projectId === props.projectId ||
        (_case.loading && !_case.projectId),
    ),
);

export const caseQueries = {
  filterCases,
};
