import { FEATURES } from 'prosumer-app/app-routing.module';
import { ERROR_MESSAGES_REDIRECT } from 'prosumer-app/app.references';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class ResourceCheckerInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        error: ({ error }) => {
          if (ERROR_MESSAGES_REDIRECT?.includes(error?.error)) {
            this.router.navigateByUrl(FEATURES.error.path);
          }
        },
      }),
    );
  }
}
