<prosumer-dialog-div
  class="w-30rem"
  [title]="
    'Scenario.messages.cascade.cascadeTitle'
      | translate
        : {
            type: translatedType | titlecase,
            action: translatedAction | titlecase
          }
  "
  [okLabel]="'continue'"
  (ok)="onOk()"
  (cancel)="onCancel()"
>
  @if (data.topLevelAction) {
    <span class="cascade-confirmation-msg">
      {{ 'Scenario.messages.cascade.topLevelConfirmation' | translate }}
    </span>
  } @else {
    <span class="cascade-confirmation-msg">
      {{
        'Scenario.messages.cascade.cascadeConfirmation'
          | translate: { type: translatedType, action: translatedAction }
      }}
    </span>
  }
  <div class="affected-entities-container">
    @if (tableData()?.delete?.length) {
      <div class="affected-entities-container-deleted">
        <h3 class="affected-entities-title">
          {{ 'Scenario.messages.cascade.deleteTitle' | translate }}
        </h3>
        <prosumer-sparkline-table
          class="affected-entities-table"
          [data]="tableData()['delete']"
          [columnsDef]="columnDef"
          [hideAdd]="true"
          [hideEdit]="true"
          [hideDelete]="true"
          [hideView]="true"
          [searchInput]="false"
          [hideMessages]="true"
          [hideHeader]="false"
          (navLinkEmitClick)="onRowClick($event)"
        ></prosumer-sparkline-table>
      </div>
    }
    @if (tableData()?.update?.length) {
      <div class="affected-entities-container-updated">
        <h3 class="affected-entities-title">
          {{ 'Scenario.messages.cascade.updateTitle' | translate }}
        </h3>
        <prosumer-sparkline-table
          class="affected-entities-table"
          [data]="tableData()['update']"
          [columnsDef]="columnDef"
          [hideAdd]="true"
          [hideEdit]="true"
          [hideDelete]="true"
          [hideView]="true"
          [searchInput]="false"
          [hideMessages]="true"
          [hideHeader]="false"
          (navLinkEmitClick)="onRowClick($event)"
        ></prosumer-sparkline-table>
      </div>
    }
  </div>
</prosumer-dialog-div>
