import { ScenarioWizardStep } from 'prosumer-app/+scenario/services/scenario-completion';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { filterNilValue } from '@datorama/akita';

export interface ActiveScenarioResult {
  readonly tab: string;
  readonly variation: string;
}

export interface ActiveEntities {
  readonly project: string;
  readonly case: string;
  readonly scenario: string;
  readonly hasEditPermission: boolean;
  readonly selectedScenarioTab: ScenarioWizardStep;
  readonly result?: Partial<ActiveScenarioResult>;
}

@Injectable({ providedIn: 'root' })
export class ActiveKeeperService {
  private readonly activeEntities = new BehaviorSubject<ActiveEntities>({
    project: '',
    case: '',
    scenario: '',
    hasEditPermission: false,
    selectedScenarioTab: ScenarioWizardStep.general,
  });

  getActiveEntities(): ActiveEntities {
    return this.activeEntities.value;
  }

  setActive(partial: Partial<ActiveEntities>): void {
    this.activeEntities.next({
      ...this.activeEntities.value,
      ...partial,
    });
  }

  setActiveTab(tab: string): void {
    this.activeEntities.next({
      ...this.activeEntities.value,
      selectedScenarioTab: this.getDefaultedActiveTab(tab),
    });
  }

  setActiveResult(partial: Partial<ActiveScenarioResult>): void {
    this.activeEntities.next({
      ...this.activeEntities.value,
      result: { ...this.activeEntities.value.result, ...partial },
    });
  }

  setActiveResultVariation(variation: string): void {
    this.setActiveResult({ variation });
  }

  selectActive(): Observable<ActiveEntities> {
    return this.activeEntities.asObservable();
  }

  selectProjectEditPermission(): Observable<boolean> {
    return this.activeEntities.pipe(
      filter((active) => this.areProjectAndCaseTruthy(active)),
      map((active) => active.hasEditPermission),
    );
  }

  selectScenarioParents(): Observable<Omit<ActiveEntities, 'scenario'>> {
    return this.activeEntities.pipe(
      filter((active) => this.areProjectAndCaseTruthy(active)),
    );
  }

  selectActiveTab(): Observable<ScenarioWizardStep> {
    return this.selectActive().pipe(
      map((active) => active.selectedScenarioTab),
      filterNilValue(),
      distinctUntilChanged(),
    );
  }

  selectActiveScenario(): Observable<string> {
    return this.selectActive().pipe(
      map((active) => active.scenario),
      filterNilValue(),
      distinctUntilChanged(),
    );
  }

  getActiveScenario(): string {
    return this.getActiveEntities().scenario;
  }

  private areProjectAndCaseTruthy(from: ActiveEntities): boolean {
    return [from.project, from.case].every(Boolean);
  }

  private getDefaultedActiveTab(tab: string): ScenarioWizardStep {
    const forcedTabValue = tab as ScenarioWizardStep;
    if (Object.values(ScenarioWizardStep).includes(forcedTabValue)) {
      return forcedTabValue;
    }
    return ScenarioWizardStep.general;
  }
}
