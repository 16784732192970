import { GeneralCommentsParams } from 'prosumer-scenario/models/comment.types';

import { inject, Injectable, signal } from '@angular/core';
import { ScenarioFacadeService } from '../state';

@Injectable({ providedIn: 'root' })
export class CommentControllerService {
  private scenarioFacade = inject(ScenarioFacadeService);

  readonly activeGeneralCommentsParams = signal<GeneralCommentsParams>(
    {} as GeneralCommentsParams,
  );
  readonly activeSpecificCommentsParams = signal<GeneralCommentsParams | null>(
    null,
  );
  readonly showComments = signal<boolean>(false);
  readonly commentingOn = signal<string>('');

  refreshScenario(params: GeneralCommentsParams) {
    this.scenarioFacade.get(params.scenarioId, params, true);
  }
}
