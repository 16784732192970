import { Scenario } from 'prosumer-app/+scenario';
import { type } from 'prosumer-app/libs/eyes-shared';

/* eslint-disable @typescript-eslint/naming-convention */
import { Action } from '@ngrx/store';

import { mapLatestScenarioToFrontEnd } from './scenario.model';

export const scenarioFeatureName = 'scenario';

export const ScenarioActionTypes = {
  DOWNLOAD_XLSX_TEMPLATE: type(
    `[${scenarioFeatureName}] Download Xlsx Template`,
  ),
  DOWNLOAD_XLSX_TEMPLATE_SUCCESS: type(
    `[${scenarioFeatureName}] Download Xlsx Template Success`,
  ),
  DOWNLOAD_XLSX_TEMPLATE_FAILURE: type(
    `[${scenarioFeatureName}] Download Xlsx Template Failure`,
  ),
  DOWNLOAD_FILE: type(`[${scenarioFeatureName}] Download File`),
  DOWNLOAD_FILE_FAILURE: type(`[${scenarioFeatureName}] Download File Failure`),
  DOWNLOAD_FILE_SUCCESS: type(`[${scenarioFeatureName}] Download File Success`),
  GET_LATEST: type(`[${scenarioFeatureName}] Get Latest Scenario`),
  GET_LATEST_SUCCESS: type(
    `[${scenarioFeatureName}] Get Latest Scenario Success`,
  ),
  GET_LATEST_FAILURE: type(
    `[${scenarioFeatureName}] Get Latest Scenario Failure`,
  ),
  GET_LATEST_MODIFIED: type(
    `[${scenarioFeatureName}] Get Latest Modified Scenario`,
  ),
  GET_LATEST_MODIFIED_SUCCESS: type(
    `[${scenarioFeatureName}] Get Latest Modified Scenario Success`,
  ),
  GET_LATEST_MODIFIED_FAILURE: type(
    `[${scenarioFeatureName}] Get Latest Modified Scenario Failure`,
  ),
  GET_DRAFTS: type(`[${scenarioFeatureName}] Get All Scenarios In Draft State`),
  GET_DRAFTS_SUCCESS: type(
    `[${scenarioFeatureName}] Get All Scenarios In Draft State (SUCCESS)`,
  ),
  GET_DRAFTS_FAILURE: type(
    `[${scenarioFeatureName}] Get All Scenarios In Draft State (FAILURE)`,
  ),
};

export class ScenarioDownloadXlsxTemplateAction implements Action {
  readonly type = ScenarioActionTypes.DOWNLOAD_XLSX_TEMPLATE;
  readonly payload: {
    endpointName: string;
  };
  constructor(endpointName: string) {
    this.payload = {
      endpointName,
    };
  }
}

export class ScenarioDownloadXlsxTemplateSuccessAction implements Action {
  readonly type = ScenarioActionTypes.DOWNLOAD_XLSX_TEMPLATE_SUCCESS;
  readonly payload: { data: Scenario; notify: boolean };
  constructor(data, notify: boolean) {
    this.payload = { data, notify };
  }
}

export class ScenarioDownloadXlsxTemplateFailureAction implements Action {
  readonly type = ScenarioActionTypes.DOWNLOAD_XLSX_TEMPLATE_FAILURE;
  readonly payload: {
    error: string;
  };
  constructor(error: string) {
    this.payload = { error };
  }
}

export class ScenarioDownloadFileAction implements Action {
  readonly type = ScenarioActionTypes.DOWNLOAD_FILE;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    endpointName: string;
    scenarioName: string;
  };
  constructor(scenario: Scenario, endpointName: string) {
    this.payload = {
      projectId: scenario.projectId,
      caseId: scenario.caseId,
      scenarioId: scenario.id,
      endpointName,
      scenarioName: scenario.name,
    };
  }
}

export class ScenarioDownloadFileFailureAction implements Action {
  readonly type = ScenarioActionTypes.DOWNLOAD_FILE_FAILURE;
  readonly payload: {
    error: string;
    projectId: string;
    caseId: string;
    scenarioId: string;
  };
  constructor(
    error: string,
    projectId?: string,
    caseId?: string,
    scenarioId?: string,
  ) {
    this.payload = { error, projectId, caseId, scenarioId };
  }
}

export class ScenarioDownloadFileSuccessAction implements Action {
  readonly type = ScenarioActionTypes.DOWNLOAD_FILE_SUCCESS;
  readonly payload: { data: Scenario; notify: boolean };
  constructor(data, notify: boolean) {
    this.payload = { data, notify };
  }
}

export class GetLatestSimulationAction implements Action {
  readonly type = ScenarioActionTypes.GET_LATEST;
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly payload: {};
  constructor() {
    this.payload = {};
  }
}

export class GetLatestSimulationFailureAction implements Action {
  readonly type = ScenarioActionTypes.GET_LATEST_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}
export class GetLatestSimulationSuccessAction implements Action {
  readonly type = ScenarioActionTypes.GET_LATEST_SUCCESS;
  readonly payload: {
    dataList: Array<Scenario>;
    message: string;
    notify: boolean;
  };
  constructor(dataList: Array<any>, message: string, notify: boolean) {
    this.payload = {
      dataList: dataList.map((data) => mapLatestScenarioToFrontEnd(data)),
      message,
      notify,
    };
  }
}

export class GetLatestModifiedAction implements Action {
  readonly type = ScenarioActionTypes.GET_LATEST_MODIFIED;
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly payload: {};
  constructor() {
    this.payload = {};
  }
}

export class GetLatestModifiedFailureAction implements Action {
  readonly type = ScenarioActionTypes.GET_LATEST_MODIFIED_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}
export class GetLatestModifiedSuccessAction implements Action {
  readonly type = ScenarioActionTypes.GET_LATEST_MODIFIED_SUCCESS;
  readonly payload: {
    dataList: Array<Scenario>;
    message: string;
    notify: boolean;
  };
  constructor(dataList: Array<any>, message: string, notify: boolean) {
    this.payload = {
      dataList: dataList.map((data) => mapLatestScenarioToFrontEnd(data)),
      message,
      notify,
    };
  }
}

export class ScenarioGetDraftsAction implements Action {
  readonly type = ScenarioActionTypes.GET_DRAFTS;
}

export class ScenarioGetDraftsSuccess implements Action {
  readonly type = ScenarioActionTypes.GET_DRAFTS_SUCCESS;
  readonly payload: { drafts: Array<any> };
  constructor(draftsList: Array<any>) {
    this.payload = { drafts: draftsList };
  }
}

export class ScenarioGetDraftsFailure implements Action {
  readonly type = ScenarioActionTypes.GET_DRAFTS_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

export type ScenarioActions =
  | any
  | ScenarioDownloadFileFailureAction
  | ScenarioDownloadFileSuccessAction
  | ScenarioDownloadFileAction
  | GetLatestSimulationAction
  | GetLatestSimulationSuccessAction
  | GetLatestSimulationFailureAction
  | ScenarioGetDraftsAction
  | ScenarioGetDraftsSuccess
  | ScenarioGetDraftsFailure;
