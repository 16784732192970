import { EndpointProvider } from 'prosumer-app/services/endpoint-provider';
import { Coerce } from 'prosumer-core/utils';
import {
  GeneralCommentsParams,
  GetCommentsResource,
} from 'prosumer-scenario/models/comment.types';
import {
  BehaviorSubject,
  catchError,
  finalize,
  map,
  Observable,
  of,
  pipe,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Resource } from 'prosumer-app/shared/models/resource.types';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  private http = inject(HttpClient);
  private endpoints = inject(EndpointProvider);
  readonly refreshSeed = new BehaviorSubject<number>(0);

  getGeneralComments(
    params: GeneralCommentsParams,
  ): Observable<GetCommentsResource> {
    return this.refreshable(
      this.http
        .get<GetCommentsResource>(this.buildGeneralCommentsUrl(params))
        .pipe(this.resourcePipe()),
    ) as Observable<GetCommentsResource>;
  }

  editGeneralComment(
    params: GeneralCommentsParams,
    content: string,
  ): Observable<Resource> {
    return this.http
      .put<Resource>(this.buildGeneralCommentUrl(params), {
        content,
      })
      .pipe(
        tap(() => this.triggerRefresh()),
        this.resourcePipe(),
      );
  }

  createGeneralComment(
    params: GeneralCommentsParams,
    content: string,
  ): Observable<Resource> {
    return this.http
      .post<Resource>(this.buildGeneralCommentsUrl(params), {
        content,
      })
      .pipe(
        tap(() => this.triggerRefresh()),
        this.resourcePipe(),
      );
  }

  deleteGeneralComment(params: GeneralCommentsParams): Observable<Resource> {
    return this.http.delete<Resource>(this.buildGeneralCommentUrl(params)).pipe(
      finalize(() => this.triggerRefresh()),
      this.resourcePipe(),
    );
  }

  private triggerRefresh() {
    this.refreshSeed.next(Math.random());
  }

  private refreshable<T>($: Observable<T>): Observable<T> {
    return this.refreshSeed.pipe(switchMap(() => $));
  }

  private resourcePipe() {
    return pipe(
      map((rsp) => ({ data: rsp, loading: false })),
      startWith({ loading: true }),
      catchError(() => of({ loading: false, error: 'yikes' })),
    );
  }

  private buildGeneralCommentUrl(params: GeneralCommentsParams): string {
    const { projectId, caseId, scenarioId, commentId } =
      Coerce.toObject(params);
    return this.endpoints.forGeneralComment(
      {
        project: projectId,
        case: caseId,
        scenario: scenarioId,
      },
      commentId,
    );
  }

  private buildGeneralCommentsUrl(params: GeneralCommentsParams): string {
    const { projectId, caseId, scenarioId } = Coerce.toObject(params);
    return this.endpoints.forGeneralComments({
      project: projectId,
      case: caseId,
      scenario: scenarioId,
    });
  }
}
