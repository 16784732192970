import { Coerce } from 'prosumer-app/core/utils';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { filterNilValue, QueryEntity } from '@datorama/akita';

import {
  FilterCriteria,
  FilterCriteriaSelections,
  TDBDataState,
  TDBDataSummary,
} from './tdb-data.state';
import { TDBDataStore } from './tdb-data.store';

@Injectable({ providedIn: 'root' })
export class TDBDataQuery extends QueryEntity<TDBDataState> {
  constructor(store: TDBDataStore) {
    super(store);
  }

  selectLoading(): Observable<boolean> {
    return this.select('loading').pipe(
      mergeMap((loading) =>
        this.selectAll().pipe(
          map((entities: Omit<TDBDataSummary, 'values'>[]) =>
            [loading, ...entities.map((entity) => entity.loading)].some(
              Boolean,
            ),
          ),
        ),
      ),
    );
  }

  getActiveFilters(): FilterCriteria {
    return Coerce.toObject(this.getValue().activeFilters);
  }

  selectActiveFilters(): Observable<FilterCriteria> {
    return this.select('activeFilters');
  }

  selectTypes(): Observable<string[]> {
    return this.select('types').pipe(filterNilValue());
  }

  selectFilters(): Observable<FilterCriteriaSelections> {
    return this.select('filters').pipe(
      filter((filters) => Object.keys(Coerce.toObject(filters)).length > 0),
    );
  }
}
