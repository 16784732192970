import {
  TDBFilters,
  TDBGetEVsResponse,
  TDBGetFiltersResponse,
  TDBGetPriceIntervalsResponse,
  TDBGetPricesQueryParameters,
  TDBGetPricesResponse,
} from 'prosumer-app/+scenario/models';
import { Coerce } from 'prosumer-app/core/utils';
import { EndpointProvider } from 'prosumer-app/services/endpoint-provider';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, finalize, tap } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'any' })
export class TransversalDbApiService {
  // TODO:
  // - rename evId to commodityName or similar
  private readonly _isLoading = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this._isLoading.asObservable();
  private readonly _tdbCommodities = new Subject<string[]>();
  readonly tdbCommodities$ = this._tdbCommodities.asObservable();
  private readonly _tdbFilters = new Subject<TDBFilters>();
  readonly tdbFilters$ = this._tdbFilters.asObservable();
  private readonly _tdbPriceIntervals =
    new Subject<TDBGetPriceIntervalsResponse>();
  readonly tdbPriceIntervals$ = this._tdbPriceIntervals.asObservable();
  private readonly _tdbPrice = new Subject<TDBGetPricesResponse>();
  readonly tdbPrice$ = this._tdbPrice.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly _endpoints: EndpointProvider,
    private readonly _notifs: NotificationsService,
  ) {}

  getTDBEnergyVectors(): Observable<TDBGetEVsResponse | HttpErrorResponse> {
    return this.requestFromTDB<TDBGetEVsResponse>('getEnergyVectors', {}).pipe(
      tap((response) => this._tdbCommodities.next(response.types)),
      catchError((err) => this._handleError(err)),
    );
  }

  getTDBFilters(
    energyVector: string,
  ): Observable<TDBGetFiltersResponse | HttpErrorResponse> {
    return this.requestFromTDB<TDBGetFiltersResponse>('getFilters', {
      energyVector,
    }).pipe(
      tap((response) => this._tdbFilters.next(response.filters)),
      catchError((err) => this._handleError(err)),
    );
  }

  getTDBPriceIntervals(
    energyVector: string,
    params: Record<string, string | number>,
  ): Observable<TDBGetPriceIntervalsResponse | HttpErrorResponse> {
    return this.requestFromTDB<TDBGetPriceIntervalsResponse>(
      'getPriceIntervals',
      {
        ...params,
        energyVector,
      },
    ).pipe(
      tap((response) => this._tdbPriceIntervals.next(response)),
      catchError((err) => this._handleError(err)),
    );
  }

  getTDBPrices(
    energyVector: string,
    params: TDBGetPricesQueryParameters,
  ): Observable<TDBGetPricesResponse | HttpErrorResponse> {
    return this.requestFromTDB<TDBGetPricesResponse>('getPrices', {
      ...params,
      energyVector,
    }).pipe(
      // TODO: add handler for yearly grannularity
      tap((response) => this._tdbPrice.next(response)),
      catchError((err) => this._handleError(err)),
    );
  }

  private requestFromTDB<T>(
    action: string,
    params: Record<string, string | number>,
  ): Observable<T> {
    this._isLoading.next(true);
    return this._http
      .get<T>(this._endpoints.forLinkWithTDB(), {
        params: { action, ...params },
      })
      .pipe(
        filter((resp) => !!resp),
        finalize(() => this._isLoading.next(false)),
      );
  }

  private _handleError(
    error: HttpErrorResponse,
  ): Observable<HttpErrorResponse> {
    this._notifs.showError(this._extractError(error));
    return throwError(error);
  }

  private _extractError(from: HttpErrorResponse): string {
    return Coerce.toString(
      Coerce.toObject(from.error).message,
      this._getDefaultErrorMsg(),
    );
  }

  private _getDefaultErrorMsg(): string {
    return 'Something went wrong...';
  }
}
