import { COHERENCE_STEP_LABELS } from 'prosumer-app/app.references';
import { sortByField } from 'prosumer-app/libs/eyes-shared';

import { CoherenceResult } from '../../models';

export class CoherenceResultMapper {
  static mapToFrontend = (payload: any): CoherenceResult =>
    !!payload
      ? {
          type: (payload.type || '').toLowerCase(),
          message: payload.message,
          stepId: (payload.location || {}).module,
          groupId: (payload.location || {}).group,
          fieldId: (payload.location || {}).field,
        }
      : {};

  static mapToDisplayMessage = (results) => {
    let msgList;

    if (results) {
      msgList = [];
      const stepIds = new Set<string>(results.map((result) => result.stepId));
      stepIds.forEach((step) => {
        const stepLabel =
          COHERENCE_STEP_LABELS[step]?.label ?? COHERENCE_STEP_LABELS[step];
        const msgItem = msgList.find(
          (msgItem) => msgItem.stepLabel === stepLabel,
        );

        if (!!msgItem) {
          msgItem.validations.push(
            ...CoherenceResultMapper.filterResults(results, step),
          );
        } else {
          msgList.push({
            stepLabel,
            validations: CoherenceResultMapper.filterResults(results, step),
          });
        }
      });
    }

    return msgList;
  };

  private static filterResults = (results, step) =>
    results
      .filter((result) => result.stepId === step)
      .map((item) => ({
        ...item,
        scenarioTab:
          COHERENCE_STEP_LABELS[step]?.tab ?? COHERENCE_STEP_LABELS[step],
        message: item.groupId
          ? `<b>${item.groupId}</b>: ${item.message}`
          : `${item.message}`,
      }))
      .sort((a, b) => sortByField('message', a, b))
      .sort((a, b) => sortByField('fieldId', a, b))
      .sort((a, b) => sortByField('groupId', a, b))
      .sort((a, b) => sortByField('type', a, b));
}
