import { EntityState } from '@datorama/akita';

export interface TDBDataState
  extends EntityState<Omit<TDBDataSummary, 'values'>> {
  readonly types?: string[];
  readonly filters?: FilterCriteriaSelections;
  readonly activeFilters?: FilterCriteria;
}

export type FilterCriteriaSelections = { [key: string]: string[] | number[] };
export type FilterCriteria = { [key: string]: string | number };

export interface FilterConfigResponse {
  readonly filters: FilterCriteriaSelections;
}

export interface FilterTypeConfigResponse {
  readonly types: string[];
}

export interface TDBDataSummary {
  year: string;
  type: string;
  scenario: string;
  geography: string;
  granularity: TDBDataGranularity;
  values: Record<number, string>;
  loading: boolean;
}

export interface TDBDataProfile {
  type: string;
  year: string;
  scenario: string;
  geography: string;
  granularity: TDBDataGranularity;
  values: number[];
}

export enum TDBDataGranularity {
  hourly = 'hourly',
  yearly = 'yearly',
}

export enum TDBFilterActions {
  technology = 'getRenewableFilters',
  commodity = 'getPriceFilters',
  emission = 'getEmissionFilters',
}

export enum TDBProfileIntervalActions {
  commodity = 'getPriceIntervals',
}

export enum TDBProfileActions {
  technology = 'getRenewableProfile',
  commodity = 'getPricesProfile',
  emission = 'getEmissionProfile',
}
