import { BaseEntityState } from 'prosumer-app/libs/eyes-shared';
import { Project } from '../models';

export interface ProjectState extends BaseEntityState<Project> {
  sample?: string;
}

export const mapProjectDetailsScenarioListToFrontEnd = (
  payload: any,
): Project => ({
  name: payload.name,
  id: payload.id,
  owner: payload.owner,
  updatedDate: payload.updatedDate,
  createdDate: payload.createdDate,
  sharedTo: payload.sharedTo,
  description: payload.description,
  permissions: payload.permissions,
  ownerName: payload.ownerName,
  createdBy: payload.createdBy,
  role: payload.role,
});
