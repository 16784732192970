import { Utils } from 'prosumer-core/utils/utils';

import { ValueType } from '@prosumer/results/models/base-results.model';

export interface BaseExtractor<T> {
  extract(resultsJson: unknown): T[];
}

export abstract class YearlyDetailsExtractor<T> {
  protected extractCumulative(data: unknown, key: string): T[] {
    const cumulative = data[key];
    const extracted = this.extractData(cumulative, ValueType.CUMULATIVE);
    return extracted;
  }

  protected extractIncremental(data: unknown, key: string): T[] {
    const incremental = data[key];
    const extracted = this.extractData(incremental, ValueType.INCREMENTAL);
    return extracted;
  }

  protected extractData(dataKey: unknown, valueType: ValueType): T[] {
    const yearAndDetailValues = Object.entries(
      Utils.resolveToEmptyObject(dataKey),
    );
    return yearAndDetailValues.reduce((acc, [year, valueChunk]) => {
      const valueChunks = Object.entries(valueChunk);
      const builtSubChunks = valueChunks.map(([details, value]) =>
        this.buildData(details, {
          value: Number(value),
          year: Number(year),
          valueType,
        }),
      );
      return [...acc, ...builtSubChunks];
    }, []);
  }

  protected buildData(details: string, partial: Partial<any>): T {
    return {
      ...partial,
      ...this.parseDetails(details),
    } as T;
  }

  protected parseDetails(details: string): Partial<any> {
    // override this implementation
    return {};
  }

  protected getElement(idx: number, fromArr: any[]): any {
    return (fromArr || [])[idx];
  }

  protected lowercaseCategory(category: string): any {
    return String(category).toLowerCase();
  }
}
