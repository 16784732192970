import { type } from 'prosumer-app/libs/eyes-shared';

/* eslint-disable @typescript-eslint/naming-convention */
import { Action } from '@ngrx/store';

export const messageFeatureName = 'message';

export const MessageActionTypes = {
  GET: type(`[${messageFeatureName}] Get Messages`),
  GET_FAILURE: type(`[${messageFeatureName}] Get Messages Failure`),
  GET_SUCCESS: type(`[${messageFeatureName}] Get Messages Success`),
  GET_EXCEL_IMPORT: type(`[${messageFeatureName}] Get Excel Import Messages`),
  GET_EXCEL_IMPORT_FAILURE: type(
    `[${messageFeatureName}] Get Excel Import Messages Failure`,
  ),
  GET_EXCEL_IMPORT_SUCCESS: type(
    `[${messageFeatureName}] Get Excel Import Messages Success`,
  ),
  CLEAR: type(`[${messageFeatureName}] Clear Scenario Messages`),
};

/**
 * Dispatched action when get message has been requested
 */
export class MessageGetAction implements Action {
  readonly type = MessageActionTypes.GET;
  readonly payload: { projectId: string; caseId: string; scenarioId: string };
  constructor(projectId: string, caseId: string, scenarioId: string) {
    this.payload = { projectId, caseId, scenarioId };
  }
}

/**
 * Dispatched action when get message was a success. Maps the retrieved domain model to view.
 */
export class MessageGetSuccessAction implements Action {
  readonly type = MessageActionTypes.GET_SUCCESS;
  readonly payload: { currentScenarioID: string; data: any; message: string };
  constructor(currentID: string, data: any, message: string) {
    this.payload = { currentScenarioID: currentID, data, message };
  }
}

/**
 * Dispatched action when get message was a failure. REturns the error msg retrieved from server.
 */
export class MessageGetFailureAction implements Action {
  readonly type = MessageActionTypes.GET_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

/**
 * Dispatched action when a clear data has been requested.
 */
export class MessageClearAction implements Action {
  readonly type = MessageActionTypes.CLEAR;
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly payload: {};
  constructor() {}
}

/**
 * Dispatched action when get excel import message has been requested
 */
export class ExcelImportMessageGetAction implements Action {
  readonly type = MessageActionTypes.GET_EXCEL_IMPORT;
  readonly payload: { projectId: string; caseId: string; scenarioId: string };
  constructor(projectId: string, caseId: string, scenarioId: string) {
    this.payload = { projectId, caseId, scenarioId };
  }
}

/**
 * Dispatched action when get excel import message was a success. Maps the retrieved domain model to view.
 */
export class ExcelImportMessageGetSuccessAction implements Action {
  readonly type = MessageActionTypes.GET_EXCEL_IMPORT_SUCCESS;
  readonly payload: { currentScenarioID: string; data: any; message: string };
  constructor(currentID: string, data: any, message: string) {
    this.payload = { currentScenarioID: currentID, data, message };
  }
}

/**
 * Dispatched action when get excel import message was a failure. Returns the error msg retrieved from server.
 */
export class ExcelImportMessageGetFailureAction implements Action {
  readonly type = MessageActionTypes.GET_EXCEL_IMPORT_FAILURE;
  readonly payload: { error: string };
  constructor(error: string) {
    this.payload = { error };
  }
}

export type MessageActions =
  | any
  | MessageGetAction
  | MessageGetSuccessAction
  | MessageGetFailureAction
  | ExcelImportMessageGetAction
  | ExcelImportMessageGetSuccessAction
  | ExcelImportMessageGetFailureAction;
