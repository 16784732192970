import { DialogDivModule } from 'prosumer-app/shared/components/dialog-div';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../../shared.module';
import { CascadeConfirmationComponent } from './cascade-confirmation.component';

@NgModule({
  declarations: [CascadeConfirmationComponent],
  imports: [
    CommonModule,
    DialogDivModule,
    TranslateModule,
    MatListModule,
    SharedModule,
  ],
  exports: [CascadeConfirmationComponent],
})
export class CascadeConfirmationModule {}
