import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  convertNestedObjToFlattenedArray,
  getKeys,
  toList,
} from 'prosumer-app/libs/eyes-shared';
import {
  CashFlowResult,
  CashFlowResultItem,
  CashFlowResultItemValue,
  DispatchResult,
  DispatchResultItem,
  EnergyBalanceResult,
  EnergyBalanceResultItem,
  EquipmentResult,
  EquipmentResultItem,
  MainResult,
  PaybackResult,
  PaybackResultItem,
  ScenarioDetails,
  ScenarioResult,
  TopologyResult,
  TopologyResultItem,
} from 'prosumer-scenario/models';

// TODO: Add mappers here

export class ResultsMapper {
  static mapToFrontEnd = (
    referenceId: string,
    id: string,
    name: string,
    result: any,
    variationId: string = undefined,
  ): ScenarioResult => ({
    id,
    name,
    referenceId,
    variationId,
    // Results
    mainResult: {
      ...ResultsMapper.mapMainResultToFrontend(result.Main_outputs || {}),
    },
    equipmentResult: {
      ...ResultsMapper.mapEquipmentResultToFrontend(
        result.DER_investments || {},
      ),
    },
    cashFlowResult: {
      ...ResultsMapper.mapCashFlowResultToFrontEnd(
        result['incremental_cashflow'] || {},
      ),
    },
    energyBalanceResult: {
      ...ResultsMapper.mapEnergyBalanceResultToFrontend(
        result.energy_balance || {},
      ),
    },
    topologyResult: {
      ...ResultsMapper.mapLineInvestmentsToFrontend(
        result.line_investments || {},
      ),
    },
    dispatchResult: {
      ...ResultsMapper.mapDispatchToFrontend(result.dispatch || {}),
    },
    paybackResult: {
      ...ResultsMapper.mapPaybackResultItemValue(
        result['incremental_cashflow'] || {},
      ),
    },
  });

  static mapMainSplitResult = (
    referenceId: string,
    id: string,
    name: string,
    result: any,
    variationId: string = undefined,
  ): ScenarioResult => ({
    id,
    name,
    referenceId,
    variationId,
    // Results
    mainResult: {
      ...ResultsMapper.mapMainResultToFrontend(result.Main_outputs || {}),
    },
    equipmentResult: {
      ...ResultsMapper.splitResultMapper(
        result.DER_investments,
        ResultsMapper.mapEquipmentResultToFrontend,
        {
          equipments: null,
        },
      ),
    },
    cashFlowResult: {
      ...ResultsMapper.splitResultMapper(
        result.incremental_cashflow,
        ResultsMapper.mapCashFlowResultToFrontEnd,
        {
          cashFlowList: null,
        },
      ),
    },
    energyBalanceResult: {
      ...ResultsMapper.splitResultMapper(
        result.energy_balance,
        ResultsMapper.mapEnergyBalanceResultToFrontend,
        {
          equipments: null,
        },
      ),
    },
    topologyResult: {
      ...ResultsMapper.splitResultMapper(
        result.line_investments,
        ResultsMapper.mapLineInvestmentsToFrontend,
        {
          topologies: null,
        },
      ),
    },
    dispatchResult: {
      ...ResultsMapper.splitResultMapper(
        result.dispatch,
        ResultsMapper.mapDispatchToFrontend,
        {
          dispatchList: null,
        },
      ),
    },
    paybackResult: {
      ...ResultsMapper.splitResultMapper(
        result.incremental_cashflow,
        ResultsMapper.mapPaybackResultItemValue,
        {
          series: null,
        },
      ),
    },
  });

  static splitResultMapper(result, mapper, defaultValue?: any) {
    return result ? { ...mapper(result) } : { ...defaultValue };
  }

  static mapCashFlowResultToFrontEnd = (item: any): CashFlowResult => ({
    cashFlowList: getKeys(item).map<
      CashFlowResultItem<CashFlowResultItemValue>
    >((key) => ({
      year: key,
      data: ResultsMapper.mapCashFlowResultItemValue(item[key]),
    })),
  });

  private static mapCashFlowResultItemValue = (
    item: any,
  ): Array<CashFlowResultItemValue> =>
    getKeys(item).map<CashFlowResultItemValue>((itemKey) => ({
      cashFlowName: itemKey,
      value: item[itemKey],
      type: itemKey.toString().indexOf('Capex') !== -1 ? 'capex' : 'opex',
    }));

  static mapPaybackResultItemValue = (item: any): PaybackResult => {
    const series = [] as Array<PaybackResultItem>;

    getKeys(item).map((key) => {
      series.push({
        name: key,
        value: toList(item[key]).reduce(
          (previous, next) => (previous || 0) + (next || 0),
        ),
      } as PaybackResultItem);
    });

    return { series };
  };

  static mapEnergyBalanceResultToFrontend = (
    item: any,
  ): EnergyBalanceResult => {
    const equipments = [] as Array<EnergyBalanceResultItem>;

    getKeys(item).map((year) => {
      const nodes = item[year];
      getKeys(nodes).map((node) => {
        const energyVectors = nodes[node];
        getKeys(energyVectors).map((energyVector) => {
          const types = energyVectors[energyVector];
          getKeys(types).map((type) => {
            const _equipments = types[type];
            getKeys(_equipments).map((equipmentName) => {
              equipments.push({
                year,
                node,
                energyVector,
                type,
                equipmentName,
                value: _equipments[equipmentName],
              } as EnergyBalanceResultItem);
            });
          });
        });
      });
    });

    return { equipments };
  };

  static mapMainResultToFrontend = (mainOutputs: any): MainResult => ({
    totalDistributedCost: coerceNumberProperty(mainOutputs.global.Total_cost),
    capexFirstYear: coerceNumberProperty(mainOutputs.global.Initial_capex),
    opexFirstYear: coerceNumberProperty(mainOutputs.global.Initial_opex),
    co2Emission: coerceNumberProperty(mainOutputs.global.Initial_co2_emissions),
  });

  static mapEquipmentResultToFrontend = (
    derInvestments: any,
  ): EquipmentResult => {
    const equipment =
      derInvestments &&
      getKeys(derInvestments).map<EquipmentResultItem>((key) => ({
        equipmentName: derInvestments[key].name,
        type: derInvestments[key].type,
        node: derInvestments[key].node,
        inputEnergyVector: derInvestments[key].input_fluid,
        outputEnergyVector: derInvestments[key].output_fluid,
        // Values
        annualEnergyConsumption: coerceNumberProperty(
          derInvestments[key].yearly_cons,
        ),
        annualEnergyProduction: coerceNumberProperty(
          derInvestments[key].yearly_prod,
        ),
        initialInvestmentCost: coerceNumberProperty(
          derInvestments[key].init_invest_cost,
        ),
        omCostFirstYear: coerceNumberProperty(
          derInvestments[key].init_oper_cost,
        ),
        sizekW: coerceNumberProperty(derInvestments[key].size_kw),
        sizekWh: coerceNumberProperty(derInvestments[key].size_kwh),
        totalDiscountedCost: coerceNumberProperty(
          derInvestments[key].total_cost,
        ),
      }));
    return {
      equipments: derInvestments ? equipment : null,
    };
  };

  static mapLineInvestmentsToFrontend = (
    lineInvestments: any,
  ): TopologyResult => ({
    topologies: getKeys(lineInvestments).map<TopologyResultItem>((key) => ({
      lineName: lineInvestments[key].name,
      energyVector: lineInvestments[key].fluid,
      sizekW: coerceNumberProperty(lineInvestments[key].size_kw),
      sizekWh: coerceNumberProperty(lineInvestments[key].yearly_flow_ab),
      sizekEUR: coerceNumberProperty(lineInvestments[key].total_cost),
      originNode: lineInvestments[key].origin_node,
      destinationNode: lineInvestments[key].destination_node,
      sizekWhBa: coerceNumberProperty(lineInvestments[key].yearly_flow_ba),
    })),
  });

  static mapDispatchToFrontend = (dispatch: any): DispatchResult => {
    const dispatchList =
      dispatch &&
      convertNestedObjToFlattenedArray<DispatchResultItem>(dispatch, [
        'year',
        'node',
        'energyVector',
        'resultType',
        'equipment',
        'values',
      ]);

    return {
      dispatchList: dispatch ? dispatchList : null,
    };
  };

  static mapScenarioDetails = (result: any): ScenarioDetails => {
    if (!result) {
      return {} as ScenarioDetails;
    }

    return {
      scenarioId: result.scenarioUuid,
      variationId: result.variationUuid,
      projectId: result.projectUuid,
      caseId: result.caseUuid,
    };
  };
}
