import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import {
  ScenarioBinActionTypes,
  scenarioBinFeatureName,
  ScenarioBinStoreActions,
} from './scenarioBin-store.actions';
import {
  ScenarioBinData,
  ScenarioBinStoreState,
} from './scenarioBin-store.model';

export const scenarioBinInitialState: ScenarioBinStoreState = {
  loading: false,
  listLoading: false,
  cachedData: new Map<string, ScenarioBinData>(),
};

export function scenarioBinStoreReducer(
  state: ScenarioBinStoreState = scenarioBinInitialState,
  action: ScenarioBinStoreActions,
): ScenarioBinStoreState {
  switch (action.type) {
    case ScenarioBinActionTypes.GET_SCENARIO_BIN:
      return {
        ...state,
        error: undefined,
        loading: true,
        data: undefined,
      };
    case ScenarioBinActionTypes.GET_SCENARIO_BIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ScenarioBinActionTypes.GET_SCENARIO_BIN_SUCCESS:
      if (!action.payload.cached) {
        const getCachedDataCopy = { ...state.cachedData };
        getCachedDataCopy[
          action.payload.data.location.concat('-', action.payload.data.id)
        ] = action.payload.data;
        return {
          ...state,
          // cachedData: getCachedDataCopy,
          loading: false,
          data: action.payload.data,
        };
      }
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ScenarioBinActionTypes.DELETE_SCENARIO_BIN:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case ScenarioBinActionTypes.DELETE_SCENARIO_BIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ScenarioBinActionTypes.DELETE_SCENARIO_BIN_SUCCESS: {
      const deleteCachedDataCopy = { ...state.cachedData };
      deleteCachedDataCopy[
        action.payload.data.location.concat('-', action.payload.data.id)
      ] = undefined;
      return {
        ...state,
        loading: false,
        // cachedData: deleteCachedDataCopy,
        data: action.payload.data,
      };
    }
    case ScenarioBinActionTypes.SAVE_SCENARIO_BIN:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case ScenarioBinActionTypes.SAVE_SCENARIO_BIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ScenarioBinActionTypes.SAVE_SCENARIO_BIN_SUCCESS: {
      const saveCachedDataCopy = { ...state.cachedData };
      saveCachedDataCopy[
        action.payload.data.location.concat('-', action.payload.data.id)
      ] = action.payload.data;
      return {
        ...state,
        // cachedData: saveCachedDataCopy,
        loading: false,
        data: action.payload.data,
      };
    }
    case ScenarioBinActionTypes.DELETE_ALL_SCENARIO_BIN:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case ScenarioBinActionTypes.DELETE_ALL_SCENARIO_BIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: { ...action.payload.data, deleteSuccess: false },
      };
    case ScenarioBinActionTypes.DELETE_ALL_SCENARIO_BIN_SUCCESS: {
      const deleteAllCachedDataCopy = { ...state.cachedData };
      action.payload.data.forEach((binData) => {
        deleteAllCachedDataCopy[
          binData['location'].concat('-', binData['local_id'])
        ] = undefined;
      });
      return {
        ...state,
        loading: false,
        // cachedData: deleteAllCachedDataCopy,
        data: { ...action.payload.data, deleteSuccess: true },
      };
    }
    case ScenarioBinActionTypes.CLEAR_SCENARIO_BIN_CACHE:
      return {
        ...state,
        cachedData: new Map<string, ScenarioBinData>(),
      };
    default:
      return state;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const scenarioBinFeature: MemoizedSelector<any, ScenarioBinStoreState> =
  createFeatureSelector<ScenarioBinStoreState>(scenarioBinFeatureName);

// eslint-disable-next-line @typescript-eslint/ban-types
export const scenarioBinSelectors: {
  [key: string]: MemoizedSelector<any, any>;
} = {
  state: scenarioBinFeature,
  loading: createSelector(
    scenarioBinFeature,
    (state: ScenarioBinStoreState) => state.loading,
  ),
  data: createSelector(
    scenarioBinFeature,
    (state: ScenarioBinStoreState) => state.data,
  ),
  listLoading: createSelector(
    scenarioBinFeature,
    (state: ScenarioBinStoreState) => state.listLoading,
  ),
  listData: createSelector(
    scenarioBinFeature,
    (state: ScenarioBinStoreState) => state.listData,
  ),
  cachedData: createSelector(
    scenarioBinFeature,
    (state: ScenarioBinStoreState) => state.cachedData,
  ),
  listError: createSelector(
    scenarioBinFeature,
    (state: ScenarioBinStoreState) => state.listError,
  ),
};
