import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { FilledStatus, ScenarioWizardStep } from './scenario-completion.models';

type StepStatusManifest = Record<ScenarioWizardStep, FilledStatus>;

@Injectable({ providedIn: 'root' })
export class ScenarioCompletionService {
  private statusSubject = new BehaviorSubject<StepStatusManifest>(
    this.initManifestWithEmpty(),
  );

  getFilledStatus(): StepStatusManifest {
    return this.statusSubject.getValue();
  }

  setForGeneral(status: FilledStatus): void {
    this.setForStep(ScenarioWizardStep.general, status);
  }

  setForEquipment(status: FilledStatus): void {
    this.setForStep(ScenarioWizardStep.equipment, status);
  }

  setForMobility(status: FilledStatus): void {
    this.setForStep(ScenarioWizardStep.mobility, status);
  }

  setForLaunch(status: FilledStatus): void {
    this.setForStep(ScenarioWizardStep.launch, status);
  }

  selectStatusFor(step: ScenarioWizardStep): Observable<FilledStatus> {
    return this.statusSubject.pipe(pluck(step));
  }

  setForStep(step: ScenarioWizardStep, status: FilledStatus): void {
    const manifest = this.statusSubject.getValue();
    manifest[step] = status;
    this.statusSubject.next(manifest);
  }

  private initManifestWithEmpty(): StepStatusManifest {
    return this.getScenarioWizardSteps().reduce((acc, step) => {
      acc[step] = FilledStatus.empty;
      return acc;
    }, {} as StepStatusManifest);
  }

  private getScenarioWizardSteps(): ScenarioWizardStep[] {
    return Object.values(ScenarioWizardStep);
  }
}
