import { NgModule, ModuleWithProviders } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { libraryFeatureName } from './library-store.actions';
import { libraryReducer } from './library-store.reducer';
import { LibraryEffects } from './library-store.effects';
import { LibraryService } from './library-store.service';

@NgModule({
  imports: [
    StoreModule.forFeature(libraryFeatureName, libraryReducer),
    EffectsModule.forFeature([LibraryEffects]),
  ],
})
export class LibraryStoreModule {
  static forRoot(): ModuleWithProviders<LibraryStoreModule> {
    return {
      ngModule: LibraryStoreModule,
      providers: [LibraryService],
    };
  }
}
