import { ConfigService, HttpService } from 'prosumer-app/libs/eyes-core';
import { generateEndpoint } from 'prosumer-app/libs/eyes-shared';
import { Observable, Observer, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { CoherenceResultMapper } from 'prosumer-app/+scenario/services/mappers';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import {
  ExcelImportMessageGetAction,
  ExcelImportMessageGetFailureAction,
  ExcelImportMessageGetSuccessAction,
  MessageActions,
  MessageActionTypes,
  MessageGetAction,
  MessageGetFailureAction,
  MessageGetSuccessAction,
} from './scenario-message.actions';

@Injectable()
export class MessageEffects {
  get$: Observable<MessageActions> = createEffect(() =>
    this._action$.pipe(
      ofType(MessageActionTypes.GET),
      switchMap((action: MessageGetAction) => {
        const projId = action.payload.projectId;
        const caseId = action.payload.caseId;
        const scenarioId = action.payload.scenarioId;

        return this._http
          .get(
            generateEndpoint(
              this._config.api.baseUrl,
              this._config.api.endpoints.scenario.getMessagesSigned,
              projId,
              caseId,
              scenarioId,
            ),
          )
          .pipe(
            switchMap((downloadSignedUrl: any) => {
              if (this.isS3Link(downloadSignedUrl.url)) {
                return this.oldS3Way(downloadSignedUrl, scenarioId);
              }
              return this._http
                .get(downloadSignedUrl.url, { responseType: 'blob' })
                .pipe(
                  map(
                    (response) =>
                      new MessageGetSuccessAction(
                        scenarioId,
                        response,
                        this._translate.instant(
                          'Message.messages.getMessagesSuccess',
                        ),
                      ),
                  ),
                  catchError((errResponse: HttpErrorResponse) =>
                    of(
                      new MessageGetFailureAction(
                        (errResponse &&
                          errResponse.error &&
                          errResponse.error.error) ||
                          this._translate.instant(
                            'Message.messages.getMessagesFailure',
                          ),
                      ),
                    ),
                  ),
                );
            }),
            catchError((errResponse: HttpErrorResponse) =>
              of(
                new MessageGetFailureAction(
                  (errResponse &&
                    errResponse.error &&
                    errResponse.error.error) ||
                    this._translate.instant(
                      'Message.messages.getMessagesFailure',
                    ),
                ),
              ),
            ),
          );
      }),
    ),
  );

  private isS3Link(link: string) {
    return link.includes('s3.amazonaws');
  }

  private oldS3Way(downloadSignedUrl, scenarioId) {
    return this._http.get(downloadSignedUrl.url).pipe(
      switchMap(
        (response) =>
          new Observable((observer: Observer<any>) => {
            const readerGoneWild = new FileReader();
            readerGoneWild.onloadend = () => {
              observer.next(readerGoneWild.result);
              observer.complete();
            };
            readerGoneWild.readAsText(response);
          }),
      ),
      map(
        (response) =>
          new MessageGetSuccessAction(
            scenarioId,
            JSON.parse(response as string),
            this._translate.instant('Message.messages.getMessagesSuccess'),
          ),
      ),
      catchError((errResponse: HttpErrorResponse) =>
        of(
          new MessageGetFailureAction(
            (errResponse && errResponse.error && errResponse.error.error) ||
              this._translate.instant('Message.messages.getMessagesFailure'),
          ),
        ),
      ),
    );
  }

  getFailure$: Observable<MessageActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(MessageActionTypes.GET_FAILURE),
        tap((action: MessageGetFailureAction) =>
          this._notification.showError(action.payload.error),
        ),
      ),
    { dispatch: false },
  );

  getSuccess$: Observable<MessageActions> = createEffect(
    () => this._action$.pipe(ofType(MessageActionTypes.GET_SUCCESS)),
    { dispatch: false },
  );

  getExcelImport$: Observable<MessageActions> = createEffect(() =>
    this._action$.pipe(
      ofType(MessageActionTypes.GET_EXCEL_IMPORT),
      switchMap((action: ExcelImportMessageGetAction) => {
        const projId = action.payload.projectId;
        const caseId = action.payload.caseId;
        const scenarioId = action.payload.scenarioId;

        return this._http
          .get(
            generateEndpoint(
              this._config.api.baseUrl,
              this._config.api.endpoints.scenario.getImportMessagesSigned,
              projId,
              caseId,
              scenarioId,
            ),
          )
          .pipe(
            switchMap((downloadSignedUrl: any) =>
              this._http.get(downloadSignedUrl.url).pipe(
                switchMap(
                  (response) =>
                    new Observable((observer: Observer<any>) => {
                      const fileReader = new FileReader();
                      fileReader.onloadend = () => {
                        observer.next(fileReader.result);
                        observer.complete();
                      };
                      fileReader.readAsText(response);
                    }),
                ),
                map((response) => {
                  const { messages } = JSON.parse(response as string);
                  return new ExcelImportMessageGetSuccessAction(
                    scenarioId,
                    {
                      isImportMessage: true,
                      messages: messages.map(
                        CoherenceResultMapper.mapToFrontend,
                      ),
                    },
                    this._translate.instant(
                      'Message.messages.getImportMessagesSuccess',
                    ),
                  );
                }),
                catchError((errResponse: HttpErrorResponse) =>
                  of(
                    new ExcelImportMessageGetFailureAction(
                      (errResponse &&
                        errResponse.error &&
                        errResponse.error.error) ||
                        this._translate.instant(
                          'Message.messages.getImportMessagesFailure',
                        ),
                    ),
                  ),
                ),
              ),
            ),
            catchError((errResponse: HttpErrorResponse) =>
              of(
                new ExcelImportMessageGetFailureAction(
                  (errResponse &&
                    errResponse.error &&
                    errResponse.error.error) ||
                    this._translate.instant(
                      'Message.messages.getImportMessagesFailure',
                    ),
                ),
              ),
            ),
          );
      }),
    ),
  );

  getExcelImportFailure$: Observable<MessageActions> = createEffect(
    () =>
      this._action$.pipe(
        ofType(MessageActionTypes.GET_EXCEL_IMPORT_FAILURE),
        tap((action: ExcelImportMessageGetFailureAction) =>
          this._notification.showError(action.payload.error),
        ),
      ),
    { dispatch: false },
  );

  getExcelImportSuccess$: Observable<MessageActions> = createEffect(
    () =>
      this._action$.pipe(ofType(MessageActionTypes.GET_EXCEL_IMPORT_SUCCESS)),
    { dispatch: false },
  );

  /**
   * Inject them beaches!
   */
  constructor(
    private _action$: Actions,
    private _config: ConfigService,
    private _http: HttpService,
    private _notification: NotificationsService,
    private _translate: TranslateService,
  ) {}
}
