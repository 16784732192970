/* eslint-disable @typescript-eslint/naming-convention */
import { Utils } from 'prosumer-core/utils';

import { TOPOLOGY_YEARLY_KEYS, TopologyResult, ValueType } from '../models';
import { BaseExtractor } from './base.extractor';

export class TopologyExtractor implements BaseExtractor<TopologyResult> {
  extract(resultsJson: unknown): TopologyResult[] {
    const years = Utils.resolveToEmptyArray(
      resultsJson['all_years'],
    ) as number[];
    return this.extractLineInvestments(resultsJson['line_investments'], years);
  }

  private extractLineInvestments(
    data: unknown = {},
    allYears: number[],
  ): TopologyResult[] {
    return Object.values(data).reduce(
      (acc, current) => [
        ...acc,
        ...this.buildYearlyData(current, allYears, ValueType.CUMULATIVE),
        ...this.buildYearlyData(current, allYears, ValueType.INCREMENTAL),
      ],
      [],
    );
  }

  private buildYearlyData(
    entry: unknown,
    allYears: number[],
    valueType: ValueType,
  ): TopologyResult[] {
    return allYears.map((year) => this.buildData(entry, year, valueType));
  }

  private buildData(
    entry: unknown,
    year: number,
    valueType: ValueType,
  ): TopologyResult {
    return {
      year,
      valueType,
      name: entry['name'],
      origin_node: entry['origin_node'],
      destination_node: entry['destination_node'],
      fluid: entry['fluid'],
      ...this.buildYearlyValues(entry, year, valueType),
    } as TopologyResult;
  }

  private buildYearlyValues(
    entry: unknown,
    year: number,
    valueType: ValueType,
  ): Partial<TopologyResult> {
    return TOPOLOGY_YEARLY_KEYS.reduce((acc, current) => {
      const jsonKey = `${valueType}_${current}`;
      const value = Utils.resolveToEmptyObject(entry[jsonKey])[String(year)];
      acc[current] = !!value ? value : 0;
      return acc;
    }, {});
  }
}
