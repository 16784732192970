import { type } from 'prosumer-app/libs/eyes-shared';

/* eslint-disable @typescript-eslint/naming-convention */
import { Action } from '@ngrx/store';

import { ScenarioBinData } from './scenarioBin-store.model';

export const scenarioBinFeatureName = 'scenarioBin';

export const ScenarioBinActionTypes = {
  SAVE_SCENARIO_BIN: type(`[${scenarioBinFeatureName}] Save Scenario Bin Data`),
  SAVE_SCENARIO_BIN_FAILURE: type(
    `[${scenarioBinFeatureName}] Save Scenario Bin Data Failure`,
  ),
  SAVE_SCENARIO_BIN_SUCCESS: type(
    `[${scenarioBinFeatureName}] Save Scenario Bin DataSuccess`,
  ),
  GET_SCENARIO_BIN: type(`[${scenarioBinFeatureName}] GET Scenario Bin Data`),
  GET_SCENARIO_BIN_FAILURE: type(
    `[${scenarioBinFeatureName}] GET Scenario Bin Data Failure`,
  ),
  GET_SCENARIO_BIN_SUCCESS: type(
    `[${scenarioBinFeatureName}] GET Scenario Bin DataSuccess`,
  ),
  DELETE_SCENARIO_BIN: type(`[${scenarioBinFeatureName}] Delete Scenario Bin`),
  DELETE_SCENARIO_BIN_FAILURE: type(
    `[${scenarioBinFeatureName}] Delete Scenario Bin Failure`,
  ),
  DELETE_SCENARIO_BIN_SUCCESS: type(
    `[${scenarioBinFeatureName}] Delete Scenario Bin Success`,
  ),
  DELETE_ALL_SCENARIO_BIN: type(
    `[${scenarioBinFeatureName}] Delete Scenario All Bin`,
  ),
  DELETE_ALL_SCENARIO_BIN_FAILURE: type(
    `[${scenarioBinFeatureName}] Delete Scenario All Bin Failuer`,
  ),
  DELETE_ALL_SCENARIO_BIN_SUCCESS: type(
    `[${scenarioBinFeatureName}] Delete Scenario All Bin Success`,
  ),
  CLEAR_SCENARIO_BIN_CACHE: type(
    `[${scenarioBinFeatureName}] Clear Scenario Bin Cache`,
  ),
};

export class ScenarioBinSaveAction implements Action {
  readonly type = ScenarioBinActionTypes.SAVE_SCENARIO_BIN;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    location: string;
    localId: string;
    data: ScenarioBinData;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    location: string,
    localId: string,
    data: ScenarioBinData,
  ) {
    this.payload = { projectId, caseId, scenarioId, location, localId, data };
  }
}

export class ScenarioBinSaveSuccessAction implements Action {
  readonly type = ScenarioBinActionTypes.SAVE_SCENARIO_BIN_SUCCESS;
  readonly payload: { data: ScenarioBinData; message: string; notify: boolean };
  constructor(data: any, message: string, notify: boolean) {
    this.payload = {
      data,
      message,
      notify,
    };
  }
}

export class ScenarioBinSaveFailureAction implements Action {
  readonly type = ScenarioBinActionTypes.SAVE_SCENARIO_BIN_FAILURE;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    location: string;
    localId: string;
    error: string;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    location: string,
    localId: string,
    error: string,
  ) {
    this.payload = {
      projectId,
      caseId,
      scenarioId,
      location,
      localId,
      error,
    };
  }
}

export class ScenarioBinGetAction implements Action {
  readonly type = ScenarioBinActionTypes.GET_SCENARIO_BIN;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    location: string;
    localId: string;
    isCustom: boolean;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    location: string,
    localId: string,
    isCustom: boolean,
  ) {
    this.payload = {
      projectId,
      caseId,
      scenarioId,
      location,
      localId,
      isCustom,
    };
  }
}

export class ScenarioBinGetFailureAction implements Action {
  readonly type = ScenarioBinActionTypes.GET_SCENARIO_BIN_FAILURE;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    location: string;
    localId: string;
    isCustom: boolean;
    error: string;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    location: string,
    localId: string,
    isCustom: boolean,
    error: string,
  ) {
    this.payload = {
      projectId,
      caseId,
      scenarioId,
      location,
      localId,
      isCustom,
      error,
    };
  }
}

export class ScenarioBinGetSuccessAction implements Action {
  readonly type = ScenarioBinActionTypes.GET_SCENARIO_BIN_SUCCESS;
  readonly payload: {
    data: ScenarioBinData;
    message: string;
    notify: boolean;
    cached: boolean;
  };
  constructor(
    data: ScenarioBinData,
    message: string,
    notify: boolean,
    cached?: boolean,
  ) {
    this.payload = {
      data,
      message,
      notify,
      cached,
    };
  }
}

export class ScenarioDeleteBinAction implements Action {
  readonly type = ScenarioBinActionTypes.DELETE_SCENARIO_BIN;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    location: string;
    localId: string;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    location: string,
    localId: string,
  ) {
    this.payload = { projectId, caseId, scenarioId, location, localId };
  }
}

export class ScenarioDeleteBinFailureAction implements Action {
  readonly type = ScenarioBinActionTypes.DELETE_SCENARIO_BIN_FAILURE;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    location: string;
    localId: string;
    error: string;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    location: string,
    localId: string,
    error: string,
  ) {
    this.payload = { projectId, caseId, scenarioId, location, localId, error };
  }
}

export class ScenarioDeleteBinSuccessAction implements Action {
  readonly type = ScenarioBinActionTypes.DELETE_SCENARIO_BIN_SUCCESS;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    message: string;
    notify: boolean;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    message: string,
    notify: boolean,
  ) {
    this.payload = { projectId, caseId, scenarioId, message, notify };
  }
}
export class ScenarioDeleteAllBinAction implements Action {
  readonly type = ScenarioBinActionTypes.DELETE_ALL_SCENARIO_BIN;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    data: any;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    data: any,
  ) {
    // Workaround due to old implem of bin store
    const mappedData = data.map((item) => ({
      local_id: item.localId,
      location: item.location,
    }));
    this.payload = { projectId, caseId, scenarioId, data: mappedData };
  }
}

export class ScenarioDeleteAllBinFailureAction implements Action {
  readonly type = ScenarioBinActionTypes.DELETE_ALL_SCENARIO_BIN_FAILURE;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    data: any;
    error: string;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    data: any,
    error: string,
  ) {
    this.payload = { projectId, caseId, scenarioId, data, error };
  }
}

export class ScenarioDeleteAllBinSuccessAction implements Action {
  readonly type = ScenarioBinActionTypes.DELETE_ALL_SCENARIO_BIN_SUCCESS;
  readonly payload: {
    projectId: string;
    caseId: string;
    scenarioId: string;
    data: Array<any>;
    message: string;
    notify: boolean;
  };
  constructor(
    projectId: string,
    caseId: string,
    scenarioId: string,
    data: Array<any>,
    message: string,
    notify: boolean,
  ) {
    this.payload = { projectId, caseId, scenarioId, data, message, notify };
  }
}

export class ScenarioBinClearCacheAction implements Action {
  readonly type = ScenarioBinActionTypes.CLEAR_SCENARIO_BIN_CACHE;
  constructor() {}
}

export type ScenarioBinStoreActions =
  | any
  | ScenarioBinSaveAction
  | ScenarioBinSaveFailureAction
  | ScenarioBinSaveSuccessAction
  | ScenarioBinGetFailureAction
  | ScenarioBinGetAction
  | ScenarioBinGetSuccessAction
  | ScenarioDeleteBinAction
  | ScenarioDeleteBinFailureAction
  | ScenarioDeleteBinSuccessAction
  | ScenarioDeleteAllBinAction
  | ScenarioDeleteAllBinFailureAction
  | ScenarioDeleteAllBinSuccessAction;
