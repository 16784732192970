@if (scenario) {
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    [ngClass]="'scenario-name-div-' + infoStyle"
  >
    <div
      fxLayout="row"
      fxLayoutGap="4px"
      fxLayoutAlign="center start"
      [class.unclickable]="!allowScenarioNameClick"
      [class.clickable]="allowScenarioNameClick"
      (mouseenter)="hovering = true"
      (mouseleave)="hovering = false"
      *ngIf="!clicked; else editName"
      data-testid="viewName"
      [ngClass]="'scenario-name-' + infoStyle"
    >
      <span
        data-testid="scenarioName"
        (click)="onClickScenarioName(); $event.stopPropagation()"
      >
        {{ scenario.name }}
      </span>
      <div
        *ngIf="!editable || !hovering; else editIconContainer"
        class="edit-icon"
      ></div>
      <ng-template #editIconContainer>
        <div *ngIf="hovering" class="edit-icon">
          <mat-icon
            aria-hidden="false"
            *ngIf="!isEditable(); else editIcon"
            class="disabled-icon"
            [inline]="true"
            data-testid="editButtonDisabled"
            >edit_off</mat-icon
          >
          <ng-template #editIcon>
            <mat-icon
              aria-hidden="false"
              data-testid="editButton"
              (click)="switchToEdit(); $event.stopPropagation()"
              [inline]="true"
              color="primary"
              >edit</mat-icon
            >
          </ng-template>
        </div>
      </ng-template>
    </div>

    <ng-template #editName>
      <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center">
        <mat-form-field appearance="outline">
          <mat-label>{{
            'Scenario.labels.general.name' | translate
          }}</mat-label>
          <input
            matInput
            type="text"
            data-testid="editName"
            [formControl]="nameControl"
            (click)="tooltip.show(); $event.stopPropagation()"
            (keyup)="tooltip.show()"
          />
          <div
            #tooltip="matTooltip"
            matTooltipClass="error-message-tooltip"
            [matTooltipDisabled]="!getErrorKey(nameControl)"
            [matTooltip]="
              'Scenario.messages.tooltip.name.' + getErrorKey(nameControl)
                | translate
            "
          ></div>
          <!-- <mat-error
            *ngFor="let error of getErrorKeys(nameControl)"
            [innerHTML]="'Scenario.messages.tooltip.name.' + error | translate"
          ></mat-error> -->
        </mat-form-field>
        <button
          mat-icon-button
          color="primary"
          data-testid="confirmChange"
          *ngrxLet="updating$ as updating"
          (click)="confirmChanges(); $event.stopPropagation()"
          [disabled]="updating || nameControl.invalid || nameControl.pristine"
          [class.spinner]="updating"
        >
          <mat-icon aria-hidden="false">check_circle</mat-icon>
        </button>
      </div>
    </ng-template>
  </div>
}
