import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ConfigService,
  DialogService,
  HttpService,
} from 'prosumer-app/libs/eyes-core';
import {
  BaseDialogComponent,
  generateEndpoint,
} from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { Scenario } from '../../../+scenario/models';

@Injectable({
  providedIn: 'root',
})
export class ConflictCheckerService {
  constructor(
    private _dialog: DialogService,
    private _http: HttpService,
    private _config: ConfigService,
    private _translate: TranslateService,
  ) {}

  checkingConflicts$ = new BehaviorSubject<boolean>(false);

  showOverwriteWarningDialog(updatedBy?: string): Observable<any> {
    return this._dialog.openDialog(BaseDialogComponent, {
      title: this._translate.instant('Generic.labels.overwriteWarning'),
      message: `<b>${updatedBy || 'Someone'}</b> ${this._translate.instant(
        'Generic.messages.overwriteWarning',
      )}`,
      confirm: this._translate.instant('Generic.labels.yes'),
      close: this._translate.instant('Generic.labels.no'),
      width: 400,
    });
  }

  showForceReloadDialog(updatedBy?: string): Observable<any> {
    return this._dialog.openDialog(BaseDialogComponent, {
      title: this._translate.instant('Generic.labels.scenarioForceReload'),
      message: `<b>${updatedBy || 'Someone'}</b> ${this._translate.instant(
        'Generic.messages.scenarioForceReload',
      )}`,
      confirm: this._translate.instant('Generic.labels.ok'),
      width: 400,
    });
  }

  /**
   * Checks conflicts by comparing scenarios updatedDates
   * retrieved from local and in the database. If updatedDate and updatedById
   * are unequal, it will be determined as conflicting scenarios and will show
   * a warning dialog asking for user's confirmation as a result
   *
   * @param scenario - scenario retrieved from local state
   * @returns boolean
   */
  checkConflictAndConfirmSave(scenario: Scenario) {
    let updatedBy: string;
    return this.getUpdatedScenario(
      scenario.projectId,
      scenario.caseId,
      scenario.id,
    ).pipe(
      map((scenarioFromDb: Scenario) => {
        updatedBy = scenarioFromDb.updatedBy;
        return this.checkForConflict(scenario, scenarioFromDb);
      }),
      mergeMap((hasConflict) => {
        if (hasConflict) {
          return this.showOverwriteWarningDialog(updatedBy).pipe(
            map((dialogResult) => !!dialogResult),
          );
        }
        return of(true);
      }),
    );
  }

  /**
   * Checks for conflict by comparing scnearios updatedDate
   * retrieved from local and in the database. If updatedDates are unequal,
   * it will be determined as conflicting scenarios
   * and will show a dialog
   *
   * @param scenario - scenario retrieved from local state
   * @returns - a Observable<boolean> that determines if scenarios has conflicts
   */
  checkConflictAndConfirmReload(scenario: Scenario): Observable<boolean> {
    let updatedBy: string;
    return this.getUpdatedScenario(
      scenario.projectId,
      scenario.caseId,
      scenario.id,
    ).pipe(
      take(1),
      mergeMap((scenarioFromDb) => {
        updatedBy = scenarioFromDb.updatedBy;
        const hasConflict = this.checkForConflict(scenario, scenarioFromDb);
        if (hasConflict) {
          return this.showForceReloadDialog(updatedBy).pipe(
            map((dialogResult) => !!dialogResult),
          );
        }
        return of(false);
      }),
    );
  }

  /**
   * Checks for conflict by comparing updatedDate property
   *
   * @param userId - id of client user
   * @param localScenario - scenario retrieved from local state
   * @param scenarioFromDb - scenario response from get scenario endpoint
   * @returns boolean
   */
  checkForConflict(localScenario: Scenario, scenarioFromDb: any): boolean {
    // both scenarios are conflict if their updatedDate/updatedAt are unequal
    this.checkingConflicts$.next(false);
    // return localScenario.updatedDate !== scenarioFromDb.updatedAt;
    return localScenario.updatedBy !== scenarioFromDb.updatedBy;
  }

  /**
   * Http request to retrieve
   * scenario details from the database
   * without saving to local state
   *
   * @param projectId - id of project
   * @param caseId - id of case
   * @param scenarioId - id of scenario
   */
  getUpdatedScenario(
    projectId: string,
    caseId: string,
    scenarioId: string,
  ): Observable<any> {
    this.checkingConflicts$.next(true);
    const endpoint = generateEndpoint(
      this._config.api.baseUrl,
      this._config.api.endpoints.scenario.get,
      projectId,
      caseId,
      scenarioId,
    );
    return this._http.get(endpoint);
  }
}
