import { RunMap } from '../../models';

import { EXECUTION_STATUS } from 'prosumer-app/app.references';

export class RunMapMapper {
  static mapToBackend = (runMap: RunMap) =>
    !!runMap
      ? {
          run: { ...runMap },
          launchDate: runMap.launchDate,
        }
      : {};

  static mapToFrontend = (scenario: { run; launchDate }): RunMap =>
    !!scenario
      ? {
          ...scenario.run,
          launchDate: scenario.launchDate,
          status:
            !(scenario.run || {}).status ||
            scenario.run.status === EXECUTION_STATUS.NOT_READY
              ? EXECUTION_STATUS.DRAFT
              : (scenario.run || {}).status,
        }
      : {};
}
