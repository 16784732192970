import { CaseFacadeService } from 'prosumer-app/+case/state';
import { ProjectFacadeService } from 'prosumer-app/+project/state';
import { ScenarioApiService } from 'prosumer-app/+scenario/services';
import {
  CustomValidators,
  doNothing,
  FormFieldOption,
  FormService,
} from 'prosumer-app/libs/eyes-shared';
import { DuplicateDialogComponent } from 'prosumer-shared/components/dialogs/duplicate-dialog';
import { NameValidator } from 'prosumer-shared/validators/name';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'prosumer-scenario-duplicate-dialog',
  templateUrl: './scenario-duplicate-dialog.component.html',
  styleUrls: ['./scenario-duplicate-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioDuplicateDialogComponent
  extends DuplicateDialogComponent
  implements OnInit, AfterViewInit
{
  duplicateEntityForm: UntypedFormGroup = this.formService.initForm({
    duplicateName: [
      `${this.data.duplicateName}_copy`,
      [Validators.required, NameValidator.validWithCore()],
    ],
    project: '',
    case: '',
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ScenarioDuplicateDialogComponent>,
    public formService: FormService,
    public projectFacade: ProjectFacadeService,
    public caseFacade: CaseFacadeService,
    public scenarioService: ScenarioApiService,
  ) {
    super(data, dialogRef, formService);
  }

  projectLoadingList$ = this.projectFacade.loadingList$.pipe(
    this.takeUntilShare(),
  );
  projectLoadedList$ = this.projectFacade.loadedList$.pipe(
    this.takeUntilShare(),
  );
  projects$ = this.projectFacade.dataList$.pipe(this.takeUntilShare());
  projectOptions$: Observable<Array<FormFieldOption<any>>> =
    this.projects$.pipe(
      map((projects) => projects.map((d) => ({ name: d.name, value: d.id }))),
    );

  caseLoadingList$ = this.caseFacade.loadingList$.pipe(this.takeUntilShare());
  caseLoadedList$ = this.caseFacade.loadedList$.pipe(this.takeUntilShare());
  cases$ = this.caseFacade.dataList$.pipe(this.takeUntilShare());
  caseListData$ = this.cases$.pipe(this.takeUntilShare());

  _namesList$ = new BehaviorSubject<Array<any>>([]);
  namesList$ = this._namesList$.asObservable();
  _loadingNames$ = new BehaviorSubject<boolean>(false);
  loadingNames$ = this._loadingNames$.asObservable();
  _caseOptions$ = new BehaviorSubject<Array<any>>([]);
  caseOptions$ = this._caseOptions$.asObservable();

  ngOnInit() {
    if (!this.data) {
      return;
    }

    this.initForm();
    this.loadExistingNames();
    this.initHandlers();
    this.loadCaseOptions();
  }

  ngAfterViewInit() {
    this.initFormHandlers();
  }

  initForm() {
    // patch default values
    this.duplicateEntityForm.controls.duplicateName.patchValue(
      `${this.data.duplicateName}_copy`,
    );
    this.duplicateEntityForm.controls.project.patchValue(
      this.data.scenario.projectId,
    );
    this.duplicateEntityForm.controls.case.patchValue(
      this.data.scenario.caseId,
    );
  }

  /**
   * Load initial select values for projects and cases
   */
  initHandlers() {
    this.projectLoadedList$
      .pipe(take(1))
      .subscribe((loadedList) =>
        !loadedList ? this.projectFacade.getList() : doNothing(),
      );

    this.caseLoadedList$
      .pipe(take(1))
      .subscribe((loadedList) =>
        !loadedList
          ? this.caseFacade.getList(this.data.scenario.projectId)
          : doNothing(),
      );

    // once names have been loaded, set the validators
    this.loadingNames$
      .pipe(this.takeUntil())
      .subscribe((done) => (done ? this.setValidators() : doNothing()));
  }

  initFormHandlers() {
    // reload duplicate form data on project select
    this.duplicateEntityForm.controls.project.valueChanges
      .pipe(this.takeUntil())
      .subscribe((project) => {
        this.caseFacade.getList(project);
        this.duplicateEntityForm.controls.case.patchValue('');
        this.duplicateEntityForm.controls.duplicateName.patchValue(
          `${this.data.duplicateName}_copy`,
        );
        this.loadCaseOptions();
      });
    // update the name list for checking of valid names on every case select
    this.duplicateEntityForm.controls.case.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() => {
        if (
          this.duplicateEntityForm.controls.case.value &&
          this.duplicateEntityForm.controls.project.value
        ) {
          this.loadExistingNames();
        }
      });
  }

  /**
   * This function will update the case option behavior and shall reflect changes in case select
   */
  loadCaseOptions(): void {
    this.caseListData$.pipe(this.takeUntil()).subscribe((cases) => {
      const _case = cases
        .filter(
          (data) =>
            data.projectId ===
              this.duplicateEntityForm.controls.project.value &&
            data.nodeType === this.data.selectedCaseNodeType,
        )
        .map((c) => ({ name: c.name, value: c.id }));
      this._caseOptions$.next(_case);
    });
  }

  /**
   * On init and on case selection, the names list for name validation is reloaded
   */
  loadExistingNames(): void {
    this._loadingNames$.next(true);
    this.scenarioService
      .getScenarioNamesList(this.duplicateEntityForm.controls.case.value, {
        projectId: this.duplicateEntityForm.controls.project.value,
      })
      .pipe(take(1))
      .subscribe((names) => {
        this._namesList$.next(names);
        this._loadingNames$.next(false);
      });
  }

  setValidators(): void {
    this.duplicateEntityForm.controls.duplicateName.setAsyncValidators(
      CustomValidators.dataExist(this.namesList$, 'name'),
    );
  }

  /**
   * Once form is valid and is submitted, the dialog will return the user data
   */
  onFinish(isOkay: boolean): void {
    this.duplicateEntityForm.controls.duplicateName.updateValueAndValidity();
    if (isOkay) {
      if (this.duplicateEntityForm.invalid) {
        return;
      }
      this.dialogRef.close({
        ...this.data,
        duplicateName: this.duplicateEntityForm.getRawValue()['duplicateName'],
        projectId: this.duplicateEntityForm.getRawValue()['project'],
        caseId: this.duplicateEntityForm.getRawValue()['case'],
      });
    } else {
      this.dialogRef.close();
    }
  }
}
