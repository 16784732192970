import {
  NO_VARIATION,
  ScenarioVariationQuery,
} from 'prosumer-app/stores/scenario-variation';
import { Coerce } from 'prosumer-core/utils';

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class VariationFinder {
  constructor(private readonly variations: ScenarioVariationQuery) {}

  getVariationName(id: string): string {
    return Coerce.toString(this.variations.getName(id), NO_VARIATION);
  }
}
