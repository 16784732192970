import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { BillingApiService } from './services';
import {
  BillingFacadeService,
  BillingEffects,
  billingFeature,
  billingReducer,
} from './state';

@NgModule({
  imports: [
    StoreModule.forFeature(billingFeature, billingReducer),
    EffectsModule.forFeature([BillingEffects]),
  ],
  providers: [BillingApiService, BillingFacadeService],
})
export class BillingModule {}
