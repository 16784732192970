import { StateFactory } from 'prosumer-libs/eyes-shared';
import { Scenario } from 'prosumer-scenario/models';

import { ScenarioState } from './scenario-state.model';

export const scenarioFeature = 'Scenario';
export const scenarioStateFactory = new StateFactory<ScenarioState, Scenario>(
  scenarioFeature,
  'id',
  'name',
  {
    ids: [],
    entities: {},
  },
);
