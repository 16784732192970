import { Library, LibraryLoads } from 'prosumer-app/+scenario/models';
import { PREDEFINED_ENERGY_VECTOR_MAP } from 'prosumer-app/app.references';
import { toTitleCase } from 'prosumer-app/libs/eyes-shared';

// The library data as state store and cache
export interface LibraryCache {
  [id: string]: Library;
}
export interface LoadsCache {
  [id: string]: LibraryLoads;
}

// The library store state model
export type LibraryState = Readonly<{
  loading?: boolean;
  library?: LibraryCache;
  loads?: LoadsCache;
  error?: any;
}>;

function mapParameters(library: any): any {
  const libParams = [];
  if (library['parameters']) {
    const parameters = library['parameters'];
    libParams.push({
      capacityLoss: parameters['technical'].capacityLoss || undefined,
      secondCapacityLoss: parameters['technical'].capacityLoss2 || undefined,
      comment: parameters['technical'].comment || undefined,
      source: parameters['technical'].source || undefined,
      maxDoD: parameters['technical'].maxDod || undefined,
      minEp: parameters['technical'].minEp || undefined,
      maxEp: parameters['technical'].maxEp || undefined,
      dissipationRate: parameters['technical'].dissipationRate || undefined,
      agingFactor: parameters['technical'].agingFactor || undefined,
      chargeDischargeRateFactor:
        parameters['technical'].chargingDischargingRateFactor || undefined,
      roundTripEfficiency: parameters['technical'].efficiency || undefined,
      efficiencyPmin: parameters['technical'].efficiencyPmin || undefined,
      efficiencyPmax: parameters['technical'].efficiencyPmax || undefined,
      minUpTime: parameters['technical'].minimumUptime || undefined,
      minDownTime: parameters['technical'].minimumDowntime || undefined,
      pmin: parameters['technical'].pmin || undefined,
      efficiency: parameters['technical'].efficiency || undefined,
      secondEfficiency: parameters['technical'].efficiency1o2 || undefined,
      individualSize: parameters['technical'].individualSize || undefined,
      startUpLoss: parameters['technical'].startUpLoss || undefined,
      minRunningTime: parameters['technical'].minimumRunningtime || undefined,
      lineStorageCapacity:
        parameters['technical'].lineStorageCapacity || undefined,
      idleLoss: parameters['technical'].idleLoss || undefined,
      additionalCost: parameters['economical'].additionalCost || undefined,
      additionalCost1: parameters['economical'].additionalCost || undefined,
      additionalCost2: parameters['economical'].additionalCost2 || undefined,
      buildCost: parameters['economical'].buildCost || undefined,
      secondBuildCost: parameters['economical'].buildCost2 || undefined,
      thirdBuildCost: parameters['economical'].buildCost3 || undefined,
      depreciationTime: parameters['economical'].depreciationTime || undefined,
      fOAndMCharge: parameters['economical'].fomCharge || undefined,
      fOAndMChargeKwh: parameters['economical'].fomCharge2 || undefined,
      fOAndMPerInstall: parameters['economical'].fomPerInstall || undefined,
      indivisibleCost: parameters['economical'].indivisibleCost || undefined,
      technicalLife: parameters['economical'].technicalLife || undefined,
      comment2: parameters['economical'].comment2 || undefined,
      source2: parameters['economical'].source2 || undefined,
      stackLifetime: parameters['economical'].stackLifetime || undefined,
      runningCost: parameters['economical'].runningCost || undefined,
      footprint: parameters['sizing'].footprint || undefined,
      maxAvailableFootprint: parameters['sizing'].maxFootprint || undefined,
      minPower: parameters['sizing'].minimumPower || undefined,
      maxPower: parameters['sizing'].maximumPower || undefined,
      comment3: parameters['sizing'].comment3 || undefined,
      source3: parameters['sizing'].source3 || undefined,
      minEnergy: parameters['sizing'].minimumEnergy || undefined,
      maxEnergy: parameters['sizing'].maximumEnergy || undefined,
      forcedInvestment: parameters['sizing'].forcedInvestment || undefined,
    });
  }
  return libParams;
}

export const mapLibraryLoadsToFrontend = (libraryLoads: any): LoadsCache => {
  const loadsCache: LoadsCache = {};
  loadsCache[libraryLoads.id] = {
    id: libraryLoads.id,
    location: libraryLoads.location,
    data: libraryLoads.data,
  };
  return loadsCache;
};

const mapEfficiencies = (item) => {
  const efficiencies = {};
  const { energyVector } = item;
  let i = 1;
  let j = 1;
  const o = 'o';
  energyVector['in']
    .filter((e) => e.id)
    .forEach((energyIn) => {
      efficiencies[energyIn.id] = {};
      energyVector['out']
        .filter((e) => e.id)
        .forEach((energyOut) => {
          let key = 'efficiency' + i + o + j;
          if (key === 'efficiency1o1') {
            key = 'efficiency';
          }
          efficiencies[energyIn.id][energyOut.id] =
            item.parameters.technical[key];
          j++;
        });
      i++;
    });
  return efficiencies;
};

/**
 * Maps the library model from backend to the frontend model
 *
 * @param libraryList the response list of libraries from backend
 */
export const mapLibraryToFrontend = (libraryList: Array<any>): LibraryCache => {
  const library: LibraryCache = {};
  libraryList.forEach((libraryItem) => {
    const energyVectorIn = libraryItem.energyVector['in']
      ? libraryItem.energyVector['in']
      : undefined;
    const energyVectorOut = libraryItem.energyVector['out']
      ? libraryItem.energyVector['out']
      : undefined;
    const libParams = mapParameters(libraryItem);
    const efficiencies =
      libraryItem.libraryType === 'der_converter'
        ? mapEfficiencies(libraryItem)
        : [];
    library[libraryItem.id] = {
      // Library Information
      type: libraryItem.libraryType.split('_')[1] || libraryItem.libraryType,
      id: libraryItem.id,
      group: libraryItem.group ? libraryItem.group : undefined,
      description: libraryItem.description
        ? libraryItem.description
        : undefined,
      country: libraryItem.country ? libraryItem.country : undefined,
      year: libraryItem.year ? libraryItem.year : undefined,
      scale: libraryItem.scale ? libraryItem.scale : undefined,
      reference: libraryItem.reference ? libraryItem.reference : undefined,
      updatedAt: libraryItem.updatedAt,
      // Energy Vectors
      energyVector: ((energyVectorIn || [{}])[0] || {}).id || 'none', // energyVectorIn ? energyVectorIn[0].id : undefined,
      secondEnergyVector: ((energyVectorIn || [{}, {}])[1] || {}).id || 'none',
      thirdEnergyVector:
        ((energyVectorIn || [{}, {}, {}])[2] || {}).id || 'none',
      outputEnergyVector: ((energyVectorOut || [{}])[0] || {}).id || 'none',
      secondOutputEnergyVector:
        ((energyVectorOut || [{}, {}])[1] || {}).id || 'none',
      thirdOutputEnergyVector:
        ((energyVectorOut || [{}, {}, {}])[2] || {}).id || 'none',
      energyVectorsIn: !!energyVectorIn
        ? energyVectorIn.filter((ev) => ev.id !== null).map((ev) => ev.id)
        : [],
      energyVectorsOut: !!energyVectorOut
        ? energyVectorOut.filter((ev) => ev.id !== null).map((ev) => ev.id)
        : [],
      energyVectorNamesIn: !!energyVectorIn
        ? energyVectorIn
            .filter((ev) => ev.id !== null)
            .map((ev) => toTitleCase(PREDEFINED_ENERGY_VECTOR_MAP[ev.id]))
        : [],
      energyVectorNamesOut: !!energyVectorOut
        ? energyVectorOut
            .filter((ev) => ev.id !== null)
            .map((ev) => toTitleCase(PREDEFINED_ENERGY_VECTOR_MAP[ev.id]))
        : [],
      // Loads Parameters
      vectorType: libraryItem.load_type,
      businessType: libraryItem.businessType,
      buildingType: libraryItem.buildingType,
      buildingCategory: libraryItem.buildingCategory,
      location: libraryItem.location,
      // Parameters
      ...libParams[0],
      efficiencies,
    };
  });
  return library;
};
