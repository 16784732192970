import {
  EnergyGridConnectionBE,
  EnergyGridConnectionInfo,
} from 'prosumer-app/stores/energy-grid-connection';
import { MarketLimit, MarketLimitBE } from 'prosumer-app/stores/market-limit';
import { Coerce, Utils } from 'prosumer-core/utils';
import {
  CommoditiesForm,
  EnergyGridConnection,
  Profile,
} from 'prosumer-scenario/models';
import {
  generateShortUID,
  hasProfilesForSaving,
  hasUpdatedTDBFilters,
  mapVariationToBackend,
  mapVariationToFrontend,
  mapYearlyProfileToBackend,
  mapYearlyProfileToFrontend,
  mapYearlyValuesToBackend,
  mapYearlyValuesToFrontend,
} from 'prosumer-shared/utils';

export class CommoditiesMapper {
  static egcToBE(grid: EnergyGridConnection): EnergyGridConnectionBE {
    const profiles = Utils.resolveToEmptyArray(grid.profiles);
    return {
      egcId: grid.id,
      name: grid.marketName,
      nodeId: { nodeIds: Utils.resolveToEmptyArray(grid.nodes) },
      type: grid.type,
      deliveryEnergyVectorId: grid.delivery,
      scenarioVariation: mapVariationToBackend(grid),
      tdbFilters: grid.tdbFilters,
      energyTariff: {
        name: 'Energy Tariff Loads',
        profiles: profiles.map((profile) => mapYearlyProfileToBackend(profile)),
      },
      capacityTariff: mapYearlyValuesToBackend(grid.capacityTariff),
      connectionTariff: mapYearlyValuesToBackend(grid.connectionTariff),
      profileUpdated: hasUpdatedTDBFilters(
        Coerce.toObject(grid.tdbFilters),
        hasProfilesForSaving(profiles),
      ),
    };
  }

  static egcToFE(grid: EnergyGridConnectionBE): EnergyGridConnectionInfo {
    return {
      scenarioUuid: grid.scenarioUuid,
      id: grid.egcId,
      marketName: grid.name,
      nodes: Utils.resolveToEmptyObject(
        Utils.parseYearlyValuesFromBE(grid.nodeId),
      ).nodeIds,
      delivery: grid.deliveryEnergyVectorId,
      type: grid.type,
      scenarioVariation: mapVariationToFrontend(grid),
      tdbFilters: grid.tdbFilters,
      capacityTariff: mapYearlyValuesToFrontend(
        Utils.parseYearlyValuesFromBE(grid.capacityTariff),
      ),
      connectionTariff: mapYearlyValuesToFrontend(
        Utils.parseYearlyValuesFromBE(grid.connectionTariff),
      ),
      profiles: Utils.resolveToEmptyArray(
        Utils.resolveToEmptyObject(grid.energyTariff).profiles,
      ).map(
        (profile) =>
          mapYearlyProfileToFrontend(
            profile,
            'ENERGY_GRID_CONNECTION',
          ) as Profile,
      ),
    };
  }

  static egcLimitToBE(limit: MarketLimit): MarketLimitBE {
    return {
      scenarioUuid: limit.scenarioUuid,
      id: limit.id,
      marketId: limit.market,
      nodeId: { nodeIds: Utils.resolveToEmptyArray(limit.nodes) },
      marketLimitsInterval: Utils.resolveToEmptyArray(
        limit.energyGridLimitIntervals,
      ),
    };
  }

  static egcLimitToFE(limit): MarketLimit {
    return {
      scenarioUuid: limit.scenarioUuid,
      id: limit.id,
      market: limit.marketId,
      nodes: Utils.resolveToEmptyObject(
        Utils.parseYearlyValuesFromBE(limit.nodeId),
      ).nodeIds,
      energyGridLimitIntervals: Utils.resolveToEmptyArray(
        limit.marketLimitsInterval,
      ),
    };
  }

  static mapToBackend = (commoditiesForm: CommoditiesForm) =>
    !!commoditiesForm
      ? {
          energyGridConnections: Utils.resolveToEmptyArray(
            commoditiesForm.grids,
          ).map((grid) => CommoditiesMapper.egcToBE(grid)),
          energyGridConnectionsEmissions: (
            commoditiesForm.connectionsEmissions || []
          ).map((connectionsEmission) => ({
            id: connectionsEmission.id || generateShortUID(),
            name: connectionsEmission.marketName,
            nodeId: connectionsEmission.node,
            scope: connectionsEmission.scope,
            emissionsRate: {
              name: 'Emissions Rate',
              profiles: connectionsEmission.profiles.map((profile) =>
                mapYearlyProfileToBackend(profile),
              ),
            },
          })),
          marketLimits: (commoditiesForm.limits || []).map((limit) => ({
            id: limit.id || generateShortUID(),
            marketId: limit.market,
            nodeId: { nodeIds: limit.nodes || [] },
            marketLimitsInterval: limit.energyGridLimitIntervals ?? [],
          })),
          fuels: (commoditiesForm.fuels || []).map((fuel) => ({
            fuelEnergyVectorId: fuel.fuel,
            nodeId: {
              nodeIds: fuel.nodes || [],
            },
            fuelCost: mapYearlyValuesToBackend(fuel.fuelCost),
            co2Rate: mapYearlyValuesToBackend(fuel.co2Rate),
            co2Scope3EmissionFactor: mapYearlyValuesToBackend(
              fuel.co2Scope3EmissionFactor,
            ),
          })),
          emissions: {
            ...(commoditiesForm.co2Price && {
              co2Price: mapYearlyValuesToBackend(commoditiesForm.co2Price),
            }),
            ...(commoditiesForm.co2Scope2Price && {
              co2Scope2Price: mapYearlyValuesToBackend(
                commoditiesForm.co2Scope2Price,
              ),
            }),
            ...(commoditiesForm.co2Scope3Price && {
              co2Scope3Price: mapYearlyValuesToBackend(
                commoditiesForm.co2Scope3Price,
              ),
            }),
          },
        }
      : {};

  static mapToFrontend = (
    scenario: {
      energyGridConnections: Array<any>;
      energyGridConnectionsEmissions: Array<any>;
      marketLimits: Array<any>;
      fuels: Array<any>;
      emissions;
    },
    startYear?: number,
    endYear?: number,
  ): CommoditiesForm =>
    !!scenario
      ? {
          binToDelete: [],
          binToClone: [],
          grids: (scenario.energyGridConnections || []).map((grid) => ({
            id: grid.id || generateShortUID(),
            marketName: grid.name,
            nodes:
              typeof grid.nodeId === 'string'
                ? [grid.nodeId]
                : grid.nodeId.nodeIds || [],
            type: grid.type,
            delivery: grid.deliveryEnergyVectorId,
            scenarioVariation: mapVariationToFrontend(grid),
            profiles: (grid.energyTariff.profiles || []).map((profile) =>
              mapYearlyProfileToFrontend(profile, 'ENERGY_GRID_CONNECTION'),
            ),
            capacityTariff: mapYearlyValuesToFrontend(
              grid.capacityTariff,
              startYear,
              endYear,
            ),
            connectionTariff: mapYearlyValuesToFrontend(
              grid.connectionTariff,
              startYear,
              endYear,
            ),
          })),
          connectionsEmissions: (
            scenario.energyGridConnectionsEmissions || []
          ).map((connectionEmissions) => ({
            id: connectionEmissions.id || generateShortUID(),
            marketName: connectionEmissions.name,
            node: connectionEmissions.nodeId,
            scope: connectionEmissions.scope,
            profiles: (connectionEmissions.emissionsRate.profiles || []).map(
              (profile) =>
                mapYearlyProfileToFrontend(
                  profile,
                  'ENERGY_GRID_CONNECTIONS_EMISSIONS',
                ),
            ),
          })),
          limits: (scenario.marketLimits || []).map((gridLimits) => ({
            id: gridLimits.id || generateShortUID(),
            market: gridLimits.marketId,
            nodes:
              typeof gridLimits.nodeId === 'string'
                ? [gridLimits.nodeId]
                : gridLimits.nodeId.nodeIds,
            energyGridLimitIntervals: gridLimits.marketLimitsInterval || [],
          })),
          fuels: (scenario.fuels || []).map((fuel) => ({
            fuel: fuel.fuelEnergyVectorId,
            nodes:
              typeof fuel.nodeId === 'string'
                ? [fuel.nodeId]
                : fuel.nodeId.nodeIds,
            fuelCost: mapYearlyValuesToFrontend(
              fuel.fuelCost,
              startYear,
              endYear,
            ),
            co2Rate: mapYearlyValuesToFrontend(
              fuel.co2Rate,
              startYear,
              endYear,
            ),
            co2Scope3EmissionFactor: mapYearlyValuesToFrontend(
              fuel.co2Scope3EmissionFactor,
              startYear,
              endYear,
            ),
          })),
          co2Price:
            scenario.emissions && scenario.emissions.co2Price
              ? mapYearlyValuesToFrontend(
                  scenario.emissions.co2Price,
                  startYear,
                  endYear,
                )
              : undefined,
          co2Scope2Price:
            scenario.emissions && scenario.emissions.co2Scope2Price
              ? mapYearlyValuesToFrontend(
                  scenario.emissions.co2Scope2Price,
                  startYear,
                  endYear,
                )
              : undefined,
          co2Scope3Price:
            scenario.emissions && scenario.emissions.co2Scope3Price
              ? mapYearlyValuesToFrontend(
                  scenario.emissions.co2Scope3Price,
                  startYear,
                  endYear,
                )
              : undefined,
        }
      : {};
}
