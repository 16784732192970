/* eslint-disable @typescript-eslint/naming-convention */
export enum ValueType {
  CUMULATIVE = 'cumulative',
  INCREMENTAL = 'incremental',
}

export interface ResultsData {
  readonly year: number;
  readonly name: string;
  readonly value?: number;
  readonly valueType?: ValueType;
}

export const YEARLY_DETAILS_REGEX_PATTERN = /(')(?:(?=(\\?))\2.)*?\1/g;
