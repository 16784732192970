import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Coerce } from 'prosumer-app/core/utils/coercion.util';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import {
  DetailEntity,
  NameValue,
  ScenarioDetailState,
} from './scenario-detail.state';
import { ScenarioDetailStore } from './scenario-detail.store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class ScenarioDetailQuery<
  T extends DetailEntity,
> extends QueryEntity<ScenarioDetailState<T>> {
  constructor(
    store: ScenarioDetailStore<T>,
    readonly keeper: ActiveKeeperService,
  ) {
    super(store);
  }

  getAllForActiveScenario(): T[] {
    return this.onlyForActiveScenario(this.getAll());
  }

  selectAllForActiveScenario(): Observable<T[]> {
    return this.selectAll().pipe(
      map((entities) => this.onlyForActiveScenario(entities)),
    );
  }

  private onlyForActiveScenario(entities: T[]): T[] {
    return entities.filter((entity) => this.isFromActiveScenario(entity));
  }

  private isFromActiveScenario(entity: T): boolean {
    return entity.scenarioUuid === this.keeper.getActiveScenario();
  }

  getActiveOptions(): NameValue[] {
    return this.mapToNameValue(this.getAllForActiveScenario());
  }

  mapToNameValue(options: T[]): NameValue[] {
    return Coerce.toArray(options).map((option: T) => ({
      value: this.getDisplayValue(option),
      name: this.getDisplayName(option),
    }));
  }

  getDisplayName(entity: T): string {
    return entity.id;
  }

  getDisplayValue(entity: T): string {
    return entity.id;
  }
}
