import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { switchMap } from 'rxjs/operators';
import { TimePeriodInfo } from './time-period.state';
import { TimePeriodStore } from './time-period.store';
import { ScenarioDetailState } from '../scenario-detail';

@Injectable({ providedIn: 'root' })
export class TimePeriodQuery extends QueryEntity<
  ScenarioDetailState<TimePeriodInfo>
> {
  constructor(
    public store: TimePeriodStore,
    private readonly keeper: ActiveKeeperService,
  ) {
    super(store);
  }

  timePeriods$ = this.keeper.selectActive().pipe(
    switchMap(({ scenario }) =>
      this.selectAll({
        filterBy: (entity) => entity.scenarioUuid === scenario,
      }),
    ),
  );

  allTimePeriod$ = this.selectAll();
}
