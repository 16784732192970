import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { ScenarioLogsComponent } from './scenario-logs.component';

@NgModule({
  declarations: [ScenarioLogsComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    LetDirective,
    PushPipe,
    MatMenuModule,
  ],
  exports: [ScenarioLogsComponent],
})
export class ScenarioLogsModule {}
