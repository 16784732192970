import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Observable } from 'rxjs';
import { Scenario } from 'prosumer-app/+scenario/models';

import { ScenarioState } from './scenario.model';
import {
  ScenarioDownloadFileAction,
  GetLatestSimulationAction,
  GetLatestModifiedAction,
  ScenarioGetDraftsAction,
  ScenarioDownloadXlsxTemplateAction,
} from './scenario.actions';
import { scenarioSelectors } from './scenario.reducer';

@Injectable()
export class ScenarioStore {
  state$: Observable<ScenarioState> = this._store$.pipe(
    select(scenarioSelectors.state),
  );

  loading$: Observable<boolean> = this._store$.pipe(
    select(scenarioSelectors.loading),
  );
  data$: Observable<Scenario> = this._store$.pipe(
    select(scenarioSelectors.data),
  );
  downloading$: Observable<boolean> = this._store$.pipe(
    select(scenarioSelectors.downloading),
  );
  error$: Observable<any> = this._store$.pipe(select(scenarioSelectors.error));
  listDrafts$: Observable<Array<any>> = this._store$.pipe(
    select(scenarioSelectors.listDrafts),
  );
  draftsLoading$: Observable<boolean> = this._store$.pipe(
    select(scenarioSelectors.draftsLoading),
  );
  draftsError$: Observable<string> = this._store$.pipe(
    select(scenarioSelectors.draftsError),
  );

  latestSimulationList$: Observable<Array<Scenario>> = this._store$.pipe(
    select(scenarioSelectors.latestSimulationList),
  );

  latestModifiedList$: Observable<Array<Scenario>> = this._store$.pipe(
    select(scenarioSelectors.latestModifiedList),
  );

  constructor(private _store$: Store<ScenarioState>) {}

  getLatestSimulation(): Observable<Array<Scenario>> {
    this._store$.dispatch(new GetLatestSimulationAction());
    return this.latestSimulationList$;
  }

  getLatestModified(): Observable<Array<Scenario>> {
    this._store$.dispatch(new GetLatestModifiedAction());
    return this.latestModifiedList$;
  }

  downloadFile(scenario: Scenario, endpointName: string): void {
    this._store$.dispatch(
      new ScenarioDownloadFileAction(scenario, endpointName),
    );
  }

  downloadXlsxlTemplate(endpointName: string): void {
    this._store$.dispatch(new ScenarioDownloadXlsxTemplateAction(endpointName));
  }

  getScenariosInDraft(): Observable<Array<any>> {
    this._store$.dispatch(new ScenarioGetDraftsAction());
    return this.listDrafts$;
  }
}
