import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';
import { Observable } from 'rxjs';
import { CascadableDetailStore } from '../cascadable-detail';
import { ScenarioDetailType } from '../scenario-detail';
import { ScenarioVariationInfo } from './scenario-variation.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: ScenarioDetailType.variation })
export class ScenarioVariationStore extends CascadableDetailStore<ScenarioVariationInfo> {
  getVariations(_: Record<string, unknown>): Observable<unknown> {
    return this.improvedGetAll();
  }

  createVariation(name: string): Observable<unknown> {
    const body: ScenarioVariationInfo = {
      scenarioUuid: undefined,
      variationUuid: undefined,
      name,
    };
    return this.create(body);
  }

  deleteVariation(data: ScenarioVariationInfo): Observable<unknown> {
    return this.deleteOne(data.id);
  }

  toFE(from: unknown): ScenarioVariationInfo {
    return this.copyIdToVariationUuid(from as ScenarioVariationInfo);
  }

  private copyIdToVariationUuid(
    data: ScenarioVariationInfo,
  ): ScenarioVariationInfo {
    return { ...data, variationUuid: data.id };
  }
}
