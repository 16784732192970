import {
  ConfigService,
  HttpService,
  RouterStore,
  UserFacadeService,
} from 'prosumer-app/libs/eyes-core';
import {
  ActionTypes,
  doNothing,
  generateEndpoint,
  GetListFailure,
  GetSuccess,
  StateEffects,
} from 'prosumer-app/libs/eyes-shared';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { ScenarioBinStore } from 'prosumer-app/stores';
import { ProsumerRoutePathService } from 'prosumer-core/services/prosumer-route-path';
import { BinaryDataToClone, Profile, Scenario } from 'prosumer-scenario/models';
import { ScenarioApiService } from 'prosumer-scenario/services/scenario-api.service';
import { forkJoin, from, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  mapTo,
  mergeMap,
  take,
  tap,
  toArray,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { ScenarioFacadeService } from './scenario-facade.service';
import { ScenarioState } from './scenario-state.model';
import * as ScenarioActions from './scenario.actions';
import { scenarioFeature, scenarioStateFactory } from './scenario.factory';

@Injectable()
export class ScenarioEffects extends StateEffects<ScenarioState, Scenario> {
  getScenarioSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ActionTypes(scenarioFeature).GET_SUCCESS),
        tap((action: GetSuccess<Scenario>) =>
          this._userFacade.get(action.payload.resultData.owner),
        ),
      ),
    { dispatch: false },
  );

  updateScenarioFromCaseView$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.UpdateScenarioFromCaseView>(
          ScenarioActions.ActionTypes.UPDATE_SCENARIO_FROM_CASE_VIEW,
        ),
        mergeMap((action) =>
          this._scenarioApi
            .updateScenarioFromCaseView(action.payload.data)
            .pipe(
              map(
                (response) =>
                  new ScenarioActions.UpdateScenarioFromCaseViewSuccess({
                    data: { ...action.payload.data, ...response },
                    notify: true,
                    message: this._translate.instant(
                      'Scenario.messages.updateSuccess',
                    ),
                  }),
              ),
              tap((action) =>
                this._notification.showSuccess(action.payload.message),
              ),
              catchError((message) =>
                of(
                  new ScenarioActions.UpdateScenarioFromCaseViewFailure({
                    data: action.payload.data,
                    notify: true,
                    message:
                      message ||
                      this._translate.instant(
                        'Scenario.messages.updateFailure',
                      ),
                  }),
                ).pipe(
                  tap((action) =>
                    this._notification.showError(action.payload.message),
                  ),
                ),
              ),
            ),
        ),
      ),
  );

  copyScenario$: Observable<ScenarioActions.All> = createEffect(() =>
    this._actions$.pipe(
      ofType<ScenarioActions.CopyScenario>(
        ScenarioActions.ActionTypes.COPY_SCENARIO,
      ),
      mergeMap((action) =>
        this._scenarioApi
          .copy(
            action.payload.data,
            action.payload.name,
            action.payload.targetProjectId,
            action.payload.targetCaseId,
          )
          .pipe(
            map(
              (response) =>
                new ScenarioActions.CopyScenarioSuccess({
                  name: action.payload.name,
                  data: action.payload.data,
                  message:
                    response.message ||
                    this._translate.instant('Scenario.messages.CopySuccess'),
                }),
            ),
            catchError((message) =>
              of(
                new ScenarioActions.CopyScenarioFailure({
                  name: action.payload.name,
                  data: action.payload.data,
                  notify: true,
                  message:
                    message ||
                    this._translate.instant('Scenario.messages.CopyFailure'),
                }),
              ),
            ),
          ),
      ),
    ),
  );

  copyScenarioFailure$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.CopyScenarioFailure>(
          ScenarioActions.ActionTypes.COPY_SCENARIO_FAILURE,
        ),
        mergeMap((action) =>
          this._notification
            .showError(action.payload.message, 'Retry')
            .onAction()
            .pipe(
              tap(() =>
                this._scenarioFacade.copy(
                  action.payload.data,
                  action.payload.name,
                  action.payload.targetProjectId,
                  action.payload.targetCaseId,
                ),
              ),
              map(() => action),
            ),
        ),
      ),
    { dispatch: false },
  );

  copyScenarioSuccess$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.CopyScenarioSuccess>(
          ScenarioActions.ActionTypes.COPY_SCENARIO_SUCCESS,
        ),
        tap((action) => this._notification.showSuccess(action.payload.message)),
      ),
    { dispatch: false },
  );

  launch$: Observable<ScenarioActions.All> = createEffect(() =>
    this._actions$.pipe(
      ofType<ScenarioActions.Launch>(ScenarioActions.ActionTypes.LAUNCH),
      mergeMap((action) =>
        this._scenarioApi.launch(action.payload.data).pipe(
          map(
            (response) =>
              new ScenarioActions.LaunchSuccess({
                data: response,
                notify: true,
                message: this._translate.instant(
                  'Scenario.messages.simluateSuccess',
                ),
              }),
          ),
          catchError((message) =>
            of(
              new ScenarioActions.LaunchFailure({
                data: action.payload.data,
                message:
                  message ||
                  this._translate.instant('Scenario.message.simulateFailure'),
                notify: true,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  launchFailure$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.LaunchFailure>(
          ScenarioActions.ActionTypes.LAUNCH_FAILURE,
        ),
        mergeMap((action) =>
          this.notification
            .showError(action.payload.message, 'Retry')
            .onAction()
            .pipe(
              tap(() => this._scenarioFacade.launch(action.payload.data)),
              map(() => action),
            ),
        ),
      ),
    { dispatch: false },
  );

  launchSuccess$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.LaunchSuccess>(
          ScenarioActions.ActionTypes.LAUNCH_SUCCESS,
        ),
        tap((action) => {
          this._routerFacade.go(
            this._routePath.scenarioRunning(
              action.payload.data.projectId,
              action.payload.data.caseId,
              action.payload.data.id,
            ),
          );
          return this._notification.showSuccess(action.payload.message);
        }),
      ),
    { dispatch: false },
  );

  download$: Observable<ScenarioActions.All> = createEffect(() =>
    this._actions$.pipe(
      ofType<ScenarioActions.Download>(ScenarioActions.ActionTypes.DOWNLOAD),
      mergeMap((action) =>
        this._scenarioApi
          .download(action.payload.data, action.payload.endpoint)
          .pipe(
            map(
              () =>
                new ScenarioActions.DownloadSuccess({
                  data: action.payload.data,
                  notify: true,
                  message: this._translate.instant(
                    this.getMessageMap().downloadSuccess,
                  ),
                }),
            ),
            catchError((message) =>
              of(
                new ScenarioActions.DownloadFailure({
                  data: action.payload.data,
                  downloadEntity: action.payload.downloadEntity,
                  message:
                    message ||
                    this._translate.instant(
                      this.getMessageMap().downloadFailure,
                    ),
                  notify: true,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  downloadFailure$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.DownloadFailure>(
          ScenarioActions.ActionTypes.DOWNLOAD_FAILURE,
        ),
        mergeMap((action) =>
          this.notification
            .showError(action.payload.message, 'Retry')
            .onAction()
            .pipe(
              tap(() =>
                this._scenarioFacade.download(
                  action.payload.data,
                  action.payload.endpoint,
                  action.payload.downloadEntity,
                ),
              ),
              map(() => action),
            ),
        ),
      ),
    { dispatch: false },
  );

  downloadSuccess$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.DownloadSuccess>(
          ScenarioActions.ActionTypes.DOWNLOAD_SUCCESS,
        ),
        tap((action) => this._notification.showSuccess(action.payload.message)),
      ),
    { dispatch: false },
  );

  downloadDraftInput$: Observable<ScenarioActions.All> = createEffect(() =>
    this.actions$.pipe(
      ofType<ScenarioActions.DownloadDraftInput>(
        ScenarioActions.ActionTypes.DOWNLOAD_DRAFT_INPUT,
      ),
      mergeMap((action) =>
        this._scenarioApi.download(action.payload.data, 'getSigned').pipe(
          mergeMap(() =>
            this._scenarioApi.get(action.payload.data.id, {
              projectId: action.payload.data.projectId,
              caseId: action.payload.data.caseId,
            }),
          ),
          map(
            (response) =>
              new ScenarioActions.DownloadDraftInputSuccess({
                data: response,
                notify: true,
                message: this._translate.instant(
                  this.getMessageMap().downloadSuccess,
                ),
              }),
          ),
        ),
      ),
    ),
  );

  downloadDraftInputFailure$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.DownloadDraftInputFailure>(
          ScenarioActions.ActionTypes.DOWNLOAD_DRAFT_INPUT_FAILURE,
        ),
        mergeMap((action) =>
          this.notification
            .showError(action.payload.message, 'Retry')
            .onAction()
            .pipe(
              tap(() =>
                this._scenarioFacade.downloadDraftInput(
                  action.payload.data,
                  action.payload.downloadEntity,
                ),
              ),
              map(() => action),
            ),
        ),
      ),
    { dispatch: false },
  );

  downloadDraftInputSuccess$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.DownloadDraftInputSuccess>(
          ScenarioActions.ActionTypes.DOWNLOAD_DRAFT_INPUT_SUCCESS,
        ),
        tap((action) => this.notification.showSuccess(action.payload.message)),
      ),
    { dispatch: false },
  );

  getDraftInput$: Observable<ScenarioActions.All> = createEffect(() =>
    this.actions$.pipe(
      ofType<ScenarioActions.GetDraftInput>(
        ScenarioActions.ActionTypes.GET_DRAFT_INPUT,
      ),
      mergeMap((action) =>
        this._scenarioApi.getDraftInput(action.payload.data).pipe(
          map(
            (response) =>
              new ScenarioActions.GetDraftInputSuccess({
                data: action.payload.data,
                notify: true,
                message:
                  response.message ||
                  this._translate.instant(
                    this.getMessageMap().getDraftInputSuccess,
                  ),
              }),
          ),
          catchError((message) =>
            of(
              new ScenarioActions.GetDraftInputFailure({
                data: action.payload.data,
                message:
                  message ||
                  this._translate.instant(
                    this.getMessageMap().getDraftInputFailure,
                  ),
                notify: true,
                downloadEntity: action.payload.downloadEntity,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getDraftInputFailure$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.GetDraftInputFailure>(
          ScenarioActions.ActionTypes.GET_DRAFT_INPUT_FAILURE,
        ),
        mergeMap((action) =>
          this.notification
            .showError(action.payload.message, 'Retry')
            .onAction()
            .pipe(
              tap(() =>
                this._scenarioFacade.getDraftInput(
                  action.payload.data,
                  action.payload.downloadEntity,
                ),
              ),
              map(() => action),
            ),
        ),
      ),
    { dispatch: false },
  );

  getDraftInputSuccess$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ScenarioActions.GetDraftInputSuccess>(
          ScenarioActions.ActionTypes.GET_DRAFT_INPUT_SUCCESS,
        ),
        tap((action) => this._notification.showSuccess(action.payload.message)),
      ),
    { dispatch: false },
  );

  upload$: Observable<ScenarioActions.All> = createEffect(() =>
    this._actions$.pipe(
      ofType<ScenarioActions.Upload>(ScenarioActions.ActionTypes.UPLOAD),
      mergeMap((action) =>
        this._scenarioApi
          .upload(
            action.payload.data,
            action.payload.file,
            action.payload.gamsFiles,
          )
          .pipe(
            map(
              (response) =>
                new ScenarioActions.UploadSuccess({
                  data: response,
                  notify: true,
                  message: this._translate.instant(
                    'Scenario.messages.uploadSuccess',
                  ),
                }),
            ),
            catchError((message) =>
              of(
                new ScenarioActions.UploadFailure({
                  data: action.payload.data,
                  message:
                    message ||
                    this._translate.instant('Scenario.messages.uploadFailure'),
                  notify: true,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  uploadFailure$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.UploadFailure>(
          ScenarioActions.ActionTypes.UPLOAD_FAILURE,
        ),
        mergeMap((action) =>
          this.notification
            .showError(
              action.payload.message.error
                ? action.payload.message.error.error
                : action.payload.message,
              'Retry',
            )
            .onAction()
            .pipe(
              tap(() =>
                this._scenarioFacade.upload(
                  action.payload.data,
                  action.payload.file,
                ),
              ),
              map(() => action),
            ),
        ),
      ),
    { dispatch: false },
  );

  uploadSuccess$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.UploadSuccess>(
          ScenarioActions.ActionTypes.UPLOAD_SUCCESS,
        ),
        tap((action) => this._notification.showSuccess(action.payload.message)),
      ),
    { dispatch: false },
  );

  uploadClone$: Observable<ScenarioActions.All> = createEffect(() =>
    this._actions$.pipe(
      ofType<ScenarioActions.UploadClone>(
        ScenarioActions.ActionTypes.UPLOAD_CLONE,
      ),
      mergeMap((action) =>
        this._scenarioApi
          .uploadClone(action.payload.data, action.payload.file)
          .pipe(
            map(
              (response) =>
                new ScenarioActions.UploadCloneSuccess({
                  data: response,
                  notify: true,
                  message: this._translate.instant(
                    'Scenario.messages.uploadCloneSuccess',
                  ),
                }),
            ),
            catchError((message) =>
              of(
                new ScenarioActions.UploadCloneFailure({
                  data: action.payload.data,
                  message:
                    message ||
                    this._translate.instant(
                      'Scenario.messages.uploadCloneFailure',
                    ),
                  notify: true,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  uploadCloneFailure$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.UploadCloneFailure>(
          ScenarioActions.ActionTypes.UPLOAD_CLONE_FAILURE,
        ),
        mergeMap((action) =>
          this.notification
            .showError(
              action.payload.message.error
                ? action.payload.message.error.error
                : action.payload.message,
              'Retry',
            )
            .onAction()
            .pipe(
              tap(() =>
                this._scenarioFacade.uploadClone(
                  action.payload.data,
                  action.payload.file,
                ),
              ),
              map(() => action),
            ),
        ),
      ),
    { dispatch: false },
  );

  uploadCloneSuccess$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.UploadCloneSuccess>(
          ScenarioActions.ActionTypes.UPLOAD_CLONE_SUCCESS,
        ),
        tap((action) => {
          this._routerFacade.go(
            this._routePath.caseDetail(
              action.payload.data.projectId,
              action.payload.data.caseId,
            ),
          );
        }),
        tap((action) => this._notification.showSuccess(action.payload.message)),
      ),
    { dispatch: false },
  );

  validate$: Observable<ScenarioActions.All> = createEffect(() =>
    this._actions$.pipe(
      ofType<ScenarioActions.Validate>(ScenarioActions.ActionTypes.VALIDATE),
      mergeMap((action) =>
        this._scenarioApi.validate(action.payload.data).pipe(
          map(
            (response) =>
              new ScenarioActions.ValidateSuccess({ data: response }),
          ),
          catchError((message) =>
            of(
              new ScenarioActions.ValidateFailure({
                data: action.payload.data,
                error:
                  message ||
                  this._translate.instant(
                    'Scenario.messages.coherenceChecks.validateFailure',
                  ),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  validateFailure$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.ValidateFailure>(
          ScenarioActions.ActionTypes.VALIDATE_FAILURE,
        ),
        mergeMap((action) =>
          this.notification
            .showError(action.payload.error, 'Retry')
            .onAction()
            .pipe(
              tap(() => this._scenarioFacade.validate(action.payload.data)),
              map(() => action),
            ),
        ),
      ),
    { dispatch: false },
  );

  validateSuccess$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.ValidateSuccess>(
          ScenarioActions.ActionTypes.VALIDATE_SUCCESS,
        ),
      ),
    { dispatch: false },
  );

  validateReset$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.Validate>(
          ScenarioActions.ActionTypes.VALIDATE_RESET,
        ),
      ),
    { dispatch: false },
  );

  /*   // fetch single variation - for future implementation
  @Effect()
  getScenarioVariation$: Observable<ScenarioActions.All> = this.actions$.pipe(
    ofType<ScenarioActions.GetScenarioVariation>(ScenarioActions.ActionTypes.GET_SCENARIO_VARIATION),
    mergeMap((action) =>
      this._scenarioApi.getScenarioVariation(action.payload.id, action.payload.params).pipe(
        map(
          (scenarioVariation) => {
            // fetch the scenarioVariation list from the state and update it with the newly fetched variation
            // then pass the updated list in the dataList property
            const variationsList: Array<ScenarioVariationMap> = [];
            return new ScenarioActions.GetScenarioVariationsSuccess({
              message: this._translate.instant(this.getMessageMap().geScenarioVariationSuccess),
              datalist: [scenarioVariation], // <- updated variationsList goes there instead
              id: action.payload.id,
            });
          }
        ),
        catchError((messages) =>
          of(
            new ScenarioActions.GetScenarioVariationsFailure({
              message: messages || this._translate.instant(this.getMessageMap().geScenarioVariationsFailure),
              id: action.payload.id,
            }),
          ),
        ),
      ),
    ),
  ); */

  getScenarioVariations$: Observable<ScenarioActions.All> = createEffect(() =>
    this.actions$.pipe(
      ofType<ScenarioActions.GetScenarioVariations>(
        ScenarioActions.ActionTypes.GET_SCENARIO_VARIATIONS,
      ),
      mergeMap((action) =>
        this._scenarioApi
          .getScenarioVariations(action.payload.id, action.payload.params)
          .pipe(
            map(
              (scenarioVariations) =>
                new ScenarioActions.GetScenarioVariationsSuccess({
                  message: this._translate.instant(
                    this.getMessageMap().geScenarioVariationsSuccess,
                  ),
                  datalist: scenarioVariations,
                  id: action.payload.id,
                }),
            ),
            catchError((messages) =>
              of(
                new ScenarioActions.GetScenarioVariationsFailure({
                  message:
                    messages ||
                    this._translate.instant(
                      this.getMessageMap().geScenarioVariationsFailure,
                    ),
                  id: action.payload.id,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getScenariosAndVariations$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ScenarioActions.GetScenariosAndVariations>(
          ScenarioActions.ActionTypes.GET_SCENARIOS_AND_VARIATIONS,
        ),
        mergeMap((action) =>
          this._scenarioApi
            .getScenariosAndVariations(action.payload.params)
            .pipe(
              map(
                (scenarioVariations) =>
                  new ScenarioActions.GetScenariosAndVariationsSuccess({
                    message: this._translate.instant(
                      this.getMessageMap().getScenariosAndVariationsSuccess,
                    ),
                    datalist: scenarioVariations,
                  }),
              ),
              catchError((messages) =>
                of(
                  new ScenarioActions.GetScenarioVariationsFailure({
                    message:
                      messages ||
                      this._translate.instant(
                        this.getMessageMap().getScenariosAndVariationsFailure,
                      ),
                  }),
                ),
              ),
            ),
        ),
      ),
  );

  getScenarioVariationsSuccess$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ScenarioActions.GetScenarioVariationsSuccess>(
          ScenarioActions.ActionTypes.GET_SCENARIO_VARIATIONS_SUCCESS,
        ),
        tap((action) =>
          action.payload.notify
            ? this.notification.showSuccess(action.payload.message)
            : doNothing(),
        ),
      ),
    { dispatch: false },
  );

  getScenarioVariationsFailure$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ScenarioActions.GetScenarioVariationsFailure>(
          ScenarioActions.ActionTypes.GET_SCENARIO_VARIATIONS_FAILURE,
        ),
        tap((action) => this._notification.showError(action.payload.message)),
      ),
    { dispatch: false },
  );

  updateWithBinaryHandling$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScenarioActions.UpdateWithBinaryHandling>(
          ScenarioActions.ActionTypes.UPDATE_WITH_BINARY_HANDLING,
        ),
        mergeMap((action: ScenarioActions.UpdateWithBinaryHandling) => {
          const binDataForSaving = this.getBinaryDataForSaving(
            action.payload.data,
          );
          const binDataToClone = this.getBinaryDataForCloning(
            action.payload.data,
          );
          const endpoint =
            this._config.api.endpoints['scenario']['createBinary'];
          const scenario = action.payload.data as Scenario;
          const binObservables =
            !!binDataForSaving && binDataForSaving.length > 0
              ? binDataForSaving.map((profile) =>
                  this._http
                    .put(
                      generateEndpoint(
                        this._config.api.baseUrl,
                        endpoint,
                        scenario.projectId,
                        scenario.caseId,
                        scenario.id,
                        profile.location,
                        profile.localId,
                      ),
                      {
                        id: profile.localId,
                        location: profile.location,
                        data: profile.loadProfile,
                      },
                    )
                    .pipe(
                      take(1),
                      mergeMap(() => of(profile)),
                    ),
                )
              : [of({} as Profile)];
          return forkJoin(binObservables).pipe(
            mergeMap(() => {
              const binToCloneObs$ =
                !!binDataToClone && binDataToClone.length > 0
                  ? binDataToClone.map((bin) =>
                      this._http
                        .put(
                          generateEndpoint(
                            this._config.api.baseUrl,
                            this._config.api.endpoints['scenario'][
                              'cloneBinary'
                            ],
                            action.payload.data.projectId,
                            action.payload.data.caseId,
                            action.payload.data.id,
                            bin.location,
                            bin.localId,
                          ),
                          {
                            newLocalId: bin.newLocalId,
                          },
                        )
                        .pipe(
                          take(1),
                          mergeMap(() => of(bin)),
                        ),
                    )
                  : [of({} as BinaryDataToClone)];
              return forkJoin(binToCloneObs$);
            }),
            mergeMap(() =>
              this._scenarioApi
                .update(action.payload.data, {
                  projectId: action.payload.data.projectId,
                  caseId: action.payload.data.caseId,
                })
                .pipe(
                  map(
                    (data) =>
                      new ScenarioActions.UpdateWithBinaryHandlingSuccess({
                        data,
                        binaryDataToDelete: this.getBinaryDataForDeletion(
                          action.payload.data,
                        ),
                        message: this.translate.instant(
                          this.getMessageMap().updateSuccess,
                        ),
                      }),
                  ),
                  catchError((message) =>
                    of(
                      new ScenarioActions.UpdateWithBinaryHandlingFailure({
                        data: action.payload.data,
                        message:
                          message ||
                          this.translate.instant(
                            this.getMessageMap().updateFailure,
                          ),
                      }),
                    ),
                  ),
                ),
            ),
          );
        }),
      ),
  );

  // Get Scenario List
  getCaseDetailScenarioList$: Observable<ScenarioActions.All> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ScenarioActions.GetCaseDetailScenarioList>(
          ScenarioActions.ActionTypes.GET_CASE_DETAIL_SCENARIO_LIST,
        ),
        mergeMap((action) =>
          this._scenarioApi
            .getCaseDetailScenarioList(action.payload.id, action.payload.params)
            .pipe(
              map(
                (dataList) =>
                  new ScenarioActions.GetCaseDetailScenarioListSuccess({
                    message: this.translate.instant(
                      this.getMessageMap().getListSuccess,
                    ),
                    id: action.payload.id,
                    params: action.payload.params,
                    results: dataList,
                  }),
              ),
              catchError((message) =>
                of(
                  new ScenarioActions.GetCaseDetailScenarioListFailure({
                    id: action.payload.id,
                    params: action.payload.params,
                    message:
                      message ||
                      this.translate.instant(
                        this.getMessageMap().getListFailure,
                      ),
                  }),
                ),
              ),
            ),
        ),
      ),
  );

  getCaseDetailScenarioListFailure$: Observable<GetListFailure> = createEffect(
    () => this.getListFailure$,
    { dispatch: false },
  );

  getCaseDetailScenarioListSuccess$: Observable<ScenarioActions.GetCaseDetailScenarioListSuccess> =
    createEffect(
      () =>
        this.actions$.pipe(
          ofType<ScenarioActions.GetCaseDetailScenarioListSuccess>(
            ScenarioActions.ActionTypes.GET_CASE_DETAIL_SCENARIO_LIST_SUCCESS,
          ),
          mergeMap((action) =>
            this._scenarioFacade
              .filterScenarios$(
                action.payload.params.projectId,
                action.payload.id,
              )
              .pipe(
                take(1),
                mergeMap((dataList) =>
                  from((dataList || []).filter((data) => !data.loaded)).pipe(
                    mergeMap((item) =>
                      of({ ...item, loading: false, loaded: true }),
                    ),
                    toArray(),
                  ),
                ),
                mapTo(action),
              ),
          ),
          tap((action) =>
            action.payload.notify
              ? this.notification.showSuccess(action.payload.message)
              : doNothing(),
          ),
        ),
      { dispatch: false },
    );

  updateWithBinaryHandlingFailure$: Observable<ScenarioActions.All> =
    createEffect(
      () =>
        this._actions$.pipe(
          ofType<ScenarioActions.UpdateWithBinaryHandlingFailure>(
            ScenarioActions.ActionTypes.UPDATE_WITH_BINARY_HANDLING_FAILURE,
          ),
          mergeMap((action) =>
            this._notification
              .showError(action.payload.message, 'Retry')
              .onAction()
              .pipe(
                tap(() =>
                  this._scenarioFacade.updateWithBinaryHandling(
                    action.payload.data,
                  ),
                ),
                map(() => action),
              ),
          ),
        ),
      { dispatch: false },
    );

  updateWithBinaryHandlingSuccess$: Observable<ScenarioActions.All> =
    createEffect(
      () =>
        this._actions$.pipe(
          ofType<ScenarioActions.UpdateWithBinaryHandlingSuccess>(
            ScenarioActions.ActionTypes.UPDATE_WITH_BINARY_HANDLING_SUCCESS,
          ),
          tap((action) =>
            action.payload.binaryDataToDelete &&
            action.payload.binaryDataToDelete.length > 0
              ? this._binaryFacade.deleteAll(
                  action.payload.data.projectId,
                  action.payload.data.caseId,
                  action.payload.data.id,
                  action.payload.binaryDataToDelete,
                )
              : doNothing(),
          ),
          tap((action) =>
            this._notification.showSuccess(action.payload.message),
          ),
        ),
      { dispatch: false },
    );

  getBinaryDataForCloning(scenario: Scenario) {
    return [].concat.apply(
      [],
      [
        ...(((scenario || {}).loads || {}).binToClone || []),
        ...(((scenario || {}).equipments || {}).binToClone || []),
        ...(((scenario || {}).commodities || {}).binToClone || []),
      ],
    );
  }

  getBinaryDataForSaving(scenario: Scenario) {
    const binaryData = [
      ...(((scenario || {}).loads || {}).loads || [])
        .map((loads) => loads.profiles)
        .map((profile) =>
          profile.filter((load) => !!load.localId && load.forSaving),
        ),
      // TODO: when mapping is done for other wizards uncomment them
      ...(((scenario || {}).equipments || {}).equipments || [])
        .map((equipment) => equipment.profiles)
        .map((profile) =>
          (profile || []).filter(
            (equipment) => !!equipment.localId && equipment.forSaving,
          ),
        ),
      ...(((scenario || {}).equipments || {}).equipments || [])
        .filter((equipment) =>
          Object.prototype.hasOwnProperty.call(
            equipment,
            'operatingCostProfiles',
          ),
        )
        .map((equipment: any) => equipment.operatingCostProfiles)
        .reduce(
          (operatingCostProfiles, next) =>
            operatingCostProfiles.concat(next, []),
          [],
        )
        .map((operatingCostProfile) => (operatingCostProfile || {}).profiles)
        .map((profiles) =>
          (profiles || []).filter(
            (profile) => !!profile.localId && profile.forSaving,
          ),
        ),
      ...(((scenario || {}).commodities || {}).grids || [])
        .map((grid) => grid.profiles)
        .map((profile) =>
          profile.filter((grid) => !!grid.localId && grid.forSaving),
        ),
      ...(((scenario || {}).commodities || {}).connectionsEmissions || [])
        .map((connectionsEmission) => connectionsEmission.profiles)
        .map((profile) =>
          profile.filter(
            (connectionEmissions) =>
              !!connectionEmissions.localId && connectionEmissions.forSaving,
          ),
        ),
      ...(
        (((scenario || {}).frequencyControl || {}).spinningReserve || {})
          .margins || []
      )
        .map((reserves) => reserves.profiles)
        .map((profile) =>
          profile.filter((margin) => !!margin.localId && margin.forSaving),
        ),
      ...((((scenario || {}).mobility || {}).routes || {}).routes || [])
        .map((route) => route.profileRoutes)
        .map((profileRoute) =>
          profileRoute.filter((vroute) => !!vroute.localId && vroute.forSaving),
        ),
    ];
    const forSaving: Array<Profile> = [];
    binaryData.forEach((outer) =>
      outer.forEach((inner) => forSaving.push(inner)),
    );
    return forSaving;
  }

  getBinaryDataForDeletion(scenario: Scenario) {
    return [].concat.apply(
      [],
      [
        ...(((scenario || {}).loads || {}).binToDelete || []),
        ...(((scenario || {}).equipments || {}).binToDelete || []),
        ...(((scenario || {}).commodities || {}).binToDelete || []),
        ...((((scenario || {}).frequencyControl || {}).spinningReserve || {})
          .binToDelete || []),
        ...((((scenario || {}).mobility || {}).routes || {}).binToDelete || []),
      ],
    );
  }

  constructor(
    private _actions$: Actions,
    private _config: ConfigService,
    private _http: HttpService,
    private _notification: NotificationsService,
    private _scenarioApi: ScenarioApiService,
    private _scenarioFacade: ScenarioFacadeService,
    private _binaryFacade: ScenarioBinStore,
    private _routePath: ProsumerRoutePathService,
    private _routerFacade: RouterStore,
    private _translate: TranslateService,
    private _userFacade: UserFacadeService,
  ) {
    super(
      _actions$,
      _scenarioApi,
      _notification,
      _scenarioFacade,
      scenarioStateFactory,
      _translate,
    );
    createEffect(() => this.get$);
    createEffect(() => this.getFailure$, { dispatch: false });
    createEffect(() => this.delete$);
    createEffect(() => this.deleteFailure$, { dispatch: false });
    createEffect(() => this.deleteSuccess$, { dispatch: false });
  }

  getMessageMap() {
    return {
      createSuccess: 'Scenario.messages.saveSuccess',
      createFailure: 'Scenario.messages.saveFailure',
      getSuccess: 'Scenario.messages.getSuccess',
      getFailure: 'Scenario.messages.getFailure',
      getListSuccess: 'Scenario.messages.getAllSuccess',
      getListFailure: 'Scenario.messages.getAllFailure',
      updateSuccess: 'Scenario.messages.updateSuccess',
      updateFailure: 'Scenario.messages.updateFailure',
      deleteSuccess: 'Scenario.messages.deleteSuccess',
      deleteFailure: 'Scenario.messages.deleteFailure',
      downloadSuccess: 'Scenario.messages.downloadSuccess',
      downloadFailure: 'Scenario.messages.downloadFailure',
      downloadDraftSuccess: 'Scenario.messages.downloadDraftSuccess',
      downloadDraftFailure: 'Scenario.messages.downloadDraftFailure',
      getDraftInputSuccess: 'Scenario.messages.getDraftInputSuccess',
      getDraftInputFailure: 'Scenario.messages.getDraftInputFailure',
      geScenarioVariationsSuccess:
        'Scenario.messages.geScenarioVariationsSuccess',
      geScenarioVariationsFailure:
        'Scenario.messages.geScenarioVariationsFailure',
      getScenariosAndVariationsSuccess:
        'Scenario.messages.getScenariosAndVariationsSuccess',
      getScenariosAndVariationsFailure:
        'Scenario.messages.getScenariosAndVariationsFailure',
      retry: 'Generic.messages.retry',
    };
  }
}
