import { AllActions } from 'prosumer-app/libs/eyes-shared';

/* eslint-disable @typescript-eslint/naming-convention */
import { Action } from '@ngrx/store';

import { Case } from '../models';

export enum ActionTypes {
  GET_CASE_LIST_BY_NODETYPE = '[Duplicate Scenario dialog] Get cases by node type',
  GET_CASE_LIST_BY_NODETYPE_FAILURE = '[Duplicate Scenario API] Get cases by node type failure',
  GET_CASE_LIST_BY_NODETYPE_SUCCESS = '[Duplicate Scenario API] Get cases by node type success',
  COPY_CASE = '[Case List Page] Copy Case',
  COPY_CASE_FAILURE = '[Case API] Copy Case Failure',
  COPY_CASE_SUCCESS = '[Case API] Copy Case Success',
  REMOVE_CASE = '[Case State] Remove Case',
  UPDATE_CHART_COLORS = '[Case] Update Chart Colors',
  UPDATE_CHART_COLORS_OK = '[Case] Update Chart Colors - OK',
  GET_LIST_WITH_DETAILS = '[Case API] Get Case List Details',
  GET_LIST_WITH_DETAILS_SUCCESS = '[Case API] Get Case List Details Success',
  GET_LIST_WITH_DETAILS_FAILED = '[Case API] Get Case List Details Failed',
}

export class UpdateChartColors implements Action {
  readonly type = ActionTypes.UPDATE_CHART_COLORS;
  constructor(public payload?: { data: Case }) {}
}
export class UpdateChartColorsOK implements Action {
  readonly type = ActionTypes.UPDATE_CHART_COLORS_OK;
  constructor(public payload?: { data: Case }) {}
}

// Get Cases by nodetype
export class GetCaseListByNodeType implements Action {
  readonly type = ActionTypes.GET_CASE_LIST_BY_NODETYPE;
  constructor(public payload?: { nodetype?: 'single' | 'multinode' }) {}
}

export class GetCaseListByNodeTypeFailure implements Action {
  readonly type = ActionTypes.GET_CASE_LIST_BY_NODETYPE_FAILURE;
  constructor(
    public payload?: { nodetype?: string; message?: string; notify?: boolean },
  ) {}
}

export class GetCaseListByNodeTypeSuccess implements Action {
  readonly type = ActionTypes.GET_CASE_LIST_BY_NODETYPE_SUCCESS;
  constructor(
    public payload?: {
      nodetype?: string;
      message?: string;
      data?: Array<Case>;
      notify?: boolean;
    },
  ) {}
}

// Copy
export class CopyCase implements Action {
  readonly type = ActionTypes.COPY_CASE;
  constructor(public payload?: { data?: Case; name?: string }) {}
}

export class CopyCaseFailure implements Action {
  readonly type = ActionTypes.COPY_CASE_FAILURE;
  constructor(
    public payload?: {
      data?: Case;
      name?: string;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class CopyCaseSuccess implements Action {
  readonly type = ActionTypes.COPY_CASE_SUCCESS;
  constructor(
    public payload?: {
      data?: Case;
      name?: string;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class RemoveCase implements Action {
  readonly type = ActionTypes.REMOVE_CASE;
  constructor(public payload?: { id?: string }) {}
}

export class GetListWithDetails implements Action {
  readonly type = ActionTypes.GET_LIST_WITH_DETAILS;
  constructor(public payload?: { id?: string }) {}
}

export class GetListWithDetailsSuccess implements Action {
  readonly type = ActionTypes.GET_LIST_WITH_DETAILS_SUCCESS;
  constructor(
    public payload?: {
      id?: string;
      datalist?: Array<Case>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class GetListWithDetailsFailure implements Action {
  readonly type = ActionTypes.GET_LIST_WITH_DETAILS_FAILED;
  constructor(
    public payload?: { id?: string; message?: string; notify?: boolean },
  ) {}
}

/* Actions Union */

export type All =
  | AllActions<Case>
  | GetCaseListByNodeType
  | GetCaseListByNodeTypeFailure
  | GetCaseListByNodeTypeSuccess
  | GetListWithDetails
  | GetListWithDetailsSuccess
  | GetListWithDetailsFailure
  | CopyCase
  | CopyCaseFailure
  | CopyCaseSuccess
  | RemoveCase
  | UpdateChartColorsOK
  | UpdateChartColors;
