import { TopologyForm } from '../../models';

import { convertDataValuesToString } from 'prosumer-app/libs/eyes-shared';
import {
  mapYearlyValuesToBackend,
  mapYearlyValuesToFrontend,
} from 'prosumer-app/shared';

const LIBRARY_TYPE_CUSTOM = 'custom';
const LIBRARY_TYPE_LIBRARY = 'library';
const CONVERSION_EXCEPTIONS = ['technicalLife', 'depreciationTime'];

export class TopologyMapper {
  static mapToBackend = (topologyForm: TopologyForm) =>
    !!topologyForm
      ? {
          nodes: (topologyForm.nodes || []).map((node) => ({
            name: node.name,
            id: node.value,
          })),
          lines: (topologyForm.lines || []).map((line) =>
            convertDataValuesToString(
              {
                name: line.name,
                energyVectorId: line.energyVectorId,
                originNodeId: line.originNodeId,
                destinationNodeId: line.destinationNodeId,
                distance: line.distance,
                bidirectional: line.bidirectional,
                libraryId: (line.library || {}).id,
                // Yearly Values
                efficiency: mapYearlyValuesToBackend(line.yearlyEfficiency),
                buildEmissionsKwm: mapYearlyValuesToBackend(
                  line.yearlyBuildEmissionsKwm,
                ),
                buildEmissionsIndivisible: mapYearlyValuesToBackend(
                  line.yearlyBuildEmissionsIndivisible,
                ),
                minSize: mapYearlyValuesToBackend(line.yearlyMinSize),
                maxSize: mapYearlyValuesToBackend(line.yearlyMaxSize),
                buildCost: mapYearlyValuesToBackend(line.yearlyBuildCost),
                indivisibleCost: mapYearlyValuesToBackend(
                  line.yearlyIndivisibleCost,
                ),
                fomCharge: mapYearlyValuesToBackend(line.yearlyFomCharge),
                fomInstall: mapYearlyValuesToBackend(line.yearlyFomInstall),
                technicalLife: mapYearlyValuesToBackend(
                  line.yearlyTechnicalLife,
                ),
                depreciationTime: mapYearlyValuesToBackend(
                  line.yearlyDepreciationTime,
                ),
                forcedInvestment: line.forcedInvestment,
                existingAsset: line.existingAsset,
                capacityExpansion: line.capacityExpansion,
              },
              CONVERSION_EXCEPTIONS,
            ),
          ),
        }
      : {};

  static mapToFrontend = (
    scenario: any,
    startYear?: number,
    endYear?: number,
  ): TopologyForm =>
    !!scenario
      ? {
          nodes: (scenario.nodes || []).map((node) => ({
            name: node.name,
            value: node.id,
          })),
          lines: (scenario.lines || []).map((line) => ({
            name: line.name,
            energyVectorId: line.energyVectorId,
            originNodeId: line.originNodeId,
            destinationNodeId: line.destinationNodeId,
            distance: line.distance,
            bidirectional: line.bidirectional,
            library: line.libraryId ? { id: line.libraryId } : undefined,
            sourceType: line.libraryId
              ? LIBRARY_TYPE_LIBRARY
              : LIBRARY_TYPE_CUSTOM,
            yearlyEfficiency: mapYearlyValuesToFrontend(
              line.efficiency,
              startYear,
              endYear,
            ),
            yearlyBuildEmissionsKwm: mapYearlyValuesToFrontend(
              line.buildEmissionsKwm,
              startYear,
              endYear,
            ),
            yearlyBuildEmissionsIndivisible: mapYearlyValuesToFrontend(
              line.buildEmissionsIndivisible,
              startYear,
              endYear,
            ),
            yearlyMinSize: mapYearlyValuesToFrontend(
              line.minSize,
              startYear,
              endYear,
            ),
            yearlyMaxSize: mapYearlyValuesToFrontend(
              line.maxSize,
              startYear,
              endYear,
            ),
            yearlyBuildCost: mapYearlyValuesToFrontend(
              line.buildCost,
              startYear,
              endYear,
            ),
            yearlyIndivisibleCost: mapYearlyValuesToFrontend(
              line.indivisibleCost,
              startYear,
              endYear,
            ),
            yearlyFomCharge: mapYearlyValuesToFrontend(
              line.fomCharge,
              startYear,
              endYear,
            ),
            yearlyFomInstall: mapYearlyValuesToFrontend(
              line.fomInstall,
              startYear,
              endYear,
            ),
            yearlyTechnicalLife: mapYearlyValuesToFrontend(
              line.technicalLife,
              startYear,
              endYear,
            ),
            yearlyDepreciationTime: mapYearlyValuesToFrontend(
              line.depreciationTime,
              startYear,
              endYear,
            ),
            forcedInvestment: line.forcedInvestment,
            existingAsset: line.existingAsset,
            capacityExpansion: line.capacityExpansion,
          })),
        }
      : {};
}
