import { AllActions } from 'prosumer-app/libs/eyes-shared';
import {
  CashFlowResult,
  DispatchResult,
  EnergyBalanceResult,
  PaybackResult,
  Result,
  ScenarioResult,
} from 'prosumer-scenario/models';

/* eslint-disable @typescript-eslint/naming-convention */
import { Action } from '@ngrx/store';
import { ComparisonTray } from '@prosumer/comparison/base';
import {
  EmissionsData,
  EquipmentResult,
  TopologyResult,
} from '@prosumer/results/models';

export const scenarioCompareFeature = 'ScenarioCompare';

export enum CompareActionTypes {
  COMPARE_SCENARIO = '[Scenario list page] Compare scenario',
  COMPARE_SCENARIO_SUCCESS = '[Scenario compare API] Compare scenario success',
  COMPARE_SCENARIO_FAILURE = '[Scenario compare API] Compare scenario failure',

  MIGRATE_SCENARIO = '[Scenario list page] Migrate scenario',
  MIGRATE_SCENARIO_SUCCESS = '[Scenario migrate API] Migrate scenario success',
  MIGRATE_SCENARIO_FAILURE = '[Scenario migrate API] Migrate scenario failure',

  COMPARE_SCENARIO_MAIN_TAB = '[Scenario compare page] Compare scenario main tab',
  COMPARE_SCENARIO_MAIN_TAB_SUCCESS = '[Scenario compare API] Compare scenario main tab success',
  COMPARE_SCENARIO_MAIN_TAB_FAILURE = '[Scenario compare API] Compare scenario main tab failure',

  COMPARE_SCENARIO_EQUIPMENT_TAB = '[Scenario compare page] Compare scenario equipment tab',
  COMPARE_SCENARIO_EQUIPMENT_TAB_SUCCESS = '[Scenario compare API] Compare scenario equipment tab success',
  COMPARE_SCENARIO_EQUIPMENT_TAB_FAILURE = '[Scenario compare API] Compare scenario equipment tab failure',

  COMPARE_SCENARIO_DISPATCH_TAB = '[Scenario compare page] Compare scenario dispatch tab',
  COMPARE_SCENARIO_DISPATCH_TAB_SUCCESS = '[Scenario compare API] Compare scenario dispatch tab success',
  COMPARE_SCENARIO_DISPATCH_TAB_FAILURE = '[Scenario compare API] Compare scenario dispatch tab failure',

  COMPARE_SCENARIO_ENERGY_BALANCE_TAB = '[Scenario compare page] Compare scenario energy balance tab',
  COMPARE_SCENARIO_ENERGY_BALANCE_TAB_SUCCESS = '[Scenario compare API] Compare scenario energy balance tab success',
  COMPARE_SCENARIO_ENERGY_BALANCE_TAB_FAILURE = '[Scenario compare API] Compare scenario energy balance tab failure',

  COMPARE_SCENARIO_TOPOLOGY_TAB = '[Scenario compare page] Compare scenario topology tab',
  COMPARE_SCENARIO_TOPOLOGY_TAB_SUCCESS = '[Scenario compare API] Compare scenario topology tab success',
  COMPARE_SCENARIO_TOPOLOGY_TAB_FAILURE = '[Scenario compare API] Compare scenario topology tab failure',

  COMPARE_SCENARIO_CO2_EMISSION_TAB = '[Scenario compare page] Compare scenario co2 emission tab',
  COMPARE_SCENARIO_CO2_EMISSION_TAB_SUCCESS = '[Scenario compare API] Compare scenario co2 emission tab success',
  COMPARE_SCENARIO_CO2_EMISSION_TAB_FAILURE = '[Scenario compare API] Compare scenario co2 emission tab failure',

  COMPARE_SCENARIO_CASHFLOW_TAB = '[Scenario compare page] Compare scenario cashflow tab',
  COMPARE_SCENARIO_CASHFLOW_TAB_SUCCESS = '[Scenario compare API] Compare scenario cashflow tab success',
  COMPARE_SCENARIO_CASHFLOW_TAB_FAILURE = '[Scenario compare API] Compare scenario cashflow tab failure',
}

export class CompareScenario implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO;
  constructor(
    public payload?: {
      referenceId: string;
      projectId: string;
      caseId: string;
      resultList: Array<Result>;
      tab?: string;
    },
  ) {}
}
export class CompareScenarioSuccess implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_SUCCESS;
  constructor(
    public payload?: {
      data: Array<ScenarioResult>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}
export class CompareScenarioFailure implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_FAILURE;
  constructor(
    public payload?: {
      referenceId: string;
      resultList: Array<Result>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}

export class MigrateScenario implements Action {
  readonly type = CompareActionTypes.MIGRATE_SCENARIO;
  constructor(
    public payload?: {
      projectId: string;
      caseId: string;
      resultList: Array<Result>;
    },
  ) {}
}
export class MigrateScenarioSuccess implements Action {
  readonly type = CompareActionTypes.MIGRATE_SCENARIO_SUCCESS;
  constructor(
    public payload?: {
      data: Array<ScenarioResult>;
      isOutputSplit?: boolean;
      message?: string;
      notify?: boolean;
    },
  ) {}
}
export class MigrateScenarioFailure implements Action {
  readonly type = CompareActionTypes.MIGRATE_SCENARIO_FAILURE;
  constructor(public payload?: { error: string }) {}
}

export class CompareScenarioMainTab implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_MAIN_TAB;
  constructor(
    public payload?: {
      referenceId: string;
      projectId: string;
      caseId: string;
      resultList: Array<Result>;
    },
  ) {}
}
export class CompareScenarioMainTabSuccess implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_MAIN_TAB_SUCCESS;
  constructor(
    public payload?: {
      data: Array<ScenarioResult>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}
export class CompareScenarioMainTabFailure implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_MAIN_TAB_FAILURE;
  constructor(
    public payload?: {
      message: string;
    },
  ) {}
}

export class CompareScenarioEquipmentTab implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_EQUIPMENT_TAB;
  constructor(
    public payload?: {
      projectId: string;
      caseId: string;
      resultList: Array<Result>;
    },
  ) {}
}
export class CompareScenarioEquipmentTabSuccess implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_EQUIPMENT_TAB_SUCCESS;
  constructor(
    public payload?: {
      data: ComparisonTray<EquipmentResult>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}
export class CompareScenarioEquipmentTabFailure implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_EQUIPMENT_TAB_FAILURE;
  constructor(
    public payload?: {
      message: string;
    },
  ) {}
}

export class CompareScenarioDispatchTab implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_DISPATCH_TAB;
  constructor(
    public payload?: {
      projectId: string;
      caseId: string;
      resultList: Array<Result>;
    },
  ) {}
}
export class CompareScenarioDispatchTabSuccess implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_DISPATCH_TAB_SUCCESS;
  constructor(
    public payload?: {
      data: Array<DispatchResult>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}
export class CompareScenarioDispatchTabFailure implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_DISPATCH_TAB_FAILURE;
  constructor(
    public payload?: {
      message: string;
    },
  ) {}
}

export class CompareScenarioEnergyBalanceTab implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_ENERGY_BALANCE_TAB;
  constructor(
    public payload?: {
      projectId: string;
      caseId: string;
      resultList: Array<Result>;
    },
  ) {}
}
export class CompareScenarioEnergyBalanceTabSuccess implements Action {
  readonly type =
    CompareActionTypes.COMPARE_SCENARIO_ENERGY_BALANCE_TAB_SUCCESS;
  constructor(
    public payload?: {
      data: Array<EnergyBalanceResult>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}
export class CompareScenarioEnergyBalanceTabFailure implements Action {
  readonly type =
    CompareActionTypes.COMPARE_SCENARIO_ENERGY_BALANCE_TAB_FAILURE;
  constructor(
    public payload?: {
      message: string;
    },
  ) {}
}

export class CompareScenarioTopologyTab implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_TOPOLOGY_TAB;
  constructor(
    public payload?: {
      projectId: string;
      caseId: string;
      resultList: Array<Result>;
    },
  ) {}
}
export class CompareScenarioTopologyTabSuccess implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_TOPOLOGY_TAB_SUCCESS;
  constructor(
    public payload?: {
      data: ComparisonTray<TopologyResult>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}
export class CompareScenarioTopologyTabFailure implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_TOPOLOGY_TAB_FAILURE;
  constructor(
    public payload?: {
      message: string;
    },
  ) {}
}

export class CompareScenarioCO2EmissionTab implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_CO2_EMISSION_TAB;
  constructor(
    public payload?: {
      projectId: string;
      caseId: string;
      resultList: Array<Result>;
    },
  ) {}
}
export class CompareScenarioCO2EmissionTabSuccess implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_CO2_EMISSION_TAB_SUCCESS;
  constructor(
    public payload?: {
      data: ComparisonTray<EmissionsData>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}
export class CompareScenarioCO2EmissionTabFailure implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_CO2_EMISSION_TAB_FAILURE;
  constructor(
    public payload?: {
      message: string;
    },
  ) {}
}

export class CompareScenarioCashFlowTab implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_CASHFLOW_TAB;
  constructor(
    public payload?: {
      projectId: string;
      caseId: string;
      resultList: Array<Result>;
    },
  ) {}
}
export class CompareScenarioCashFlowTabSuccess implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_CASHFLOW_TAB_SUCCESS;
  constructor(
    public payload?: {
      data: Array<[CashFlowResult, PaybackResult]>;
      message?: string;
      notify?: boolean;
    },
  ) {}
}
export class CompareScenarioCashFlowTabFailure implements Action {
  readonly type = CompareActionTypes.COMPARE_SCENARIO_CASHFLOW_TAB_FAILURE;
  constructor(
    public payload?: {
      message: string;
    },
  ) {}
}

export type AllCompareActions =
  | AllActions<ScenarioResult>
  | CompareScenario
  | CompareScenarioSuccess
  | CompareScenarioFailure
  | MigrateScenario
  | MigrateScenarioSuccess
  | MigrateScenarioFailure
  | CompareScenarioMainTab
  | CompareScenarioMainTabSuccess
  | CompareScenarioMainTabFailure
  | CompareScenarioEquipmentTab
  | CompareScenarioEquipmentTabSuccess
  | CompareScenarioEquipmentTabFailure
  | CompareScenarioDispatchTab
  | CompareScenarioDispatchTabSuccess
  | CompareScenarioDispatchTabFailure
  | CompareScenarioEnergyBalanceTab
  | CompareScenarioEnergyBalanceTabSuccess
  | CompareScenarioEnergyBalanceTabFailure
  | CompareScenarioTopologyTab
  | CompareScenarioTopologyTabSuccess
  | CompareScenarioTopologyTabFailure
  | CompareScenarioCO2EmissionTab
  | CompareScenarioCO2EmissionTabSuccess
  | CompareScenarioCO2EmissionTabFailure
  | CompareScenarioCashFlowTab
  | CompareScenarioCashFlowTabSuccess
  | CompareScenarioCashFlowTabFailure;
