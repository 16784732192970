import { Utils } from 'prosumer-core/utils/utils';

import { BaseExtractor } from './base.extractor';

export class AllYearsExtractor implements BaseExtractor<number> {
  extract(resultsJson: unknown | number[]): number[] {
    return resolveToNumberArray(resultsJson['all_years'] || resultsJson);
  }
}

export class OptimizedYearsExtractor implements BaseExtractor<number> {
  extract(resultsJson: unknown | number[]): number[] {
    return resolveToNumberArray(resultsJson['optimized_years'] || resultsJson);
  }
}

function resolveToNumberArray(alleged: unknown[]): number[] {
  return Utils.resolveToEmptyArray(alleged).map((allegedNumber) =>
    Number(allegedNumber),
  );
}
