import { Injectable } from '@angular/core';
import { EnergyVectorReq } from 'prosumer-app/+scenario/models';
import {
  EnergyVectorInfo,
  EnergyVectorStore,
} from 'prosumer-app/stores/energy-vector';
import { Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class EnergyVectorInfoService {
  constructor(private readonly store: EnergyVectorStore) {}

  getEnergyVectors(): Observable<unknown> {
    return this.store.getEnergyVectors({});
  }

  createEnergyVector(energyVector: EnergyVectorReq): Observable<unknown> {
    return this.store.createEnergyVector(energyVector as EnergyVectorInfo);
  }

  deleteEnergyVector(id: string): Observable<unknown> {
    return this.store.deleteOne(id);
  }
}
