import {
  CashFlowResult,
  ComparisonTabsName,
  DispatchResult,
  EnergyBalanceResult,
  PaybackResult,
  Result,
  ScenarioResult,
} from 'prosumer-app/+scenario/models';
import { ComparisonPreparerService } from 'prosumer-app/comparison/state';
import { ConfigService } from 'prosumer-app/libs/eyes-core';
import { ApiHandler } from 'prosumer-app/libs/eyes-shared';
import { Observable, Observer, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComparisonTray } from '@prosumer/comparison/base/comparison-models';
import { EmissionsData, TopologyResult } from '@prosumer/results/models';
import { EquipmentResult } from '@prosumer/results/models/equipment-results.model';
import { EmissionsExtractor, TopologyExtractor } from '@prosumer/results/state';
import { EquipmentExtractor } from '@prosumer/results/state/equipment.extractor';

import { ResultsMapper } from './mappers/result-mapper';

@Injectable()
export class ScenarioCompareApiService extends ApiHandler {
  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private comparisonPreparer: ComparisonPreparerService,
  ) {
    super(_config.api.baseUrl);
  }
  private _resultSignedEndpoint =
    this._config.api.endpoints.scenario?.getResultSigned;

  getSignedUrl(
    { url, scenarioId, projectId, caseId },
    downloadSignedUrl,
  ): Observable<any> {
    return this._http
      .get<Array<string>>(this.endpoint(url, scenarioId, { projectId, caseId }))
      .pipe(
        switchMap((response: any) => downloadSignedUrl(response.url, response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  downloadSignedUrl(url): Observable<any> {
    return this._http.get(url, { responseType: 'blob' }).pipe(
      switchMap(
        (response: any) =>
          new Observable((observer: Observer<any>) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              observer.next(reader.result);
              observer.complete();
            };
            reader.readAsText(response);
          }),
      ),
    );
  }

  compare(
    referenceId: string,
    projectId: string,
    caseId: string,
    scenarioId: string,
    name: string,
    variationId: string = undefined,
  ): Observable<ScenarioResult> {
    const url = `${this._resultSignedEndpoint}?${this.addVariationQueryParam(
      variationId,
    )}&action=download`;
    return this.getSignedUrl(
      {
        url,
        projectId,
        caseId,
        scenarioId,
      },
      (signedURL) =>
        this.downloadSignedUrl(signedURL).pipe(
          tap((data) =>
            this.comparisonPreparer.addToTray(name, JSON.parse(data)),
          ),
          map((data) =>
            ResultsMapper.mapToFrontEnd(
              referenceId,
              scenarioId,
              name,
              JSON.parse(data as string),
              variationId,
            ),
          ),
        ),
    );
  }

  compareMain(
    referenceId: string,
    projectId: string,
    caseId: string,
    scenarioId: string,
    name: string,
    variationId: string = undefined,
  ): Observable<ScenarioResult> {
    const url = this.buildEntityId(ComparisonTabsName.main, variationId);
    return this.getSignedUrl(
      {
        url,
        projectId,
        caseId,
        scenarioId,
      },
      (signedURL) =>
        this.downloadSignedUrl(signedURL).pipe(
          tap((data) =>
            this.comparisonPreparer.addToTray(name, JSON.parse(data)),
          ),
          map((data) =>
            ResultsMapper.mapMainSplitResult(
              referenceId,
              scenarioId,
              name,
              JSON.parse(data as string),
              variationId,
            ),
          ),
        ),
    );
  }

  compareEquipment(
    projectId: string,
    caseId: string,
    scenarioId: string,
    name: string,
    variationId: string = undefined,
  ): Observable<ComparisonTray<EquipmentResult>> {
    const url = this.buildEntityId(ComparisonTabsName.equipment, variationId);
    return this.getSignedUrl(
      {
        url,
        projectId,
        caseId,
        scenarioId,
      },
      (signedURL) =>
        this.downloadSignedUrl(signedURL).pipe(
          tap((data) =>
            this.comparisonPreparer.addToTray(name, JSON.parse(data)),
          ),
          map((data) => {
            const extractor = new EquipmentExtractor();
            const extractedData = extractor.extract(JSON.parse(data as string));
            return {
              [name]: extractedData,
            };
          }),
        ),
    );
  }

  compareDispatch(
    projectId: string,
    caseId: string,
    scenarioId,
    name: string,
    variationId: string = undefined,
  ): Observable<DispatchResult> {
    const url = this.buildEntityId(ComparisonTabsName.dispatch, variationId);
    return this.getSignedUrl(
      {
        url,
        projectId,
        caseId,
        scenarioId,
      },
      (signedURL, details) =>
        this.downloadSignedUrl(signedURL).pipe(
          map((data) => {
            const { dispatch } = JSON.parse(data as string);
            return {
              ...ResultsMapper.mapDispatchToFrontend(dispatch),
              ...ResultsMapper.mapScenarioDetails(details),
            };
          }),
        ),
    );
  }

  compareEnergyBalance(
    projectId: string,
    caseId: string,
    scenarioId,
    name: string,
    variationId: string = undefined,
  ): Observable<EnergyBalanceResult> {
    const url = this.buildEntityId(
      ComparisonTabsName.energyBalance,
      variationId,
    );
    return this.getSignedUrl(
      {
        url,
        projectId,
        caseId,
        scenarioId,
      },
      (signedURL, details) =>
        this.downloadSignedUrl(signedURL).pipe(
          tap((data) =>
            this.comparisonPreparer.addToTray(name, JSON.parse(data)),
          ),
          map((data) => {
            const { energy_balance } = JSON.parse(data as string);
            return {
              ...ResultsMapper.mapEnergyBalanceResultToFrontend(energy_balance),
              ...ResultsMapper.mapScenarioDetails(details),
            };
          }),
        ),
    );
  }

  compareTopology(
    projectId: string,
    caseId: string,
    scenarioId,
    name: string,
    variationId: string = undefined,
  ): Observable<ComparisonTray<TopologyResult>> {
    const url = this.buildEntityId(ComparisonTabsName.topology, variationId);
    return this.getSignedUrl(
      {
        url,
        projectId,
        caseId,
        scenarioId,
      },
      (signedURL) =>
        this.downloadSignedUrl(signedURL).pipe(
          tap((data) =>
            this.comparisonPreparer.addToTray(name, JSON.parse(data)),
          ),
          map((data) => {
            const extractor = new TopologyExtractor();
            const extractedData = extractor.extract(JSON.parse(data as string));
            return {
              [name]: extractedData,
            };
          }),
        ),
    );
  }

  compareCO2Emission(
    projectId: string,
    caseId: string,
    scenarioId,
    name: string,
    variationId: string = undefined,
  ): Observable<ComparisonTray<EmissionsData>> {
    const url = this.buildEntityId(
      ComparisonTabsName.co2Emissions,
      variationId,
    );
    return this.getSignedUrl(
      {
        url,
        projectId,
        caseId,
        scenarioId,
      },
      (signedURL) =>
        this.downloadSignedUrl(signedURL).pipe(
          tap((data) => {
            this.comparisonPreparer.addToTray(name, JSON.parse(data));
          }),
          map((data) => {
            const extractor = new EmissionsExtractor();
            const extractedData = extractor.extract(JSON.parse(data as string));
            return {
              [name]: extractedData,
            };
          }),
        ),
    );
  }
  compareCashFlow(
    projectId: string,
    caseId: string,
    scenarioId,
    name: string,
    variationId: string = undefined,
  ): Observable<[CashFlowResult, PaybackResult]> {
    const url = this.buildEntityId(ComparisonTabsName.cashflow, variationId);
    return this.getSignedUrl(
      {
        url,
        projectId,
        caseId,
        scenarioId,
      },
      (signedURL) =>
        this.downloadSignedUrl(signedURL).pipe(
          tap((data) =>
            this.comparisonPreparer.addToTray(name, JSON.parse(data)),
          ),
          map((data) => {
            const { incremental_cashflow } = JSON.parse(data as string);
            return [
              ResultsMapper.mapCashFlowResultToFrontEnd(incremental_cashflow),
              ResultsMapper.mapPaybackResultItemValue(incremental_cashflow),
            ];
          }),
        ),
    );
  }

  migrate(
    projectId: string,
    caseId: string,
    scenarioId: string,
    variationId?: string,
  ): Observable<Result> {
    return this._http
      .post(
        this.endpoint(this._config.api.endpoints.scenario.migrate, scenarioId, {
          projectId,
          caseId,
        }),
        {
          isMultipleMigration: true,
          ...(variationId && { variationUuid: variationId }),
        },
      )
      .pipe(
        map((data) => {
          const isOutputSplit = !!data['run'] && !!data['run']['isOutputSplit'];
          return {
            name: data['name'],
            id: data['scenarioUuid'],
            isOutputSplit,
          };
        }),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  private buildEntityId = (tabName: string, variationId: string) =>
    `${this._resultSignedEndpoint}?tab=${tabName}${this.addVariationQueryParam(
      variationId,
    )}&action=download`;

  private addVariationQueryParam = (variationId?: string) =>
    variationId ? `&variationUuid=${variationId}` : '';
}
