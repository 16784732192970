import { GeneralForm } from '../../models';

const ECONOMICS_DEFAULT_VALUE = '0.0';

export class GeneralMapper {
  static mapToBackend = (generalForm: GeneralForm) =>
    !!generalForm
      ? {
          name: generalForm.name,
          economics: {
            wacc: generalForm.wacc,
            inflationRate: ECONOMICS_DEFAULT_VALUE,
            taxDepreciation: ECONOMICS_DEFAULT_VALUE,
            taxRevenue: ECONOMICS_DEFAULT_VALUE,
          },
          energyVectors: [
            ...((generalForm.energyVectors || {}).predefined || []).map(
              (predefined) => ({
                name: predefined.name,
                id: predefined.value,
                isCustom: false,
              }),
            ),
            ...((generalForm.energyVectors || {}).others || []).map(
              (others) => ({
                name: others.name,
                id: others.value,
                isCustom: true,
                vectorType: others.type,
              }),
            ),
          ],
          scenarioVariations: (generalForm.scenarioVariations || []).map(
            (variation) => ({
              name: variation.name,
              id: variation.value,
            }),
          ),
        }
      : {};

  static mapToFrontend = (scenario: {
    name;
    economics;
    energyVectors;
    scenarioVariations;
  }): GeneralForm =>
    !!scenario
      ? {
          name: scenario.name,
          wacc: (scenario.economics || {}).wacc,
          // Disabled mapping of energyVector
          // energyVectors: {
          //   predefined: (scenario.energyVectors || [])
          //     .filter((energyVector) => !energyVector.isCustom)
          //     .map((predefined) => ({
          //       name: toTitleCase(predefined.name),
          //       value: predefined.id,
          //     })),
          //   others: (scenario.energyVectors || [])
          //     .filter((energyVector) => energyVector.isCustom)
          //     .map((custom) => ({
          //       name: custom.name,
          //       value: custom.id,
          //       type: custom.vectorType,
          //       custom: true,
          //     })),
          // },
          scenarioVariations: (scenario.scenarioVariations || []).map(
            (variation) => ({
              name: variation.name,
              value: variation.id,
            }),
          ),
        }
      : {};
}
