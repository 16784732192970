import { NgModule, ModuleWithProviders } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { scenarioFeatureName } from './scenario.actions';
import { scenarioReducer } from './scenario.reducer';
import { ScenarioStore } from './scenario-store.service';
import { ScenarioEffects } from './scenario.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(scenarioFeatureName, scenarioReducer),
    EffectsModule.forFeature([ScenarioEffects]),
  ],
})
export class ScenarioStoreModule {
  static forRoot(): ModuleWithProviders<ScenarioStoreModule> {
    return {
      ngModule: ScenarioStoreModule,
      providers: [ScenarioStore],
    };
  }
}
