import { ConfigService } from 'prosumer-app/libs/eyes-core';
import { ApiService } from 'prosumer-app/libs/eyes-shared';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Project, User } from '../models';

@Injectable()
export class ProjectApiService extends ApiService<Project> {
  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
  ) {
    super(_config.api.baseUrl);
  }

  create(project: Project): Observable<Project> {
    return this._http
      .post(
        this.endpoint(this._config.api.endpoints.project.create),
        this.mapDataToBackend(project),
      )
      .pipe(
        map((response) => this.mapDataToFrontend(response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  get(id: string): Observable<Project> {
    return this._http
      .get<any>(this.endpoint(this._config.api.endpoints.project.get, id))
      .pipe(
        map((response) => this.mapDataToFrontend(response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  getList(): Observable<Array<string>> {
    return this._http
      .get<
        Array<string>
      >(this.endpoint(this._config.api.endpoints.project.list))
      .pipe(catchError((error) => throwError(this.handleError(error))));
  }

  /* For getting list of cases: slim=2 */
  getListWithDetails(): Observable<Array<Project>> {
    return this._http
      .get<
        Array<Project>
      >(this.endpoint(this._config.api.endpoints.project.listWithDetails))
      .pipe(
        map((projects) =>
          projects.map((data) => this.mapDataToFrontend(data) as Project),
        ),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  update(project: Project): Observable<Project> {
    return this._http
      .post(
        this.endpoint(this._config.api.endpoints.project.update, project.id),
        this.mapDataToBackend(project),
      )
      .pipe(
        map((response) => this.mapDataToFrontend(response)),
        catchError((error) => throwError(this.handleError(error))),
      );
  }

  delete(project: Project): Observable<any> {
    return this._http
      .delete<any>(
        this.endpoint(this._config.api.endpoints.project.delete, project.id),
      )
      .pipe(catchError((error) => throwError(this.handleError(error))));
  }

  copy(project: Project, name: string): Observable<any> {
    return this._http
      .post<any>(
        this.endpoint(this._config.api.endpoints.project.copy, project.id),
        { name },
      )
      .pipe(catchError((error) => throwError(this.handleError(error))));
  }

  share(project: Project, userIds: Array<User>): Observable<any> {
    return this._http
      .post<any>(
        this.endpoint(this._config.api.endpoints.project.share, project.id),
        { users: userIds },
      )
      .pipe(catchError((error) => throwError(this.handleError(error))));
  }

  unshare(project: Project, userIds: Array<string>): Observable<any> {
    return this._http
      .post<any>(
        this.endpoint(this._config.api.endpoints.project.unshare, project.id),
        { users: userIds },
      )
      .pipe(catchError((error) => throwError(this.handleError(error))));
  }

  mapDataToBackend(project: Project): any {
    return {
      projectUuid: project.id,
      owner: project.owner,
      name: project.name,
      description: project.description,
      createdAt: project.createdDate,
      updatedAt: project.updatedDate,
      shareInfo: project.sharedTo
        ? this.mapShareInfoToBackend(project)
        : undefined,
    };
  }

  mapDataToFrontend(response: any): Project {
    return {
      id: response.projectUuid,
      name: response.name,
      description: response.description,
      createdDate: response.createdAt,
      updatedDate: response.updatedAt,
      owner: response.owner,
      ownerName: response.ownerName,
      copyStatus: response.duplicateStatus,
      isOwned: response.isOwned,
      sharedTo: response.shareInfo
        ? response.shareInfo.sharedTo.map((data) =>
            this.buildSharedUserSkeleton(data),
          )
        : [],
      permissions: response.permissions,
      createdBy: response.createdBy,
      role: response.role,
    };
  }

  mapShareInfoToBackend(response: Project): any {
    return {
      isShared: response.isShared,
      sharedTo: response.sharedTo.map((userInfo) => ({
        id: userInfo.id,
        permissions: userInfo.permissions,
      })),
    };
  }

  buildSharedUserSkeleton({ id, permissions }: User): User {
    return { id, permissions };
  }
}
