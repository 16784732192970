import { FormFieldOption, StateFacadeService } from 'prosumer-libs/eyes-shared';
import { EquipmentCircuitInfo, Scenario } from 'prosumer-scenario/models';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Action, ActionsSubject, select, Store } from '@ngrx/store';

import {
  CoherenceResult,
  DownloadEntity,
  EnergyGridConnection,
  EnergyGridConnectionsEmissions,
  EnergyVector,
  Equipment,
  Fuel,
  Line,
  Load,
  Metering,
  Netting,
  Node,
  Routes,
  ScenarioVariation,
  StationVehicleAssoc,
  VehiclesDispatch,
} from '../models';
import { ScenarioState } from './scenario-state.model';
import * as ScenarioActions from './scenario.actions';
import { scenarioStateFactory } from './scenario.factory';
import { scenarioQueries } from './scenario.selectors';

@Injectable()
export class ScenarioFacadeService extends StateFacadeService<
  ScenarioState,
  Scenario
> {
  selectedStartYear$ = this.store.pipe<number>(
    select(scenarioQueries.selectedStartYear),
  );
  selectedEndYear$ = this.store.pipe<number>(
    select(scenarioQueries.selectedEndYear),
  );

  predefinedEnergyVectors$ = this.store.pipe(
    select(scenarioQueries.predefinedEnergyVectors),
  );

  selectedEnergyVectors$ = this.store.pipe<Array<EnergyVector>>(
    select(scenarioQueries.selectedEnergyVectors),
  );
  selectedNodes$ = this.store.pipe<Array<Node>>(
    select(scenarioQueries.selectedNodes),
  );
  selectedEquipments$ = this.store.pipe<Array<Equipment>>(
    select(scenarioQueries.selectedEquipments),
  );
  selectedLines$ = this.store.pipe<Array<Line>>(
    select(scenarioQueries.selectedLines),
  );
  selectedLoads$ = this.store.pipe<Array<Load>>(
    select(scenarioQueries.selectedLoads),
  ); // TODO
  selectedEnergyGridConnections$ = this.store.pipe<Array<EnergyGridConnection>>(
    select(scenarioQueries.selectedEnergyGridConnections),
  );
  selectedFuels$ = this.store.pipe<Array<Fuel>>(
    select(scenarioQueries.selectedFuels),
  );
  selectedMeterings$ = this.store.pipe<Array<Metering>>(
    select(scenarioQueries.selectedMeterings),
  );
  selectedShortCircuitEquipments$ = this.store.pipe<
    Array<EquipmentCircuitInfo>
  >(select(scenarioQueries.selectedEquipmentShortCircuits));
  selectedConnectionsEmissions$ = this.store.pipe<
    Array<EnergyGridConnectionsEmissions>
  >(select(scenarioQueries.selectedConnectionsEmissions));
  selectedNettings$ = this.store.pipe<Array<Netting>>(
    select(scenarioQueries.selectedNetting),
  );
  selectedScenarioVariations$ = this.store.pipe<Array<ScenarioVariation>>(
    select(scenarioQueries.selectedScenarioVariations),
  );
  selectedScenarioSucceededVariations$ = this.store.pipe<
    Array<FormFieldOption<string>>
  >(select(scenarioQueries.selectedScenarioSucceededVariations));

  validating$ = this.store.pipe<boolean>(select(scenarioQueries.validating));
  recentCoherenceResults$ = this.store.pipe<Array<CoherenceResult>>(
    select(scenarioQueries.coherenceResults),
  );
  uploading$ = this.store.pipe<boolean>(select(scenarioQueries.uploading));
  uploadingEntitiesStatus$ = this.store.pipe(
    select(scenarioQueries.uploadingEntitiesStatus),
  );
  simulating$ = this.store.pipe<boolean>(select(scenarioQueries.simulating));
  launched$ = this.store.pipe<boolean>(select(scenarioQueries.launched));
  selectedVehicles$ = this.store.pipe<Array<VehiclesDispatch>>(
    select(scenarioQueries.selectedVehiclesDispatch),
  );
  selectedStationVehicleAssoc$ = this.store.pipe<Array<StationVehicleAssoc>>(
    select(scenarioQueries.selectedStationVehicleAssoc),
  );

  selectedScenarioVariationId$ = this.store.pipe<string>(
    select(scenarioQueries.selectedScenarioVariationId),
  );

  deletedEnergyVectors$ = (energyVectorsToCompare: Array<EnergyVector>) =>
    this.store.pipe(
      select(scenarioQueries.deletedEnergyVectors, energyVectorsToCompare),
    );

  deletedScenarioVariations$ = (
    scenarioVariationToCompare: Array<ScenarioVariation>,
  ) =>
    this.store.pipe(
      select(
        scenarioQueries.deletedScenarioVariations,
        scenarioVariationToCompare,
      ),
    );

  deletedNodes$ = (nodesToCompare: Array<Node>) =>
    this.store.pipe(select(scenarioQueries.deletedNodes, nodesToCompare));

  deletedLoads$ = (loadsToCompare: Array<Load>) =>
    this.store.pipe(select(scenarioQueries.deletedLoads, loadsToCompare));

  deleteEquipments$ = (equipmentsToCompare: Array<Equipment>) =>
    this.store.pipe(
      select(scenarioQueries.deletedEquipments, equipmentsToCompare),
    );

  deleteEnergyGridConnections$ = (egcsToCompare: EnergyGridConnection[]) =>
    this.store.pipe(select(scenarioQueries.deletedEnergyGrids, egcsToCompare));

  deleteRoutes$ = (routesToCompare: Routes[]) =>
    this.store.pipe(select(scenarioQueries.deletedRoutes, routesToCompare));

  deleteVehicles$ = (vehiclesToCompare: VehiclesDispatch[]) =>
    this.store.pipe(select(scenarioQueries.deletedVehicles, vehiclesToCompare));

  deleteStationVehiclesAssoc$ = (
    stationVehicleAssocToCompare: StationVehicleAssoc[],
  ) =>
    this.store.pipe(
      select(
        scenarioQueries.deletedStationsVehicleAssoc,
        stationVehicleAssocToCompare,
      ),
    );

  edittedLoads$ = (loadsToCompare: Array<Load>) =>
    this.store.pipe(select(scenarioQueries.edittedLoads, loadsToCompare));

  edittedEquipments$ = (equipmentsToCompare: Array<Equipment>) =>
    this.store.pipe(
      select(scenarioQueries.edittedEquipments, equipmentsToCompare),
    );

  filterEnergyGridConnectionsByScenarioVariation$ = (variationId: string) =>
    this.store.pipe(
      select(
        scenarioQueries.filterEnergyGridConnectionsByVariation,
        variationId,
      ),
    );

  filterConnectionsEmissionsByScenarioVariation$ = (variationId: string) =>
    this.store.pipe(
      select(
        scenarioQueries.filterConnectionsEmissionsByVariation,
        variationId,
      ),
    );

  filterEnergyGridLimitsByScenarioVariation$ = (variationId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterEnergyGridLimitsByVariation, variationId),
    );

  filterReserveMarketByScenarioVariation$ = (variationId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterReserveMarketByVariation, variationId),
    );

  filterEnergyGridConnectionsByEnergyVector$ = (energyVectorId: string) =>
    this.store.pipe(
      select(
        scenarioQueries.filterEnergyGridConnectionsByEnergyVector,
        energyVectorId,
      ),
    );

  filterEnergyGridConnectionEmissionsByEnergyVector$ = (
    energyVectorId: string,
  ) =>
    this.store.pipe(
      select(
        scenarioQueries.filterEnergyGridConnectionEmissionsByEnergyVector,
        energyVectorId,
      ),
    );

  filterAffectedEnergyGridLimitsByEnergyVector$ = (energyVectorId: string) =>
    this.store.pipe(
      select(
        scenarioQueries.filterAffectedEnergyGridLimitsByEnergyVector,
        energyVectorId,
      ),
    );

  filterEnergyGridConnectionsByNode$ = (nodeId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterEnergyGridConnectionsByNode, nodeId),
    );

  filterConnectionsEmissionsByNode$ = (nodeId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterConnectionsEmissionsByNode, nodeId),
    );

  filterEnergyGridLimitsByNodeAndConnection$ = (nodeId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterEnergyGridLimitsByNodeAndConnection, nodeId),
    );

  filterConnectionsEmissionsByEnergyGrid$ = (gridId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterConnectionsEmissionsByEnergyGrid, gridId),
    );

  filterEquipmentsByEnergyVector$ = (energyVectorId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterEquipmentsByEnergyVector, energyVectorId),
    );

  filterVehiclesByEnergyVectors$ = (energyVectorId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterVehiclesByEnergyVectors, energyVectorId),
    );

  filterStationVehiclesAssocsByEnergyVectors$ = (energyVectorId: string) =>
    this.store.pipe(
      select(
        scenarioQueries.filterStationVehiclesAssocsByEnergyVectors,
        energyVectorId,
      ),
    );

  filterEquipmentsByNode$ = (nodeId: string) =>
    this.store.pipe(select(scenarioQueries.filterEquipmentsNode, nodeId));

  filterFuelsByEnergyVector$ = (energyVectorId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterFuelsByEnergyVector, energyVectorId),
    );

  filterFuelsByNode$ = (nodeId: string) =>
    this.store.pipe(select(scenarioQueries.filterFuelsByNode, nodeId));

  filterLinesByEnergyVector$ = (energyVectorId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterLinesByEnergyVector, energyVectorId),
    );

  filterLinesByNode$ = (nodeId: string) =>
    this.store.pipe(select(scenarioQueries.filterLinesByNode, nodeId));

  filterLoadsByEnergyVector$ = (energyVectorId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterLoadsByEnergyVector, energyVectorId),
    );

  filterLoadsByNode$ = (nodeId: string) =>
    this.store.pipe(select(scenarioQueries.filterLoadsByNode, nodeId));

  filterMeteringsByEquipment$ = (equipmentId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterMeteringsByEquipment, equipmentId),
    );

  filterShortCircuitByEnergyVector$ = (energyVectorId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterShortCircuitsByEnergyVector, energyVectorId),
    );

  filterShortCircuitsByEquipment$ = (equipmentId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterShortCircuitsByEquipment, equipmentId),
    );

  filterReservesByEnergyVector$ = (vectorId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterReservesByEnergyVector, vectorId),
    );

  filterReservesByEquipments$ = (equipmentId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterReservesByEquipments, equipmentId),
    );

  filterVehiclesDispatchByEquipments$ = (equipmentId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterVehiclesDispatchByEquipments, equipmentId),
    );

  filterStationVehicleAssocByEquipments$ = (equipmentId: string) =>
    this.store.pipe(
      select(
        scenarioQueries.filterStationVehicleAssocByEquipments,
        equipmentId,
      ),
    );

  filterReservesByEnergyGrid$ = (energyGridConnectionId: string) =>
    this.store.pipe(
      select(
        scenarioQueries.filterReservesByEnergyGrid,
        energyGridConnectionId,
      ),
    );

  filterRoutesByNode$ = (nodeId: string) =>
    this.store.pipe(select(scenarioQueries.filterRoutesByNode, nodeId));

  filterVehiclesByNode$ = (nodeId: string) =>
    this.store.pipe(select(scenarioQueries.filterVehiclesByNode, nodeId));

  filterSationVehiclesAssocByNode$ = (nodeId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterStationVehicleAssocByNode, nodeId),
    );

  filterVehiclesDispatchByRoutes$ = (routeId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterVehiclesDispatchByRoute, routeId),
    );

  filterStationVehicleAssocByRoutes$ = (routeId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterStationVehicleAssocByRoute, routeId),
    );

  filterStationVehicleAssocByVehicle$ = (vehicleId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterStationVehicleAssocByVehicle, vehicleId),
    );

  filterNettingsByEnergyVector$ = (energyVectorId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterNettingsByEnergyVector, energyVectorId),
    );

  filterAffectedNettingsByEnergyVector$ = (energyVectorId: string) =>
    this.store.pipe(
      select(
        scenarioQueries.filterAffectedNettingsByEnergyVector,
        energyVectorId,
      ),
    );

  filterNettingsByNode$ = (nodeId: string) =>
    this.store.pipe(select(scenarioQueries.filterNettingsByNode, nodeId));

  filterNettingsByLoad$ = (loadId: string) =>
    this.store.pipe(select(scenarioQueries.filterNettingsByLoad, loadId));

  filterNettingsByEquipment$ = (equipmentId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterNettingsByEquipment, equipmentId),
    );

  filterTaxAndSubsidiesByNodeAndNetting$ = (nodeId: string) =>
    this.store.pipe(
      select(scenarioQueries.filterTaxAndSubsidiesByNodeAndNetting, nodeId),
    );

  filterAffectedTaxAndSubsidiesByEnergyVector$ = (energyVectorId: string) =>
    this.store.pipe(
      select(
        scenarioQueries.filterAffectedTaxAndSubsidiesByEnergyVector,
        energyVectorId,
      ),
    );

  filterAffectedTaxAndSubsidiesByEquipment$ = (equipmentId: string) =>
    this.store.pipe(
      select(
        scenarioQueries.filterAffectedTaxAndSubsidiesByEquipment,
        equipmentId,
      ),
    );

  filterScenarios$ = (projectId: string, caseId: string) =>
    this.store.pipe<Array<Scenario>>(
      select(scenarioQueries.filterScenarios, { projectId, caseId }),
    );

  filterScenarioList$ = (projectId: string, caseId: string) =>
    this.store.pipe<Array<Scenario>>(
      select(scenarioQueries.filterScenarios, { projectId, caseId }),
    );

  constructor(
    public store: Store<ScenarioState>,
    public actionSubject$: ActionsSubject,
  ) {
    super(store, scenarioStateFactory, actionSubject$);
  }

  copy(
    data: Scenario,
    name: string,
    targetProjectId?: string,
    targetCaseId?: string,
  ) {
    this.store.dispatch(
      new ScenarioActions.CopyScenario({
        data,
        name,
        targetProjectId,
        targetCaseId,
      }),
    );
  }

  remove(id: string) {
    this.store.dispatch(new ScenarioActions.RemoveScenario({ id }));
  }

  download(
    data: Scenario,
    endpointName: string,
    downloadEntity: DownloadEntity,
  ) {
    this.store.dispatch(
      new ScenarioActions.Download({
        data,
        endpoint: endpointName,
        downloadEntity,
      }),
    );
  }

  downloadDraftInput(data: Scenario, downloadEntity: DownloadEntity) {
    this.store.dispatch(
      new ScenarioActions.DownloadDraftInput({ data, downloadEntity }),
    );
  }

  getDraftInput(data: Scenario, downloadEntity: DownloadEntity) {
    this.store.dispatch(
      new ScenarioActions.GetDraftInput({ data, downloadEntity }),
    );
  }

  upload(data: Scenario, file: File, gamsFiles: File[] = []) {
    this.store.dispatch(new ScenarioActions.Upload({ data, file, gamsFiles }));
  }

  uploadClone(data: Scenario, file: File) {
    this.store.dispatch(new ScenarioActions.UploadClone({ data, file }));
  }

  launch(data: Scenario) {
    this.store.dispatch(new ScenarioActions.Launch({ data }));
  }

  resetCoherence(id: string) {
    this.store.dispatch(new ScenarioActions.ValidateReset({ id }));
  }

  validate(data: Scenario) {
    this.store.dispatch(new ScenarioActions.Validate({ data }));
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type === ScenarioActions.ActionTypes.VALIDATE_SUCCESS,
      ),
    );
  }

  getCaseDetailScenarioList(
    id?: string,
    params?: Dictionary<string>,
  ): Observable<Action> {
    this.store.dispatch(
      new ScenarioActions.GetCaseDetailScenarioList({ id, params }),
    );
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type ===
          ScenarioActions.ActionTypes.GET_CASE_DETAIL_SCENARIO_LIST_SUCCESS,
      ),
    );
  }

  getLatestSimulations() {
    this.store.dispatch(new ScenarioActions.GetLatestSimulations());
  }

  updateWithBinaryHandling(data: Scenario) {
    this.store.dispatch(new ScenarioActions.UpdateWithBinaryHandling({ data }));
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type ===
          ScenarioActions.ActionTypes.UPDATE_WITH_BINARY_HANDLING_SUCCESS,
      ),
    );
  }

  /*   // fetch single variation - for future implementation
  getScenarioVariation(id?: string, params?: Dictionary<string | string[]>): Observable<Action> {
    this.store.dispatch(new ScenarioActions.GetScenarioVariation({ id, params }));
    return this.actionSubject$.pipe(
      filter((action) => action.type === ScenarioActions.ActionTypes.GET_SCENARIO_VARIATIONS_SUCCESS),
    );
  } */

  getScenarioVariations(
    id?: string,
    params?: Dictionary<string | string[]>,
  ): Observable<Action> {
    this.store.dispatch(
      new ScenarioActions.GetScenarioVariations({ id, params }),
    );
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type ===
          ScenarioActions.ActionTypes.GET_SCENARIO_VARIATIONS_SUCCESS,
      ),
    );
  }

  getScenariosAndVariations(
    params?: Dictionary<string | string[]>,
  ): Observable<Action> {
    this.store.dispatch(
      new ScenarioActions.GetScenariosAndVariations({ params }),
    );
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type ===
          ScenarioActions.ActionTypes.GET_SCENARIOS_AND_VARIATIONS_SUCCESS,
      ),
    );
  }

  selectScenarioVariationId(id: string) {
    this.store.dispatch(new ScenarioActions.SelectScenarioVariationId({ id }));
  }

  updateScenarioFromCaseView(data: Scenario): Observable<Action> {
    this.store.dispatch(
      new ScenarioActions.UpdateScenarioFromCaseView({ data }),
    );
    return this.actionSubject$.pipe(
      filter(
        (action) =>
          action.type ===
          ScenarioActions.ActionTypes.UPDATE_SCENARIO_FROM_CASE_VIEW_SUCCESS,
      ),
    );
  }
}
