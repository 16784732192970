import { CaseState } from './case-state.model';
import * as CaseActions from './case.actions';
import { caseStateFactory } from './case.factory';

export function caseReducer(
  state: CaseState = caseStateFactory.initialState,
  action: CaseActions.All,
): CaseState {
  switch (action.type) {
    // Copy
    case CaseActions.ActionTypes.COPY_CASE:
      return caseStateFactory.adapter.upsertOne(
        { ...action.payload.data, copying: true, error: undefined },
        state,
      );
    case CaseActions.ActionTypes.COPY_CASE_FAILURE:
      return caseStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          copying: false,
          error: action.payload.message,
        },
        state,
      );
    case CaseActions.ActionTypes.COPY_CASE_SUCCESS:
      return caseStateFactory.adapter.upsertOne(
        { ...action.payload.data, copying: false },
        state,
      );

    // Get case list by nodetype
    case CaseActions.ActionTypes.GET_CASE_LIST_BY_NODETYPE:
      return caseStateFactory.adapter.upsertOne(
        { ...action.payload.data, loading: true, error: undefined },
        state,
      );
    case CaseActions.ActionTypes.GET_CASE_LIST_BY_NODETYPE_FAILURE:
      return caseStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          loading: false,
          error: action.payload.message,
        },
        state,
      );
    case CaseActions.ActionTypes.GET_CASE_LIST_BY_NODETYPE_SUCCESS:
      return caseStateFactory.adapter.upsertOne(
        {
          ...action.payload.data,
          loading: false,
          error: action.payload.message,
        },
        state,
      );

    // Remove
    case CaseActions.ActionTypes.REMOVE_CASE:
      return caseStateFactory.adapter.removeOne(action.payload.id, state);

    // Update Chart Colors
    case CaseActions.ActionTypes.UPDATE_CHART_COLORS_OK:
      return caseStateFactory.adapter.upsertOne(action?.payload?.data, state);

    // Get Case List with details
    case CaseActions.ActionTypes.GET_LIST_WITH_DETAILS:
      return { ...state, loading: true, error: undefined };

    case CaseActions.ActionTypes.GET_LIST_WITH_DETAILS_SUCCESS: {
      const stateIds: any[] = state.ids;
      return caseStateFactory.adapter.upsertMany(
        action.payload.datalist
          .filter((item) => !stateIds.includes(item.id))
          .map((item) => ({
            ...item,
            loaded: true,
            loading: false,
            error: undefined,
          })),
        { ...state, loading: false, loaded: true },
      );
    }
    /* Default */
    default:
      return caseStateFactory.createReducer(state, action);
  }
}
