/* eslint-disable @typescript-eslint/naming-convention */
import { ResultsData } from './base-results.model';

export enum CashflowCategory {
  DECOMISSIONING_EOL = 'decommissioning_eol',
  DECOMISSIONING = 'decommissioning',
  FINANCING = 'financing',
  CAPEX = 'capex',
  OPEX = 'opex',
}

export interface CashflowData extends ResultsData {
  readonly type: string;
  readonly node: string;
  readonly category: CashflowCategory;
}
