<prosumer-progress-card
  [showProgress]="
    (projectLoadingList$ | async) ||
    (caseLoadingList$ | async) ||
    (loadingNames$ | async)
  "
>
  <form [formGroup]="duplicateEntityForm" (ngSubmit)="onFinish(true)">
    <h3 data-testid="scenario-duplicate-dialog-title" mat-dialog-title>
      Duplicate {{ data?.entityArchetype | titlecase }}
      {{ data[data?.entityArchetype]?.name | titlecase }}
    </h3>
    <div fxLayout="column" mat-dialog-content>
      <div
        *ngIf="data?.entityArchetype !== 'scenario'"
        class="mat-caption italic"
      >
        {{ 'Generic.messages.duplicateUploadExclusion' | translate }}
      </div>
      <prosumer-select
        [required]="true"
        [placeholder]="'Select a Project'"
        [disabled]="projectLoadingList$ | async"
        [options]="projectOptions$ | async"
        [label]="'Select a Project'"
        [control]="duplicateEntityForm?.controls?.project"
        [errorMessageMap]="duplicateNameErrorMap"
        data-testid="scenario-duplicate-project-select"
      >
      </prosumer-select>
      <prosumer-select
        [required]="true"
        [placeholder]="'Select a Case'"
        [disabled]="
          (caseLoadingList$ | async) || !(caseOptions$ | async).length
        "
        [options]="caseOptions$ | async"
        [label]="'Select a Case'"
        [control]="duplicateEntityForm?.controls?.case"
        [errorMessageMap]="duplicateNameErrorMap"
        data-testid="scenario-duplicate-case-select"
      >
      </prosumer-select>
      <prosumer-input
        module="Scenario"
        formFieldName="duplicate.scenario"
        data-testid="scenario-duplicate-name-input"
        [label]="'New ' + (data?.entityArchetype | titlecase) + ' Name'"
        [placeholder]="'New ' + (data?.entityArchetype | titlecase) + ' Name'"
        [control]="duplicateEntityForm?.controls?.duplicateName"
        [required]="true"
      >
      </prosumer-input>
    </div>
    <div mat-dialog-actions fxLayout="row">
      <button
        mat-flat-button
        type="submit"
        color="primary"
        [disabled]="
          duplicateEntityForm?.invalid ||
          (loadingNames$ | async) ||
          (projectLoadingList$ | async) ||
          (caseLoadingList$ | async) ||
          !(caseOptions$ | async).length
        "
        data-testid="scenario-duplicate-duplicate-button"
      >
        Duplicate
      </button>
      <button
        mat-flat-button
        type="button"
        color=""
        [disabled]="data.isBeingProcessed$ | async"
        (click)="onFinish(false)"
        data-testid="scenario-duplicate-cancel-button"
      >
        {{ 'Generic.labels.cancel' | translate }}
      </button>
    </div>
  </form>
</prosumer-progress-card>
