import { OptimizationCockpitForm } from 'prosumer-scenario/models';
import {
  mapYearlyValuesToBackend,
  mapYearlyValuesToFrontend,
} from 'prosumer-shared/utils';

export class OptimizationCockpitMapper {
  static mapToBackend = (optimizationCockpitForm: OptimizationCockpitForm) =>
    !!optimizationCockpitForm
      ? {
          limits: {
            yearlyMaximumEmissions: mapYearlyValuesToBackend(
              optimizationCockpitForm.limits.yearlyMaximumEmissions,
            ),
            yearlyMaximumCosts: mapYearlyValuesToBackend(
              optimizationCockpitForm.limits.yearlyMaximumCosts,
            ),
            globalMaximumEmissions:
              optimizationCockpitForm.limits.globalMaximumEmissions?.toString(),
            globalMaximumCosts:
              optimizationCockpitForm.limits.globalMaximumCosts?.toString(),
          },
          options: {
            mipGap:
              optimizationCockpitForm.options?.mipGap?.toString() || '0.02',
            limitRunHour:
              optimizationCockpitForm.options?.limitRunHour?.toString() || '8',
            preventSimultaneousCharge: optimizationCockpitForm.options
              ?.preventSimultaneousCharge
              ? 1
              : 0,
            lcox: optimizationCockpitForm.options?.lcox ? 1 : 0,
          },
          customOptions: (optimizationCockpitForm.customOptions || []).map(
            (data) => ({
              name: data.name,
              value: data.value,
            }),
          ),
        }
      : {};

  static mapToFrontend = (
    scenario: {
      limits;
      options;
      customOptions;
    },
    startYear?: number,
    endYear?: number,
  ): OptimizationCockpitForm =>
    !!scenario
      ? {
          limits: {
            yearlyMaximumEmissions:
              scenario.limits && scenario.limits.yearlyMaximumEmissions
                ? mapYearlyValuesToFrontend(
                    scenario.limits.yearlyMaximumEmissions,
                    startYear,
                    endYear,
                  )
                : undefined,
            yearlyMaximumCosts:
              scenario.limits && scenario.limits.yearlyMaximumCosts
                ? mapYearlyValuesToFrontend(
                    scenario.limits.yearlyMaximumCosts,
                    startYear,
                    endYear,
                  )
                : undefined,
            globalMaximumEmissions:
              scenario.limits && scenario.limits.globalMaximumEmissions
                ? scenario.limits.globalMaximumEmissions
                : undefined,
            globalMaximumCosts:
              scenario.limits && scenario.limits.globalMaximumCosts
                ? scenario.limits.globalMaximumCosts
                : undefined,
          },
          options: {
            mipGap: scenario.options?.mipGap
              ? scenario.options.mipGap
              : undefined,
            limitRunHour: scenario.options?.limitRunHour
              ? scenario.options.limitRunHour
              : undefined,
            preventSimultaneousCharge: scenario.options
              ?.preventSimultaneousCharge
              ? 1
              : 0,
            lcox: scenario.options?.lcox ? 1 : 0,
          },
          customOptions: scenario.customOptions || [],
        }
      : {};
}
