import { PREDEFINED_ENERGY_VECTORS } from 'prosumer-app/app.references';
import { contains, getDeletedItems } from 'prosumer-app/libs/eyes-shared';
import {
  getEdittedEquipments,
  getEdittedLoads,
  isSimulationSuccessful,
} from 'prosumer-app/shared';

import { createSelector } from '@ngrx/store';

import {
  ActivationReserve,
  CommonReserve,
  Converter,
  EnergyGridConnection,
  EnergyGridConnectionsEmissions,
  EnergyGridLimit,
  EnergyVector,
  EnergyVectorCircuitInfo,
  Equipment,
  EquipmentCircuitInfo,
  EquipmentReserve,
  Fuel,
  Generator,
  Line,
  Load,
  MarginReserve,
  MarketReserve,
  Metering,
  Netting,
  NetworkReserve,
  Node,
  RenewableEnergy,
  Routes,
  Scenario,
  SCENARIO_NAME_DEFAULT_ID,
  ScenarioVariation,
  Station,
  StationVehicleAssoc,
  Storage,
  TaxAndSubsidies,
  Vehicle,
  VehiclesDispatch,
} from '../models';
import { ScenarioState } from './scenario-state.model';
import { scenarioStateFactory } from './scenario.factory';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function translate_energy_vector_value_into_id(type, not_found) {
  const found = PREDEFINED_ENERGY_VECTORS.find((item) => item.value === type);
  if (found !== undefined) {
    return found.id;
  }
  return not_found;
}

const selectedEnergyVectors = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    [
      ...((((selectedScenario || {}).general || {}).energyVectors || {})
        .predefined || []),
      ...((((selectedScenario || {}).general || {}).energyVectors || {})
        .others || []),
    ].map((energyVector) => ({
      ...energyVector,
      // typeId: !!energyVector.type ? (PREDEFINED_ENERGY_VECTORS.find(item => item.value === energyVector.type)).id : energyVector.value
      typeId: !!energyVector.type
        ? translate_energy_vector_value_into_id(
            energyVector.type,
            energyVector.value,
          )
        : energyVector.value,
    })),
);

const selectedStartYear = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    (((selectedScenario || {}).time || {}).projectDuration || [])[0] || 0,
);

const selectedEndYear = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    (((selectedScenario || {}).time || {}).projectDuration || [])[1] || 0,
);

const selectedNodes = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    ((selectedScenario || {}).topology || {}).nodes || [],
);

const selectedEquipments = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    ((selectedScenario || {}).equipments || {}).equipments || [],
);

const selectedLines = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    ((selectedScenario || {}).topology || {}).lines || [],
);

const selectedLoads = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    ((selectedScenario || {}).loads || {}).loads || [],
);

export const selectedReserve = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScen: Scenario) =>
    ((selectedScen || {}).frequencyControl || {}).spinningReserve || {},
);

export const selectedReserves = createSelector(selectedReserve, (reserve) =>
  Object.keys(reserve).reduce(
    (acc, reserveKey) => acc.concat(reserve[reserveKey]),
    [],
  ),
);

const selectedEnergyGridConnections = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    ((selectedScenario || {}).commodities || {}).grids || [],
);

const selectedConnectionsEmissions = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    ((selectedScenario || {}).commodities || {}).connectionsEmissions || [],
);

const selectedEnergyGridLimits = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    ((selectedScenario || {}).commodities || {}).limits || [],
);

const selectedFuels = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    ((selectedScenario || {}).commodities || {}).fuels || [],
);

const selectedMeterings = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    ((selectedScenario || {}).regulations || {}).meterings || [],
);

const selectedEnergyVectorShortCircuits = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    (((selectedScenario || {}).frequencyControl || {}).shortCircuit || {})
      .energyVectors || [],
);

const selectedEquipmentShortCircuits = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    (((selectedScenario || {}).frequencyControl || {}).shortCircuit || {})
      .equipments || [],
);

const selectedRoutes = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    (((selectedScenario || {}).mobility || {}).routes || {}).routes || [],
);

const selectedVehiclesDispatch = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    ((selectedScenario || {}).mobility || {}).vehicles || [],
);

const selectedStationVehicleAssoc = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    ((selectedScenario || {}).mobility || {}).stationVehicleAssoc || [],
);

const selectedNetting = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    ((selectedScenario || {}).netting || {}).netting || [],
);

const selectedTaxAndSubsidies = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    ((selectedScenario || {}).netting || {}).taxAndSubsidies || [],
);

const selectedScenarioVariations = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) => [
    {
      name: ((selectedScenario || {}).general || {}).name || '',
      value: SCENARIO_NAME_DEFAULT_ID,
    },
    ...(((selectedScenario || {}).general || {}).scenarioVariations || []),
  ],
);

const selectedScenarioSucceededVariations = createSelector(
  scenarioStateFactory.queries.getSelectedData,
  (selectedScenario: Scenario) =>
    ((selectedScenario || {}).scenarioVariations || [])
      .filter((variation) => isSimulationSuccessful(variation.run))
      .map((variation) => ({
        name: variation.name,
        value: variation.variationId,
      })),
);

const deletedEnergyVectors = createSelector(
  selectedEnergyVectors,
  (
    _selectedEnergyVectors: Array<EnergyVector>,
    compareEnergyVectors: Array<EnergyVector>,
  ) =>
    getDeletedItems<EnergyVector>(
      _selectedEnergyVectors,
      compareEnergyVectors,
      'value',
    ),
);

const deletedScenarioVariations = createSelector(
  selectedScenarioVariations,
  (
    _selectedScenarioVariations: Array<ScenarioVariation>,
    compareScenarioVariations: Array<ScenarioVariation>,
  ) =>
    getDeletedItems<ScenarioVariation>(
      _selectedScenarioVariations.filter(
        (variation) => variation.value !== SCENARIO_NAME_DEFAULT_ID,
      ),
      compareScenarioVariations,
      'value',
    ),
);

const deletedNodes = createSelector(
  selectedNodes,
  (_selectedNodes: Array<Node>, compareNodes: Array<Node>) =>
    getDeletedItems<Node>(_selectedNodes, compareNodes, 'value'),
);

const deletedLoads = createSelector(
  selectedLoads,
  (_selectedLoads: Array<Load>, compareLoads: Array<Load>) =>
    getDeletedItems<Load>(_selectedLoads, compareLoads),
);

const deletedEquipments = createSelector(
  selectedEquipments,
  (
    _selectedEquipments: Array<Equipment>,
    compareEquipments: Array<Equipment>,
  ) => getDeletedItems<Equipment>(_selectedEquipments, compareEquipments),
);

const deletedEnergyGrids = createSelector(
  selectedEnergyGridConnections,
  (
    _selectedGrids: EnergyGridConnection[],
    compareGrids: EnergyGridConnection[],
  ) => getDeletedItems<EnergyGridConnection>(_selectedGrids, compareGrids),
);

const deletedRoutes = createSelector(
  selectedRoutes,
  (_selectedRoutes: Routes[], compareRoutes: Routes[]) =>
    getDeletedItems<Routes>(_selectedRoutes, compareRoutes),
);

const deletedVehicles = createSelector(
  selectedVehiclesDispatch,
  (
    _selectedVehicles: VehiclesDispatch[],
    compareVehicles: VehiclesDispatch[],
  ) => getDeletedItems<VehiclesDispatch>(_selectedVehicles, compareVehicles),
);

const deletedStationsVehicleAssoc = createSelector(
  selectedStationVehicleAssoc,
  (
    _selectedStationVehicleAssoc: StationVehicleAssoc[],
    compareStationVehicleAssoc: StationVehicleAssoc[],
  ) =>
    getDeletedItems<StationVehicleAssoc>(
      _selectedStationVehicleAssoc,
      compareStationVehicleAssoc,
    ),
);

const edittedLoads = createSelector(
  selectedLoads,
  (_selectedLoads: Array<Load>, compareLoads: Array<Load>) =>
    getEdittedLoads<Load>(_selectedLoads, compareLoads),
);

const edittedEquipments = createSelector(
  selectedEquipments,
  (_selectedEquipments: Equipment[], compareEquipments: Equipment[]) =>
    getEdittedEquipments<Equipment>(_selectedEquipments, compareEquipments),
);

const filterLinesByEnergyVector = createSelector(
  selectedLines,
  (lines: Array<Line>, energyVectorId: string) =>
    lines.filter((line) => line.energyVectorId === energyVectorId),
);

const filterLinesByNode = createSelector(
  selectedLines,
  (lines: Array<Line>, nodeId: string) =>
    lines.filter(
      (line) =>
        line.originNodeId === nodeId || line.destinationNodeId === nodeId,
    ),
);

const filterLoadsByEnergyVector = createSelector(
  selectedLoads,
  (loads: Array<Load>, energyVectorId: string) =>
    loads.filter((load) => load.energyVector === energyVectorId),
);

const filterLoadsByNode = createSelector(
  selectedLoads,
  (loads: Array<Load>, nodeId: string) =>
    loads.filter((load) =>
      load.nodes.length !== 1
        ? load.nodes.includes(nodeId)
        : load.nodes[0] !== 'ALL'
          ? load.nodes[0] === nodeId
          : false,
    ),
);

// Utility Function to filter Equipment
const filterEquipmentsByEnerygyVectotyWithFilterId = (
  equipments: Array<
    | RenewableEnergy
    | Converter
    | Storage
    | Generator
    | Vehicle
    | Station
    | Equipment
  >,
  energyVectorId: string,
) => {
  const filteredEquipmentIDs = [];
  const filteredEquipments = equipments.filter((equipment) => {
    const vectorIds = (equipment as Converter).efficiencyMatrix
      ? (equipment as Converter).efficiencyMatrix.efficiencies.reduce(
          (a, b) => {
            a.push(b.energyVectorIn);
            a.push(b.energyVectorOut);
            return a;
          },
          [],
        )
      : [];
    if (
      vectorIds.includes(energyVectorId) ||
      equipment.outputEnergyVector === energyVectorId ||
      (equipment as Storage).energyVector === energyVectorId ||
      (equipment as Storage).outputEnergyVector === energyVectorId ||
      (equipment as Generator).inputEnergyVector === energyVectorId ||
      (equipment as Generator).outputEnergyVector === energyVectorId ||
      (equipment as Vehicle).outputEnergyVector === energyVectorId ||
      (equipment as Station).energyVector === energyVectorId ||
      (equipment as Station).outputEnergyVector === energyVectorId
    ) {
      filteredEquipmentIDs.push(equipment.id);
      return true;
    }
    return false;
  });
  return {
    filteredEquipmentIDs,
    filteredEquipments,
  };
};

const filterEquipmentsByEnergyVector = createSelector(
  selectedEquipments,
  (
    equipments: Array<
      | RenewableEnergy
      | Converter
      | Storage
      | Generator
      | Vehicle
      | Station
      | Equipment
    >,
    energyVectorId: string,
  ) =>
    filterEquipmentsByEnerygyVectotyWithFilterId(equipments, energyVectorId)
      .filteredEquipments,
);

const filterVehiclesByEnergyVectors = createSelector(
  selectedEquipments,
  selectedVehiclesDispatch,
  (
    equipments: Array<
      | RenewableEnergy
      | Converter
      | Storage
      | Generator
      | Vehicle
      | Station
      | Equipment
    >,
    vehicles: Array<VehiclesDispatch>,
    energyVectorId: string,
  ) =>
    vehicles.filter((vehicle) =>
      contains(
        filterEquipmentsByEnerygyVectotyWithFilterId(equipments, energyVectorId)
          .filteredEquipmentIDs,
        vehicle.vehicleId,
      ),
    ),
);

const filterStationVehiclesAssocsByEnergyVectors = createSelector(
  selectedEquipments,
  selectedVehiclesDispatch,
  selectedStationVehicleAssoc,
  (
    equipments: Array<
      | RenewableEnergy
      | Converter
      | Storage
      | Generator
      | Vehicle
      | Station
      | Equipment
    >,
    vehicles: Array<VehiclesDispatch>,
    assocs: Array<StationVehicleAssoc>,
    energyVectorId: string,
  ) => {
    const filteredEquipment = filterEquipmentsByEnerygyVectotyWithFilterId(
      equipments,
      energyVectorId,
    );

    const filteredVehicleIDs = [];
    vehicles.filter((vehicle) => {
      if (contains(filteredEquipment.filteredEquipmentIDs, vehicle.vehicleId)) {
        filteredVehicleIDs.push(vehicle.id);
        return true;
      }
      return false;
    });

    return assocs.filter(
      (assoc) =>
        assoc.vehicleNames.filter((vehicleName) =>
          contains(filteredVehicleIDs, vehicleName),
        ).length > 0,
    );
  },
);

const filterEquipmentsByNode = createSelector(
  selectedEquipments,
  (
    equipments: Array<
      | RenewableEnergy
      | Converter
      | Storage
      | Generator
      | Vehicle
      | Station
      | Equipment
    >,
    nodeId: string,
  ) =>
    equipments.filter((equipment) => {
      /* Vehicle equipment does no have nodes */
      if (!!!equipment.nodes) {
        return false;
      }
      return equipment.nodes.indexOf(nodeId) !== -1;
    }),
);

const filterEnergyGridConnectionsByVariation = createSelector(
  selectedEnergyGridConnections,
  (energyGridConnections: Array<EnergyGridConnection>, variationId: string) =>
    energyGridConnections.filter(
      (egc) => egc.scenarioVariation === variationId,
    ),
);

const filterConnectionsEmissionsByVariation = createSelector(
  selectedEnergyGridConnections,
  selectedConnectionsEmissions,
  (
    energyGridConnections: Array<EnergyGridConnection>,
    energyGridConnectionEmissions: Array<EnergyGridConnectionsEmissions>,
    variationId: string,
  ) => {
    const forDeleteIds = energyGridConnections
      .filter((egc) => egc.scenarioVariation === variationId)
      .map((egc) => egc.id);
    const retVal = energyGridConnectionEmissions.filter(
      (emission) => forDeleteIds.indexOf(emission.marketName) > -1,
    );
    return retVal;
  },
);

const filterEnergyGridLimitsByVariation = createSelector(
  selectedEnergyGridConnections,
  selectedEnergyGridLimits,
  (
    energyGridConnections: Array<EnergyGridConnection>,
    energyGridLimits: Array<EnergyGridLimit>,
    variationId: string,
  ) => {
    const forDeleteIds = energyGridConnections
      .filter((egc) => egc.scenarioVariation === variationId)
      .map((egc) => egc.id);
    const retVal = energyGridLimits.filter(
      (limit) => forDeleteIds.indexOf(limit.market) > -1,
    );
    return retVal;
  },
);

const filterReserveMarketByVariation = createSelector(
  selectedEnergyGridConnections,
  selectedReserves,
  (
    energyGridConnections: Array<EnergyGridConnection>,
    commonReserves: Array<CommonReserve>,
    variationId: string,
  ) => {
    const forDeleteIds = energyGridConnections
      .filter((egc) => egc.scenarioVariation === variationId)
      .map((egc) => egc.id);
    const retVal = commonReserves.filter(
      (reserve) =>
        forDeleteIds.indexOf(
          (reserve as MarketReserve).energyGridConnectionId,
        ) > -1,
    );
    return retVal as MarketReserve[];
  },
);

const filterEnergyGridConnectionsByEnergyVector = createSelector(
  selectedEnergyGridConnections,
  (
    energyGridConnections: Array<EnergyGridConnection>,
    energyVectorId: string,
  ) =>
    energyGridConnections.filter(
      (energyGridConnection) =>
        energyGridConnection.delivery === energyVectorId,
    ),
);

const filterEnergyGridConnectionEmissionsByEnergyVector = createSelector(
  selectedConnectionsEmissions,
  selectedEnergyGridConnections,
  (
    energyGridConnectionEmissions: Array<EnergyGridConnectionsEmissions>,
    energyGridConnections: Array<EnergyGridConnection>,
    energyVectorId: string,
  ) => {
    const filteredEnergyGridConnections = energyGridConnections.filter(
      (energyGridConnection) =>
        energyGridConnection.delivery === energyVectorId,
    );
    const filteredEnergyGridIds = filteredEnergyGridConnections.map(
      (egc) => egc.id,
    );
    return energyGridConnectionEmissions.filter((egcEmission) =>
      filteredEnergyGridIds.includes(egcEmission.marketName),
    );
  },
);

const filterAffectedEnergyGridLimitsByEnergyVector = createSelector(
  selectedEnergyGridLimits,
  selectedEnergyGridConnections,
  (
    energyGridLimits: Array<EnergyGridLimit>,
    energyGridConnections: Array<EnergyGridConnection>,
    energyVectorId: string,
  ) => {
    const filteredConnectionsByEnergyVector = energyGridConnections
      .filter(
        (energyGridConnection) =>
          energyGridConnection.delivery === energyVectorId,
      )
      .map((energyGridConnection) => energyGridConnection.id);
    return energyGridLimits.filter((energyGridLimit) =>
      contains(filteredConnectionsByEnergyVector, energyGridLimit.market),
    );
  },
);

const filterEnergyGridConnectionsByNode = createSelector(
  selectedEnergyGridConnections,
  (energyGridConnections: Array<EnergyGridConnection>, nodeId: string) => {
    const retVal = energyGridConnections.filter(
      (energyGridConnection) =>
        energyGridConnection.nodes.indexOf(nodeId) !== -1,
    );
    return retVal;
  },
);

const filterConnectionsEmissionsByNode = createSelector(
  selectedConnectionsEmissions,
  (egcEmissions: Array<EnergyGridConnectionsEmissions>, nodeId: string) => {
    const retVal = egcEmissions.filter(
      (egcEmission) => egcEmission.node.indexOf(nodeId) !== -1,
    );
    return retVal;
  },
);

const filterEnergyGridLimitsByNodeAndConnection = createSelector(
  selectedEnergyGridLimits,
  selectedEnergyGridConnections,
  (
    energyGridLimits: Array<EnergyGridLimit>,
    energyGridConnections: Array<EnergyGridConnection>,
    nodeId: string,
  ) => {
    const filteredConnections = energyGridConnections
      .filter((connection) => contains(connection.nodes, nodeId))
      .map((connection) => connection.id);
    return energyGridLimits.filter(
      (energyGridLimit) =>
        contains(energyGridLimit.nodes, nodeId) ||
        (contains(filteredConnections, energyGridLimit.market) &&
          energyGridLimit.nodes[0] === 'ALL'),
    );
  },
);

const filterConnectionsEmissionsByEnergyGrid = createSelector(
  selectedConnectionsEmissions,
  (egcEmissions: Array<EnergyGridConnectionsEmissions>, gridId: string) => {
    const retVal = egcEmissions.filter(
      (emission) => emission.marketName === gridId,
    );
    return retVal;
  },
);

const filterFuelsByEnergyVector = createSelector(
  selectedFuels,
  (fuels: Array<Fuel>, energyVectorId: string) =>
    fuels.filter((fuel) => fuel.fuel === energyVectorId),
);

const filterFuelsByNode = createSelector(
  selectedFuels,
  (fuels: Array<Fuel>, nodeId: string) =>
    fuels.filter((fuel) => contains(fuel.nodes, nodeId)),
);

const filterMeteringsByEquipment = createSelector(
  selectedMeterings,
  (meterings: Array<Metering>, equipmentId: string) =>
    meterings.filter((metering) => metering.technologyId === equipmentId),
);

const filterShortCircuitsByEnergyVector = createSelector(
  selectedEnergyVectorShortCircuits,
  (
    energyVectorShortCircuits: Array<EnergyVectorCircuitInfo>,
    energyVectorId: string,
  ) =>
    energyVectorShortCircuits.filter(
      (evci) => evci.energyVectorId === energyVectorId,
    ),
);

const filterShortCircuitsByEquipment = createSelector(
  selectedEquipmentShortCircuits,
  (equipmentShortCircuits: Array<EquipmentCircuitInfo>, equipmentId: string) =>
    equipmentShortCircuits.filter((eci) => eci.equipmentId === equipmentId),
);

const filterScenarios = createSelector(
  scenarioStateFactory.queries.getDataList,
  (
    scenarioList: Array<Scenario>,
    props: { projectId: string; caseId: string },
  ) =>
    scenarioList.filter(
      (scenario) =>
        (scenario.projectId === props.projectId &&
          scenario.caseId === props.caseId) ||
        (scenario.loading && !scenario.projectId && !scenario.caseId),
    ),
);

const filterReservesByEnergyVector = createSelector(
  selectedReserves,
  (reserves: CommonReserve[], vectorId: string) =>
    reserves.filter(
      (reserve) =>
        (reserve as MarginReserve).energyVectorId === vectorId ||
        (reserve as ActivationReserve).energyVectorId === vectorId ||
        (reserve as EquipmentReserve).energyVectorId === vectorId ||
        (reserve as NetworkReserve).energyVectorId === vectorId,
    ),
);

const filterReservesByEquipments = createSelector(
  selectedReserves,
  (reserves: EquipmentReserve[], equipmentId: string) =>
    reserves.filter(
      (reserve) => (reserve as EquipmentReserve).equipmentId === equipmentId,
    ),
);

const filterReservesByEnergyGrid = createSelector(
  selectedReserves,
  (reserves: CommonReserve[], egcID: string) =>
    reserves
      .filter(
        (reserve) =>
          (reserve as MarketReserve).energyGridConnectionId === egcID,
      )
      .map((reserve) => reserve as MarketReserve),
);

const filterRoutesByNode = createSelector(
  selectedRoutes,
  (routes: Array<Routes>, nodeId: string) =>
    routes.filter(
      (route) =>
        route.profileRoutes.filter(
          (profile) =>
            profile.nodes.filter((node) => node === nodeId).length > 0,
        ).length > 0,
    ),
);

const filterVehiclesByNode = createSelector(
  selectedVehiclesDispatch,
  selectedRoutes,
  (
    vehicles: Array<VehiclesDispatch>,
    routes: Array<Routes>,
    nodeId: string,
  ) => {
    const filteredRoutesByNode = routes.filter(
      (route) =>
        route.profileRoutes.filter(
          (profile) =>
            profile.nodes.filter((node) => node === nodeId).length > 0,
        ).length > 0,
    );

    return vehicles.filter(
      (vehicle) =>
        vehicle.routeIds.filter((routeId) =>
          contains(
            filteredRoutesByNode.map((route) => route.id),
            routeId,
          ),
        ).length > 0,
    );
  },
);

const filterStationVehicleAssocByNode = createSelector(
  selectedStationVehicleAssoc,
  selectedVehiclesDispatch,
  selectedRoutes,
  selectedEquipments,
  (
    stationVehicleAssocs: Array<StationVehicleAssoc>,
    vehicles: Array<VehiclesDispatch>,
    routes: Array<Routes>,
    equipments: Array<
      | RenewableEnergy
      | Converter
      | Storage
      | Generator
      | Vehicle
      | Station
      | Equipment
    >,
    nodeId: string,
  ) => {
    const filteredRoutesByNode = routes.filter(
      (route) =>
        route.profileRoutes.filter(
          (profile) =>
            profile.nodes.filter((node) => node === nodeId).length > 0,
        ).length > 0,
    );

    const filteredEquipmentByNode = equipments.filter((equipment) => {
      if (!!!equipment.nodes) {
        return false;
      }
      return equipment.nodes.indexOf(nodeId) !== -1;
    });

    const filteredVehicleByRoute = vehicles.filter(
      (vehicle) =>
        vehicle.routeIds.filter((routeId) =>
          contains(
            filteredRoutesByNode.map((route) => route.id),
            routeId,
          ),
        ).length > 0,
    );

    return stationVehicleAssocs.filter(
      (assoc) =>
        assoc.vehicleNames.filter((vehicleId) =>
          contains(
            filteredVehicleByRoute.map((vehicle) => vehicle.id),
            vehicleId,
          ),
        ).length > 0 ||
        filteredEquipmentByNode.map((eq) => eq.id).includes(assoc.stationId) ||
        contains(assoc.stationNodes, nodeId),
    );
  },
);

const filterVehiclesDispatchByEquipments = createSelector(
  selectedVehiclesDispatch,
  (vehicles: VehiclesDispatch[], equipmentId: string) =>
    vehicles.filter((vehicle) => vehicle.vehicleId === equipmentId),
);

const filterStationVehicleAssocByEquipments = createSelector(
  selectedStationVehicleAssoc,
  selectedVehiclesDispatch,
  (
    assocs: StationVehicleAssoc[],
    vehicles: VehiclesDispatch[],
    equipmentId: string,
  ) => {
    const filteredVehicle = vehicles.filter(
      (vehicle) => vehicle.vehicleId === equipmentId,
    );
    return assocs.filter(
      (assoc) =>
        assoc.vehicleId === equipmentId ||
        assoc.stationId === equipmentId ||
        assoc.vehicleNames.filter((name) =>
          filteredVehicle.map((vehicle) => vehicle.id).includes(name),
        ).length > 0,
    );
  },
);

const filterVehiclesDispatchByRoute = createSelector(
  selectedVehiclesDispatch,
  (vehicles: VehiclesDispatch[], routeId: string) =>
    vehicles.filter((vehicle) => contains(vehicle.routeIds, routeId)),
);

const filterStationVehicleAssocByRoute = createSelector(
  selectedVehiclesDispatch,
  selectedStationVehicleAssoc,
  (
    vehicles: VehiclesDispatch[],
    assocs: StationVehicleAssoc[],
    routeId: string,
  ) => {
    const vehicleIds = [];
    vehicles.filter((vehicle) => {
      if (contains(vehicle.routeIds, routeId)) {
        vehicleIds.push(vehicle.id);
        return true;
      }
      return false;
    });

    return assocs.filter(
      (assoc) =>
        assoc.vehicleNames.filter((vehicle) => contains(vehicleIds, vehicle))
          .length > 0,
    );
  },
);

const filterStationVehicleAssocByVehicle = createSelector(
  selectedStationVehicleAssoc,
  (assocs: StationVehicleAssoc[], vehicleId: string) =>
    assocs.filter((assoc) => contains(assoc.vehicleNames, vehicleId)),
);

const filterNettingsByEnergyVector = createSelector(
  selectedNetting,
  (nettings: Array<Netting>, energyVectorId: string) =>
    nettings.filter((netting) => netting.energyVectorId === energyVectorId),
);

const filterAffectedNettingsByEnergyVector = createSelector(
  selectedNetting,
  selectedEquipments,
  (
    nettings: Array<Netting>,
    equipments: Array<
      | RenewableEnergy
      | Converter
      | Storage
      | Generator
      | Vehicle
      | Station
      | Equipment
    >,
    energyVectorId: string,
  ) => {
    const filteredEquipmentIds = filterEquipmentsByEnerygyVectotyWithFilterId(
      equipments,
      energyVectorId,
    ).filteredEquipmentIDs;
    return nettings.filter(
      (netting) =>
        netting.energyVectorId === energyVectorId ||
        netting.equipments.some((eqptId) =>
          contains(filteredEquipmentIds, eqptId),
        ),
    );
  },
);

const filterNettingsByNode = createSelector(
  selectedNetting,
  (nettings: Array<Netting>, nodeId: string) =>
    nettings.filter((netting) => contains(netting.nodes, nodeId)),
);

const filterNettingsByLoad = createSelector(
  selectedNetting,
  (nettings: Array<Netting>, loadId: string) =>
    nettings.filter((netting) => contains(netting.loads, loadId)),
);

const filterNettingsByEquipment = createSelector(
  selectedNetting,
  (nettings: Array<Netting>, equipmentId: string) =>
    nettings.filter((netting) => contains(netting.equipments, equipmentId)),
);

const filterTaxAndSubsidiesByNodeAndNetting = createSelector(
  selectedTaxAndSubsidies,
  selectedNetting,
  (
    taxAndSubsidies: Array<TaxAndSubsidies>,
    nettings: Array<Netting>,
    nodeId: string,
  ) => {
    const filteredNettingsByNode = nettings
      .filter((netting) => contains(netting.nodes, nodeId))
      .map((netting) => netting.id);
    return taxAndSubsidies.filter(
      (taxAndSubsidy) =>
        contains(taxAndSubsidy.nodes, nodeId) ||
        contains(filteredNettingsByNode, taxAndSubsidy.netting),
    );
  },
);

const filterAffectedTaxAndSubsidiesByEnergyVector = createSelector(
  selectedTaxAndSubsidies,
  selectedNetting,
  (
    taxAndSubsidies: Array<TaxAndSubsidies>,
    nettings: Array<Netting>,
    energyVectorId: string,
  ) => {
    const filteredNettingsByEnergyVector = nettings
      .filter((netting) => netting.energyVectorId === energyVectorId)
      .map((netting) => netting.id);
    return taxAndSubsidies.filter((taxAndSubsidy) =>
      contains(filteredNettingsByEnergyVector, taxAndSubsidy.netting),
    );
  },
);

const filterAffectedTaxAndSubsidiesByEquipment = createSelector(
  selectedTaxAndSubsidies,
  selectedNetting,
  (
    taxAndSubsidies: Array<TaxAndSubsidies>,
    nettings: Array<Netting>,
    equipmentId: string,
  ) => {
    const filteredNettingsByEquipment = nettings
      .filter((netting) => contains(netting.equipments, equipmentId))
      .map((netting) => netting.id);
    return taxAndSubsidies.filter((taxAndSubsidy) =>
      contains(filteredNettingsByEquipment, taxAndSubsidy.netting),
    );
  },
);

export const scenarioQueries = {
  predefinedEnergyVectors: createSelector(
    scenarioStateFactory.featureState,
    (state: ScenarioState) => state.predefinedEnergyVectors,
  ),
  uploading: createSelector(
    scenarioStateFactory.featureState,
    (state: ScenarioState) => state.uploading,
  ),
  uploadingEntitiesStatus: createSelector(
    scenarioStateFactory.featureState,
    (state: ScenarioState) => state.uploadingEntitiesStatus,
  ),
  validating: createSelector(
    scenarioStateFactory.featureState,
    (state: ScenarioState) => state.validating,
  ),
  simulating: createSelector(
    scenarioStateFactory.featureState,
    (state: ScenarioState) => state.simulating,
  ),
  launched: createSelector(
    scenarioStateFactory.featureState,
    (state: ScenarioState) => state.launched,
  ),
  coherenceResults: createSelector(
    scenarioStateFactory.featureState,
    (state: ScenarioState) => state.coherenceResults,
  ),
  selectedEnergyVectors,
  selectedStartYear,
  selectedEndYear,
  selectedNodes,
  selectedEquipments,
  selectedLines,
  selectedLoads,
  selectedEnergyGridConnections,
  selectedConnectionsEmissions,
  selectedEnergyGridLimits,
  selectedFuels,
  selectedMeterings,
  selectedEnergyVectorShortCircuits,
  selectedEquipmentShortCircuits,
  selectedRoutes,
  selectedVehiclesDispatch,
  selectedStationVehicleAssoc,
  selectedNetting,
  selectedTaxAndSubsidies,
  selectedScenarioVariations,
  selectedScenarioSucceededVariations,
  selectedScenarioVariationId: createSelector(
    scenarioStateFactory.featureState,
    (state: ScenarioState) => state.selectedScenarioVariationId,
  ),
  deletedEnergyVectors,
  deletedScenarioVariations,
  deletedNodes,
  deletedLoads,
  deletedEquipments,
  deletedEnergyGrids,
  deletedRoutes,
  deletedVehicles,
  deletedStationsVehicleAssoc,
  edittedLoads,
  edittedEquipments,
  filterLinesByEnergyVector,
  filterLinesByNode,
  filterLoadsByEnergyVector,
  filterLoadsByNode,
  filterEquipmentsByEnergyVector,
  filterVehiclesByEnergyVectors,
  filterStationVehiclesAssocsByEnergyVectors,
  filterShortCircuitsByEnergyVector,
  filterShortCircuitsByEquipment,
  filterEquipmentsNode: filterEquipmentsByNode,
  filterEnergyGridConnectionsByVariation,
  filterConnectionsEmissionsByVariation,
  filterEnergyGridLimitsByVariation,
  filterReserveMarketByVariation,
  filterEnergyGridConnectionsByEnergyVector,
  filterEnergyGridConnectionEmissionsByEnergyVector,
  filterAffectedEnergyGridLimitsByEnergyVector,
  filterEnergyGridConnectionsByNode,
  filterConnectionsEmissionsByNode,
  filterEnergyGridLimitsByNodeAndConnection,
  filterConnectionsEmissionsByEnergyGrid,
  filterFuelsByEnergyVector,
  filterFuelsByNode,
  filterMeteringsByEquipment,
  filterScenarios,
  filterReservesByEnergyVector,
  filterReservesByEquipments,
  filterReservesByEnergyGrid,
  filterRoutesByNode,
  filterVehiclesByNode,
  filterStationVehicleAssocByNode,
  filterVehiclesDispatchByEquipments,
  filterStationVehicleAssocByEquipments,
  filterVehiclesDispatchByRoute,
  filterStationVehicleAssocByRoute,
  filterStationVehicleAssocByVehicle,
  filterNettingsByEnergyVector,
  filterAffectedNettingsByEnergyVector,
  filterNettingsByNode,
  filterNettingsByLoad,
  filterNettingsByEquipment,
  filterTaxAndSubsidiesByNodeAndNetting,
  filterAffectedTaxAndSubsidiesByEnergyVector,
  filterAffectedTaxAndSubsidiesByEquipment,
};
