import { inject, Injectable } from '@angular/core';
import { Case } from 'prosumer-app/+case/models';
import { StoreApiService } from 'prosumer-app/services/store-api';
import { Observable } from 'rxjs';
import {
  ScenarioCascadableAttribs,
  ScenarioGenericStore,
} from '../../../stores/scenario-generic';

@Injectable({
  providedIn: 'root',
})
export class ScenarioInfoService {
  private api = inject(StoreApiService);
  private store = inject(ScenarioGenericStore);

  getScenario(): Observable<unknown> {
    this.store.setActive(null);
    return this.store.handleAdd(this.store.getInfo());
  }

  createScenario(name: string, caseData?: Case): Observable<unknown> {
    return this.store.handleAdd(
      this.store.postInfo('create', {
        ...this.getScenarioDefaults(caseData),
        name,
      }),
    );
  }

  updateScenario(data: unknown, forced: boolean = false): Observable<unknown> {
    if (!!forced) {
      return this.store.handleUpdate(this.store.postInfo('forceUpdate', data));
    }
    return this.store.handleUpdate(this.store.postInfo('update', data));
  }

  cascadeUpdate<T>(attribute: ScenarioCascadableAttribs, data: T) {
    return this.api.getAffectedEntitiesFromScenario(attribute, data).pipe();
  }

  private getScenarioDefaults(caseData: Case): { [key: string]: unknown } {
    return {
      economics: {
        inflationRate: '0.0',
        taxDepreciation: '0.0',
        taxRevenue: '0.0',
        wacc: caseData.wacc ?? '0.0',
      },
      timeHorizon: { fullTimeHorizon: true, periods: [] },
      projectDuration: { duration: 20, startYear: new Date().getFullYear() },
      energyVectors: [],
      emissions: {},
    };
  }
}
