import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ResultStore } from './result-store.service';
import { resultFeatureName } from './result.actions';
import { ResultEffects } from './result.effects';
import { resultReducer } from './result.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(resultFeatureName, resultReducer),
    EffectsModule.forFeature([ResultEffects]),
  ],
})
export class ResultStoreModule {
  static forRoot(): ModuleWithProviders<ResultStoreModule> {
    return {
      ngModule: ResultStoreModule,
      providers: [ResultStore],
    };
  }
}
