import moment from 'moment';

import { TimeForm } from '../../models';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS+HHmm';
const TIME_HORIZON_FULL = 'full';
const TIME_HORIZON_PERIODS = 'periods';

export class TimeMapper {
  static mapToBackend = (timeForm: TimeForm) =>
    !!timeForm
      ? {
          projectDuration: {
            duration:
              (timeForm.projectDuration || [0, 0])[1] -
              (timeForm.projectDuration || [0])[0] +
              1,
            startYear: (timeForm.projectDuration || [0])[0],
          },
          yearsToOptimize: { defaultYear: 0, years: timeForm.yearsToOptimize },
          timeHorizon: {
            fullTimeHorizon: timeForm.timeHorizon === TIME_HORIZON_FULL,
            periods: (timeForm.timeHorizonPeriods || []).map((period) => ({
              year: period.year,
              startDate: (period.startDate || {}).format(DEFAULT_DATE_FORMAT),
              endDate: (period.endDate || {}).format(DEFAULT_DATE_FORMAT),
              weight: period.weight,
              blockLength: 1,
            })),
          },
        }
      : {};

  static mapToFrontend = (scenario: {
    projectDuration;
    yearsToOptimize;
    timeHorizon;
    fullTimeHorizon;
  }): TimeForm =>
    !!scenario
      ? {
          projectDuration: [
            (scenario.projectDuration || {}).startYear,
            (scenario.projectDuration || {}).startYear +
              (scenario.projectDuration || {}).duration -
              1,
          ],
          yearsToOptimize: [...((scenario.yearsToOptimize || {}).years || [])],
          timeHorizon:
            scenario.fullTimeHorizon ||
            (scenario.timeHorizon || {}).fullTimeHorizon
              ? TIME_HORIZON_FULL
              : TIME_HORIZON_PERIODS,
          timeHorizonPeriods: ((scenario.timeHorizon || {}).periods || []).map(
            (period) => ({
              year: period.year,
              startDate: moment(period.startDate),
              endDate: moment(period.endDate),
              weight: period.weight,
              blockLength: period.blockLength,
            }),
          ),
        }
      : {};
}
