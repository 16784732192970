import {
  MobilityRoute,
  MobilityRouteBE,
} from 'prosumer-app/stores/mobility-route';
import {
  MobilityStation,
  MobilityStationBE,
} from 'prosumer-app/stores/mobility-station';
import { VehicleDispatchBE } from 'prosumer-app/stores/vehicles-dispatch';
import { Coerce } from 'prosumer-core/utils';
import {
  MobilityForm,
  Routes,
  VehiclesDispatch,
} from 'prosumer-scenario/models';
import {
  mapYearlyProfileRoutesToFrontend,
  mapYearlyProfileRouteToBackend,
} from 'prosumer-shared/utils';

export class MobilityMapper {
  static mapToBackend = (mobilityForm: MobilityForm): any =>
    !!mobilityForm
      ? {
          mobility: {
            routes: MobilityMapper.routesToBE(
              Coerce.toObject(mobilityForm.routes).routes,
            ),
            vehicles: Coerce.toArray(mobilityForm.vehicles),
            stationVehicleAssoc: (mobilityForm.stationVehicleAssoc || []).map(
              (sva) => ({
                id: sva.id,
                vehicleId: sva.vehicleId,
                stationId: sva.stationId,
                vehicleNames: sva.vehicleNames,
                stationNodes: sva.stationNodes,
              }),
            ),
          },
        }
      : {};

  static mapToFrontend = (scenario: {
    routes: any[];
    vehicles?: any[];
    stationVehicleAssoc?: any[];
  }): MobilityForm => {
    const form = !!scenario
      ? {
          routes: {
            binToDelete: [],
            routes: Coerce.toArray(scenario.routes).map((route) =>
              MobilityMapper.routeToFE(route),
            ),
          },
          vehicles: Coerce.toArray(scenario.vehicles),
          stationVehicleAssoc: (scenario.stationVehicleAssoc || []).map(
            (sva) => ({
              id: sva.id,
              stationId: sva.stationId,
              vehicleId: sva.vehicleId,
              vehicleNames: sva.vehicleNames,
              stationNodes: sva.stationNodes,
            }),
          ),
        }
      : undefined;
    return form;
  };

  private static routesToBE(routes: Routes[]): unknown[] {
    return Coerce.toArray(routes).map((route) =>
      MobilityMapper.routeToBE(route),
    );
  }

  static dispatchToFE(dispatch: VehicleDispatchBE): VehiclesDispatch {
    return { ...dispatch, routeIds: JSON.parse(dispatch.routeIds) };
  }

  static routeToBE(route: Routes): unknown {
    return {
      id: route.id,
      name: route.name,
      profiles: Coerce.toArray(route.profiles).map((profile) =>
        mapYearlyProfileRouteToBackend(profile),
      ),
    };
  }

  static routeToFE(route: MobilityRouteBE): MobilityRoute {
    return {
      id: route.id,
      name: route.name,
      profileRoutes: Coerce.toArray(route.profiles).map((profile) =>
        mapYearlyProfileRoutesToFrontend(profile, 'MOBILITY_ROUTES'),
      ),
    };
  }

  static stationToBE(station: MobilityStation): MobilityStationBE {
    return {
      id: station.id,
      stationId: station.stationId,
      stationNodes: Coerce.toArray(station.stationNodes),
      vehiclesDispatchIds: Coerce.toArray(station.vehicleNames),
      vehicleIds: [station.vehicleId],
    };
  }

  static stationToFE(station: MobilityStationBE): MobilityStation {
    return {
      id: station.id,
      stationId: station.stationId,
      stationNodes: Coerce.toArray(station.stationNodes),
      vehicleNames: Coerce.toArray(station.vehiclesDispatchIds),
      vehicleIds: Coerce.toArray(station.vehicleIds),
      vehicleId: Coerce.toArray(station.vehicleIds)[0],
    };
  }
}
