import { HttpInterceptorFn } from '@angular/common/http';

export const httpSwBypassHeaderInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.method === 'PUT') {
    const modifiedReq = req.clone({
      setHeaders: { 'ngsw-bypass': 'true' },
    });
    return next(modifiedReq);
  }
  return next(req);
};
