/* eslint-disable @typescript-eslint/naming-convention */
import { ResultNameValue } from '../components/results-visualizer';

export interface YearlyOpexResult {
  readonly opex: { [year: string]: number };
}

export interface YearlyEmissionResult {
  scope1: YearlyValueResult;
  scope2: YearlyValueResult;
  scope3: YearlyValueResult;
}

export interface EmissionResult {
  year: number;
  values: ResultNameValue[];
}
export interface YearlyValueResult {
  readonly name: string;
  readonly yearlyValue: { [year: string]: number };
}

export interface YearlyCapexResult {
  readonly capex: { [year: string]: number };
}

export interface YearlyCapexOpexResult {
  capex: YearlyCapexResult;
  opex: YearlyOpexResult;
}

export interface MainOutputResult {
  readonly capex: YearlyCapexResult;
  readonly opex: YearlyOpexResult;
  readonly emissions: EmissionResult[];
  readonly firstYear: SizingAndCostResult;
  readonly lastYear: SizingAndCostResult;
  readonly total: SizingAndCostResult;
}

export interface SizingAndCostResult {
  readonly sizing: SizingResult[];
  readonly capex: MainCapexResult[];
  readonly opex: MainOpexResult[];
}

export interface SizingResult {
  readonly name: string;
  readonly size_kw: number;
}

export interface MainOpexResult {
  readonly name: string;
  readonly opex: number;
}

export interface MainCapexResult {
  readonly name: string;
  readonly capex: number;
}

export enum MAIN_OUTPUT_KEYS {
  FIRST_YEAR = 'first_year',
  LAST_YEAR = 'last_year',
  TOTAL = 'total',
  ALL_YEARS = 'all_years',
}

export enum SIZING_AND_COST_KEYS {
  FIRST_YEAR = 'firstYear',
  LAST_YEAR = 'lastYear',
  TOTAL = 'total',
}

export enum COST_TYPE {
  CAPEX = 'capex',
  OPEX = 'opex',
}
