/* eslint-disable @typescript-eslint/naming-convention */
import { ColumnDefinition } from 'prosumer-app/libs/eyes-shared';

export const MESSAGES_LOGS_COLUMN_DEF_V_1_0_TRANSLATED: ColumnDefinition = {
  Level: {
    name: 'Level',
    type: 'reference',
    referenceKey: 'Level',
    sortable: true,
    flex: '85px',
  },
  Name: {
    name: 'Name',
    type: 'reference',
    referenceKey: 'Name',
    sortable: true,
    flex: '105px',
  },
  Message: {
    name: 'Message',
    type: 'reference',
    referenceKey: 'Message',
  },
};

export const MESSAGES_LOGS_FILTERS_V_1_0_TRANSLATED = {
  level: {
    filterBy: 'Level',
    filterTitle: 'Level',
    fxFlex: '20%',
  },
};

export const MESSAGES_LOGS_COLUMN_DEF_V_1_1: ColumnDefinition = {
  Timestamp: {
    name: 'Timestamp',
    type: 'reference',
    referenceKey: 'Timestamp',
    sortable: true,
    flex: '116px',
  },
  Level: {
    name: 'Level',
    type: 'reference',
    referenceKey: 'Level',
    sortable: true,
    flex: '100px',
  },
  Criticality: {
    name: 'Criticality',
    type: 'reference',
    referenceKey: 'Criticality',
    sortable: true,
    flex: '97px',
  },
  Name: {
    name: 'Name',
    type: 'reference',
    referenceKey: 'Name',
    sortable: true,
    flex: '165px',
  },
  ID: {
    name: 'ID',
    type: 'reference',
    referenceKey: 'ID',
    sortable: true,
    flex: '63px',
  },
  Errorclass: {
    name: 'Errorclass',
    type: 'reference',
    referenceKey: 'Errorclass',
    sortable: true,
    flex: '125px',
  },
  Message: {
    name: 'Message',
    type: 'reference',
    referenceKey: 'Message',
  },
};

export const MESSAGES_LOGS_FILTERS_V_1_1 = {
  level: {
    filterBy: 'Level',
    filterTitle: 'Level',
    fxFlex: '20%',
  },
  criticality: {
    filterBy: 'Criticality',
    filterTitle: 'Criticality',
    fxFlex: '20%',
  },
  id: {
    filterBy: 'ID',
    filterTitle: 'ID',
    fxFlex: '20%',
  },
  errorclass: {
    filterBy: 'Errorclass',
    filterTitle: 'Errorclass',
    fxFlex: '20%',
  },
};
