import { Injectable } from '@angular/core';
import { filterNilValue, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Scenario } from 'prosumer-app/+scenario/models';

import {
  Emissions,
  Limits,
  OptimizationOptions,
  ProjectDuration,
  ScenarioGeneric,
  ScenarioGenericState,
} from './scenario-generic.state';
import { ScenarioGenericStore } from './scenario-generic.store';
import { mapCaseDetailsScenarioListToFrontEnd } from '../scenario/scenario.model';

@Injectable({ providedIn: 'root' })
export class ScenarioGenericQuery extends QueryEntity<ScenarioGenericState> {
  constructor(public store: ScenarioGenericStore) {
    super(store);
  }

  scenarioId$ = this.selectActive(
    (entity: ScenarioGeneric) => entity.scenarioUuid,
  );
  scenarioName$ = this.selectActive((entity: ScenarioGeneric) => entity.name);
  economicsWacc$ = this.selectActive(
    (entity: ScenarioGeneric) => entity.economics.wacc,
  );
  projectDuration$ = this.selectActive(
    (entity: ScenarioGeneric) => entity.projectDuration,
  );
  emissions$ = this.selectActive((entity: ScenarioGeneric) => entity.emissions);
  fullTimeHorizon$ = this.selectActive(this.getFullTimeHorizon);
  yearsToOptimize$ = this.selectActive(
    (entity: ScenarioGeneric) =>
      entity.yearsToOptimize.years || [entity.projectDuration.startYear],
  );

  getActivePeriod(): [number, number] {
    return this.calculatePeriod(this.getActive().projectDuration);
  }

  selectActivePeriod(): Observable<[number, number]> {
    return this.selectTruthyActive().pipe(
      map((entity) => this.calculatePeriod(entity.projectDuration)),
    );
  }

  selectActiveEmissions(): Observable<Emissions> {
    return this.selectTruthyActive().pipe(map((entity) => entity.emissions));
  }

  selectActiveLimits(): Observable<Limits> {
    return this.selectTruthyActive().pipe(map((entity) => entity.limits));
  }

  selectActiveOptions(): Observable<OptimizationOptions> {
    return this.selectTruthyActive().pipe(map((entity) => entity.options));
  }

  selectActiveScenarioMapped(): Observable<Scenario> {
    return this.selectTruthyActive().pipe(
      map((entity) => mapCaseDetailsScenarioListToFrontEnd(entity)),
    );
  }

  private selectTruthyActive(): Observable<ScenarioGeneric> {
    return this.selectActive().pipe(filterNilValue());
  }

  private calculatePeriod(from: ProjectDuration): [number, number] {
    const startYear = from.startYear;
    const endYear = startYear + from.duration - 1;
    return [startYear, endYear];
  }

  private getFullTimeHorizon(entity: ScenarioGeneric) {
    if (entity.timeHorizon) {
      return entity.timeHorizon.fullTimeHorizon;
    }
    return !!entity.fullTimeHorizon;
  }
}
