import { StateFactory } from 'prosumer-app/libs/eyes-shared';

import { Case } from '../models';
import { CaseState } from './case-state.model';

export const caseFeature = 'Case';
export const caseStateFactory = new StateFactory<CaseState, Case>(
  caseFeature,
  'id',
  'name',
  {
    ids: [],
    entities: {},
    sample: 'Test',
  },
);
