import { DialogService } from 'prosumer-app/libs/eyes-core';
import { BaseDialogComponent, contains } from 'prosumer-app/libs/eyes-shared';
import { Observable } from 'rxjs';

import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Netting, Scenario } from '../models';

@Injectable()
export class LoadCascaderService {
  constructor(
    private _dialog: DialogService,
    private _titleCase: TitleCasePipe,
    private _translate: TranslateService,
  ) {}

  // prettier-ignore
  generateAffectedMessage([nettings, loadName]: [
    Array<Netting>, string,
  ]) {
    const affectedNettings = nettings.map((netting) => netting.name).reduce((prev, next) => prev + `<li>${next}</li>`, '');

    if (affectedNettings.length === 0) {
      return '';
    }

    const affectedMessage =
      `<div class="list-wrapper"><b>Affected Data - ${loadName}</b>` +
      `${affectedNettings.length > 0 ? `<br>Netting:<br><ul>${affectedNettings}</ul>` : ''}` +
      `</div>`;
    return affectedMessage;
  }

  generateMessage(
    base: string,
    deletedNames?: Array<string>,
    affectedMessage?: string,
    isEdit?: boolean,
  ) {
    let deletedStr = '';
    deletedNames.forEach(
      (name) => (deletedStr += `<li>${this._titleCase.transform(name)}</li>`),
    );
    return `${base}<div class="list-wrapper"><b>To ${
      isEdit ? 'Edit' : 'Delete'
    }</b><ul>${deletedStr}</ul></div>${affectedMessage}`;
  }

  isNettingAffectedByEnergyVector(
    loadId: string,
    currentEnergyVectors: Array<string>,
    nettingList: Array<Netting>,
  ): boolean {
    if (
      !loadId ||
      loadId === '' ||
      !currentEnergyVectors ||
      currentEnergyVectors.some((ev) => !ev || ev === '')
    ) {
      return false;
    }

    const associatedNetting = nettingList.filter((netting) =>
      netting.loads.includes(loadId),
    );

    const commonVectors =
      currentEnergyVectors.filter((ev) =>
        associatedNetting.some((netting) => ev === netting.energyVectorId),
      ) || [];
    if (commonVectors.length <= 0) {
      return false;
    }
    return true;
  }

  isNettingAffectedByNode(
    loadId: string,
    currentNodeIds: Array<string>,
    netting: Array<Netting>,
  ) {
    const associatedNetting = netting.filter((netting) =>
      netting.loads.includes(loadId),
    );
    if (
      !loadId ||
      loadId === '' ||
      !currentNodeIds ||
      currentNodeIds.some((node) => !node || node === '') ||
      !netting ||
      !associatedNetting ||
      associatedNetting.length < 1
    ) {
      return false;
    }

    const commonNodes =
      currentNodeIds.filter((node) =>
        associatedNetting.some(
          (assocNetting) =>
            assocNetting.nodes.includes('ALL') ||
            assocNetting.nodes.includes(node) ||
            node === 'ALL',
        ),
      ) || [];

    if (commonNodes.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  showDeletedDialog(
    deletedNodeNames: Array<string>,
    affectedMessage?: string,
  ): Observable<any> {
    return this._dialog.openDialog(BaseDialogComponent, {
      title: this._translate.instant(
        'Scenario.dialog.selectionDeleted.load.title',
      ),
      message: this.generateMessage(
        this._translate.instant(
          'Scenario.dialog.selectionDeleted.load.message',
        ),
        deletedNodeNames,
        affectedMessage,
      ),
      confirm: this._translate.instant('Generic.labels.yes'),
      close: this._translate.instant('Generic.labels.no'),
      width: 400,
    });
  }

  showEdittedDialog(
    edittedNodeNames: Array<string>,
    affectedMessage?: string,
  ): Observable<any> {
    return this._dialog.openDialog(BaseDialogComponent, {
      title: this._translate.instant(
        'Scenario.dialog.selectionEditted.load.title',
      ),
      message: this.generateMessage(
        this._translate.instant(
          'Scenario.dialog.selectionEditted.load.message',
        ),
        edittedNodeNames,
        affectedMessage,
        true,
      ),
      confirm: this._translate.instant('Generic.labels.yes'),
      close: this._translate.instant('Generic.labels.no'),
      width: 400,
    });
  }

  cascadeLoadDeletion(
    scenario: Scenario,
    deletedLoadIds: Array<string>,
  ): Scenario {
    if (!!!scenario) {
      return;
    }
    const nettings = ((scenario || {}).netting || {}).netting;
    const cascadedNettings = this.filterNettings(nettings, deletedLoadIds);

    return {
      ...scenario,
      netting: {
        ...scenario.netting,
        netting: cascadedNettings,
      },
    };
  }

  /** Return the nettings after removing the node that is part of the deletedNodeIds */
  filterNettings(nettings: Array<Netting>, deletedLoadsIds: Array<string>) {
    if (!!!nettings) {
      return undefined;
    }
    return nettings.map((netting) => {
      const updatedItem = {
        ...netting,
        loads: netting.loads.filter(
          (loadId) => !contains(deletedLoadsIds, loadId),
        ),
      };
      return updatedItem;
    });
  }
}
