import { Injectable } from '@angular/core';
import { from, fromEvent, Observable } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

const DEFAULT_SELECTOR = '.mat-drawer-content';
const DELAY = 600;

@Injectable({
  providedIn: 'root',
})
export class SupportLinkService {
  isLinkShowing$: Observable<boolean>;

  public initScrollDetection(
    selector: string = DEFAULT_SELECTOR,
  ): Observable<boolean> {
    return from(this._findElementInTemplate(selector)).pipe(
      switchMap((el) => {
        this.isLinkShowing$ = fromEvent(el, 'scroll').pipe(
          map(() => this.isVisibleInViewport()),
        );
        return this.isLinkShowing$;
      }),
    );
  }

  private isVisibleInViewport(): boolean {
    const el = document.querySelector('.prosumer-support') as HTMLElement;
    const rect = el.getBoundingClientRect();
    return rect.top >= 0 && rect.bottom - el.clientHeight <= window.innerHeight;
  }

  private async _findElementInTemplate(selector: string) {
    while (document.querySelector(selector) === null) {
      await new Promise((resolve) => setTimeout(resolve, DELAY));
    }
    return document.querySelector(selector) as HTMLElement;
  }
}
