import { DialogService } from 'prosumer-libs/eyes-core';
import { BaseDialogComponent, contains } from 'prosumer-libs/eyes-shared';
import {
  cascadeFilterLimitsByConnections,
  cascadeFilterNettingByEquipment,
  cascadeFilterTaxAndSubsidiesByNetting,
  getAffectedEnergyGridEmissions,
  getAffectedEnergyGridLimits,
  getAffectedTaxAndSubsidies,
  getBinsToDeleteOnDelete,
  getUpdatedStationVehicleAssoc,
} from 'prosumer-shared/utils';
import { Observable, of } from 'rxjs';

import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  ActivationReserve,
  CommonReserve,
  Converter,
  EnergyGridConnection,
  EnergyGridConnectionsEmissions,
  EnergyGridLimit,
  EnergyVectorCircuitInfo,
  Equipment,
  EquipmentCircuitInfo,
  EquipmentReserve,
  EQUIPS_WITH_BIN,
  Fuel,
  Generator,
  Line,
  Load,
  MarginReserve,
  MarketReserve,
  Metering,
  Netting,
  NetworkReserve,
  Node,
  RenewableEnergy,
  reserveToString,
  Scenario,
  Station,
  StationVehicleAssoc,
  Storage,
  TaxAndSubsidies,
  Vehicle,
  VehiclesDispatch,
} from '../models';

@Injectable()
export class EnergyVectorCascaderService {
  constructor(
    private _dialog: DialogService,
    private _titleCase: TitleCasePipe,
    private _translate: TranslateService,
  ) {}

  // prettier-ignore
  generateAffectedMessage([
    equipments,
    vehicles,
    stationVehicleAssocs,
    fuels,
    energyGrids,
    energyGridEmissions,
    energyGridLimits,
    lines,
    loads,
    shortCircuits,
    eqShortCircuits,
    energyVectorName,
    nodes,
    reserves,
    nettings,
    taxAndSubsidies,
  ]: [
      Array<Equipment>,
      Array<VehiclesDispatch>,
      Array<StationVehicleAssoc>,
      Array<Fuel>,
      Array<EnergyGridConnection>,
      Array<EnergyGridConnectionsEmissions>,
      Array<EnergyGridLimit>,
      Array<Line>,
      Array<Load>,
      Array<EnergyVectorCircuitInfo>,
      Array<EquipmentCircuitInfo>,
      string,
      Array<Node>,
      CommonReserve[],
      Array<Netting>,
      Array<TaxAndSubsidies>,
    ]) {
    const affectedEquipments = equipments
      .map((equipment) => equipment.name)
      .reduce((prev, next) => prev + `<li>${next}</li>`, '');
    const affectedFuels = fuels
      .map((fuel) => {
        const nodeNames = [];
        fuel.nodes.forEach(nodeId => {
          const name = (nodes.find((node) => node.value === nodeId) || { name: 'ALL' }).name;
          nodeNames.push(name);
        });
        const message = nodeNames.map((name) => `${name}`).reduce((prev, next) => prev + `<li>${next}</li>`);
        return `Node: ${message}`;
      })
      .reduce((prev, next) => prev + `<li>${next}</li>`, '');
    const affectedEnergyGrids = energyGrids
      .map((energyGrid) => energyGrid.marketName)
      .reduce((prev, next) => prev + `<li>${next}</li>`, '');
    // eslint-disable-next-line max-len
    const affectedEnergyGridEmissions = getAffectedEnergyGridEmissions(energyGridEmissions, energyGrids);
    const affectedEnergyGridLimits = getAffectedEnergyGridLimits(energyGridLimits, energyGrids);
    const affectedLines = lines.map((line) => line.name).reduce((prev, next) => prev + `<li>${next}</li>`, '');
    const affectedLoads = loads.map((load) => load.name).reduce((prev, next) => prev + `<li>${next}</li>`, '');
    const affectedShortCircuits = shortCircuits
      .map((evci) => energyVectorName)
      .reduce((prev, next) => prev + `<li>${next}</li>`, '');
    const affectedEqShortCircuits =
      (!!eqShortCircuits && !!equipments
        ? eqShortCircuits
          .filter((eqsc) => equipments.map((eq) => eq.id).includes(eqsc.equipmentId))
          .map((eqsc) => equipments.find((eq) => eq.id === eqsc.equipmentId).name)
        : [])
        .reduce((prev, next) => prev + `<li>${next}</li>`, '');
    const affectedReserves = reserves
      .map((reserve) => reserveToString(reserve))
      .reduce((prev, next) => prev + `<li>${next}</li>`, '');
    const affectedVehicles = vehicles
      .map((vehicle) => vehicle.vehicleName)
      .reduce((prev, next) => prev + `<li>${next}</li>`, '');
    // eslint-disable-next-line max-len
    const affectedStationVehicleAssocs = (stationVehicleAssocs || []).length > 0 ? `${stationVehicleAssocs.length} Station/Vehicle Associations` : '';
    const affectedNettings = nettings.map((netting) => netting.name).reduce((prev, next) => prev + `<li>${next}</li>`, '');
    const affectedtaxAndSubsidies = getAffectedTaxAndSubsidies(taxAndSubsidies, nettings);

    if (
      affectedEquipments.length === 0 &&
      affectedFuels.length === 0 &&
      affectedEnergyGrids.length === 0 &&
      affectedEnergyGridEmissions.length === 0 &&
      affectedEnergyGridLimits.length === 0 &&
      affectedLines.length === 0 &&
      affectedLoads.length === 0 &&
      affectedShortCircuits.length === 0 &&
      affectedEqShortCircuits.length === 0 &&
      affectedReserves.length === 0 &&
      affectedVehicles.length === 0 &&
      affectedStationVehicleAssocs.length === 0 &&
      affectedNettings.length === 0 &&
      affectedtaxAndSubsidies.length === 0
    ) {
      return '';
    }

    const affectedMessage =
      `<div class="list-wrapper"><b>Affected Data - ${energyVectorName}</b>` +
      `${affectedEquipments.length > 0 ? `<br>Equipments:<br><ul>${affectedEquipments}</ul>` : ''}` +
      `${affectedFuels.length > 0 ? `<br>Commodities - Fuels:<br><ul>${affectedFuels}</ul>` : ''}` +
      `${affectedEnergyGrids.length > 0
        ? `<br>Commodities - Energy Grid Connections:<br><ul>${affectedEnergyGrids}</ul>`
        : ''
      }` +
      `${affectedEnergyGridEmissions.length > 0
        ? `<br>Commodities - Energy Grid Connections Emissions:<br><ul>${affectedEnergyGridEmissions}</ul>`
        : ''
      }` +
      `${affectedEnergyGridLimits.length > 0 ? `<br>Commodities - Market Limits:<br><ul>${affectedEnergyGridLimits}</ul>` : ''}` +
      `${affectedLines.length > 0 ? `<br>Topology - Lines:<br><ul>${affectedLines}</ul>` : ''}` +
      `${affectedLoads.length > 0 ? `<br>Loads:<br><ul>${affectedLoads}</ul>` : ''}` +
      `${affectedShortCircuits.length > 0 ? `<br>Short Circuit - Power Needed:<br><ul>${affectedShortCircuits}</ul>` : ''
      }` +
      `${affectedEqShortCircuits.length > 0
        ? `<br>Short Circuit - Equipment:<br><ul>${affectedEqShortCircuits}</ul>`
        : ''
      }` +
      `${affectedReserves.length > 0 ? `<br>Frequency Control - Spinning Reserves:<br><ul>${affectedReserves}</ul>` : ''
      }` +
      `${affectedVehicles.length > 0 ? `<br>Mobility - Vehicles Dispatch:<br><ul>${affectedVehicles}</ul>` : ''
      }` +
      `${affectedStationVehicleAssocs.length > 0
        ? `<br>Mobility - Station/Vehicle Association:<br>${affectedStationVehicleAssocs}<br>`
        : ''
      }` +
      `${affectedNettings.length > 0 ? `<br>Netting:<br><ul>${affectedNettings}</ul>` : ''}` +
      `${affectedtaxAndSubsidies.length > 0 ? `<br>Tax & Subsidies:<br><ul>${affectedtaxAndSubsidies}</ul>` : ''}` +
      `<br></div>`;
    return affectedMessage;
  }

  generateMessage(
    base: string,
    deletedNames?: Array<string>,
    affectedMessage?: string,
  ) {
    let deletedStr = '';
    deletedNames.forEach(
      (name) => (deletedStr += `<li>${this._titleCase.transform(name)}</li>`),
    );

    return `${base}<div class="list-wrapper"><b>To Delete</b><ul>${deletedStr}</ul></div>${affectedMessage}`;
  }

  showDeletedDialog(
    deletedEVNames: Array<string>,
    affectedMessage?: string,
  ): Observable<any> {
    return this._dialog.openDialog(BaseDialogComponent, {
      title: this._translate.instant(
        'Scenario.dialog.selectionDeleted.energyVector.title',
      ),
      message: this.generateMessage(
        this._translate.instant(
          'Scenario.dialog.selectionDeleted.energyVector.message',
        ),
        deletedEVNames,
        affectedMessage,
      ),
      confirm: this._translate.instant('Generic.labels.yes'),
      close: this._translate.instant('Generic.labels.no'),
      width: 400,
    });
  }

  cascadeEnergyVectorDeletion(
    scenario: Scenario,
    deletedEnergyVectorIds: Array<string>,
  ): Scenario {
    if (!!!scenario) {
      return;
    }

    const equipments = ((scenario || {}).equipments || {}).equipments;
    const fuels = ((scenario || {}).commodities || {}).fuels;
    const energyGridConnections = ((scenario || {}).commodities || {}).grids;
    const energyGridConnectionsEmissions = ((scenario || {}).commodities || {})
      .connectionsEmissions;
    const connectionsEmissions = ((scenario || {}).commodities || {})
      .connectionsEmissions;
    const energyGridLimits = ((scenario || {}).commodities || {}).limits;
    const lines = ((scenario || {}).topology || {}).lines;
    const loads = ((scenario || {}).loads || {}).loads;
    const meterings = ((scenario || {}).regulations || {}).meterings;
    const _freqControl = (scenario || {}).frequencyControl || {};
    const scPowerNeeded = (_freqControl.shortCircuit || {}).energyVectors;
    const _spinningReserve = _freqControl.spinningReserve || {};
    const collectReserves = () =>
      Object.keys(_spinningReserve).reduce(
        (acc, curr) => acc.concat(_spinningReserve[curr] || []),
        [],
      );
    const margins = _spinningReserve.margins || [];
    const networks = _spinningReserve.networks || [];
    const vehicles = ((scenario || {}).mobility || {}).vehicles;
    const stationVehiclesAssoc = ((scenario || {}).mobility || {})
      .stationVehicleAssoc;
    const nettings = ((scenario || {}).netting || {}).netting;
    const taxAndSubsidies = ((scenario || {}).netting || {}).taxAndSubsidies;

    const cascadedEquipments = this.filterEquipments(
      equipments,
      deletedEnergyVectorIds,
    );
    const cascadedFuels = this.filterFuels(fuels, deletedEnergyVectorIds);
    const cascadedEnergyGridConnections = this.filterEnergyGridConnections(
      energyGridConnections,
      deletedEnergyVectorIds,
    );
    const cascadedEnergyGridConnectionsEmissions =
      this.filterEnergyGridConnectionsEmissions(
        energyGridConnectionsEmissions,
        cascadedEnergyGridConnections,
      );
    const cascadedEnergyGridLimits = cascadeFilterLimitsByConnections(
      energyGridLimits,
      cascadedEnergyGridConnections.map(
        (energyGridConnection) => energyGridConnection.id,
      ),
    );
    const cascadedLines = this.filterLines(lines, deletedEnergyVectorIds);
    const cascadedLoads = this.filterLoads(loads, deletedEnergyVectorIds);
    const cascadedMeterings = this.filterMeterings(
      meterings,
      cascadedEquipments,
    );
    const cascadedScPowerNeeded = this.filterScPowerNeeded(
      scPowerNeeded,
      deletedEnergyVectorIds,
    );
    const cascadedScEquipments = this.filterScEquipments(
      scenario,
      cascadedEquipments,
    );
    const cascadedVehicles = this.filterVehicles(vehicles, cascadedEquipments);
    const cascadedStationVehiclesAssoc = this.filterStationVehiclesAssocs(
      stationVehiclesAssoc,
      vehicles,
      cascadedVehicles,
      cascadedEquipments,
    );

    // Cascade to Reserves with Energy Vector
    let cascadedReserves = this.filterReservesByVectors(
      collectReserves(),
      deletedEnergyVectorIds,
    );
    // Cascade to Equipment Reserves with cascaded equipments
    cascadedReserves = this.filterReservesByEquipments(
      cascadedReserves,
      equipments.map((equipment) => equipment.id),
    );
    // Cascade to Market Reserves with cascaded EGCs
    cascadedReserves = this.filterReservesByMarkets(
      cascadedReserves,
      energyGridConnections.map((egc) => egc.id),
    );
    const cascadedMargins = cascadedReserves.filter(
      (_r) => _r.nature === 'margin',
    ) as MarginReserve[];
    let cascadedNettings = this.filterNettingsByEnergyVector(
      nettings,
      deletedEnergyVectorIds,
    );
    cascadedNettings = cascadeFilterNettingByEquipment(
      cascadedNettings,
      cascadedEquipments.map((equipment) => equipment.id),
    );
    const cascadedTaxAndSubsidies = cascadeFilterTaxAndSubsidiesByNetting(
      taxAndSubsidies,
      cascadedNettings.map((netting) => netting.id),
    );

    return {
      ...scenario,
      equipments: {
        ...scenario.equipments,
        equipments: cascadedEquipments,
        binToDelete: [].concat(
          ...equipments
            .filter((equipment) => !contains(cascadedEquipments, equipment))
            .map((equipment) => {
              let toDelete = [];
              if (EQUIPS_WITH_BIN.includes(equipment.type)) {
                toDelete.push(
                  getBinsToDeleteOnDelete(undefined, equipment.profiles),
                );
              }
              toDelete = toDelete.concat(
                [].concat(
                  ...(equipment['operatingCostProfiles'] || []).map(
                    (costProfile) => costProfile.profiles,
                  ),
                ),
              );
              return toDelete;
            }),
        ),
      },
      commodities: {
        ...scenario.commodities,
        fuels: cascadedFuels,
        grids: cascadedEnergyGridConnections,
        connectionsEmissions: cascadedEnergyGridConnectionsEmissions,
        limits: cascadedEnergyGridLimits,
        binToDelete: [].concat(
          ...[
            energyGridConnections
              .filter((grid) => !contains(cascadedEnergyGridConnections, grid))
              .map((grid) => getBinsToDeleteOnDelete(undefined, grid.profiles)),
            connectionsEmissions
              .filter(
                (emission) =>
                  !contains(cascadedEnergyGridConnectionsEmissions, emission),
              )
              .map((emission) =>
                getBinsToDeleteOnDelete(undefined, emission.profiles),
              ),
          ],
        ),
      },
      topology: {
        ...scenario.topology,
        lines: cascadedLines,
      },
      loads: {
        ...scenario.loads,
        loads: cascadedLoads,
        binToDelete: [].concat(
          ...loads
            .filter((load) => !contains(cascadedLoads, load))
            .map((load) => getBinsToDeleteOnDelete(undefined, load.profiles)),
        ),
      },
      frequencyControl: {
        ...scenario.frequencyControl,
        shortCircuit: {
          equipments: !!cascadedScEquipments
            ? cascadedScEquipments
            : [...scenario.frequencyControl.shortCircuit.equipments],
          energyVectors: cascadedScPowerNeeded,
        },
        spinningReserve: {
          ...scenario.frequencyControl.spinningReserve,
          margins: cascadedMargins,
          activations: cascadedReserves.filter(
            (_r) => _r.nature === 'activation',
          ) as ActivationReserve[],
          equipments: cascadedReserves.filter(
            (_r) => _r.nature === 'equipment',
          ) as EquipmentReserve[],
          markets: cascadedReserves.filter(
            (_r) => _r.nature === 'market',
          ) as MarketReserve[],
          binToDelete: [].concat(
            ...margins
              .filter((margin) => !contains(cascadedMargins, margin))
              .map((margin) =>
                getBinsToDeleteOnDelete(undefined, margin.profiles),
              ),
          ),
          networks: cascadedReserves.filter(
            (_r) => _r.nature === 'network',
          ) as NetworkReserve[],
        },
      },
      regulations: {
        ...scenario.regulations,
        meterings: cascadedMeterings,
      },
      mobility: {
        ...scenario.mobility,
        vehicles: cascadedVehicles,
        stationVehicleAssoc: cascadedStationVehiclesAssoc,
      },
      netting: {
        netting: cascadedNettings,
        taxAndSubsidies: cascadedTaxAndSubsidies,
      },
    };
  }

  /** Return the nettings without the deletedEnergyVectorIds */
  filterNettingsByEnergyVector(
    nettings: Array<Netting>,
    deletedEnergyVectorIds: Array<string>,
  ) {
    if (!!!nettings) {
      return undefined;
    }
    return nettings.filter(
      (netting) => !contains(deletedEnergyVectorIds, netting.energyVectorId),
    );
  }

  /** Return the reserves WITHOUT the deletedId */
  filterReservesByVectors = (
    reserves: CommonReserve[],
    deletedIds: string[],
  ): CommonReserve[] =>
    reserves.filter(
      (reserve) =>
        !contains(deletedIds, (reserve as MarginReserve).energyVectorId) &&
        !contains(deletedIds, (reserve as ActivationReserve).energyVectorId) &&
        !contains(deletedIds, (reserve as EquipmentReserve).energyVectorId) &&
        !contains(deletedIds, (reserve as NetworkReserve).energyVectorId),
    );

  /**
   * Return the reserves that doesn't have their equipmentId included in the
   * deleted equipment IDs
   */
  filterReservesByEquipments = (
    reserves: CommonReserve[],
    deletedIds: string[],
  ): CommonReserve[] =>
    reserves.filter(
      (reserve) =>
        reserve.nature !== 'equipment' ||
        !contains(deletedIds, (reserve as EquipmentReserve).equipmentId),
    );

  /**
   * Filter the reserves that don't have their energyGridConnectionID
   * included in the deleted market IDs
   */
  filterReservesByMarkets = (
    reserves: CommonReserve[],
    deletedIds: string[],
  ): CommonReserve[] =>
    reserves.filter(
      (reserve) =>
        reserve.nature !== 'market' ||
        !contains(
          deletedIds,
          (reserve as MarketReserve).energyGridConnectionId,
        ),
    );

  filterMeterings = (
    meterings: Array<Metering>,
    savedEquipments: Array<Equipment>,
  ) =>
    (meterings || []).filter((metering) =>
      savedEquipments.map((equip) => equip.id).includes(metering.technologyId),
    );

  filterEquipments(
    equipments: Array<
      | RenewableEnergy
      | Converter
      | Storage
      | Generator
      | Vehicle
      | Station
      | Equipment
    >,
    deletedEnergyVectorIds: Array<string>,
  ) {
    if (!!!equipments) {
      return undefined;
    }

    return equipments.filter((equipment) =>
      !contains(deletedEnergyVectorIds, equipment.outputEnergyVector) &&
      (equipment as Converter).efficiencyMatrix
        ? (equipment as Converter).efficiencyMatrix.efficiencies.every(
            (element) =>
              !contains(deletedEnergyVectorIds, element.energyVectorIn) &&
              !contains(deletedEnergyVectorIds, element.energyVectorOut),
          )
        : true &&
          !contains(
            deletedEnergyVectorIds,
            (equipment as Storage).energyVector,
          ) &&
          !contains(
            deletedEnergyVectorIds,
            (equipment as Storage).outputEnergyVector,
          ) &&
          !contains(
            deletedEnergyVectorIds,
            (equipment as Generator).inputEnergyVector,
          ) &&
          !contains(
            deletedEnergyVectorIds,
            (equipment as Generator).outputEnergyVector,
          ) &&
          !contains(
            deletedEnergyVectorIds,
            (equipment as Vehicle).outputEnergyVector,
          ) &&
          !contains(
            deletedEnergyVectorIds,
            (equipment as Station).energyVector,
          ) &&
          !contains(
            deletedEnergyVectorIds,
            (equipment as Station).outputEnergyVector,
          ),
    );
  }

  filterFuels(fuels: Array<Fuel>, deletedEnergyVectorIds: Array<string>) {
    if (!!!fuels) {
      return undefined;
    }
    return fuels.filter((fuel) => !contains(deletedEnergyVectorIds, fuel.fuel));
  }

  filterEnergyGridConnections(
    energyGridConnections: Array<EnergyGridConnection>,
    deletedEnergyVectorIds: Array<string>,
  ) {
    if (!!!energyGridConnections) {
      return undefined;
    }
    return energyGridConnections.filter(
      (energyGridConnection) =>
        !contains(deletedEnergyVectorIds, energyGridConnection.delivery),
    );
  }

  filterEnergyGridConnectionsEmissions(
    energyGridConnectionsEmissions: Array<EnergyGridConnectionsEmissions>,
    cascadedEnergyGridConnections: Array<EnergyGridConnection>,
  ) {
    if (!!!energyGridConnectionsEmissions) {
      return undefined;
    }
    return energyGridConnectionsEmissions.filter((egcEmissions) =>
      cascadedEnergyGridConnections
        .map((egc) => egc.id)
        .includes(egcEmissions.marketName),
    );
  }

  filterLines(lines: Array<Line>, deletedEnergyVectorIds: Array<string>) {
    if (!!!lines) {
      return undefined;
    }
    return lines.filter(
      (line) => !contains(deletedEnergyVectorIds, line.energyVectorId),
    );
  }

  filterLoads(loads: Array<Load>, deletedEnergyVectorIds: Array<string>) {
    if (!!!loads) {
      return loads;
    }
    return loads.filter(
      (load) => !contains(deletedEnergyVectorIds, load.energyVector),
    );
  }

  filterScPowerNeeded(
    energyVectorCircuitInfos: Array<EnergyVectorCircuitInfo>,
    deletedEnergyVectorIds: Array<string>,
  ) {
    if (!!!energyVectorCircuitInfos) {
      return energyVectorCircuitInfos;
    }

    return energyVectorCircuitInfos.filter(
      (evci) => !contains(deletedEnergyVectorIds, evci.energyVectorId),
    );
  }

  filterScEquipments(
    scenario: Scenario,
    deletedEquipments: Array<
      RenewableEnergy | Converter | Storage | Generator | Equipment
    >,
  ) {
    if (!!!deletedEquipments) {
      return undefined;
    }
    const shortCircuitsEquips = (
      ((scenario || {}).frequencyControl || {}).shortCircuit || {}
    ).equipments;

    return shortCircuitsEquips.filter((equip) =>
      deletedEquipments.map((delEq) => delEq.id).includes(equip.equipmentId),
    );
  }

  filterVehicles(
    vehicles: Array<VehiclesDispatch>,
    cascadedEquipment: Array<
      | RenewableEnergy
      | Converter
      | Storage
      | Generator
      | Equipment
      | Vehicle
      | Station
    >,
  ) {
    if (!!!vehicles || !!!cascadedEquipment) {
      return undefined;
    }

    return vehicles.filter((vehicle) =>
      cascadedEquipment.map((delEq) => delEq.id).includes(vehicle.vehicleId),
    );
  }

  filterStationVehiclesAssocs(
    stationVehicleAssoc: Array<StationVehicleAssoc>,
    currentVehiclesList: Array<VehiclesDispatch>,
    cascadedVehicles: Array<VehiclesDispatch>,
    cascadedEquipments: Array<
      | RenewableEnergy
      | Converter
      | Storage
      | Generator
      | Equipment
      | Vehicle
      | Station
    >,
  ) {
    if (
      !!!stationVehicleAssoc ||
      !!!currentVehiclesList ||
      !!!cascadedVehicles
    ) {
      return undefined;
    }

    const remainingVehiclesIds = cascadedVehicles.map((vehicle) => vehicle.id);
    const remainingEquipmentIds = cascadedEquipments.map((eq) => eq.id);

    const updatedAssoc: StationVehicleAssoc[] = getUpdatedStationVehicleAssoc(
      stationVehicleAssoc,
      currentVehiclesList,
      cascadedVehicles,
    );

    return updatedAssoc
      .filter(
        (assoc) =>
          assoc.vehicleNames.filter(
            (vehicleName) => !contains(remainingVehiclesIds, vehicleName),
          ).length === 0,
      )
      .filter(
        (assoc) =>
          contains(remainingEquipmentIds, assoc.vehicleId) &&
          contains(remainingEquipmentIds, assoc.stationId),
      );
  }

  filterNettingByEquipmentEV(
    nettingArr: Array<Netting>,
    equipments: Array<Equipment>,
  ): Array<Netting> {
    if (!nettingArr || !equipments) {
      return undefined;
    }
    const updatedNettingArr = nettingArr
      .map((netting) => {
        let updatedEquipmentList = netting.equipments;
        netting.equipments.forEach((equipmentId) => {
          const equipment =
            equipments.find((equipment) => equipment.id === equipmentId) || {};
          // get all energyVectors
          const { outputEnergyVector, inputEnergyVector, efficiencyMatrix } =
            (equipment || {}) as any;
          const { mainEnergyVectorInput, mainEnergyVectorOutput } =
            (efficiencyMatrix || {}) as any;
          const equipmentEV = [
            outputEnergyVector,
            inputEnergyVector,
            mainEnergyVectorInput,
            mainEnergyVectorOutput,
          ].filter((ev) => !!ev);

          if (!equipmentEV.includes(netting.energyVectorId)) {
            // remove equipment
            updatedEquipmentList = netting.equipments.filter(
              (nettingEquipment) => nettingEquipment !== equipment.id,
            );
          }
        });

        return { ...netting, equipments: updatedEquipmentList };
      })
      .filter(
        (netting) => !!netting.equipments && netting.equipments.length > 0,
      );
    return updatedNettingArr;
  }

  // TODO: Adapt other equipments
  showCascadingEffectOnOperatingCost(
    currentValues: Array<string>,
    associatedTableVectors: Array<string>,
  ): Observable<any> {
    const commonVector = associatedTableVectors.filter((tableVector) =>
      currentValues.some((vector) => tableVector === vector),
    );

    if (!!commonVector && commonVector.length >= 1) {
      return this._dialog.openDialog(BaseDialogComponent, {
        title: this._translate.instant(
          'Scenario.dialog.energyVectors.updateVectorTitle',
        ),
        message: this._translate.instant(
          'Scenario.dialog.energyVectors.operatingCostWarning',
        ),
        confirm: this._translate.instant('Generic.labels.yes'),
        close: this._translate.instant('Generic.labels.no'),
        disableClose: true,
        width: 400,
      });
    }
    return of(true);
  }

  isOperatingCostAffected(
    currentValues: Array<string>,
    associatedTableVectors: Array<string>,
  ): boolean {
    const commonVector = associatedTableVectors.filter((tableVector) =>
      currentValues.some((vector) => tableVector === vector),
    );

    if (!!commonVector && commonVector.length >= 1) {
      return true;
    }
    return false;
  }

  isNettingAffected(
    equipmentId: string,
    currentEnergyVectors: Array<string>,
    nettingList: Array<Netting>,
  ): boolean {
    if (
      !equipmentId ||
      equipmentId === '' ||
      !currentEnergyVectors ||
      currentEnergyVectors.some((ev) => !ev || ev === '')
    ) {
      return false;
    }

    const associatedNetting = nettingList.filter((netting) =>
      netting.equipments.includes(equipmentId),
    );
    const commonVectors =
      currentEnergyVectors.filter((ev) =>
        associatedNetting.some((netting) => ev === netting.energyVectorId),
      ) || [];

    if (commonVectors.length <= 0) {
      return false;
    }
    return true;
  }

  showCascadingWarningDialog(affectedData: Array<boolean>): Observable<any> {
    if (affectedData.every((data) => !data)) {
      return of(true);
    }

    return this._dialog.openDialog(BaseDialogComponent, {
      title: this._translate.instant(
        'Scenario.dialog.energyVectors.updateVectorTitle',
      ),
      message: this._translate.instant(
        'Scenario.dialog.energyVectors.affectedDataWarning',
      ),
      confirm: this._translate.instant('Generic.labels.yes'),
      close: this._translate.instant('Generic.labels.no'),
      disableClose: true,
      width: 400,
    });
  }

  cascadeEnergyVectorNettingUpdate(scenario: Scenario): Scenario {
    const filteredNettings = this.filterNettingByEquipmentEV(
      scenario.netting.netting,
      scenario.equipments.equipments,
    );
    const filteredTaxAndSubsidies = cascadeFilterTaxAndSubsidiesByNetting(
      scenario.netting.taxAndSubsidies,
      filteredNettings.map((netting) => netting.id),
    );

    return {
      ...scenario,
      netting: {
        netting: filteredNettings,
        taxAndSubsidies: filteredTaxAndSubsidies,
      },
    };
  }
}
