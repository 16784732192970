import { ScenarioWizardStepForDetail } from 'prosumer-app/+scenario/services/scenario-completion';
import { ColumnDefinition } from 'prosumer-app/libs/eyes-shared';
import { CascadableEntity } from 'prosumer-app/services/cascade-controller/cascade-controller.types';
import { AffectedEntitiesDetailResponseI } from 'prosumer-app/services/store-api';
import { NavLinkEmitI } from 'prosumer-app/shared/models';
import { DETAIL_TYPE_NAMES } from 'prosumer-app/stores/scenario-detail/scenario-detail.state';
import { Coerce } from 'prosumer-core/utils';

import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'prosumer-cascade-confirmation',
  templateUrl: './cascade-confirmation.component.html',
  styleUrls: ['./cascade-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CascadeConfirmationComponent implements OnInit {
  readonly data: CascadableEntity = inject(MAT_DIALOG_DATA);
  private readonly dialogRef: MatDialogRef<unknown> = inject(MatDialogRef);

  readonly translatedType = this.getTranslatedDetailType();
  readonly translatedAction = this.data.action;
  readonly tableData = signal<
    Pick<AffectedEntitiesDetailResponseI, 'delete' | 'update'> | undefined
  >(undefined);

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.initTableData(this.data);
  }

  onOk(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onRowClick(e: NavLinkEmitI): void {
    this.router.navigate([], {
      queryParams: { scenarioTab: ScenarioWizardStepForDetail[e.value] },
    });
    this.onCancel();
  }

  private getTranslatedDetailType(): string {
    const type = this.resolveDetailType();
    return Coerce.toString(DETAIL_TYPE_NAMES[type], type);
  }

  private resolveDetailType(): string {
    return Coerce.toString(Coerce.toObject(this.data).type);
  }

  private initTableData(d: CascadableEntity) {
    if (d.affectedEntitiesData.affected_entities) {
      this.tableData.set({
        delete: d.affectedEntitiesData.delete,
        update: d.affectedEntitiesData.update,
      });
    }
  }

  readonly columnDef: ColumnDefinition = {
    typeLabel: {
      type: 'navLinkEmit',
      keyNavigation: 'dataType',
      name: 'Type',
      flex: '30%',
      sortable: true,
    },
    name: {
      name: 'Name',
      flex: '70%',
      sortable: true,
    },
  };
}
