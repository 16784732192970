import { FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import {
  CompareCashFlowResultItem,
  CompareDispatchResultItem,
  CompareEnergyBalanceResultItem,
  CompareEquipmentResultItem,
  CompareMainResult,
  ComparePaybackResult,
  CompareTopologyResultItem,
  Result,
  Scenario,
  ScenarioResult,
} from 'prosumer-scenario/models';
import { buildEntityId } from 'prosumer-shared/utils';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ComparisonTray } from '@prosumer/comparison/base';
import {
  EmissionsData,
  EquipmentResult,
  TopologyResult,
} from '@prosumer/results/models';

import { ScenarioCompareState } from './scenario-compare-state.model';
import * as ScenarioCompareActions from './scenario-compare.actions';
import { scenarioCompareQueries } from './scenario-compare.selectors';

@Injectable()
export class ScenarioCompareFacadeService {
  constructor(private store$: Store<ScenarioCompareState>) {}

  migratedScenarios$ = new BehaviorSubject<Array<string>>([]);

  /* Base */
  state$ = this.store$.pipe<ScenarioCompareState>(
    select(scenarioCompareQueries.selectState),
  );
  loaded$ = this.store$.pipe<boolean>(
    select(scenarioCompareQueries.selectLoaded),
  );
  loading$ = this.store$.pipe<boolean>(
    select(scenarioCompareQueries.selectLoading),
  );
  error$ = this.store$.pipe<boolean>(
    select(scenarioCompareQueries.selectError),
  );
  message$ = this.store$.pipe<any>(
    select(scenarioCompareQueries.selectMessage),
  );
  referenceId$ = this.store$.pipe<string>(
    select(scenarioCompareQueries.selectReferenceId),
  );
  resultList$ = this.store$.pipe<Array<Result>>(
    select(scenarioCompareQueries.selectScenarioList),
  );
  scenarioResultList$ = this.store$.pipe<Array<ScenarioResult>>(
    select(scenarioCompareQueries.selectResultList),
  );
  isOutputSplit$ = this.store$.pipe<boolean>(
    select(scenarioCompareQueries.selectIsOutputSplit),
  );
  equipmentsResult$ = this.store$.pipe<ComparisonTray<EquipmentResult>>(
    select(scenarioCompareQueries.selectEquipmentsResult),
  );
  topologiesResult$ = this.store$.pipe<ComparisonTray<TopologyResult>>(
    select(scenarioCompareQueries.selectTopologiesResult),
  );
  emissionsResult$ = this.store$.pipe<ComparisonTray<EmissionsData>>(
    select(scenarioCompareQueries.selectEmissionsResult),
  );
  /* Derived */
  referenceScenario$ = this.store$.pipe<Scenario>(
    select(scenarioCompareQueries.selectReferenceScenario),
  );
  nonReferenceScenarios$ = this.store$.pipe<Array<Scenario>>(
    select(scenarioCompareQueries.selectNonReferenceScenarios),
  );

  /* Main */
  mainResultList$ = this.store$.pipe<Array<CompareMainResult>>(
    select(scenarioCompareQueries.selectMainResultList),
  );

  referenceMainScenario$ = this.store$.pipe<CompareMainResult>(
    select(scenarioCompareQueries.selectMainReferenceScenario),
  );

  /* Equipment */
  equipmentResultList$ = this.store$.pipe<Array<CompareEquipmentResultItem>>(
    select(scenarioCompareQueries.selectEquipmentResultList),
  );

  equipmentNodeOptions$ = this.store$.pipe<Array<string>>(
    select(scenarioCompareQueries.selectEquipmentNodeOptions),
  );

  equipmentTypeOptions$ = this.store$.pipe<Array<string>>(
    select(scenarioCompareQueries.selectEquipmentTypeOptions),
  );

  equipmentEnergyVectorOptions$ = this.store$.pipe<Array<string>>(
    select(scenarioCompareQueries.selectEquipmentEnergyVectorOptions),
  );

  /* Equipment */
  energyBalanceResultList$ = this.store$.pipe<
    Array<CompareEnergyBalanceResultItem>
  >(select(scenarioCompareQueries.selectEnergyBalanceResultList));

  energyBalanceNodeOptions$ = this.store$.pipe<Array<string>>(
    select(scenarioCompareQueries.selectEnergyBalanceNodeOptions),
  );

  energyBalanceEnergyVectorOptions$ = this.store$.pipe<Array<string>>(
    select(scenarioCompareQueries.selectEnergyBalanceEnergyVectorOptions),
  );

  energyBalanceYearOptions$ = this.store$.pipe<Array<string>>(
    select(scenarioCompareQueries.selectEnergyBalanceYearOptions),
  );

  /* Topology */
  topologyResultList$ = this.store$.pipe<Array<CompareTopologyResultItem>>(
    select(scenarioCompareQueries.selectTopologyResultList),
  );

  topologyOriginNodeOptions$ = this.store$.pipe<Array<string>>(
    select(scenarioCompareQueries.selectTopologyNodeOptions),
  );

  topologyDestinationNodeOptions$ = this.store$.pipe<Array<string>>(
    select(scenarioCompareQueries.selectTopologyDestinationNodeOptions),
  );

  topologyEnergyVectorOptions$ = this.store$.pipe<Array<string>>(
    select(scenarioCompareQueries.selectTopologyEnergyVectorOptions),
  );

  /* CashFlow */
  cashFlowResultList$ = this.store$.pipe<Array<CompareCashFlowResultItem>>(
    select(scenarioCompareQueries.selectCashFlowResultList),
  );

  /* Payback */
  paybackResultList$ = this.store$.pipe<Array<ComparePaybackResult>>(
    select(scenarioCompareQueries.selectPaybackResultList),
  );

  /* Dispatch */
  dispatchResultList$ = this.store$.pipe<Array<CompareDispatchResultItem>>(
    select(scenarioCompareQueries.selectDispatchResultList),
  );

  dispatchTypeOptions$ = this.store$.pipe<Array<FormFieldOption<string>>>(
    select(scenarioCompareQueries.selectDispatchTypeOptions),
  );

  dispatchYearOptions$ = this.store$.pipe<Array<string>>(
    select(scenarioCompareQueries.selectDispatchYearOptions),
  );

  dispatchNodeOptions$ = this.store$.pipe<Array<string>>(
    select(scenarioCompareQueries.selectDispatchNodeOptions),
  );

  dispatchEnergyVectorOptions$ = this.store$.pipe<Array<string>>(
    select(scenarioCompareQueries.selectDispatchEnergyVectorOptions),
  );

  dispatchResultTypeOptions$ = this.store$.pipe<
    Array<'production' | 'consumption' | 'storage_mvts' | 'soc'>
  >(select(scenarioCompareQueries.selectDispatchResultTypeOptions));

  /* Dispatchers */
  compare(
    referenceId: string,
    projectId: string,
    caseId: string,
    resultList: Array<Result>,
  ) {
    this.store$.dispatch(
      new ScenarioCompareActions.CompareScenario({
        referenceId,
        projectId,
        caseId,
        resultList,
      }),
    );
  }

  migrate(
    referenceId: string,
    projectId: string,
    caseId: string,
    resultList: Array<Result>,
  ) {
    let isCompared = false;

    this.store$.dispatch(
      new ScenarioCompareActions.MigrateScenario({
        projectId,
        caseId,
        resultList,
      }),
    );
    combineLatest([
      this.migratedScenarios$,
      this.isOutputSplit$,
      this.loading$,
    ]).subscribe(([migratedScenarioIds, isOutputSplit]) => {
      if (
        resultList.every(({ id }) => migratedScenarioIds.includes(id)) &&
        !isCompared
      ) {
        if (isOutputSplit) {
          this.compareMain(referenceId, projectId, caseId, resultList);
        } else {
          this.compare(referenceId, projectId, caseId, resultList);
        }
        isCompared = true;
      }
    });
  }

  setFailedMigration(message: string) {
    this.store$.dispatch(
      new ScenarioCompareActions.MigrateScenarioFailure({ error: message }),
    );
  }

  setMigratedScenario(scenarioId: string, variationId: string = undefined) {
    const migratedScenarioIds = this.migratedScenarios$.getValue();
    const entityId = buildEntityId(scenarioId, variationId);
    if (!migratedScenarioIds.includes(entityId)) {
      migratedScenarioIds.push(entityId);
      this.migratedScenarios$.next(migratedScenarioIds);
    }
  }

  compareMain(
    referenceId: string,
    projectId: string,
    caseId: string,
    resultList: Array<Result>,
  ) {
    this.store$.dispatch(
      new ScenarioCompareActions.CompareScenarioMainTab({
        referenceId,
        projectId,
        caseId,
        resultList,
      }),
    );
  }

  compareEquipment(
    projectId: string,
    caseId: string,
    resultList: Array<Result>,
  ) {
    this.equipmentsResult$
      .pipe(
        filter((data) => !Object.keys(data).length),
        take(1),
      )
      .subscribe(() => {
        this.store$.dispatch(
          new ScenarioCompareActions.CompareScenarioEquipmentTab({
            projectId,
            caseId,
            resultList,
          }),
        );
      });
  }

  compareDispatch(
    projectId: string,
    caseId: string,
    resultList: Array<Result>,
  ) {
    this.scenarioResultList$
      .pipe(
        filter(
          (data) =>
            !data.every((result) => !!result.dispatchResult.dispatchList),
        ),
        take(1),
      )
      .subscribe(() => {
        this.store$.dispatch(
          new ScenarioCompareActions.CompareScenarioDispatchTab({
            projectId,
            caseId,
            resultList,
          }),
        );
      });
  }

  compareEnergyBalance(
    projectId: string,
    caseId: string,
    resultList: Array<Result>,
  ) {
    this.scenarioResultList$
      .pipe(
        filter(
          (data) =>
            !data.every((result) => !!result.energyBalanceResult.equipments),
        ),
        take(1),
      )
      .subscribe(() => {
        this.store$.dispatch(
          new ScenarioCompareActions.CompareScenarioEnergyBalanceTab({
            projectId,
            caseId,
            resultList,
          }),
        );
      });
  }

  compareCO2Emission(
    projectId: string,
    caseId: string,
    resultList: Array<Result>,
  ) {
    this.emissionsResult$
      .pipe(
        filter((data) => !Object.keys(data).length),
        take(1),
      )
      .subscribe(() => {
        this.store$.dispatch(
          new ScenarioCompareActions.CompareScenarioCO2EmissionTab({
            projectId,
            caseId,
            resultList,
          }),
        );
      });
  }

  compareTopology(
    projectId: string,
    caseId: string,
    resultList: Array<Result>,
  ) {
    this.topologiesResult$
      .pipe(
        filter((data) => !Object.keys(data).length),
        take(1),
      )
      .subscribe(() => {
        this.store$.dispatch(
          new ScenarioCompareActions.CompareScenarioTopologyTab({
            projectId,
            caseId,
            resultList,
          }),
        );
      });
  }

  compareCashFlow(
    projectId: string,
    caseId: string,
    resultList: Array<Result>,
  ) {
    this.scenarioResultList$
      .pipe(
        filter(
          (data) =>
            !data.every(
              (result) =>
                !!result.cashFlowResult.cashFlowList &&
                !!result.paybackResult.series,
            ),
        ),
        take(1),
      )
      .subscribe(() => {
        this.store$.dispatch(
          new ScenarioCompareActions.CompareScenarioCashFlowTab({
            projectId,
            caseId,
            resultList,
          }),
        );
      });
  }
}
