import { CommentControllerService } from 'prosumer-scenario/services/comment-controller.service';

import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'prosumer-comment-href',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, MatBadgeModule],
  templateUrl: './comment-href.component.html',
  styleUrl: './comment-href.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentHrefComponent {
  private readonly commentController = inject(CommentControllerService);

  @Input() dialogMode = false;
  @Input() iconMode = false;
  @Input() toggleMode = false;
  @Input() commentingOn = '';
  @Input() projectId = '';
  @Input() caseId = '';
  @Input() scenarioId = '';
  @Input() count = 0;

  get displayCount(): string {
    if (this.count > 9) {
      return '9+';
    }
    return String(this.count);
  }

  onClick(): void {
    this.commentController.showComments.set(
      this.shouldShowCommentsBasedOnToggleMode(),
    );
    this.commentController.commentingOn.set(this.commentingOn);
    this.commentController.activeGeneralCommentsParams.set({
      caseId: this.caseId,
      projectId: this.projectId,
      scenarioId: this.scenarioId,
    });
  }

  private shouldShowCommentsBasedOnToggleMode(): boolean {
    return this.toggleMode ? !this.commentController.showComments() : true;
  }
}
