/* eslint-disable @typescript-eslint/naming-convention */
import { Action } from '@ngrx/store';

import { ComputingTime, Storage } from '../models';

export enum ActionTypes {
  CLEAR = '[Billing] Clear State',
  GET_COMPUTING_TIME = '[Dashboard Page] Get Computing Time',
  GET_COMPUTING_TIME_FAILURE = '[Billing API] Get Computing Time Failure',
  GET_COMPUTING_TIME_SUCCESS = '[Billing API] Get Computing Time Success',
  GET_STORAGE = '[Dashboard Page] Get Storage',
  GET_STORAGE_FAILURE = '[Billing API] Get Storage Failure',
  GET_STORAGE_SUCCESS = '[Billing API] Get Storage Success',
  SET_COMPUTING_TIME = '[Billing] Set Computing Time',
  SET_STORAGE = '[Billing] Set Storage',
}

export class ClearState implements Action {
  readonly type = ActionTypes.CLEAR;
  constructor(public payload?: any) {}
}

export class GetComputingTime implements Action {
  readonly type = ActionTypes.GET_COMPUTING_TIME;
  constructor(public payload?: { userId?: string }) {}
}

export class GetComputingTimeFailure implements Action {
  readonly type = ActionTypes.GET_COMPUTING_TIME_FAILURE;
  constructor(public payload?: { userId?: string; message?: string }) {}
}

export class GetComputingTimeSuccess implements Action {
  readonly type = ActionTypes.GET_COMPUTING_TIME_SUCCESS;
  constructor(
    public payload?: {
      userId?: string;
      data?: ComputingTime;
      message?: string;
    },
  ) {}
}

export class GetStorage implements Action {
  readonly type = ActionTypes.GET_STORAGE;
  constructor(public payload?: { userId?: string }) {}
}

export class GetStorageFailure implements Action {
  readonly type = ActionTypes.GET_STORAGE_FAILURE;
  constructor(public payload?: { userId?: string; message?: string }) {}
}

export class GetStorageSuccess implements Action {
  readonly type = ActionTypes.GET_STORAGE_SUCCESS;
  constructor(
    public payload?: { userId?: string; data?: Storage; message?: string },
  ) {}
}

export class SetComputingTime implements Action {
  readonly type = ActionTypes.SET_COMPUTING_TIME;
  constructor(public payload?: { data?: ComputingTime }) {}
}

export class SetStorage implements Action {
  readonly type = ActionTypes.SET_STORAGE;
  constructor(public payload?: { data?: Storage }) {}
}

export type All =
  | ClearState
  | GetComputingTime
  | GetComputingTimeFailure
  | GetComputingTimeSuccess
  | GetStorage
  | GetStorageFailure
  | GetStorageSuccess
  | SetComputingTime
  | SetStorage;
