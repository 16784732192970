@if (iconMode) {
  <div class="comment-href-icon-ctr">
    <button
      mat-icon-button
      color="primary"
      class="comment-badge"
      data-testid="show-comments"
      (click)="onClick()"
    >
      <mat-icon>comment</mat-icon>
    </button>
    @if (count) {
      <span class="badge" data-testid="href-icon-count">
        {{ displayCount }}
      </span>
    }
  </div>
} @else {
  <button
    mat-stroked-button
    (click)="onClick()"
    color="primary"
    class="comment-badge"
    data-testid="show-comments"
    [matBadge]="displayCount"
    [matBadgeHidden]="!count"
    matBadgeSize="small"
    matBadgeColor="warn"
  >
    <mat-icon>comment</mat-icon>
    Comments
  </button>
}
