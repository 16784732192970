import { Utils } from 'prosumer-app/core/utils/utils';
import { mapYearlyValuesToBackend } from 'prosumer-app/shared';
import { ScenarioGeneric } from 'prosumer-app/stores/scenario-generic';

export enum ScenarioMapping {
  name = '{ "name": $value }',
  wacc = '{ "economics": { "wacc": $value } }',
  fullTimeHorizon = '{ "fullTimeHorizon": $value }',
  energyVector = '{ "energyVectors": $value }',
  variation = '{"scenarioVariations": $value}',
  emissions = '{ "emissions": $value }',
  co2Price = '{ "emissions": { "co2Price": $value} }',
  co2Scope2Price = '{ "emissions": "co2Scope2Price": $value }',
  co2Scope3Price = '{ "emissions": "co2Scope3Price": $value }',
  limits = '{ "limits": $value }',
  globalMaximumEmissions = '{ "limits": { "globalMaximumEmissions": $value } }',
  mipGap = '{ "options": { "mipGap": $value } }',
  limitRunHour = '{ "options": { "limitRunHour": $value } }',
  preventSimultaneousCharge = '{ "options": { "preventSimultaneousCharge": $value } }',
  lcox = '{ "options": { "lcox": $value } }',
  preventCurtailment = '{ "options": { "preventCurtailment": $value } }',
}
export type ScenarioMappingKey = keyof typeof ScenarioMapping;

export enum ScenarioMapType {
  yearlyValues = 'yearlyValues',
  stringValue = 'stringValue',
  bitValue = 'bitValue',
  default = 'default',
}

export const TYPE_MAPPER = {
  yearlyValues: (value) => mapYearlyValuesToBackend(value),
  stringValue: (value) => JSON.stringify(JSON.parse(value)),
  bitValue: (value) => Number(value),
  default: (value) => value,
};

export type MapperFn = (value: unknown, type: string) => unknown;

export class ScenarioMapper {
  static getMapperFn(key: ScenarioMappingKey): MapperFn {
    return (value: unknown, type: string): unknown =>
      JSON.parse(ScenarioMapper.mapValue(key, value, type));
  }

  private static mapValue(
    key: ScenarioMappingKey,
    value: unknown,
    type: string,
  ): string {
    return ScenarioMapping[key].replace(
      '$value',
      ScenarioMapper.handleDataType(value, type),
    );
  }

  private static handleDataType(value: unknown, type: string): string {
    return JSON.stringify(TYPE_MAPPER[type](value));
  }

  static mapYearlyValuesFromBE(scenario: ScenarioGeneric): ScenarioGeneric {
    return this.mapLimitsYearlyValuesFromBE(
      this.mapEmissionsYearlyValuesFromBE(scenario),
    );
  }

  static mapLimitsYearlyValuesFromBE(
    scenario: ScenarioGeneric,
  ): ScenarioGeneric {
    if (!scenario.limits) {
      return scenario;
    }
    return {
      ...scenario,
      limits: {
        yearlyMaximumEmissions: Utils.parseYearlyValuesFromBE(
          scenario.limits?.yearlyMaximumEmissions,
        ),
        yearlyMaximumCosts: Utils.parseYearlyValuesFromBE(
          scenario.limits?.yearlyMaximumCosts,
        ),
        globalMaximumEmissions: scenario.limits?.globalMaximumEmissions,
        globalMaximumCosts: scenario.limits?.globalMaximumCosts,
      },
    };
  }

  static mapEmissionsYearlyValuesFromBE(
    scenario: ScenarioGeneric,
  ): ScenarioGeneric {
    if (!scenario.emissions) {
      return scenario;
    }
    return {
      ...scenario,
      emissions: {
        co2Price: Utils.parseYearlyValuesFromBE(scenario.emissions?.co2Price),
        co2Scope2Price: Utils.parseYearlyValuesFromBE(
          scenario.emissions?.co2Scope2Price,
        ),
        co2Scope3Price: Utils.parseYearlyValuesFromBE(
          scenario.emissions?.co2Scope3Price,
        ),
      },
    };
  }
}
