import { BillingState } from './billing-state.model';
import * as BillingActions from './billing.actions';

export const initialState: BillingState = {
  computingTime: {
    used: 0,
    reserved: 0,
    limit: 6000,
    unit: 'CC',
    licensePlan: 'silver',
    loading: true,
  },
  storage: {
    used: 0,
    reserved: 0,
    limit: 10000000000,
    unit: 'GB',
  },
};

export function billingReducer(
  state: BillingState = initialState,
  action: BillingActions.All,
): BillingState {
  switch (action.type) {
    case BillingActions.ActionTypes.CLEAR:
      return initialState;
    case BillingActions.ActionTypes.GET_COMPUTING_TIME:
      return {
        ...state,
        computingTime: {
          ...state.computingTime,
          loading: true,
        },
      };
    case BillingActions.ActionTypes.GET_COMPUTING_TIME_FAILURE:
      return {
        ...state,
        computingTime: {
          ...state.computingTime,
          loading: false,
          error: action.payload.message,
        },
      };
    case BillingActions.ActionTypes.GET_COMPUTING_TIME_SUCCESS:
      return {
        ...state,
        computingTime: {
          ...state.computingTime,
          loading: false,
          loaded: true,
          used: action.payload.data.used,
          limit: action.payload.data.limit,
          reserved: action.payload.data.reserved,
          licensePlan: action.payload.data.licensePlan,
        },
      };
    case BillingActions.ActionTypes.GET_STORAGE:
      return {
        ...state,
        storage: {
          ...state.storage,
          loading: true,
        },
      };
    case BillingActions.ActionTypes.GET_STORAGE_FAILURE:
      return {
        ...state,
        storage: {
          ...state.storage,
          loading: false,
          error: action.payload.message,
        },
      };
    case BillingActions.ActionTypes.GET_STORAGE_SUCCESS:
      return {
        ...state,
        storage: {
          ...state.storage,
          loading: false,
          loaded: true,
          used: action.payload.data.used,
          limit: action.payload.data.limit,
          reserved: action.payload.data.reserved,
        },
      };
    case BillingActions.ActionTypes.SET_COMPUTING_TIME:
      return {
        ...state,
        computingTime: {
          ...state.computingTime,
          ...action.payload.data,
        },
      };
    case BillingActions.ActionTypes.SET_STORAGE:
      return {
        ...state,
        storage: {
          ...state.storage,
          ...action.payload.data,
        },
      };
    default:
      return state;
  }
}
