import { Coerce } from 'prosumer-app/core/utils';
import { EndpointProvider } from 'prosumer-app/services/endpoint-provider';
import { TdbAdditionalCostsHelperService } from 'prosumer-app/shared/modules/tdb/services';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, tap } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig, transaction } from '@datorama/akita';

import {
  FilterConfigResponse,
  FilterCriteria,
  FilterTypeConfigResponse,
  TDBDataProfile,
  TDBDataState,
  TDBDataSummary,
  TDBFilterActions,
  TDBProfileActions,
  TDBProfileIntervalActions,
} from './tdb-data.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'transversaldb', idKey: 'year' })
export class TDBDataStore extends EntityStore<TDBDataState> {
  private readonly cancelOngoingRequests = new Subject<unknown>();

  constructor(
    private endpoint: EndpointProvider,
    private http: HttpClient,
    private readonly tdbAddCosthelper: TdbAdditionalCostsHelperService,
  ) {
    super({ loading: false });
  }

  updateActiveFilters(activeFilters: FilterCriteria): void {
    const { additionalCosts, ...criteria } = Coerce.toObject(activeFilters);
    activeFilters = JSON.parse(JSON.stringify(criteria));
    this.tdbAddCosthelper.injectAdditionalCostsForYear(
      activeFilters,
      additionalCosts as any,
    );
    this.update({ activeFilters });
  }

  getTDBEnergyVectors(): Observable<unknown> {
    const action = 'getEnergyVectors';
    return this.requestFromTDB<FilterTypeConfigResponse>(action, {}).pipe(
      tap(({ types }) => this.update({ types })),
      catchError((err) => this.onError(action, err)),
    );
  }

  getTDBFilters(
    params: Record<string, string>,
    action: string = TDBFilterActions.commodity,
  ): Observable<FilterConfigResponse | HttpErrorResponse> {
    return this.requestFromTDB<FilterConfigResponse>(action, params).pipe(
      tap(({ filters }) => this.update({ filters })),
      catchError((err) => this.onError(action, err)),
    );
  }

  getTDBDataIntervals(
    energyVector: string,
    filterCriteria: FilterCriteria,
  ): Observable<TDBDataSummary | HttpErrorResponse> {
    const action: string = TDBProfileIntervalActions.commodity;
    const params = { ...filterCriteria, energyVector };
    return this.requestFromTDB<TDBDataSummary>(action, params).pipe(
      catchError((err) => this.onError(action, err)),
    );
  }

  getTDBData(
    filterCriteria: FilterCriteria,
    action: string = TDBProfileActions.commodity,
  ): Observable<TDBDataProfile | HttpErrorResponse> {
    const params = { ...filterCriteria }; //, energyVector };
    this.upsert(params.year, { ...params, loading: true });
    return this.requestFromTDB<TDBDataProfile>(action, params).pipe(
      tap(({ values }: TDBDataProfile) => this.setErrorOnEmpty(action, values)),
      // TODO: add handler for yearly grannularity
      tap(({ values, ...summary }: TDBDataProfile) =>
        this.upsert(params.year, { ...summary }),
      ),
      catchError((err) => this.onError(action, err)),
      finalize(() => this.upsert(params.year, { loading: false })),
    );
  }

  private requestFromTDB<T>(
    action: string,
    filterCriteria: FilterCriteria,
  ): Observable<T> {
    this.markStateAsStarting();
    const params = { action, ...filterCriteria };
    return this.http.get<T>(this.endpoint.forLinkWithTDB(), { params }).pipe(
      filter((resp) => !!resp),
      finalize(() => this.setLoading(false)),
    );
  }

  @transaction()
  private markStateAsStarting(): void {
    this.cancelOngoingRequests.next(true);
    this.update({ error: undefined, loading: true });
  }

  private setErrorOnEmpty(action: string, values: number[]): void {
    if (Coerce.toArray(values).length === 0) {
      this.onError(action, {
        error: { message: 'Missing Data!' },
      } as HttpErrorResponse);
    }
  }

  private onError(
    action: string,
    error: HttpErrorResponse,
  ): Observable<HttpErrorResponse> {
    this.setError({
      action,
      ...error,
    });
    return of(error);
  }

  initStoreValues(): TDBDataState {
    return {
      entities: {},
      loading: false,
      error: null,
      activeFilters: null,
      ids: [],
      filters: null,
      types: null,
    };
  }
}
