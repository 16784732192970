import { DetailEntity } from 'prosumer-app/stores/scenario-detail';
import { Limits } from 'prosumer-app/stores/scenario-generic';

export interface ScenarioVariationInfo
  extends Record<string, unknown>,
    DetailEntity {
  readonly scenarioUuid: string;
  readonly name: string;
  readonly variationUuid: string;
  readonly limits?: Limits;
}

export const NO_VARIATION = 'basecase';
