import { EntityState } from '@datorama/akita';

export interface HasScenarioUuid {
  readonly scenarioUuid: string;
}

export interface HasLoading {
  readonly loading: boolean;
}

export interface HasName {
  readonly name: string;
}

export interface HasMarket {
  readonly marketName: string;
}

export interface HasNode {
  readonly node: string;
}

export interface HasScope {
  readonly scope: string;
}

export interface HasScenarioVariation {
  readonly scenarioVariation: string;
}

export interface HasStatus {
  readonly status: ScenarioDetailStatus;
}

export interface HasId {
  readonly id: string;
}

export interface HasDataType {
  readonly dataType: string;
}

export type Duplicable = HasName & HasScenarioVariation;

export interface HasNodeTypeFlag {
  readonly isMultiNode: boolean;
}

export interface DuplicateDetailData<T = Duplicable> extends HasNodeTypeFlag {
  readonly existing: T[];
  readonly origin: T & Partial<HasId>;
}

export type DetailEntity = Partial<HasLoading> &
  Partial<HasStatus> &
  Partial<HasId> &
  Partial<HasScenarioUuid>;

export interface DetailResponse<T> {
  readonly details: T[];
}

interface HasGetAllError {
  readonly getAllError: string;
}

export interface ScenarioDetailState<T extends DetailEntity>
  extends EntityState<T, string>,
    Partial<HasGetAllError> {
  readonly loading: boolean;
  readonly error: unknown;
}

export interface ScenarioDetailInfo<T> {
  readonly scenarioUuid: string;
  readonly dataType: string;
  readonly details: T[];
}

export type NameValue = {
  name: string;
  value: string;
  tooltip?: string;
  tooltipPosition?: 'left' | 'center' | 'right';
};

export type ScenarioDetailStatus = 'duplicating' | 'ready' | 'DUPLICATING';

export enum ScenarioDetailType {
  yearsToOptimize = 'yearsToOptimize',
  projectDuration = 'projectDuration',
  energyVector = 'energyVector',
  variation = 'variation',
  timePeriod = 'timePeriod',
  line = 'line',
  node = 'node',
  load = 'load',
  equipment = 'equipment',
  opCosts = 'operationalCost',
  energyGridConnection = 'energyGridConnection',
  fuel = 'fuel',
  egcEmission = 'egcEmission',
  marketLimit = 'marketLimits', //because I'm an adult and I construct my own opinions and in my opinion, I should be plural,
  netting = 'netting',
  taxAndSubsidy = 'taxAndSubsidy',
  vehiclesDispatch = 'vehiclesDispatch',
  mobilityRoute = 'route',
  mobilityStation = 'stationVehicleAssociation',
  regulation = 'metering',
  powerNeeded = 'shortCircuitPowerNeeded',
  scEquipment = 'shortCircuitEquipment',
  srMargin = 'reserveMargin',
  srActivation = 'reserveActivation',
  reserveEquipment = 'reserveEquipment',
  reserveMarket = 'reserveMarket',
  reserveNetwork = 'reserveNetwork',
  customOption = 'customOption',
}

export const DETAIL_TYPE_NAMES: Partial<Record<string, string>> = {
  [ScenarioDetailType.energyGridConnection]: 'Energy Grid Connection',
  [ScenarioDetailType.vehiclesDispatch]: 'Vehicles Dispatch',
  [ScenarioDetailType.netting]: 'netting',
  [ScenarioDetailType.energyVector]: 'energy vector',
  [ScenarioDetailType.variation]: 'scenario variation',
  [ScenarioDetailType.projectDuration]: 'project duration',
  [ScenarioDetailType.yearsToOptimize]: 'years to optimize',
};

export type DuplicableDetailType =
  | ScenarioDetailType.equipment
  | ScenarioDetailType.load
  | ScenarioDetailType.energyGridConnection
  | ScenarioDetailType.egcEmission
  | ScenarioDetailType.marketLimit
  | ScenarioDetailType.line;

export type Params = Record<string, string>;
