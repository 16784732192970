import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import {
  ScenarioActions,
  ScenarioActionTypes,
  scenarioFeatureName,
} from './scenario.actions';
import { ScenarioState } from './scenario.model';

export const scenarioInitialState: ScenarioState = {
  downloading: false,
};

export function scenarioReducer(
  state: ScenarioState = scenarioInitialState,
  action: ScenarioActions,
): ScenarioState {
  switch (action.type) {
    case ScenarioActionTypes.DOWNLOAD_XLSX_TEMPLATE:
      return {
        ...state,
        error: undefined,
        downloading: true,
      };
    case ScenarioActionTypes.DOWNLOAD_XLSX_TEMPLATE_FAILURE:
      return {
        ...state,
        downloading: false,
        error: action.payload.error,
      };
    case ScenarioActionTypes.DOWNLOAD_XLSX_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloading: false,
        data: action.payload.data,
      };
    case ScenarioActionTypes.DOWNLOAD_FILE:
      return {
        ...state,
        error: undefined,
        downloading: true,
      };
    case ScenarioActionTypes.DOWNLOAD_FILE_FAILURE:
      return {
        ...state,
        downloading: false,
        error: action.payload.error,
      };
    case ScenarioActionTypes.DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        downloading: false,
        data: action.payload.data,
      };
    case ScenarioActionTypes.GET_LATEST_MODIFIED:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case ScenarioActionTypes.GET_LATEST_MODIFIED_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ScenarioActionTypes.GET_LATEST_MODIFIED_SUCCESS:
      return {
        ...state,
        loading: false,
        latestModifiedList: action.payload.dataList,
      };
    case ScenarioActionTypes.GET_LATEST:
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case ScenarioActionTypes.GET_LATEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ScenarioActionTypes.GET_LATEST_SUCCESS:
      return {
        ...state,
        loading: false,
        latestSimulationList: action.payload.dataList,
      };
    case ScenarioActionTypes.GET_DRAFTS:
      return {
        ...state,
        listDrafts: undefined,
        draftsLoading: true,
      };
    case ScenarioActionTypes.GET_DRAFTS_SUCCESS:
      return {
        ...state,
        listDrafts: action.payload.drafts,
        draftsLoading: false,
        draftsError: undefined,
      };
    case ScenarioActionTypes.GET_DRAFTS_FAILURE:
      return {
        ...state,
        draftsError: action.payload.error,
        draftsLoading: false,
      };
    default:
      return state;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const scenarioFeature: MemoizedSelector<any, ScenarioState> =
  createFeatureSelector<ScenarioState>(scenarioFeatureName);

export const scenarioSelectors = {
  state: scenarioFeature,
  loading: createSelector(
    scenarioFeature,
    (state: ScenarioState) => state.loading,
  ),
  data: createSelector(scenarioFeature, (state: ScenarioState) => state.data),
  downloading: createSelector(
    scenarioFeature,
    (state: ScenarioState) => state.downloading,
  ),
  error: createSelector(scenarioFeature, (state: ScenarioState) => state.error),
  latestSimulationList: createSelector(
    scenarioFeature,
    (state: ScenarioState) => state.latestSimulationList,
  ),
  latestModifiedList: createSelector(
    scenarioFeature,
    (state: ScenarioState) => state.latestModifiedList,
  ),
  listDrafts: createSelector(
    scenarioFeature,
    (state: ScenarioState) => state.listDrafts,
  ),
  draftsLoading: createSelector(
    scenarioFeature,
    (state: ScenarioState) => state.draftsLoading,
  ),
  draftsError: createSelector(
    scenarioFeature,
    (state: ScenarioState) => state.draftsError,
  ),
};
