import { ConfigService } from 'prosumer-app/libs/eyes-core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { parseTemplate } from 'url-template';

/* eslint-disable no-useless-escape */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ScenarioCascadableAttribs } from 'prosumer-app/stores/scenario-generic';
import {
  AffectedEntitiesDetailsKeysType,
  EndpointProvider,
  ScenarioParentage,
} from '../endpoint-provider';
import {
  AffectedEntitiesDetailResponseI,
  ApiRequest,
  EndpointParam,
  GetAllResponse,
} from './store-api.model';

@Injectable({ providedIn: 'root' })
export class StoreApiService {
  constructor(
    private http: HttpClient,
    private _config: ConfigService,
    private readonly keeper: ActiveKeeperService,
    private readonly endpointsProvider: EndpointProvider,
  ) {}

  getSingle(
    dataType: string,
    id: string,
    params: Record<string, string>,
  ): Observable<unknown> {
    if (params) {
      return this.http.get(this.buildDetailEndpoint(dataType, id), {
        params,
      });
    }
    return this.http.get(this.buildDetailEndpoint(dataType, id));
  }

  getAll(
    dataType: string,
    params: Record<string, string> = {},
  ): Observable<GetAllResponse> {
    return this.http.get<GetAllResponse>(this.buildDetailsEndpoint(), {
      params: { ...params, dataType },
    });
  }

  httpGet<T>(request: ApiRequest<T>): Observable<unknown> {
    return this.http.get(this.getEndpoint(request)[0]);
  }

  httpPost<T>(request: ApiRequest<T>): Observable<unknown> {
    return this.http.post(this.getEndpoint(request)[0], request.body);
  }

  weirdPatch<T>(
    request: ApiRequest<T>,
    params: Record<string, string> = {},
  ): Observable<unknown> {
    return this.http.patch(this.getEndpoint(request)[0], request.body, {
      params,
    });
  }

  httpPatch<T>(request: ApiRequest<T>): Observable<unknown> {
    return this.http.patch(this.getEndpoint(request)[0], request.body);
  }

  httpDelete<T>(request: ApiRequest<T>): Observable<unknown> {
    const [endpoint, scenarioUuid] = this.getEndpoint(request);
    return this.http.delete(endpoint).pipe(map(() => ({ scenarioUuid })));
  }

  getEndpoint(params: EndpointParam): [string, string] {
    const expandData = { ...this.keeper.getActiveEntities(), ...params.data };
    return [
      parseTemplate(this.getUrlTemplate(params.key)).expand(expandData),
      expandData.scenario,
    ];
  }

  duplicate(dataType: string, id: string, body: unknown): Observable<unknown> {
    return this.http.post(this.buildDuplicateEndpoint(dataType, id), body);
  }

  getAffectedEntitiesFromDelete(
    keys: Pick<
      AffectedEntitiesDetailsKeysType,
      'detailId' | 'dataType' | 'operationalType'
    >,
  ): Observable<AffectedEntitiesDetailResponseI> {
    return this.http.get<AffectedEntitiesDetailResponseI>(
      this.endpointsProvider.forAffectedEntitiesDetails({
        ...this.getParentage(),
        ...keys,
      }),
    );
  }

  getAffectedEntitiesFromUpdate(
    keys: Pick<
      AffectedEntitiesDetailsKeysType,
      'detailId' | 'dataType' | 'operationalType'
    >,
    changes?: Record<string, unknown>,
  ): Observable<AffectedEntitiesDetailResponseI> {
    return this.http.post<AffectedEntitiesDetailResponseI>(
      this.endpointsProvider.forAffectedEntitiesDetails({
        ...this.getParentage(),
        ...keys,
      }),
      changes,
    );
  }

  getAffectedEntitiesFromScenario<T>(
    attribute: ScenarioCascadableAttribs,
    data: T,
  ) {
    return this.http.post<AffectedEntitiesDetailResponseI>(
      this.endpointsProvider.forAffectedEntitiesFromScenario(
        this.getParentage(),
      ),
      { data, attribute },
    );
  }

  private getParentage(): ScenarioParentage {
    const active = this.keeper.getActiveEntities();
    return {
      project: active.project,
      case: active.case,
      scenario: active.scenario,
    };
  }

  private getUrlTemplate(urlKey: string): string {
    const endpoint = this.scenarioEndpoints[urlKey];
    const cleanEndpoint = endpoint.replace(/\:(\w+)Id|\:(\w+)/g, '{$1$2}');
    return `${this._config.api.baseUrl}${cleanEndpoint}`;
  }

  private buildDuplicateEndpoint(dataType: string, id: string): string {
    return `${this.buildDetailEndpoint(dataType, id)}/duplicate`;
  }

  private buildDetailEndpoint(dataType: string, id: string): string {
    return this.expand(this.getDetailEndpointTemplate(), {
      ...this.keeper.getActiveEntities(),
      dataType,
      id,
    });
  }

  private buildDetailsEndpoint(): string {
    return this.expand(
      this.getDetailsEndpointTemplate(),
      this.keeper.getActiveEntities(),
    );
  }

  private expand(templateUrl: string, params: unknown): string {
    return parseTemplate(templateUrl).expand(params as Record<string, string>);
  }

  private getDetailsEndpointTemplate(): string {
    return `${this.getBaseUrl()}${this.scenarioEndpoints['details']}`;
  }

  private getDetailEndpointTemplate(): string {
    return `${this.getBaseUrl()}${this.getSingleDetailEndpoint()}`;
  }

  private getBaseUrl(): string {
    return this._config.api.baseUrl;
  }

  private getSingleDetailEndpoint(): string {
    return this.scenarioEndpoints['singleDetail'];
  }

  private get scenarioEndpoints(): Record<string, string> {
    return this._config.api.endpoints['scenario'];
  }
}
