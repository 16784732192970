import { InjectionToken, Provider, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { DetailEntity } from './scenario-detail.state';

export interface Upserter<T extends DetailEntity = DetailEntity> {
  create(data: T): Observable<unknown>;
  edit(id: string, data: T): Observable<unknown>;
}

export const UPSERTER = new InjectionToken<Upserter>(
  'scenario-detail-upserter',
);

export const provideUpserter = (useExisting: Type<Upserter>): Provider => ({
  provide: UPSERTER,
  useExisting,
});
