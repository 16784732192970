import { DetailEntity } from '../scenario-detail';

export interface EnergyVectorInfo
  extends Record<string, unknown>,
    DetailEntity {
  readonly cascadeEnergyVectorId: string;
  readonly scenarioUuid: string;
  readonly energyVectorId: string;
  readonly isCustom: boolean;
  readonly vectorType: string;
  readonly name: string;
  readonly value: string;
}

export const postEnergyVector = (
  params: Record<string, unknown>,
): EnergyVectorInfo => ({
  scenarioUuid: undefined,
  cascadeEnergyVectorId: undefined,
  energyVectorId: params['energyVectorId'] as string,
  isCustom: params['isCustom'] as boolean,
  name: params['name'] as string,
  vectorType: params['vectorType'] as string,
  value: undefined,
});
