import { Injectable } from '@angular/core';
import { BillingFacadeService } from '../../../core/modules/billing';
import { filter, map, take } from 'rxjs/operators';
import { LICENSE_PLAN_COLORS } from '../../../app.references';

@Injectable({
  providedIn: 'root',
})
export class LicensePlanService {
  computingTime$ = this.billingFacade.computingTime$.pipe(
    filter((computingTime) => computingTime !== undefined),
  );

  licensePlan$ = this.computingTime$.pipe(
    map((computingTime) => computingTime.licensePlan),
  );

  licensePlanStyle$ = this.licensePlan$.pipe(
    map((licensePlan) => this.getLicensePlanStyle(licensePlan)),
  );

  loading$ = this.computingTime$.pipe(
    map((computingTime) => computingTime.loading),
  );

  constructor(private billingFacade: BillingFacadeService) {}

  getLicensePlanStyle(value: string) {
    return { color: this.getPlanColor(value) };
  }

  getPlanColor(value: string) {
    if (!value) {
      return LICENSE_PLAN_COLORS.silver;
    }
    switch (value.toLowerCase()) {
      case 'gold':
        return LICENSE_PLAN_COLORS.gold;
      case 'unlimited':
        return LICENSE_PLAN_COLORS.unlimited;
      case 'eldorado':
        return LICENSE_PLAN_COLORS.eldorado;
      default:
        return LICENSE_PLAN_COLORS.silver;
    }
  }
}
