export interface LinksResult {
  source: string;
  target: string;
  value: number;
}

export interface NodesResult {
  name: string;
}

export interface SankeyResult {
  links: LinksResult[];
  nodes: NodesResult[];
}

export interface FlowResult {
  readonly sankey: { [year: string]: SankeyResult };
}

export interface LinksResultEnhanced {
  id?: string;
  color?: string;
  source: string;
  target: string;
  value: number;
}

export interface NodesResultEnhanced {
  id?: string;
  name?: string;
  color?: string;
}

export interface SankeyResultEnhanced {
  links: LinksResultEnhanced[];
  nodes: NodesResultEnhanced[];
}

export type SankeyDataTypes =
  | SankeyEditorBasicDataType.LINK
  | SankeyEditorBasicDataType.NODE;
export enum SankeyEditorBasicDataType {
  NODE = 'node',
  LINK = 'link',
}

export enum SankeyEditorBasicInputTypes {
  NAME = 'name',
  COLOR = 'color',
  SOURCE = 'source',
  TARGET = 'target',
  VALUE = 'value',
}

export interface InputChangeHandling {
  changedData: CtrlChangedData;
  type: SankeyEditorBasicDataType;
}

export interface CtrlChangedData {
  id: string;
  key?: string;
  value: unknown;
}
