/* eslint-disable @typescript-eslint/naming-convention */
import { ResultsData } from './base-results.model';

export interface TopologyResult extends ResultsData {
  readonly origin_node: string;
  readonly destination_node: string;
  readonly fluid: string;
  readonly size_kw: number;
  readonly invest_cost: number;
  readonly oper_cost: number;
  readonly total_cost: number;
  readonly yearly_flow_ab: number;
  readonly yearly_flow_ba: number;
}

export const TOPOLOGY_YEARLY_KEYS = [
  'size_kw',
  'invest_cost',
  'oper_cost',
  'total_cost',
  'yearly_flow_ab',
  'yearly_flow_ba',
];
