import { HasCreatedBy } from 'prosumer-core/utils';

import { ProjectEntity } from './project-entity.model';

export enum ProjectRole {
  owner = 'owner',
  edit = 'edit',
  view = 'view',
}

export interface Project extends ProjectEntity, HasCreatedBy, HasRole {
  readonly owner?: string;
  readonly name?: string;
  readonly description?: string;
  readonly createdDate?: string;
  readonly updatedDate?: string;
  readonly copyStatus?: string;
  readonly sharedTo?: Array<any>;
  readonly isOwned?: boolean;
  readonly isShared?: boolean;
  readonly ownerName?: string;
  readonly permissions?: string;
}

export interface HasRole {
  readonly role?: ProjectRole;
}
