import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filterNilValue } from '@datorama/akita';
import { EndpointProvider } from 'prosumer-app/services/endpoint-provider';
import { ScenarioGeneric } from 'prosumer-app/stores/scenario-generic';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { CoherenceResult } from '../models';
import { CoherenceResultMapper } from './mappers';

export type CoherenceCheckRsp = Readonly<{
  issues: unknown[];
}>;

export type Coherence = Readonly<{
  results: CoherenceResult[];
}>;

@Injectable({
  providedIn: 'root',
})
export class CoherenceTracker {
  private readonly lastChecked = new BehaviorSubject<Date>(null);
  private readonly recentChecks = new BehaviorSubject<Coherence>(null);
  private readonly checking = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly http: HttpClient,
    private readonly endpoints: EndpointProvider,
  ) {}

  selectHasErrors(): Observable<boolean> {
    return this.selectRecentChecks().pipe(
      map((coherence) => this.hasErrors(coherence)),
    );
  }

  selectRecentChecks(): Observable<Coherence> {
    return this.recentChecks.asObservable().pipe(filterNilValue());
  }

  selectChecking(): Observable<boolean> {
    return this.checking.asObservable();
  }

  selectLastChecked(): Observable<Date> {
    return this.lastChecked.asObservable().pipe(filterNilValue());
  }

  check(scenario: ScenarioGeneric): Observable<CoherenceCheckRsp> {
    return this.shouldCheck(scenario) ? this.doCheck(scenario) : of(null);
  }

  private shouldCheck(scenario: ScenarioGeneric): boolean {
    return [this.notYetChecked(), this.wasUpdatedAfterLastCheck(scenario)].some(
      Boolean,
    );
  }

  private notYetChecked(): boolean {
    return !this.lastChecked.value;
  }

  private wasUpdatedAfterLastCheck(scenario: ScenarioGeneric): boolean {
    return new Date(scenario.updatedAt) > this.lastChecked.value;
  }

  private doCheck(scenario: ScenarioGeneric): Observable<CoherenceCheckRsp> {
    this.checking.next(true);
    return this.http
      .get<CoherenceCheckRsp>(this.buildCoherenceEndpoint(scenario))
      .pipe(
        tap((rsp) => this.recentChecks.next(this.toCoherence(rsp))),
        finalize(() => this.onCheckFinalize()),
      );
  }

  private onCheckFinalize(): void {
    this.checking.next(false);
    this.lastChecked.next(new Date());
  }

  private hasErrors(coherence: Coherence): boolean {
    return coherence.results.some((result) => result.type === 'error');
  }

  private toCoherence(rsp: CoherenceCheckRsp): Coherence {
    return {
      results: rsp.issues.map((issue) =>
        CoherenceResultMapper.mapToFrontend(issue),
      ),
    };
  }

  private buildCoherenceEndpoint(scenario: ScenarioGeneric): string {
    return this.endpoints.forCoherenceCheck({
      project: scenario.projectUuid,
      scenario: scenario.scenarioUuid,
      case: scenario.caseUuid,
    });
  }
}
