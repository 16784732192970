import { Injectable } from '@angular/core';
import { ConfigService, HttpService } from 'prosumer-app/libs/eyes-core';
import { generateEndpoint } from 'prosumer-app/libs/eyes-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CoreVersionService {
  constructor(
    private _http: HttpService,
    private _config: ConfigService,
  ) {}

  getLatestVersion(): Observable<string> {
    const endpoint = generateEndpoint(
      this._config.api.baseUrl,
      this._config.api.endpoints.core.latestVersion,
    );
    return this._http
      .get(endpoint)
      .pipe(map((response) => response['version']));
  }
}
