import capitalize from 'lodash/capitalize';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { CascadeController } from 'prosumer-app/services/cascade-controller';
import { StoreApiService } from 'prosumer-app/services/store-api';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { StoreConfig } from '@datorama/akita';

import { CascadableDetailStore } from '../cascadable-detail';
import { ScenarioDetailInfo, ScenarioDetailType } from '../scenario-detail';
import { EnergyVectorInfo, postEnergyVector } from './energy-vector.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: ScenarioDetailType.energyVector,
  idKey: 'cascadeEnergyVectorId',
})
export class EnergyVectorStore extends CascadableDetailStore<EnergyVectorInfo> {
  constructor(
    api: StoreApiService,
    controller: CascadeController,
    private readonly keeper: ActiveKeeperService,
  ) {
    super(api, controller);
  }

  getEnergyVectors(data: Record<string, unknown>): Observable<unknown> {
    this.setLoading(true);
    return this.handleUpsert(
      this.get({ key: 'getDetailByDataType', data, body: undefined }).pipe(
        map((data: ScenarioDetailInfo<EnergyVectorInfo>) => data.details),
        map((data: EnergyVectorInfo[]) =>
          data.map((datum) => ({ ...datum, name: capitalize(datum.name) })),
        ),
        tap((data: EnergyVectorInfo[]) => {
          if (
            !!data &&
            data.length > 0 &&
            data.every((datum) => datum.scenarioUuid)
          ) {
            this.remove(
              ({ scenarioUuid }) => scenarioUuid === data[0].scenarioUuid,
            );
          }
        }),
      ),
    ).pipe(finalize(() => this.setLoading(false)));
  }

  createEnergyVector(data: Record<string, unknown>): Observable<unknown> {
    const body: EnergyVectorInfo = postEnergyVector(data as EnergyVectorInfo);
    return this.handleAdd(this.post({ key: 'createDetail', data: {}, body }));
  }

  deleteEnergyVector(energyVectorId: string): Observable<unknown> {
    return this.deleteOne(energyVectorId);
  }

  postCascadeDeletion(id: string): void {
    this.remove(this.buildEVID(id));
  }

  private handleAdd($: Observable<unknown>): Observable<unknown> {
    return $.pipe(tap((data: EnergyVectorInfo) => this.add(data)));
  }

  private handleUpsert($: Observable<unknown[]>): Observable<unknown> {
    return $.pipe(tap((data: EnergyVectorInfo[]) => this.upsertMany(data)));
  }

  protected getDataUuid(data: Record<string, unknown>): unknown {
    return data['energyVectorId'];
  }

  private buildEVID(id: string): string {
    const builtId = `${this.keeper.getActiveScenario()}_${id}`;
    console.log(builtId);
    return builtId;
  }
}
