import { Coerce, Utils } from 'prosumer-app/core/utils';
import {
  TaxAndSubsidy,
  TaxAndSubsidyBE,
} from 'prosumer-app/stores/tax-and-subsidy';
import { Netting, NettingForm, TaxAndSubsidies } from '../../models';

export class NettingMapper {
  static mapToBackend = (nettingForm: NettingForm) =>
    !!nettingForm
      ? {
          netting: (nettingForm.netting || []).map((data) => ({
            id: data.id,
            name: data.name,
            timePartition: data.timePartition,
            energyVectorId: data.energyVectorId,
            nodeId: { nodeIds: data.nodes },
            ...(data.equipments && { equipments: data.equipments }),
            ...(data.loads && { loads: data.loads }),
          })),
          taxAndSubsidies: (nettingForm.taxAndSubsidies || []).map((data) => ({
            id: data.id,
            nettingId: data.netting,
            nodeId: { nodeIds: data.nodes },
            taxSubsidyIntervals: data.taxSubsidyIntervals ?? [],
          })),
        }
      : {};

  static mapToFrontEnd = (scenario: {
    netting;
    taxAndSubsidies;
  }): NettingForm =>
    !!scenario
      ? {
          netting: Coerce.toArray(scenario.netting).map((data) =>
            NettingMapper.nettingToFE(data),
          ),
          taxAndSubsidies: (scenario.taxAndSubsidies || []).map((data) => ({
            ...data,
            netting: data.nettingId,
            nodes:
              typeof data.nodeId === 'string'
                ? data.nodeId
                : data.nodeId.nodeIds,
          })),
        }
      : {};

  static nettingToFE(data: unknown): Netting {
    return {
      ...(data as Netting),
      nodes: NettingMapper.extractNodesUsingInception(data),
    };
  }

  static nettingToBE(data: Netting): unknown {
    return {
      id: data.id,
      name: data.name,
      timePartition: data.timePartition,
      energyVectorId: data.energyVectorId,
      nodeId: { nodeIds: data.nodes },
      equipments: Coerce.toArray(data.equipments),
      loads: Coerce.toArray(data.loads),
    };
  }

  static taxAndSubsidyToFE(data: TaxAndSubsidyBE): TaxAndSubsidy {
    return {
      ...(data as TaxAndSubsidy),
      netting: data['nettingId'],
      nodes: NettingMapper.deduceNodesValue(data),
    };
  }

  static taxAndSubsidyToBE(data: TaxAndSubsidy): TaxAndSubsidyBE {
    return {
      id: data.id,
      nettingId: data.netting,
      nodeId: { nodeIds: data.nodes },
      taxSubsidyIntervals: Coerce.toArray(data.taxSubsidyIntervals),
    };
  }

  private static deduceNodesValue(from: unknown): string[] {
    return Utils.resolveToEmptyObject(
      Utils.parseYearlyValuesFromBE(from['nodeId']),
    )?.nodeIds;
  }

  private static extractNodesUsingInception(from: unknown): string[] {
    return Coerce.toArray(
      Utils.resolveToEmptyObject(Utils.parseYearlyValuesFromBE(from['nodeId']))
        ?.nodeIds,
    );
  }

  private static isString(value: unknown): boolean {
    return typeof value === 'string';
  }
}
