import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { ConfigService, HttpService } from 'prosumer-app/libs/eyes-core';
import { generateEndpoint } from 'prosumer-app/libs/eyes-shared';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { NotificationsService } from 'prosumer-app/shared/services/notification';
import {
  LibraryActionTypes,
  LibraryActions,
  LibraryGetAction,
  LibraryGetFailureAction,
  LibraryGetLoadsAction,
  LibraryGetLoadsFailureAction,
  LibraryGetLoadsSuccessAction,
  LibraryGetSuccessAction,
} from './library-store.actions';
import { LibraryService } from './library-store.service';

@Injectable()
export class LibraryEffects {
  get$: Observable<LibraryActions> = createEffect(() =>
    this._actions$.pipe(
      ofType(LibraryActionTypes.GET),
      switchMap((action: LibraryGetAction) =>
        this._libraryService.getLibraryList$(action.payload.type).pipe(
          take(1),
          switchMap((libraryList) => {
            // Temporarily Removed Caching due to selection issue
            // if (libraryList && libraryList.length > 0) {
            //   const library = toObj(libraryList, 'id');
            //   return of(new LibraryGetSuccessAction(library, this._translate.instant('Library.messages.getSuccess'), false, true));
            // }

            let endpoint = '';
            if (action.payload.type === 'topology') {
              endpoint = generateEndpoint(
                this._config.api.baseUrl,
                this._config.api.endpoints.library.topology,
              );
            } else if (action.payload.type === 'loads') {
              // endpoint = 'assets/mocks/library.mock.json';
              endpoint = generateEndpoint(
                this._config.api.baseUrl,
                this._config.api.endpoints.library.loads,
              );
            } else {
              endpoint = generateEndpoint(
                this._config.api.baseUrl,
                this._config.api.endpoints.library.equipment,
                action.payload.type,
              );
            }
            return this._http.get(endpoint).pipe(
              map(
                (librarySource) =>
                  new LibraryGetSuccessAction(
                    librarySource,
                    this._translate.instant('Library.messages.getSuccess'),
                    false,
                  ),
              ),
              catchError((response: HttpErrorResponse) =>
                of(
                  new LibraryGetFailureAction(
                    action.payload.type,
                    (response && response.error && response.error.error) ||
                      this._translate.instant('Library.messages.getFailure'),
                  ),
                ),
              ),
            );
          }),
        ),
      ),
    ),
  );

  getFailure$: Observable<LibraryActions> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(LibraryActionTypes.GET_FAILURE),
        switchMap((action: LibraryGetFailureAction) =>
          this._notification
            .showError(action.payload.error, 'Retry')
            .onAction()
            .pipe(tap(() => this._libraryService.get(action.payload.type))),
        ),
      ),
    { dispatch: false },
  );

  getLoads$: Observable<LibraryActions> = createEffect(() =>
    this._actions$.pipe(
      ofType(LibraryActionTypes.GET_LOADS),
      switchMap((action: LibraryGetLoadsAction) => {
        // const endpoint = 'assets/mocks/library-loads.mock.json';
        const endpoint = generateEndpoint(
          this._config.api.baseUrl,
          this._config.api.endpoints.library.loadsBinary,
          action.payload.id,
        );
        return this._http.get(endpoint).pipe(
          map(
            (loadsSource) =>
              new LibraryGetLoadsSuccessAction(
                loadsSource,
                this._translate.instant('Library.messages.getSuccess'),
                false,
              ),
          ),
          catchError((response: HttpErrorResponse) =>
            of(
              new LibraryGetLoadsFailureAction(
                action.payload.id,
                (response && response.error && response.error.error) ||
                  this._translate.instant('Library.messages.getFailure'),
              ),
            ),
          ),
        );
      }),
    ),
  );

  getLoadsFailure$: Observable<LibraryActions> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(LibraryActionTypes.GET_LOADS_FAILURE),
        switchMap((action: LibraryGetLoadsFailureAction) =>
          this._notification
            .showError(action.payload.error, 'Retry')
            .onAction()
            .pipe(tap(() => this._libraryService.get(action.payload.id))),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private _actions$: Actions,
    private _config: ConfigService,
    private _http: HttpService,
    private _libraryService: LibraryService,
    private _notification: NotificationsService,
    private _translate: TranslateService,
  ) {}
}
