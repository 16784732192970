/* eslint-disable @typescript-eslint/naming-convention */
import { type } from 'prosumer-app/libs/eyes-shared';

export const StoreActionTypes = (featureName: string): any => ({
  CLEAR: type(`[${featureName}] Clear ${featureName}`),
  CLEAR_DATA: type(`[${featureName}] Clear ${featureName} Data`),
  CLEAR_DATA_LIST: type(`[${featureName}] Clear ${featureName} Data List`),
  GET: type(`[${featureName}] Get ${featureName}`),
  GET_SUCCESS: type(`[${featureName}] Get ${featureName} Success`),
  GET_FAILURE: type(`[${featureName}] Get ${featureName} Failure`),
  GET_ALL: type(`[${featureName}] Get all ${featureName}`),
  GET_ALL_SUCCESS: type(`[${featureName}] Get all ${featureName} Success`),
  GET_ALL_FAILURE: type(`[${featureName}] Get all ${featureName} Failure`),
  DELETE: type(`[${featureName}] Delete ${featureName}`),
  DELETE_SUCCESS: type(`[${featureName}] Delete ${featureName} Success`),
  DELETE_FAILURE: type(`[${featureName}] Delete ${featureName} Failure`),
  SAVE: type(`[${featureName}] Save ${featureName}`),
  SAVE_FAILURE: type(`[${featureName}] Save ${featureName} Failure`),
  SAVE_SUCCESS: type(`[${featureName}] Save ${featureName} Success`),
  SET_DATA: type(`[${featureName}] Set ${featureName} Data`),
  UPDATE: type(`[${featureName}] Update ${featureName}`),
  UPDATE_FAILURE: type(`[${featureName}] Update ${featureName} Failure`),
  UPDATE_SUCCESS: type(`[${featureName}] Update ${featureName} Success`),
  COPY: type(`[${featureName}] Copy ${featureName}`),
  COPY_FAILURE: type(`[${featureName}] Copy ${featureName} Failure`),
  COPY_SUCCESS: type(`[${featureName}] Copy ${featureName} Success`),
  SHARE: type(`[${featureName}] Share ${featureName}`),
  SHARE_FAILURE: type(`[${featureName}] Share ${featureName} Failure`),
  SHARE_SUCCESS: type(`[${featureName}] Share ${featureName} Success`),
  UNSHARE: type(`[${featureName}] Unshare ${featureName}`),
  UNSHARE_FAILURE: type(`[${featureName}] Unshare ${featureName} Failure`),
  UNSHARE_SUCCESS: type(`[${featureName}] Unshare ${featureName} Success`),
});
