import { type } from 'prosumer-app/libs/eyes-shared';

/* eslint-disable @typescript-eslint/naming-convention */
import { Action } from '@ngrx/store';

import { StoreActionTypes } from '../utils';
import {
  LibraryCache,
  LoadsCache,
  mapLibraryLoadsToFrontend,
  mapLibraryToFrontend,
} from './library-store.model';

// The feature name of the library store
export const libraryFeatureName = 'library';

// The library action types using the store action types helper
export const LibraryActionTypes = {
  ...StoreActionTypes(libraryFeatureName),
  GET_LOADS: type(`[${libraryFeatureName}] Get ${libraryFeatureName} Loads`),
  GET_LOADS_FAILURE: type(
    `[${libraryFeatureName}] Get ${libraryFeatureName} Loads Failure`,
  ),
  GET_LOADS_SUCCESS: type(
    `[${libraryFeatureName}] Get ${libraryFeatureName} Loads Success`,
  ),
};

/**
 * Clears the library data from the store
 */
export class LibraryClearAction implements Action {
  readonly type = LibraryActionTypes.CLEAR_DATA;
}

/**
 * The action to initiate the retrieval of library data
 */
export class LibraryGetAction implements Action {
  readonly type = LibraryActionTypes.GET;
  readonly payload: { type: string };
  /**
   * @param libraryType the type of the library
   */
  constructor(libraryType: string) {
    this.payload = { type: libraryType };
  }
}

/**
 * The failure action after trying to retrieve the library data
 */
export class LibraryGetFailureAction implements Action {
  readonly type = LibraryActionTypes.GET_FAILURE;
  readonly payload: { type: string; error: string };
  /**
   * @param libraryType the type of the library
   * @param error       the error message
   */
  constructor(libraryType: string, error: string) {
    this.payload = { type: libraryType, error };
  }
}

/**
 * The success action after retrieving the library data
 */
export class LibraryGetSuccessAction implements Action {
  readonly type = LibraryActionTypes.GET_SUCCESS;
  readonly payload: { library: LibraryCache; message: string; notify: boolean };
  /**
   * @param librarySource the library source of data from the backend or cache
   * @param message       the message to display in the snackbar notification
   * @param notify        if true, display the snackbar notification
   * @param isCached      if true, the data comes from the cache
   */
  constructor(
    librarySource: Array<any> | LibraryCache | any,
    message: string,
    notify: boolean,
    isCached?: boolean,
  ) {
    this.payload = {
      library: isCached ? librarySource : mapLibraryToFrontend(librarySource),
      message,
      notify,
    };
  }
}

/**
 * The action to initiate the retrieval of library loads list
 */
export class LibraryGetLoadsAction implements Action {
  readonly type = LibraryActionTypes.GET_LOADS;
  readonly payload: { id: string };

  /**
   * @param id  the library id
   */
  constructor(id: string) {
    this.payload = { id };
  }
}

/**
 * The failure action after retrieving the library loads list
 */
export class LibraryGetLoadsFailureAction implements Action {
  readonly type = LibraryActionTypes.GET_LOADS_FAILURE;
  readonly payload: { id: string; error: string };

  /**
   * @param id    the library id
   * @param error the error message
   */
  constructor(id: string, error: string) {
    this.payload = { id, error };
  }
}

/**
 * The success action after retrieving the library loads list
 */
export class LibraryGetLoadsSuccessAction implements Action {
  readonly type = LibraryActionTypes.GET_LOADS_SUCCESS;
  readonly payload: { loads: LoadsCache; message: string; notify: boolean };

  /**
   * @param loadsSource the loads source of data from the backend or cache
   * @param message     the message to display in the snackbar notification
   * @param notify      if true, display the snackbar notification
   * @param isCached    if true, the data comes from the cache
   */
  constructor(
    loadsSource: LoadsCache | any,
    message: string,
    notify: boolean,
    isCached?: boolean,
  ) {
    this.payload = {
      loads: isCached ? loadsSource : mapLibraryLoadsToFrontend(loadsSource),
      message,
      notify,
    };
  }
}

// A type to represent all the library actions
export type LibraryActions =
  | any
  | LibraryClearAction
  | LibraryGetAction
  | LibraryGetFailureAction
  | LibraryGetSuccessAction
  | LibraryGetLoadsAction
  | LibraryGetLoadsFailureAction
  | LibraryGetLoadsSuccessAction;
