import { LoadInfo } from 'prosumer-app/stores/load';
import { Utils } from 'prosumer-core/utils/utils';
import { Load } from 'prosumer-scenario/models';
import {
  mapVariationToBackend,
  mapVariationToFrontend,
  mapYearlyProfileToBackend,
  mapYearlyProfileToFrontend,
} from 'prosumer-shared/utils';

const LOAD_PROFILE_NAME = 'Load Profile';

export class LoadMapper {
  static mapToBackend = (load: Load): any => {
    const item = {
      loadId: load.id,
      name: load.name,
      energyVectorId: load.energyVector,
      nodeId: { nodeIds: load.nodes },
      loadProfile: LoadMapper.toBELoadProfile(load),
      scenarioVariation: mapVariationToBackend(load),
    };

    return item;
  };

  static mapToFrontend = (load: any): LoadInfo => {
    const item = {
      ...load,
      id: load.loadId,
      loadId: load.loadId,
      energyVector: load.energyVectorId,
      nodes: Utils.resolveToEmptyObject(LoadMapper.parseValue(load.nodeId))
        .nodeIds,
      scenarioVariation: mapVariationToFrontend(load),
      profiles: LoadMapper.mapProfiles(load),
    };
    const { energyVectorId, nodeId, loadProfile, ...returnItem } = item;
    return returnItem;
  };

  static toBELoadProfile(load: any): any {
    return {
      name: LOAD_PROFILE_NAME,
      profiles: Utils.resolveToEmptyArray(load.profiles).map((profile) =>
        mapYearlyProfileToBackend(profile),
      ),
    };
  }

  static mapProfiles(load: any): any[] {
    return Utils.resolveToEmptyArray(
      Utils.resolveToEmptyObject(load.loadProfile).profiles,
    ).map((profile) => mapYearlyProfileToFrontend(profile, 'END_USE_LOADS'));
  }

  static parseValue(item: any) {
    if (typeof item === 'string') {
      return JSON.parse(item);
    }
    return item;
  }
}
