import { Injectable } from '@angular/core';
import { Store, ActionsSubject, select } from '@ngrx/store';

import { ComputingTime, Storage } from '../models';
import { BillingState } from './billing-state.model';
import { billingQueries } from './billing.selectors';
import * as BillingActions from './billing.actions';

@Injectable()
export class BillingFacadeService {
  state$ = this.store$.pipe(select(billingQueries.state));
  computingTime$ = this.store$.pipe(select(billingQueries.computingTime));
  storage$ = this.store$.pipe(select(billingQueries.storage));

  constructor(
    public store$: Store<BillingState>,
    public actionSubject$: ActionsSubject,
  ) {}

  clear() {
    this.store$.dispatch(new BillingActions.ClearState());
  }

  getComputingTime(userId?: string) {
    this.store$.dispatch(new BillingActions.GetComputingTime({ userId }));
  }

  getStorage(userId?: string) {
    this.store$.dispatch(new BillingActions.GetStorage({ userId }));
  }

  setComputingTime(data?: ComputingTime) {
    this.store$.dispatch(new BillingActions.SetComputingTime({ data }));
  }

  setStorage(data?: Storage) {
    this.store$.dispatch(new BillingActions.SetStorage({ data }));
  }
}
