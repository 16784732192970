export enum TDBCommodityPricesAdditionalCosts {
  margin = 'margin',
  transpoAndDistrib = 'transpoAndDistrib',
  indexation = 'indexation',
  taxes = 'taxes',
}

export const TDB_COMMODITY_PRICES_ADDITIONAL_COSTS_PERCENTAGE_TYPE_KEYS_LIST = [
  TDBCommodityPricesAdditionalCosts.margin,
  TDBCommodityPricesAdditionalCosts.indexation,
  TDBCommodityPricesAdditionalCosts.taxes,
];
