import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  CascadableEntity,
  CascadeAction,
} from 'prosumer-app/services/cascade-controller/cascade-controller.types';
import {
  AffectedEntitiesDetailResponseI,
  StoreApiService,
} from 'prosumer-app/services/store-api';
import { CascadeConfirmationComponent } from 'prosumer-app/shared/components/cascade-confirmation/cascade-confirmation.component';
import { ScenarioDetailType } from 'prosumer-app/stores';
import { ScenarioCascadableAttribs } from 'prosumer-app/stores/scenario-generic';
import { of, switchMap } from 'rxjs';
import { ScenarioInfoService } from './scenario-info.service';

@Injectable({ providedIn: 'root' })
export class ScenarioInfoCascader {
  private storeApi = inject(StoreApiService);
  private dialog = inject(MatDialog);
  private infoService = inject(ScenarioInfoService);

  update<T>(attribute: ScenarioCascadableAttribs, data: T) {
    return this.storeApi.getAffectedEntitiesFromScenario(attribute, data).pipe(
      switchMap((rsp) =>
        this.shouldConfirm(rsp) ? this.confirm(rsp) : of({}),
      ),
      switchMap((go) =>
        go ? this.infoService.updateScenario(data) : of(null),
      ),
    );
  }

  private confirm(data: AffectedEntitiesDetailResponseI) {
    return this.dialog
      .open(CascadeConfirmationComponent, this.buildDialogConfig(data))
      .afterClosed();
  }

  private buildDialogConfig(
    data: AffectedEntitiesDetailResponseI,
  ): MatDialogConfig {
    return {
      data: this.buildDialogData(data),
    };
  }

  private buildDialogData(
    data: AffectedEntitiesDetailResponseI,
  ): CascadableEntity {
    return {
      affectedEntitiesData: data,
      action: CascadeAction.update,
      type: ScenarioDetailType.yearsToOptimize,
      topLevelAction: true,
    };
  }

  private shouldConfirm(rsp: AffectedEntitiesDetailResponseI) {
    return rsp.affected_entities;
  }
}
