import {
  CascadableEntity,
  CascadeAction,
  CascadeController,
} from 'prosumer-app/services/cascade-controller';
import {
  AffectedEntitiesDetailResponseI,
  StoreApiService,
} from 'prosumer-app/services/store-api';
import { Observable, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import {
  DetailEntity,
  ScenarioDetailType,
} from '../scenario-detail/scenario-detail.state';
import { ScenarioDetailStore } from '../scenario-detail/scenario-detail.store';

@Injectable({ providedIn: 'root' })
export abstract class CascadableDetailStore<
  T extends DetailEntity,
> extends ScenarioDetailStore<T> {
  constructor(
    readonly storeApi: StoreApiService,
    readonly cascader: CascadeController,
  ) {
    super(storeApi);
  }

  deleteOne(id: string): Observable<unknown> {
    const dataType = this.getEntity(id)['dataType'] || this.storeName;
    return this.storeApi
      .getAffectedEntitiesFromDelete({
        detailId: id,
        dataType: dataType,
        operationalType: CascadeAction.delete,
      })
      .pipe(
        switchMap((data) => {
          if (data.affected_entities) {
            return this.confirmCascade(id, CascadeAction.delete, data);
          } else {
            return of(true);
          }
        }),
        switchMap(() => super.deleteOne(id)),
        tap(() => this.postCascadeDeletion(id)),
      );
  }

  cascadeEdit(id: string, data: T): Observable<unknown> {
    const dataType = this.getEntity(id)['dataType'] || this.storeName;
    const operationalType = CascadeAction.update;
    return this.storeApi
      .getAffectedEntitiesFromUpdate(
        {
          detailId: id,
          dataType,
          operationalType,
        },
        this.toBEWithDataType(data),
      )
      .pipe(
        switchMap((res) => this.confirmCascade(id, operationalType, res)),
        switchMap(() => super.edit(id, data)),
        tap((d) => this.postCascadeEdit(id, d)),
      );
  }

  confirmCascade(
    id: string,
    action: CascadeAction,
    affectedEntitiesData?: AffectedEntitiesDetailResponseI,
  ): Observable<unknown> {
    if (!affectedEntitiesData.affected_entities) return of(true);
    return this.cascader
      .confirmIfApplicable({
        ...this.buildCascadableEntity(id, action),
        affectedEntitiesData,
      })
      .pipe(filter((confirmed) => !!confirmed));
  }

  postCascadeDeletion(id: string): void {
    // do something
  }

  postCascadeEdit(id: string, d: unknown): void {
    // do something
  }

  private buildCascadableEntity(
    id: string,
    action: CascadeAction,
  ): CascadableEntity {
    return {
      ...this.getEntity(id),
      type: this.storeName as ScenarioDetailType,
      action,
    };
  }
}
