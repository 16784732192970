import { NgModule, ModuleWithProviders } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { scenarioBinFeatureName } from './scenarioBin-store.actions';
import { scenarioBinStoreReducer } from './scenarioBin-store.reducer';
import { ScenarioBinStore } from './scenarioBin-store.service';
import { ScenarioBinStoreEffects } from './scenarioBin-store.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(scenarioBinFeatureName, scenarioBinStoreReducer),
    EffectsModule.forFeature([ScenarioBinStoreEffects]),
  ],
})
export class ScenarioBinStoreModule {
  static forRoot(): ModuleWithProviders<ScenarioBinStoreModule> {
    return {
      ngModule: ScenarioBinStoreModule,
      providers: [ScenarioBinStore],
    };
  }
}
