import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import {
  NameValue,
  ScenarioDetailQuery,
} from 'prosumer-app/stores/scenario-detail';
import { ScenarioGenericQuery } from 'prosumer-app/stores/scenario-generic';
import { Coerce } from 'prosumer-core/utils';
import { SCENARIO_NAME_DEFAULT_ID } from 'prosumer-scenario/models/general.model';
import { map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { ScenarioVariationInfo } from './scenario-variation.state';
import { ScenarioVariationStore } from './scenario-variation.store';

@Injectable({ providedIn: 'root' })
export class ScenarioVariationQuery extends ScenarioDetailQuery<ScenarioVariationInfo> {
  constructor(
    public store: ScenarioVariationStore,
    private scenarioQuery: ScenarioGenericQuery,
    readonly keeper: ActiveKeeperService,
  ) {
    super(store, keeper);
  }

  scenarioVariations$ = this.scenarioQuery.scenarioId$.pipe(
    switchMap((scenarioId) =>
      this.selectAll({
        filterBy: (entity) => entity.scenarioUuid === scenarioId,
      }),
    ),
  );

  selectActiveVariation() {
    return this.scenarioVariations$.pipe(
      map((variations) => this.mapToNameValue(variations)),
      map((variations) => this.appendScenarioName(variations)),
    );
  }

  mapToNameValue(variations: ScenarioVariationInfo[]) {
    return Coerce.toArray(variations).map((variation) => ({
      name: variation.name,
      value: variation.variationUuid,
    }));
  }

  appendScenarioName(variations: NameValue[]) {
    const scenario = Coerce.toObject(this.scenarioQuery.getActive());
    variations.unshift({
      name: scenario.name,
      value: SCENARIO_NAME_DEFAULT_ID,
    });
    return variations;
  }

  getName(id: string): string {
    return Coerce.toObject(this.getEntity(id)).name;
  }

  getActiveOptionsWithBasecase() {
    return this.appendScenarioName(this.getActiveOptions());
  }
}
